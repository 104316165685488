import React from "react"
import PropTypes from "prop-types"

const ActionsDeleteSimpleIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.526 1c-.423 0-.829.18-1.128.503-.3.322-.468.759-.468 1.214v.396H1.614c-.34 0-.614.296-.614.66 0 .365.275.661.614.661h1.351v8.85c0 .454.168.891.468 1.213.299.322.705.503 1.128.503h6.878c.423 0 .83-.18 1.129-.503.299-.322.467-.758.467-1.214V4.434h1.351c.34 0 .614-.296.614-.66 0-.365-.275-.66-.614-.66H11.07v-.397c0-.455-.168-.892-.467-1.214A1.542 1.542 0 009.473 1H6.527zm-.26 1.437a.356.356 0 01.26-.116h2.948c.098 0 .191.041.26.116.07.074.108.175.108.28v.396H6.158v-.396c0-.105.039-.206.108-.28zM4.193 4.434v8.85c0 .104.039.205.108.28.069.074.163.115.26.115h6.878a.356.356 0 00.26-.116.412.412 0 00.108-.28V4.434H4.193zm2.333 1.849c.34 0 .615.296.615.66v4.227c0 .364-.275.66-.615.66-.339 0-.614-.296-.614-.66V6.943c0-.364.275-.66.614-.66zm3.561.66c0-.364-.275-.66-.614-.66-.34 0-.614.296-.614.66v4.227c0 .364.275.66.614.66.34 0 .614-.296.614-.66V6.943z"
      fill="currentColor"
    />
  </svg>
)

ActionsDeleteSimpleIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsDeleteSimpleIcon svgRef={ref} {...props} />
))
export default ForwardRef
