import React from "react"
import PropTypes from "prop-types"

const ActionsUIToggleSidebarIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.204 1A2.204 2.204 0 001 3.204v9.592C1 14.013 1.987 15 3.204 15H12.796A2.204 2.204 0 0015 12.796V3.204A2.204 2.204 0 0012.796 1H3.204zm6.87 1.296h-6.87a.907.907 0 00-.908.908v9.592c0 .501.407.908.908.908h6.87V2.296zm1.296 11.408V2.296h1.426c.501 0 .908.407.908.908v9.592a.907.907 0 01-.908.908H11.37z"
      fill="currentColor"
    />
  </svg>
)

ActionsUIToggleSidebarIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsUIToggleSidebarIcon svgRef={ref} {...props} />
))
export default ForwardRef
