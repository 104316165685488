import React from "react"
import PropTypes from "prop-types"

const EssentialsPasswordShowIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M8 3.376c2.76-.04 5.508 1.804 7.19 3.598a1.606 1.606 0 010 2.199c-1.648 1.753-4.381 3.64-7.191 3.6-2.81.039-5.543-1.848-7.189-3.601M8 3.376C5.237 3.336 2.49 5.18.81 6.973a1.606 1.606 0 000 2.199M7.99 4.626C5.738 4.589 3.3 6.143 1.722 7.828a.356.356 0 000 .488c1.551 1.653 3.973 3.242 6.267 3.206h.02c2.294.037 4.716-1.552 6.27-3.205a.356.356 0 000-.487c-1.582-1.687-4.019-3.241-6.27-3.204h-.02z" />
      <path d="M6.567 6.641a2.025 2.025 0 112.864 2.864A2.025 2.025 0 016.567 6.64zM8 7.298a.775.775 0 100 1.55.775.775 0 000-1.55z" />
    </g>
  </svg>
)

EssentialsPasswordShowIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsPasswordShowIcon svgRef={ref} {...props} />
))
export default ForwardRef
