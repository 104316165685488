import React, { useState } from "react"
import classNames from "classnames"
import { Link } from "react-router-dom"
import { Popover } from "@headlessui/react"

import Overline from "@sonato/core/components/Overline"
import Thread from "@sonato/member/src/pages/member/messages/Thread"
import withSuspense from "@sonato/core/hooks/withSuspense"
import { ActionsCheckIcon, EssentialsBellIcon } from "@sonato/core/icons"
import { useFormatters } from "@sonato/core/format"
import { useAuthentication } from "@sonato/core/hooks/authentication"
import { useSubscription } from "@sonato/core/socket"
import { locationImageURL } from "@sonato/core/images"

function MemberNotificationBell() {
  const notifications = useNotifications()
  const hasNotifications = !!notifications.find(n => !n.readAt)
  const bgClass = hasNotifications ? "bg-red-900" : "bg-gold-300"
  const textClass = hasNotifications ? "text-white" : "text-gold-500"

  return (
    <Popover className="sm:relative">
      <Popover.Button
        className={classNames(
          "w-8 h-8 rounded-full flex items-center justify-center focus:outline-none",
          bgClass
        )}
      >
        <EssentialsBellIcon className={classNames("w-4 h-4", textClass)} />
      </Popover.Button>

      <Popover.Panel className="absolute z-10 right-0 top-22 sm:top-12">
        <div className="w-screen max-w-sm">
          <div className="w-11/12 sm:w-full mx-auto bg-white border border-gold-300 rounded shadow-md overflow-hidden">
            <div className="w-full max-h-144 text-gray-900 divide-y divide-gold-300 overflow-y-auto overscroll-none rounded">
              {hasNotifications ? (
                <>
                  {notifications.map(n => (
                    <Notification key={n.id} notification={n} />
                  ))}
                </>
              ) : (
                <div className="p-10">
                  <div className="w-10 h-10 rounded-full flex items-center justify-center mx-auto bg-gold-300">
                    <ActionsCheckIcon className="w-5 h-5 text-gold-700" />
                  </div>
                  <h3 className="text-center mt-5">great news</h3>
                  <p className="text-center mt-1">You're all caught up on notifications</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  )
}

function useNotifications() {
  const [notifications, setNotifications] = useState([])
  const { auth } = useAuthentication()
  const path = `member/${auth.identity.membership.memberUserId}/notifications`
  useSubscription(path, ({ notifications }) => setNotifications(notifications))
  return notifications
}

function Notification({ notification }) {
  const { relativeDateTime, longDateTime } = useFormatters()
  const location = notification.location
  const imageUrl = locationImageURL(location, "square", 80)
  const agoText = relativeDateTime(notification.insertedAt)
  if (notification.message) {
    const to = Thread.pathFor(location.subdomain)
    const text = `Message from ${location.name}: ${notification.message.body}`
    return (
      <NotificationCell
        agoText={agoText}
        imageUrl={imageUrl}
        text={text}
        unread={!notification.readAt}
        to={to}
      />
    )
  } else if (notification.visitRequest) {
    const { approvedAt, scheduledAt } = notification.visitRequest
    const to = "/member/visits"
    const text = approvedAt
      ? `Congratulations! Your request to visit ${location.name} on ${longDateTime(
          scheduledAt,
          location.timezone
        )} has been approved.`
      : `Unfortunately your request to visit ${location.name} on ${longDateTime(
          scheduledAt,
          location.timezone
        )} has been declined.`
    return (
      <NotificationCell
        agoText={agoText}
        imageUrl={imageUrl}
        imageAlt={location.name}
        text={text}
        unread={!notification.readAt}
        to={to}
      />
    )
  }
  return null
}

function NotificationCell({ agoText, imageUrl, imageAlt, text, to, unread }) {
  return (
    <Popover.Button
      as={Link}
      className="appearance-none flex space-x-3 p-4 items-center hover:bg-gold-100 focus:bg-gold-100 focus:outline-none"
      to={to}
    >
      <div className="flex-shrink-0">
        <img className="w-10 h-10 rounded-full" src={imageUrl} alt={imageAlt} />
      </div>
      <div className="flex-grow">
        <p className={classNames("text-gray-900 line-clamp-3", unread && "font-bold")}>{text}</p>
        <Overline.Small className="text-gray-300">{agoText}</Overline.Small>
      </div>
      <div className="flex-shrink-0">
        <div className={classNames("w-3 h-3 rounded-full", unread && "bg-green-900")} />
      </div>
    </Popover.Button>
  )
}

export default withSuspense(MemberNotificationBell, null)
