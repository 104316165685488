import React from "react"
import PropTypes from "prop-types"

const EssentialsNominationIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.813 1.438c0-.346.28-.625.625-.625h13.124c.346 0 .626.28.626.625v5.359a8.5 8.5 0 01-7.084 8.382.628.628 0 01-.208 0A8.5 8.5 0 01.813 6.797V1.438zm13.124.625v1.374H2.063V2.063h11.874zm0 2.624H2.063v2.11A7.25 7.25 0 008 13.927a7.25 7.25 0 005.938-7.13v-2.11zm-5.428.77a.971.971 0 01.358.389l.555 1.091h.851a.965.965 0 01.687 1.656l-.832.818.464 1.065a.969.969 0 01-1.376 1.213L8 11.003l-1.216.684a.968.968 0 01-1.376-1.213l.004-.01.46-1.055-.832-.819a.964.964 0 01.688-1.655h.851l.555-1.09a.972.972 0 011.375-.388zm-.028 2.389L8 6.899l-.481.945a.625.625 0 01-.557.341H6.41l.644.634a.625.625 0 01.135.695l-.295.676.8-.449a.625.625 0 01.612 0l.798.45-.294-.675a.625.625 0 01.135-.694l.644-.635h-.552a.625.625 0 01-.557-.341z"
      fill="currentColor"
    />
  </svg>
)

EssentialsNominationIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsNominationIcon svgRef={ref} {...props} />
))
export default ForwardRef
