import React from "react"
import PropTypes from "prop-types"

const ActionsUIColumnsIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M1.429 3.2a1.771 1.771 0 113.543 0v9.6a1.771 1.771 0 01-3.543 0V3.2zM3.2 2.57a.629.629 0 00-.628.629v9.6a.629.629 0 001.257 0V3.2A.629.629 0 003.2 2.57zM6.229 3.2a1.771 1.771 0 113.543 0v9.6a1.771 1.771 0 01-3.543 0V3.2zM8 2.57a.629.629 0 00-.628.629v9.6a.629.629 0 001.257 0V3.2A.629.629 0 008 2.57zM11.029 3.2a1.771 1.771 0 113.543 0v9.6a1.771 1.771 0 01-3.543 0V3.2zM12.8 2.57a.629.629 0 00-.628.629v9.6a.629.629 0 101.257 0V3.2a.629.629 0 00-.629-.629z" />
    </g>
  </svg>
)

ActionsUIColumnsIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsUIColumnsIcon svgRef={ref} {...props} />
))
export default ForwardRef
