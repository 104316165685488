import React, { useState } from "react"
import PropTypes from "prop-types"

import { useTranslation, Trans } from "react-i18next"
import { useParams } from "react-router-dom"
import DefaultBackground from "@sonato/core/assets/home_background.jpg"
import Alert from "@sonato/core/components/Alert"
import { Button } from "@sonato/core/components/Buttons"
import CobrandedLogos from "@sonato/core/components/CobrandedLogos"
import Footer from "@sonato/core/components/Footer"
import { Form, useServerChangesetForm } from "@sonato/core/form"
import { CallToActionLayout, CallToAction } from "@sonato/core/components/Layouts"
import { useApiCall } from "@sonato/core/client"
import SignIn from "@sonato/core/pages/authentication/SignIn"
import { marketingUrl } from "../../urls"

const ForgotPassword = ({ alertMessage, alertStyle = Alert.STYLE.success }) => {
  const { t } = useTranslation()
  const { location: subdomain } = useParams()
  const [showAlert, setShowAlert] = useState(!!alertMessage)
  const [emailAddress, setEmailAddress] = useState(null)
  const [successMessageVisible, setSuccessMessageVisible] = useState(false)

  const title = successMessageVisible ? t("Check Email") : t("Forgot Password?")
  const buttonText = setSuccessMessageVisible
    ? t("Send Password Reset Link")
    : t("Re-send Password Reset Link")
  const [currentLocation, error, isLoading] = useApiCall("public/locations", "current", {
    subdomain,
    includeStub: true
  })
  const form = useServerChangesetForm("public/auth", "createPasswordResetToken", {}, { ajax: true })

  let backgroundImage
  if (isLoading) {
    backgroundImage = ""
  } else if (currentLocation) {
    backgroundImage = currentLocation.images.landscape.uri
  } else if (error && error !== "not_found") {
    window.location = marketingUrl()
  } else {
    backgroundImage = DefaultBackground
  }

  const placeholderStyles = {
    backgroundImage: `url("${backgroundImage}")`,
    backgroundSize: "cover",
    backgroundPosition: "center center"
  }

  const forgotPassword = () => {
    setEmailAddress(form.value("emailAddress"))
    form.submit(() => setSuccessMessageVisible(true))
  }

  const submitOnEnter = e => {
    if (e.key === "Enter") {
      forgotPassword()
    }
  }

  return (
    <>
      {showAlert && (
        <Alert onDismiss={() => setShowAlert(false)} style={alertStyle}>
          {alertMessage}
        </Alert>
      )}
      <CallToActionLayout
        placeholderClasses="bg-corrected-brand-500"
        placeholderStyles={placeholderStyles}
      >
        <CallToAction header={<CobrandedLogos location={currentLocation} />} footer={<Footer />}>
          <h2 className="text-center">{title}</h2>

          {successMessageVisible && (
            <Trans>
              <div className="mt-6">
                <p>
                  If <span className="font-bold">{{ emailAddress }}</span> is a registered email in
                  our system, we have sent instructions to reset your password. Please wait up to 10
                  minutes and check your spam folder before re-requesting.
                </p>
                <p className="mt-2">
                  If you made a mistake, enter your correct email address below:
                </p>
              </div>
            </Trans>
          )}

          <Form value={form}>
            <fieldset className="flex flex-col w-full gap-3 mt-6">
              <Form.GlobalErrors />
              <Form.TextInput
                type="email"
                name="emailAddress"
                placeholder={t("Email Address")}
                large={true}
                onKeyPress={submitOnEnter}
              />
              <Button className="mt-4" tall={true} onClick={() => forgotPassword()}>
                {buttonText}
              </Button>
              <div className="text-center">
                <Trans>
                  Or go to{" "}
                  <a className="text-gold-900" href={SignIn.url(subdomain)}>
                    login
                  </a>
                </Trans>
              </div>
            </fieldset>
          </Form>
        </CallToAction>
      </CallToActionLayout>
    </>
  )
}

ForgotPassword.path = "/:location/forgot-password"
ForgotPassword.globalPath = "/forgot-password"

ForgotPassword.url = subdomain => {
  const path = subdomain
    ? ForgotPassword.path.replace(":location", subdomain)
    : ForgotPassword.globalPath

  return `https://${window.location.hostname}${path}${window.location.search}`
}
ForgotPassword.propTypes = {
  alertMessage: PropTypes.node,
  alertStyle: PropTypes.oneOf(Object.keys(Alert.STYLE))
}
export default ForgotPassword
