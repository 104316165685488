import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import backgroundImage from "@sonato/core/static/images/backgrounds/home.jpg"
import Signup, { SignupFooter } from "./Signup"
import TextInput from "@sonato/core/components/form/TextInput"
import { Button } from "@sonato/core/components/Buttons"
import { CallToAction, CallToActionLayout } from "@sonato/core/components/Layouts"
import { ErrorHeader } from "@sonato/core/form"
import { SonatoOnlyLogo } from "@sonato/core/components/CobrandedLogos"
import { useAjax } from "@sonato/core/hooks/ajax"

/**
 * An intermediate signup page used by the mobile app until it implements the
 * signup flow in-app.
 *
 * Lets the user enter their email address, looks if any pending invites exist
 * for given email address and if so, redirects to the regular sign-up page for
 * the first invite returned.
 */
export default function SignupMobile() {
  const { t } = useTranslation()
  const ajax = useAjax()
  const history = useHistory()

  const [emailAddress, setEmailAddress] = useState(null)
  const [fieldError, setFieldError] = useState(null)
  const [globalError, setGlobalError] = useState(null)

  const onChange = value => {
    setEmailAddress(value.trim())
    setFieldError(null)
  }

  const onKeyPress = event => {
    if (event.key === "Enter") {
      signUp()
    }
  }

  const signUp = () => {
    setGlobalError(null)
    setFieldError(null)

    if (emailAddress === "") {
      setFieldError(t("is required"))
      return
    }

    if (!emailAddress.match(/^[^\s@]+@[^\s@]+$/)) {
      setFieldError(t("not a valid email address"))
      return
    }

    ajax
      .get("public/authentication/pendingMemberInvites", { emailAddress })
      .then(processInvites)
      .catch(() => {
        setGlobalError(t("An unexpected error occured. Please contact support."))
      })
  }

  const processInvites = invites => {
    if (invites.length === 0) {
      setFieldError(t("No invites found for this email address."))
      return
    }

    const subdomain = invites[0].location.subdomain
    const path = Signup.path.replace(":location", subdomain)
    history.push(`${path}?emailAddress=${encodeURIComponent(emailAddress)}`)
  }

  return (
    <CallToActionLayout placeholderStyles={{ backgroundImage: `url(${backgroundImage})` }}>
      <CallToAction footer={<SignupFooter />} header={<SonatoOnlyLogo />}>
        <h2 className="text-center">{t("Sign Up")}</h2>

        {globalError && <ErrorHeader>{globalError}</ErrorHeader>}

        <fieldset className="flex flex-col space-y-3 mt-6">
          <TextInput
            error={fieldError}
            large={true}
            largeFont={true}
            name="emailAddress"
            onChange={onChange}
            onKeyPress={onKeyPress}
            placeholder={t("Email address")}
          />
        </fieldset>

        <fieldset className="w-full mt-6">
          <Button className="w-full" tall={true} onClick={signUp}>
            {t("Sign Up")}
          </Button>
        </fieldset>
      </CallToAction>
    </CallToActionLayout>
  )
}

SignupMobile.path = "/sign-up/mobile"
