import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import * as URLs from "@sonato/core/urls"
import { ajaxReq } from "../client"
import { useAuthentication } from "@sonato/core/hooks/authentication"

export function useAjax() {
  const domain = URLs.apiDomain()
  const {
    auth: { token }
  } = useAuthentication()

  return useMemo(() => {
    const get = (path, query = {}, headers = {}) =>
      ajaxReq(domain, "GET", path, { query, token, headers })
    const post = (path, payload = {}, headers = {}) =>
      ajaxReq(domain, "POST", path, { payload, token, headers })
    return { get, post }
  }, [token, domain])
}

/**
 * Used to make a POST request with multipart/form-data content.
 *
 * This is somewhat duplicated code from ajaxReq, but it's different enough to
 * make it difficult to make it a parameter. In the future we should consider
 * rewriting the ajax code to make it support this use case.
 */
export function useAjaxPostFormData() {
  const { t } = useTranslation()
  const domain = URLs.apiDomain()
  const {
    auth: { token }
  } = useAuthentication()

  return (path, body) => {
    const url = new URL(`https://${domain}${path}`)

    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }

    const fetchParams = { method: "POST", headers, body }

    return new Promise((resolve, reject) => {
      fetch(url, fetchParams)
        .then(raw => {
          raw.json().then(decoded => {
            if (raw.ok) {
              resolve(decoded)
            } else {
              reject(decoded)
            }
          })
        })
        .catch(e => {
          console.error("Upload failed", e)
          reject(t("Upload failed"))
        })
    })
  }
}
