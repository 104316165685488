import React from "react"
import PropTypes from "prop-types"

const ActionsDeleteItemIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.665 2.69a1.5 1.5 0 012.562 1.06c0 .346.28.625.625.625h2.125a.225.225 0 01.225.226v1.275a.625.625 0 101.25 0V4.601a1.475 1.475 0 00-1.475-1.476H9.405a2.75 2.75 0 00-5.357 0H2.475A1.475 1.475 0 001 4.601v8.927a1.475 1.475 0 001.475 1.475h4.251a.625.625 0 100-1.25h-4.25a.225.225 0 01-.226-.225V4.6a.225.225 0 01.225-.226h2.126c.345 0 .625-.28.625-.625 0-.397.158-.78.44-1.06zm-1.49 3.836a.625.625 0 100 1.25h2.976a.625.625 0 100-1.25H4.176zm0 2.126a.625.625 0 000 1.25h1.701a.625.625 0 100-1.25h-1.7zm-.624 2.75c0-.345.28-.625.625-.625h1.7a.625.625 0 110 1.25h-1.7a.625.625 0 01-.625-.625zm7.426-3.2a2.776 2.776 0 100 5.55 2.776 2.776 0 000-5.55zM6.95 10.976a4.026 4.026 0 118.052 0 4.026 4.026 0 01-8.052 0zm2.326-.625a.625.625 0 100 1.25h3.401a.625.625 0 100-1.25h-3.4z"
      fill="currentColor"
    />
  </svg>
)

ActionsDeleteItemIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsDeleteItemIcon svgRef={ref} {...props} />
))
export default ForwardRef
