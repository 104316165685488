import React from "react"
import PropTypes from "prop-types"

const ChevronRightIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M11 8.012a.993.993 0 01-.081.395.957.957 0 01-.23.325L6.237 12.82a.717.717 0 01-1.028-.081.79.79 0 01.054-1.074L9.156 8.09a.104.104 0 00.034-.078.108.108 0 00-.034-.079L5.263 4.36a.765.765 0 01-.26-.531.801.801 0 01.18-.567.741.741 0 01.516-.26.71.71 0 01.538.203l4.45 4.086a.96.96 0 01.23.326c.055.124.083.26.083.396z"
      fill="currentColor"
    />
  </svg>
)

ChevronRightIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ChevronRightIcon svgRef={ref} {...props} />)
export default ForwardRef
