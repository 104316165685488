export const isExtendedNetwork = location => location.kind === "stub"

export const isHotelOnly = location => location.settings.hotelOnly

export const extendedNetworkCopy = location =>
  `${location.name} is part of your club's network, but not yet part of Sonato. This means communication with this location will be handled outside of Sonato.`

export const localityWithPostalCode = location =>
  [location.address.postalCode, location.address.locality].filter(x => !!x).join(", ")

export const streetAddress = location =>
  [location.address.streetAddress, location.address.subPremise].filter(x => !!x).join(", ")
