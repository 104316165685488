import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/**
 * Wraps an input component to provide consistent:
 *
 * - border colors
 * - background colors
 * - error display if error is present
 *
 * Usage:
 *
 * <InputWrapper error={...} disabled={...} display={...}>
 *   <Text Input ... />
 * </InputWrapper>
 */
const InputWrapper = ({ error, disabled, display, onClick, children }) => {
  if (display) {
    return <div className="rounded bg-gold-300">{children}</div>
  }

  const borderClasses = classNames("border rounded", {
    "border-red-900": error,
    "border-gold-500": disabled,
    "border-gold-300 hover:border-gold-900 focus-within:border-gold-900": !disabled && !error
  })

  const errorBgClasses = classNames({
    "bg-red-900": error,
    "bg-opacity-80": error && disabled
  })

  const outerClasses = classNames(borderClasses, errorBgClasses, {
    "cursor-not-allowed": disabled
  })

  const innerClasses = classNames("rounded", disabled ? "bg-gold-100" : "bg-white")

  return (
    <div className={outerClasses} onClick={onClick}>
      <div className={innerClasses}>{children}</div>
      {error && !display && <p className="text-white px-3">{error}</p>}
    </div>
  )
}

InputWrapper.propTypes = {
  error: PropTypes.string,
  disabled: PropTypes.bool,
  display: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node
}

export default InputWrapper
