import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"

import { useAlert } from "@sonato/core/components/Alert"
import Loading from "@sonato/core/components/Loading"
import ExtendedNetworkNote from "@sonato/core/components/ExtendedNetworkNote"
import ResponsivePanel from "@sonato/core/components/ResponsivePanel"
import { Form } from "@sonato/core/form"
import { useFormatters } from "@sonato/core/format"
import { useAuthentication } from "@sonato/core/hooks/authentication"
import { locationShape } from "@sonato/core/shapes"
import { noOp } from "@sonato/core/utils"
import {
  getMinDateTimeForVisit,
  guestCountOptions,
  isDateEnabled,
  validateRequestForm
} from "@sonato/core/utils/visitRequest"
import TextInput from "@sonato/core/components/form/TextInput"
import AvatarImageField from "components/AvatarImageField"
import { useBlackoutDateRanges, useCreateVisitRequestForm } from "../visits/hooks"

const BookRoomPanel = ({ location, visible, onSuccess = noOp, onDismiss = noOp }) => {
  const { t } = useTranslation()
  const { data: blackoutDateRanges } = useBlackoutDateRanges(location.id)
  const { auth, resendAuthentication } = useAuthentication()
  const { form, submit } = useCreateVisitRequestForm(location.id)

  const alert = useAlert()
  const format = useFormatters()
  const topRef = useRef()

  const user = auth.identity.subject
  const showImageUpload = user.avatar.isFallback
  const [hasImage, setHasImage] = useState(!user.avatar.isFallback)

  // Round to next hour to avoid it changing all the time
  const minDateTime = getMinDateTimeForVisit(location)
    .set({ minute: 0, second: 0, millisecond: 0 })
    .plus({ hours: 1 })

  const scheduledAt = minDateTime.plus({ days: 1 })
  const date = scheduledAt.toISODate()
  const time = scheduledAt.toISOTime()
  const hasCellPhone = !!auth.identity.subject.cellPhone

  const hotelStartDate = form.value("hotelStartDate")
  const minStartDate = minDateTime.toISODate()
  const minEndDate = hotelStartDate || minStartDate

  const onImageChange = () => {
    setHasImage(true)
  }

  function onCreateSuccess(visitRequest) {
    const locationName = location.name
    alert.success(
      <Trans>
        <p>
          <b className="mr-1">Success!</b>
          {{ locationName }} has received your visit request! Please note: they will need to approve
          your visit before you go.
        </p>
      </Trans>
    )
    onSuccess(visitRequest)
    resendAuthentication()
  }

  const action = {
    text: "Book Your Visit",
    onClick() {
      submit({
        onSuccess: onCreateSuccess,
        onError() {
          alert.error(t("There was a problem creating your visit request."))
        },
        onValidate(form) {
          const isValid = validateRequestForm(form, hasImage, t, format, scheduledAt, minDateTime)
          if (!isValid && !hasImage) {
            topRef.current.scrollIntoView({ behavior: "smooth" })
          }
          return isValid
        }
      })
    }
  }

  if (!form.isLoaded()) {
    return <Loading />
  }

  return (
    <ResponsivePanel
      title="Book Room"
      action={action}
      onDismiss={onDismiss}
      visible={visible}
      centerContent={false}
    >
      <ExtendedNetworkNote location={location} />
      <p ref={topRef} className="text-gray-500">
        {t("When would you like to go?")}
      </p>
      <fieldset className="grid gap-3 mt-5">
        <Form value={form}>
          <Form.GlobalErrors />
          <TextInput label={t("Club")} value={location.name} display={true} large={true} />
          <Form.HiddenInput name="isHotel" value={true} />
          <Form.HiddenInput name="date" value={date} />
          <Form.HiddenInput name="time" value={time} />
          <div className="grid grid-cols-2 gap-3">
            <Form.DateInput.Dropdown
              name="hotelStartDate"
              label="Check In Date"
              minDate={minStartDate}
              isDateEnabled={d => isDateEnabled(location, blackoutDateRanges, d)}
            />
            <Form.DateInput.Dropdown
              name="hotelEndDate"
              label="Check Out Date"
              dropdownOffset={{ right: 4, top: 44 }}
              minDate={minEndDate}
            />
          </div>
          <Form.Select
            options={guestCountOptions(location)}
            large={true}
            name="guestCount"
            label={t("Guests")}
          />
          {!hasCellPhone && (
            <Form.PhoneNumberInput large={true} name="cellPhone" label={t("Your mobile no.")} />
          )}
          <Form.TextArea
            large={true}
            rows={3}
            name="message"
            label={t("Optional note or request")}
            placeholder={t(
              "Would you like to request a reservation for drinks or dining? Leave a note here..."
            )}
            maxLength={180}
          />
        </Form>
      </fieldset>

      {showImageUpload && <AvatarImageField user={user} onChange={onImageChange} />}
    </ResponsivePanel>
  )
}

BookRoomPanel.propTypes = {
  location: locationShape.isRequired,
  visible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func,
  onSuccess: PropTypes.func
}

export default BookRoomPanel
