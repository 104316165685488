import * as React from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useServerChangesetForm } from "@sonato/core/form"
import { useAjax } from "@sonato/core/hooks/ajax"

const queryKeys = {
  all: ["visits"],
  locationMap: ["visits", "locationMap"],
  pastVisits: ["visits", "past"],
  stats: ["visits", "stats"],
  upcomingVisits: ["visits", "upcoming"],
  blackoutDateRanges: locationId => ["blackoutDateRanges", locationId]
}

// queries

export function useLocationMap() {
  const ajax = useAjax()
  const queryKey = queryKeys.locationMap
  const queryFn = () => ajax.get("/member/locations/map")
  return useQuery(queryKey, queryFn, { suspense: true })
}

export function usePastVisits() {
  const ajax = useAjax()
  const queryKey = queryKeys.pastVisits
  const queryFn = () => ajax.get("/member/visits/past")
  return useQuery(queryKey, queryFn, { suspense: true })
}

export function useUpcomingVisits() {
  const ajax = useAjax()
  const queryKey = queryKeys.upcomingVisits
  const queryFn = () => ajax.get("/member/visits/upcoming")
  return useQuery(queryKey, queryFn, { suspense: true })
}

export function useVisitsStats() {
  const ajax = useAjax()
  const queryKey = queryKeys.stats
  const queryFn = () => ajax.get("/member/visits/stats")
  return useQuery(queryKey, queryFn, { suspense: true })
}

export function useBlackoutDateRanges(locationId) {
  const ajax = useAjax()
  const queryKey = queryKeys.blackoutDateRanges(locationId)
  const queryFn = () => ajax.get("/member/locations/blackoutDateRanges", { locationId })
  return useQuery(queryKey, queryFn, { suspense: true })
}

// mutations

export function useCancelVisitRequestMutation() {
  const ajax = useAjax()
  const queryClient = useQueryClient()
  const mutateFn = visitRequestId => ajax.post("/member/visits/cancel", { visitRequestId })
  const onSuccess = () => queryClient.invalidateQueries(queryKeys.upcomingVisits)
  return useMutation(mutateFn, { onSuccess })
}

export function useCreateVisitRequestForm(locationId) {
  const queryClient = useQueryClient()

  const form = useServerChangesetForm(
    "member/visits",
    "create",
    { destinationLocationId: locationId },
    { ajax: true }
  )

  const submit = React.useCallback(
    ({ onSuccess, onError, onValidate }) =>
      form.submit(
        ({ reply }) => {
          queryClient.invalidateQueries(queryKeys.upcomingVisits)
          form.refresh()
          onSuccess(reply)
        },
        onError,
        onValidate
      ),
    [form, queryClient]
  )
  return { form, submit }
}
