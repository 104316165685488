import React from "react"
import PropTypes from "prop-types"

const EssentialsDiningOutlineIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M4.667 1.5a.5.5 0 01.5.5v12.5a.5.5 0 11-1 0V2a.5.5 0 01.5-.5z" />
      <path d="M3 1.5a.5.5 0 01.5.5v3.75a1.172 1.172 0 001.167 1.167 1.172 1.172 0 001.166-1.168V2a.5.5 0 011 0v3.751a2.172 2.172 0 01-2.165 2.166h-.003A2.172 2.172 0 012.5 5.75V2a.5.5 0 01.5-.5zM9.738 1.574a.5.5 0 01.488-.02c.558.282 1.247 1.019 1.794 2.26.554 1.256.98 3.067.98 5.57 0 .562-.246.962-.583 1.205-.311.224-.67.294-.917.294h-1V14.5a.5.5 0 01-1 0V2a.5.5 0 01.238-.426zm.762 8.31V3.145c.197.268.406.62.605 1.07.488 1.107.895 2.776.895 5.167 0 .237-.088.337-.167.394a.619.619 0 01-.333.106h-1z" />
    </g>
  </svg>
)

EssentialsDiningOutlineIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsDiningOutlineIcon svgRef={ref} {...props} />
))
export default ForwardRef
