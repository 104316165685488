import React from "react"
import PropTypes from "prop-types"

const ArrowUpIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M2.244 8.88a.727.727 0 001.015-.064l3.79-4.232a.128.128 0 01.14-.033.127.127 0 01.083.116v8.626a.7.7 0 00.21.5.725.725 0 001.017 0 .7.7 0 00.21-.5V4.667a.124.124 0 01.083-.116.13.13 0 01.14.033l3.792 4.232a.716.716 0 00.498.257.73.73 0 00.534-.174A.707.707 0 0014 8.4a.695.695 0 00-.196-.518L8.89 2.4a1.196 1.196 0 00-.899-.4 1.215 1.215 0 00-.9.4L2.18 7.883a.703.703 0 00-.178.512.696.696 0 00.243.485z"
      fill="currentColor"
    />
  </svg>
)

ArrowUpIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ArrowUpIcon svgRef={ref} {...props} />)
export default ForwardRef
