import React, { useState } from "react"
import PropTypes from "prop-types"
import TextInput from "./TextInput"
import { noOp } from "@sonato/core/utils"
import { useTranslation } from "react-i18next"

let hexRegex = /^#([a-fA-F0-9]){6}$/

const ColorInput = ({
  name,
  value,
  label,
  error,
  placeholder,
  large,
  display = false,
  disabled = false,
  onChange = noOp
}) => {
  value = value.toLowerCase()
  const { t } = useTranslation()
  const [lastValidColor, setLastValidColor] = useState(hexRegex.test(value) ? value : "#000000")
  const [textInputValue, setTextInputValue] = useState(value)
  const [formatError, setFormatError] = useState(null)

  const handleTextChange = val => {
    val = val.startsWith("#") ? val : `#${val}`
    if (val.length <= 7) {
      setTextInputValue(val)
    }

    if (hexRegex.test(val)) {
      setFormatError(null)
      setLastValidColor(val)
      onChange(val)
    } else if (val.length === 7) {
      setFormatError(t("That's not a correct hex color"))
    }
  }

  const handleColorChange = e => {
    const hexValue = e.target?.value
    setTextInputValue(hexValue)
    onChange(hexValue)
  }

  const colorInput = (
    <input
      type="color"
      className="border-none w-8 h-8 cursor-pointer"
      disabled={disabled}
      name={name}
      value={lastValidColor}
      onChange={handleColorChange}
    />
  )

  const colorSquare = <div className="w-8 h-7" style={{ backgroundColor: value }}></div>

  const frame = display ? colorSquare : colorInput

  return (
    <TextInput
      value={textInputValue}
      onChange={handleTextChange}
      disabled={disabled}
      display={display}
      rightFrame={frame}
      frameClasses="h-8 w-8"
      placeholder={placeholder}
      large={large}
      label={label}
      error={formatError || error}
    />
  )
}

ColorInput.propTypes = {
  /** Name of input. */
  name: PropTypes.string,
  /** Visible label. */
  label: PropTypes.string,
  /** Value (defaults to `label`). */
  value: PropTypes.string,
  /** Invokes onChange handler passing `value`. */
  onChange: PropTypes.func,
  /** Error message. */
  error: PropTypes.string,
  /** Display-only mode, if true. */
  display: PropTypes.bool,
  /** Disabled if true. */
  disabled: PropTypes.bool,
  /** Placeholder text. */
  placeholder: PropTypes.string,
  /** Large if true. */
  large: PropTypes.bool
}

export default ColorInput
