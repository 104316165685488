import React from "react"

const ColorMode = {
  dark: "dark",
  medium: "medium",
  light: "light"
}

export const Context = React.createContext(ColorMode.light)

Object.freeze(ColorMode)

export default ColorMode
