import React from "react"
import PropTypes from "prop-types"

const ArrowRightIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M7.12 2.244a.727.727 0 00.063 1.014l4.232 3.791a.128.128 0 01.034.14.124.124 0 01-.117.082l-8.626.001a.7.7 0 00-.499.21.725.725 0 000 1.017.7.7 0 00.5.21h8.625a.124.124 0 01.117.083.13.13 0 01-.034.14l-4.232 3.792a.718.718 0 00-.256.498.732.732 0 00.174.534.707.707 0 00.498.243.695.695 0 00.518-.197L13.6 8.89a1.195 1.195 0 00.4-.9 1.214 1.214 0 00-.4-.899L8.116 2.18A.703.703 0 007.606 2a.696.696 0 00-.486.243z"
      fill="currentColor"
    />
  </svg>
)

ArrowRightIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ArrowRightIcon svgRef={ref} {...props} />)
export default ForwardRef
