import { groupBy } from "lodash"
import v8n from "v8n"
import { isPossiblePhoneNumber } from "libphonenumber-js"

const NumberValidations = Object.freeze({
  gt: (base, val) => base.greaterThan(val),
  ge: (base, val) => base.greaterThanOrEqual(val),
  lt: (base, val) => base.lessThan(val),
  le: (base, val) => base.lessThanOrEqual(val),
  eq: (base, val) => base.equal(val),
  ne: (base, val) => base.not.equal(val)
})

const namedValidator = (fieldName, validator) => ({ fieldName, validator })

const buildNumberValidator = (defaultFieldName, t, opts) => {
  const toMessage = errors => {
    if (errors.length === 0) {
      return null
    }

    const error = errors[0]
    const [value] = error.rule.args

    switch (error.rule.name) {
      case "greaterThan":
        return t("must be greater than {{ value }}", { value })

      case "greaterThanOrEqual":
        return t("must be greater than or equal to {{ value }}", { value })

      case "lessThan":
        return t("must be less than {{ value }}", { value })

      case "lessThanOrEqual":
        return t("must be less than or equal to {{ value }}", { value })

      case "equal":
        // this is cheesy, but only one of the above validators has a modifier
        if (error.rule.modifiers.length > 0) {
          return t("must not be equal to {{ value }}", { value })
        } else {
          return t("must be equal to {{ value }}", { value })
        }

      default:
        break
    }
  }

  const numberValidations = Object.entries(opts).reduce((acc, [name, value]) => {
    const validator = NumberValidations[name]
    if (validator) {
      return validator(acc, value)
    }
    return acc
  }, v8n())

  return namedValidator(defaultFieldName, (changeset, explicitFieldName) => {
    const fieldName = explicitFieldName || defaultFieldName
    const errors = numberValidations.testAll(changeset.getValue(fieldName))
    if (errors.length === 0) {
      return true
    }

    if (opts.message) {
      changeset.setError(fieldName, opts.message)
    } else {
      changeset.setError(fieldName, toMessage(errors))
    }

    return false
  })
}

const buildExclusionValidator = (defaultFieldName, t, opts) => {
  return namedValidator(defaultFieldName, (changeset, explicitFieldName) => {
    const fieldName = explicitFieldName || defaultFieldName
    if (v8n().not.includes(changeset.getValue(fieldName)).test(opts)) {
      return true
    }
    changeset.setError(fieldName, t("is reserved"))
    return false
  })
}

const buildInclusionValidator = (defaultFieldName, t, opts) => {
  return namedValidator(defaultFieldName, (changeset, explicitFieldName) => {
    const fieldName = explicitFieldName || defaultFieldName
    if (v8n().includes(changeset.getValue(fieldName)).test(opts)) {
      return true
    }
    changeset.setError(fieldName, t("is invalid"))
    return false
  })
}

const buildFormatValidator = (defaultFieldName, t, opts) => {
  const regex = new RegExp(opts)
  const validator = v8n().pattern(regex)
  return namedValidator(defaultFieldName, (changeset, explicitFieldName) => {
    const fieldName = explicitFieldName || defaultFieldName
    if (validator.test(changeset.getValue(fieldName))) {
      return true
    }
    changeset.setError(fieldName, t("has invalid format"))
    return false
  })
}

const LengthValidations = Object.freeze({
  min: (base, val) => base.minLength(val),
  max: (base, val) => base.maxLength(val),
  is: (base, val) => base.length(val)
})

const buildLengthValidator = (defaultFieldName, t, opts) => {
  const lengthValidator = Object.entries(opts).reduce((acc, [name, value]) => {
    const validator = LengthValidations[name]
    if (validator) {
      return validator(acc, value)
    }

    return acc
  }, v8n())

  const toStringMessage = errors => {
    if (errors.length === 0) {
      return null
    }

    if (opts.message) {
      return opts.message
    }

    const error = errors[0]
    const [value] = error.rule.args

    switch (error.rule.name) {
      case "minLength":
        return t("should be at least {{value}} character(s)", { value })

      case "maxLength":
        return t("should be at most {{value}} character(s)", { value })

      case "length":
        return t("should be {{value}} character(s)", { value })

      default:
        console.log("No validator with name", error.rule.name, " for field ", defaultFieldName)
        return null
    }
  }

  const toArrayMessage = errors => {
    if (errors.length === 0) {
      return null
    }

    const error = errors[0]
    const [value] = error.rule.args

    switch (error.rule.name) {
      case "minLength":
        return t("should have at least {{value}} item(s)", { value })

      case "maxLength":
        return t("should have at most {{value}} item(s)", { value })

      case "length":
        return t("should have {{value}} item(s)", { value })

      default:
        console.log("No validator with name", error.rule.name, " for field ", defaultFieldName)
        return null
    }
  }

  return namedValidator(defaultFieldName, (changeset, explicitFieldName) => {
    const fieldName = explicitFieldName || defaultFieldName
    const value = changeset.getValue(fieldName)
    const errors = lengthValidator.testAll(value)
    if (errors.length === 0) {
      return true
    }

    if (typeof value === "string") {
      changeset.setError(fieldName, toStringMessage(errors))
    } else {
      changeset.setError(fieldName, toArrayMessage(errors))
    }

    return false
  })
}

const buildConfirmationValidator = (defaultFieldName, t) => {
  const defaultConfirmationName = `${defaultFieldName}Confirmation`

  const isConfirmationField = fieldName => fieldName.endsWith("Confirmation")
  const computeOtherFieldName = name => {
    const confirmationIndex = name.indexOf("Confirmation")
    if (confirmationIndex > 0) {
      return name.substring(0, confirmationIndex)
    }
    return name + "Confirmation"
  }

  const confirmationValidator = (changeset, explicitFieldName) => {
    const fieldName = explicitFieldName || defaultFieldName
    const confirmationName = computeOtherFieldName(fieldName)
    const [field, fieldConfirmation] = changeset.getValues(fieldName, confirmationName)

    if (field === "" || fieldConfirmation === "" || field === fieldConfirmation) {
      changeset.clearError(fieldName)
      changeset.clearError(confirmationName)
      if (isConfirmationField(fieldName)) {
        changeset.validateField(confirmationName)
      }
      return true
    }

    const error = t("are not the same")
    changeset.setError(fieldName, error)
    changeset.setError(confirmationName, error)
    return false
  }

  return [
    namedValidator(defaultFieldName, confirmationValidator),
    namedValidator(defaultConfirmationName, confirmationValidator)
  ]
}

const buildAcceptanceValidator = (defaultFieldName, t) => {
  return namedValidator(defaultFieldName, (changeset, explicitFieldName) => {
    const fieldName = explicitFieldName || defaultFieldName
    const value = changeset.getValue(fieldName)
    if (value) {
      if (value === true || value.trim() === "true" || value.trim() === "on") {
        return true
      }
    }

    changeset.setError(fieldName, t("must be accepted"))
    return false
  })
}

const empty = v8n().empty()

const buildRequiredFieldValidator = (defaultFieldName, t) => {
  return namedValidator(defaultFieldName, (changeset, explicitFieldName) => {
    const fieldName = explicitFieldName || defaultFieldName
    if (empty.test(changeset.getValue(fieldName))) {
      changeset.setError(fieldName, t("is required"))
      return false
    }

    return true
  })
}

const buildPhoneNumberValidator = (defaultFieldName, t, opts) => {
  return namedValidator(defaultFieldName, (changeset, explicitFieldName) => {
    const fieldName = explicitFieldName || defaultFieldName
    const value = changeset.getValue(fieldName).trim()

    if (value.length > 0 && !isPossiblePhoneNumber(value)) {
      const message = opts.message || t("is not a valid phone number")
      changeset.setError(fieldName, message)
      return false
    }

    return true
  })
}

const buildValidator = (fieldName, t, validatorName, opts) => {
  switch (validatorName) {
    case "acceptance":
      return buildAcceptanceValidator(fieldName, t, opts)

    case "format":
      return buildFormatValidator(fieldName, t, opts)

    case "length":
      return buildLengthValidator(fieldName, t, opts)

    case "confirmation":
      return buildConfirmationValidator(fieldName, t, opts)

    case "inclusion":
      return buildInclusionValidator(fieldName, t, opts)

    case "exclusion":
      return buildExclusionValidator(fieldName, t, opts)

    case "number":
      return buildNumberValidator(fieldName, t, opts)

    case "phone":
      return buildPhoneNumberValidator(fieldName, t, opts)

    default:
      throw new Error(`No ${validatorName} validator defined.`)
  }
}

const buildFieldValidators = (requiredFields, validations, t) => {
  const fieldValidators = Object.entries(validations).flatMap(([fieldName, validators]) =>
    validators.map(([name, opts]) => buildValidator(fieldName, t, name, opts))
  )
  const requiredValidators = requiredFields.map(fieldName =>
    buildRequiredFieldValidator(fieldName, t)
  )

  const validators = groupBy([...requiredValidators, ...fieldValidators].flat(), "fieldName")
  return Object.entries(validators).reduce((namedValidators, [fieldName, fieldValidators]) => {
    const validatorFunctions = fieldValidators.map(({ validator }) => validator)
    const validatorFn = (changeset, explicitFieldName) => {
      return validatorFunctions.reduce(
        (result, validator) => result && validator(changeset, explicitFieldName),
        true
      )
    }
    namedValidators[fieldName] = validatorFn
    return namedValidators
  }, {})
}

const buildFormValidator = (requiredFields, validations, t) => {
  const fieldValidators = buildFieldValidators(requiredFields, validations, t)
  return changeset => {
    changeset.fieldNames().map(fieldName => changeset.validateField(fieldName))
    return Object.values(fieldValidators)
      .map(validator => validator(changeset))
      .every(x => x === true)
  }
}

export {
  buildFieldValidators,
  buildFormValidator,
  buildAcceptanceValidator,
  buildConfirmationValidator,
  buildExclusionValidator,
  buildFormatValidator,
  buildInclusionValidator,
  buildLengthValidator,
  buildNumberValidator
}
