import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ActionsCrossIcon } from "@sonato/core/icons"
import { Transition } from "@headlessui/react"

const STYLE = Object.freeze({
  error: "error",
  info: "info",
  success: "success"
})
const CONTAINER_CLASSES = {
  [STYLE.error]: "bg-red-100 border-red-900",
  [STYLE.info]: "bg-orange-100 border-orange-900",
  [STYLE.success]: "bg-green-100 border-green-900"
}
const LINK_CLASSES = {
  [STYLE.error]: "text-red-900",
  [STYLE.info]: "text-orange-900",
  [STYLE.success]: "text-green-900"
}

const BASE_CLASSES =
  "relative z-50 h-12 pl-2 pr-8 text-black flex justify-center items-center border-b"

const Alert = ({ style, onDismiss, children }) => (
  <div className={classNames(BASE_CLASSES, CONTAINER_CLASSES[style])}>
    <button
      className={classNames(
        "absolute top-0 right-0 p-4 outline-none focus:outline-none",
        LINK_CLASSES[style]
      )}
      onClick={onDismiss}
    >
      <ActionsCrossIcon />
    </button>
    {children}
  </div>
)
Alert.STYLE = STYLE
Alert.LINK_CLASSES = LINK_CLASSES

Alert.propTypes = {
  /** Alert style enum: success, error, or info. */
  style: PropTypes.oneOf(Object.values(Alert.STYLE)).isRequired,
  /** On dismiss handler. */
  onDismiss: PropTypes.func.isRequired,
  /** The message, typically plain text but can be an arbitarily complex element. */
  children: PropTypes.node.isRequired
}

const AlertContext = React.createContext()

const DEFAULT_TIMEOUT = 5000

export const AlertContextProvider = ({ className, children }) => {
  const [isVisible, setVisible] = useState(false)
  const [style, setStyle] = useState(STYLE.success)
  const [content, setContent] = useState("")
  const [timer, setTimer] = useState(null)

  const onDismiss = () => {
    clearTimeout(timer)
    setVisible(false)
  }

  const show = (content, style, timeout = DEFAULT_TIMEOUT) => {
    setStyle(style)
    setContent(content)
    clearTimeout(timer)
    setVisible(true)

    if (timeout) {
      const timer = setTimeout(() => setVisible(false), timeout)
      setTimer(timer)
    }
  }

  const info = (content, timeout = DEFAULT_TIMEOUT) => show(content, STYLE.info, timeout)
  const error = (content, timeout = DEFAULT_TIMEOUT) => show(content, STYLE.error, timeout)
  const success = (content, timeout = DEFAULT_TIMEOUT) => show(content, STYLE.success, timeout)

  const context = {
    show,
    info,
    error,
    success
  }
  return (
    <AlertContext.Provider value={context}>
      <Transition
        show={isVisible}
        className={className}
        enter="transform transition ease-out duration-300 z-50"
        enterFrom="opacity-0 -translate-y-full"
        enterTo="opacity-100 translate-y-0"
        leave="transform transition ease-in duration-300 z-50"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-full"
      >
        <Alert onDismiss={onDismiss} style={style}>
          {content}
        </Alert>
      </Transition>
      {children}
    </AlertContext.Provider>
  )
}

Alert.Context = AlertContextProvider

export const useAlert = () => useContext(AlertContext)

export default Alert
