import React from "react"
import PropTypes from "prop-types"

const EssentialsTimeIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 17"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M8.34 2.068a6.273 6.273 0 100 12.546 6.273 6.273 0 000-12.546zM.713 8.34a7.628 7.628 0 1115.257 0 7.628 7.628 0 01-15.257 0z" />
      <path d="M8.341 3.493c.374 0 .678.303.678.677v3.752l2.405 1.203a.678.678 0 11-.606 1.212l-2.78-1.39a.678.678 0 01-.375-.606V4.17c0-.375.304-.678.678-.678z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" transform="scale(1.04261)" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

EssentialsTimeIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <EssentialsTimeIcon svgRef={ref} {...props} />)
export default ForwardRef
