import React from "react"
import PropTypes from "prop-types"

const ActionsHeartIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.091 3a3.827 3.827 0 00-4.08-.356 3.92 3.92 0 00-.659 6.388l4.8 4.71a1.183 1.183 0 001.687.007l4.768-4.682a3.92 3.92 0 001.39-2.997 3.877 3.877 0 00-2.01-3.426 3.813 3.813 0 00-4.8 1.076L8 3.937l-.164-.186A3.827 3.827 0 007.09 3zm5.283.79a2.513 2.513 0 00-3.15.716l-.027.035-1.193 1.37-1.18-1.34-.03-.041a2.527 2.527 0 00-3.168-.74 2.62 2.62 0 00-.423 4.26l.03.026L8 12.752l4.732-4.646.037-.032a2.619 2.619 0 00.93-2.003v-.01a2.577 2.577 0 00-1.324-2.27z"
      fill="currentColor"
    />
  </svg>
)

ActionsHeartIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ActionsHeartIcon svgRef={ref} {...props} />)
export default ForwardRef
