import { range } from "lodash"
import { DateTime } from "luxon"
import * as dateFns from "date-fns"

export const VISIT_REQUEST_STATUS = {
  Pending: "pending",
  Approved: "approved",
  Canceled: "canceled",
  Declined: "declined"
}

/** Earliest datetime for a visit allowed by location. */
export const getMinDateTimeForVisit = location =>
  DateTime.now()
    .setZone(location.timezone)
    .plus({ hours: location.profile.visitAdvanceNoticeHours })

export const validateRequestForm = (form, image, t, format, scheduledAt, earliestVisitDate) => {
  let isValid = true

  if (!image) {
    isValid = false
    form.setGlobalError(t("Please add a photo"))
  }

  if (scheduledAt < earliestVisitDate) {
    isValid = false
    form.setError(
      "time",
      t("must be after {{ time }} on this day", { time: format.shortTime(earliestVisitDate) })
    )
  }

  return isValid
}

export const canCancel = visit =>
  visit?.status === VISIT_REQUEST_STATUS.Approved || visit?.status === VISIT_REQUEST_STATUS.Pending

export const guestCountOptions = location => {
  if (location) {
    return range(location.profile.guestsAllowed + 1).map(n => ({
      value: n,
      label: n.toString()
    }))
  } else {
    return [{ value: 0, label: "0" }]
  }
}

export const getOpeningHours = (profile, date) => {
  return {
    0: profile.sunday,
    1: profile.monday,
    2: profile.tuesday,
    3: profile.wednesday,
    4: profile.thursday,
    5: profile.friday,
    6: profile.saturday
  }[date.getDay()]
}

export const isDateEnabled = (location, blackoutDateRanges, date) => {
  const isOpen = location && !!getOpeningHours(location.profile, date)
  const inBlackout = blackoutDateRanges.some(
    ({ startDate, endDate }) =>
      date >= dateFns.parseISO(startDate) && date <= dateFns.parseISO(endDate)
  )

  return isOpen && !inBlackout
}
