import React from "react"
import PropTypes from "prop-types"

const ActionsUpdatePersonIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.461 2.222a1.742 1.742 0 100 3.484 1.742 1.742 0 000-3.484zM2.497 3.964a2.964 2.964 0 114.091 2.742c.213.056.421.127.625.213a.611.611 0 01-.48 1.125 3.24 3.24 0 00-4.51 2.98A.611.611 0 111 11.021a4.463 4.463 0 013.336-4.315 2.965 2.965 0 01-1.839-2.743zm9.984 3.564a.667.667 0 01.943 0l.006.006a.667.667 0 010 .943L8.7 13.206l-1.185.237.237-1.186 4.73-4.729zm.472-1.564c-.54 0-1.058.214-1.44.596l-4.876 4.875a.685.685 0 00-.187.35l-.48 2.396a.685.685 0 00.806.806l2.396-.48a.685.685 0 00.35-.187l4.875-4.875a2.036 2.036 0 00.001-2.88l-.006-.005a2.036 2.036 0 00-1.44-.596z"
      fill="#B3956E"
    />
  </svg>
)

ActionsUpdatePersonIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsUpdatePersonIcon svgRef={ref} {...props} />
))
export default ForwardRef
