import React from "react"
import PropTypes from "prop-types"

const ChevronFilledUpIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M10.943 9.391L8.34 6.192a.398.398 0 00-.682 0s-2.565 3.139-2.602 3.2a.398.398 0 00.341.603h5.204a.398.398 0 00.34-.604z"
      fill="currentColor"
    />
  </svg>
)

ChevronFilledUpIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ChevronFilledUpIcon svgRef={ref} {...props} />)
export default ForwardRef
