import { DateTime } from "luxon"
import { parsePhoneNumber, AsYouType } from "libphonenumber-js"
import { useLocale } from "@sonato/core/hooks/i18n"
import { useTranslation } from "react-i18next"

const usdFormatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })

const formatUSD = value => {
  if (!value || isNaN(value)) {
    return value
  }

  // Strip trailing decimals if integer
  return usdFormatter.format(value).replace(/\.00$/, "")
}

const formatPhoneNumber = phoneNumber => {
  try {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber)
    return parsedPhoneNumber.formatNational()
  } catch (_) {
    return phoneNumber
  }
}

const formatPhoneNumberInternational = phoneNumber => {
  try {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber)
    return parsedPhoneNumber.formatInternational()
  } catch (_) {
    return phoneNumber
  }
}

const buildLivePhoneNumberFormatter = locale => {
  const asYouType = new AsYouType(locale)
  return asYouType.input
}

/** Converts given value to a DateTime object without checking validity. */
const coerceToDateTime = value => {
  if (value.isLuxonDateTime) {
    return value
  }

  if (typeof value === "string") {
    return DateTime.fromISO(value)
  }

  if (value instanceof Date) {
    return DateTime.fromJSDate(value)
  }

  throw new Error(
    `Invalid datetime value given of type '${typeof date}'. Expected string, JS Date, or Luxon DateTime.`
  )
}

/**
 * Parses the given value and returns the corresponding DateTime.
 *
 * @param {string|Date|DateTime} value - the input to parse, can be an ISO
 *   formatted string, a Javascript Date object or a Luxon DateTime object
 * @returns {DateTime} the parsed datetime
 * @throws {Error} if input cannot be parsed to a valid datetime
 */
export const parseDateTime = value => {
  const dateTime = coerceToDateTime(value)

  if (dateTime.isValid) {
    return dateTime
  }

  throw new Error(dateTime.invalid.explanation || "Invalid input")
}

const buildDateFormatter = (format, defaultLocale) => {
  return (value, timezone = null, locale = defaultLocale) => {
    try {
      // Luxon uses locales separated by -, while the backend uses _
      const luxonLocale = locale.replace("_", "-")
      const parsed = parseDateTime(value).setLocale(luxonLocale)
      const dateTime = timezone ? parsed.setZone(timezone) : parsed

      // This can be triggered if an invalid timezone or locale is given
      if (!dateTime.isValid) {
        throw new Error(`Invalid DateTime: ${dateTime.invalid.explanation}`)
      }

      return dateTime.toLocaleString(format)
    } catch {
      // Don't break if formatting fails, return the unformatted value instead
      return value
    }
  }
}

const buildFormatRelativeDateTime = (defaultLocale, t) => {
  return (dateTime, locale = defaultLocale) => {
    if (!dateTime) {
      return ""
    }

    const diff = parseDateTime(dateTime).diff(DateTime.now())
    const isPast = diff.as("seconds") < 0

    const seconds = Math.floor(Math.abs(diff.as("seconds")))

    if (seconds < 30) {
      return t("Just now")
    }

    const minutes = Math.floor(Math.abs(diff.as("minutes")))
    if (minutes < 1) {
      return isPast
        ? t("{{ count }} seconds ago", { count: seconds })
        : t("{{ count }} seconds from now", { count: seconds })
    }

    const hours = Math.floor(Math.abs(diff.as("hours")))
    if (hours < 1) {
      return isPast
        ? t("{{ count }} minutes ago", { count: minutes })
        : t("{{ count }} minutes from now", { count: minutes })
    }

    const days = Math.floor(Math.abs(diff.as("days")))
    if (days < 1) {
      return isPast
        ? t("{{ count }} hours ago", { count: hours })
        : t("{{ count }} hours from now", { count: hours })
    }

    const months = Math.floor(Math.abs(diff.as("months")))
    if (months < 1) {
      return isPast
        ? t("{{ count }} days ago", { count: days })
        : t("{{ count }} days from now", { count: days })
    }

    const years = Math.floor(Math.abs(diff.as("years")))
    if (years < 1) {
      return isPast
        ? t("{{ count }} months ago", { count: months })
        : t("{{ count }} months from now", { count: months })
    }

    return isPast
      ? t("{{ count }} years ago", { count: years })
      : t("{{ count }} years from now", { count: years })
  }
}

const formatCoordinates = coordinates => {
  if (!coordinates?.latitude || !coordinates?.longitude) {
    return null
  }

  const longitude = Math.abs(coordinates.longitude)
  const latitude = Math.abs(coordinates.latitude)
  const ew = coordinates.longitude < 0 ? "W" : "E"
  const ns = coordinates.latitude < 0 ? "S" : "N"

  return `${longitude}${ew} ${latitude}${ns}`
}

/**
 * Luxon uses Intl.DateTimeFormat to define formats, see:
 * https://moment.github.io/luxon/docs/manual/formatting.html#tolocalestring--strings-for-humans-
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 */
export const useFormatters = () => {
  const activeLocale = useLocale()
  const { t } = useTranslation()

  const SHORT_DATE_NO_YEAR = { month: "short", day: "numeric" }
  const LONG_DATE_NO_YEAR = { month: "long", day: "numeric" }

  const SHORT_DATETIME_WITH_WEEKDAY = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    weekday: "short"
  }

  const SHORT_DATETIME_WITH_TIMEZONE = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short"
  }

  const LONG_TIME = {
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short"
  }

  return {
    priceUSD: formatUSD,
    phoneNumber: formatPhoneNumber,
    phoneNumberInternational: formatPhoneNumberInternational,
    livePhoneNumber: buildLivePhoneNumberFormatter(activeLocale),
    longDate: buildDateFormatter(DateTime.DATE_MED, activeLocale),
    longDateNoYear: buildDateFormatter(LONG_DATE_NO_YEAR, activeLocale),
    longDateWithWeekday: buildDateFormatter(DateTime.DATE_MED_WITH_WEEKDAY, activeLocale),
    shortDate: buildDateFormatter(DateTime.DATE_MED, activeLocale),
    shortDateNoYear: buildDateFormatter(SHORT_DATE_NO_YEAR, activeLocale),
    shortTime: buildDateFormatter(DateTime.TIME_SIMPLE, activeLocale),
    longTime: buildDateFormatter(LONG_TIME, activeLocale),
    shortDateTime: buildDateFormatter(DateTime.DATETIME_MED, activeLocale),
    shortDateTimeWithTimezone: buildDateFormatter(SHORT_DATETIME_WITH_TIMEZONE, activeLocale),
    shortDateTimeWithWeekday: buildDateFormatter(SHORT_DATETIME_WITH_WEEKDAY, activeLocale),
    longDateTime: buildDateFormatter(DateTime.DATETIME_FULL, activeLocale),
    relativeDateTime: buildFormatRelativeDateTime(activeLocale, t),
    coordinates: formatCoordinates
  }
}
