import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import InputWrapper from "./InputWrapper"
import Label from "./Label"

export const Filters = Object.freeze({
  match: (value, inputValue) => {
    return value.match(inputValue)
  },

  matchIgnoreCase: (value, inputValue) => {
    return value.match(new RegExp(inputValue, "i"))
  },

  startsWith: (value, inputValue) => {
    return value.startsWith(inputValue)
  },
  startsWithIgnoreCase: (value, inputValue) => {
    const normalizedInputValue = inputValue.toUpperCase()
    return value.toUpperCase().startsWith(normalizedInputValue)
  },
  hasSubstring: (value, inputValue) => {
    return value.indexOf(inputValue) > 0
  },
  hasSubstringIgnoreCase: (value, inputValue) => {
    const normalizedValue = value.toUpperCase()
    const normalizedInputValue = inputValue.toUpperCase()
    return Filters.hasSubstring(normalizedValue, normalizedInputValue)
  }
})

const TextInputTypeahead = ({
  disabled,
  display,
  error,
  label,
  large,
  name,
  onChange = () => {},
  value,
  values,
  matcher = Filters.startsWithIgnoreCase,
  placeholder,
  onFocus = () => {},
  onBlur = () => {}
}) => {
  const [filteredValues, setFilteredValues] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const boxClasses = classNames(
    "flex flex-col justify-center px-3 rounded relative",
    large ? "h-16" : "h-11"
  )

  const inputClasses = classNames("flex-grow focus:outline-none", {
    "h-10": !large,
    "font-bold": value,
    "text-gold-500 placeholder-gold-500 cursor-not-allowed": disabled
  })

  const showLabel = large && label

  const handleFilter = inputValue => {
    if (inputValue) {
      setFilteredValues(values.filter(value => matcher(value, inputValue)))
    } else {
      setFilteredValues([])
    }
  }
  const handleChange = event => {
    onChange(event.target.value)
    handleFilter(event.target.value)
  }
  const handleClick = event => {
    onChange(event.target.dataset.value)
    setFilteredValues([])
  }
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      setFilteredValues([])
      setSelectedIndex(0)
    }
  }

  const handleKeyDown = event => {
    switch (event.key) {
      case "ArrowDown":
        setSelectedIndex((selectedIndex + 1) % filteredValues.length)
        break
      case "ArrowUp":
        setSelectedIndex((selectedIndex - 1) % filteredValues.length)
        onChange(filteredValues[selectedIndex])
        break
      case "Enter":
        setFilteredValues([])
        setSelectedIndex(0)
        break
      default:
        break
    }
  }

  return (
    <InputWrapper error={error} display={display} disabled={disabled}>
      <div className={boxClasses}>
        {showLabel && <Label text={label} error={error} />}
        <div className={classNames("flex items-center", { "mt-1": showLabel })}>
          {display && <p className="font-bold">{value}</p>}
          {!display && (
            <input
              autoComplete="off"
              className={inputClasses}
              disabled={disabled}
              name={name}
              onBlur={onBlur}
              onChange={handleChange}
              onFocus={onFocus}
              onKeyDown={handleKeyDown}
              onKeyPress={handleKeyPress}
              style={{ caretColor: "#b3956e" }}
              value={value}
              placeholder={placeholder}
            />
          )}
          <div
            className="absolute inset-x-0 bg-white divide-y divide-gold-300 rounded overflow-hidden shadow-md z-10"
            style={{ top: "100%" }}
          >
            {filteredValues &&
              filteredValues.map((n, idx) => {
                return (
                  <div key={idx} className="flex px-5 py-3 font-bold hover:bg-gold-100">
                    <button data-value={n} onClick={handleClick} className="block w-full text-left">
                      {n}
                    </button>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </InputWrapper>
  )
}

TextInputTypeahead.propTypes = {
  /** Disabled if true */
  disabled: PropTypes.bool,
  /** Display-only mode, if true. */
  display: PropTypes.bool,
  /** Error message. */
  error: PropTypes.string,
  /** Input label. */
  label: PropTypes.string,
  /** Large if true. */
  large: PropTypes.bool,
  /** Name of input field.  */
  name: PropTypes.string,
  /** Value of input. */
  value: PropTypes.string,
  /** Change handler, invoked with new value. */
  onChange: PropTypes.func.isRequired,
  /** Array of possible values to autocomplete on */
  values: PropTypes.array,
  /** Focus callback */
  onFocus: PropTypes.func,
  /** Blur callback */
  onBlur: PropTypes.func,
  /** The function that determines whether or not the input matches one of the options **/
  matcher: PropTypes.func,
  /** Placeholder text **/
  placeholder: PropTypes.string
}

export default TextInputTypeahead
