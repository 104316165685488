import React from "react"
import PropTypes from "prop-types"

const EssentialsBellIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M6.783 13.467a.625.625 0 01.775.424.673.673 0 001.293 0 .625.625 0 111.2.352 1.923 1.923 0 01-3.692 0 .625.625 0 01.424-.776zM8.203.375c.345 0 .625.28.625.625v1.4a.625.625 0 11-1.25 0V1c0-.345.28-.625.625-.625z" />
      <path d="M8.203 3.025a4.042 4.042 0 00-4.042 4.042c0 2.019-.239 3.363-.492 4.221-.03.103-.06.198-.09.287h9.198a6.053 6.053 0 01-.036-.108c-.259-.817-.496-2.169-.496-4.4a4.042 4.042 0 00-4.042-4.042zm0-1.25a5.292 5.292 0 00-5.292 5.292c0 1.922-.227 3.144-.44 3.868a4.43 4.43 0 01-.28.736 1.638 1.638 0 01-.088.154.625.625 0 00.5 1h11.2a.625.625 0 00.426-1.082.632.632 0 01-.043-.062 2.601 2.601 0 01-.254-.592c-.207-.655-.437-1.87-.437-4.022a5.292 5.292 0 00-5.292-5.292z" />
    </g>
  </svg>
)

EssentialsBellIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <EssentialsBellIcon svgRef={ref} {...props} />)
export default ForwardRef
