import { useFormatters } from "@sonato/core/format"
import { useDateFnsLocale } from "@sonato/core/hooks/i18n"
import { locationShape } from "@sonato/core/shapes"
import * as dateFns from "date-fns"
import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

const DAYS_OF_WEEK = [
  ["monday", 1],
  ["tuesday", 2],
  ["wednesday", 3],
  ["thursday", 4],
  ["friday", 5],
  ["saturday", 6],
  ["sunday", 0]
]

/**
 * Given opening hours by weekday, groups them into groups which share the same
 * opening and closing time.
 */
const groupHours = profile => {
  const parse = x => dateFns.parse(x.slice(0, 8), "HH:mm:ss", new Date())

  // Parse times and convert weekday name into index (0-6) as used by Date
  const hoursByDay = DAYS_OF_WEEK.map(([attr, day]) => {
    const hours = profile[attr] // Can be null if the location is closed
    const duration = hours && [parse(hours.start), parse(hours.stop)]
    return { day, duration }
  })

  const isEqual = (duration1, duration2) =>
    (duration1 === null && duration2 === null) ||
    (duration1 &&
      duration2 &&
      duration1[0].getTime() === duration2[0].getTime() &&
      duration1[1].getTime() === duration2[1].getTime())

  // Group weekdays by opening hours duration
  const groups = []
  for (const h of hoursByDay) {
    const lastGroup = groups.length > 0 && groups[groups.length - 1]

    if (lastGroup && isEqual(lastGroup.duration, h.duration)) {
      lastGroup.days.push(h.day)
    } else {
      groups.push({ duration: h.duration, days: [h.day] })
    }
  }

  // Remove groups where duration is null, indicating the location is closed
  return groups.filter(group => group.duration)
}

const OpeningHoursGroup = ({ duration, days, renderItem }) => {
  const { t } = useTranslation()
  const { localize } = useDateFnsLocale()
  const format = useFormatters()
  const open = format.shortTime(duration[0])
  const close = format.shortTime(duration[1])

  const label = days => {
    switch (days.join()) {
      case "1,2,3,4,5,6,0":
        return t("Every Day")
      case "1,2,3,4,5":
        return t("Weekdays")
      case "6,0":
        return t("Weekends")
      default:
        const first = localize.day(days[0])
        const last = localize.day(days[days.length - 1])

        if (days.length === 1) {
          return first
        } else {
          return t("{{ startDay }} - {{ endDay }}", { startDay: first, endDay: last })
        }
    }
  }

  const isMidnight = date => date.getHours() === 0 && date.getMinutes() === 0
  const isAllDay = isMidnight(duration[0]) && isMidnight(duration[1])
  const durationText = isAllDay ? t("24 hours") : `${open} - ${close}`

  if (renderItem) {
    return renderItem(label(days), durationText)
  }

  return (
    <>
      <span className="font-bold">{label(days)}</span>
      {durationText}
    </>
  )
}

OpeningHoursGroup.propTypes = {
  duration: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  days: PropTypes.arrayOf(PropTypes.number).isRequired,
  renderItem: PropTypes.func
}

const OpeningHours = ({ location, renderItem }) => {
  const openingHours = location.profile ? groupHours(location.profile) : []
  return (
    <>
      {openingHours.map((h, key) => (
        <OpeningHoursGroup key={key} duration={h.duration} days={h.days} renderItem={renderItem} />
      ))}
    </>
  )
}

OpeningHours.propTypes = {
  location: locationShape.isRequired,
  renderItem: PropTypes.func
}

export default OpeningHours
