import React, { useEffect, useRef } from "react"
import { useHistory, useLocation } from "react-router-dom"

const useScrollOnNavigate = () => {
  const { pathname } = useLocation()
  const { action } = useHistory()
  const ref = useRef()

  useEffect(() => {
    // no scrolling for back and forward
    if (ref?.current && action !== "POP") {
      ref.current.scrollIntoView({ block: "nearest", inline: "start" })
    }
  }, [action, pathname, ref])

  return ref
}

export const ScrollToAnchor = () => {
  const ref = useScrollOnNavigate()
  return <div ref={ref} style={{ height: 0 }} />
}

export default useScrollOnNavigate
