import React from "react"
import PropTypes from "prop-types"

const AppsMembershipIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M15 14.708v-1.75a.292.292 0 00-.086-.207l-5.56-5.559a4.375 4.375 0 10-2.162 2.163l1.35 1.348-.376 1.128a.583.583 0 00.641.758l1.41-.21.423.423-.386.85a.583.583 0 00.688.803l1.056-.295.754.754a.292.292 0 00.206.085h1.75a.292.292 0 00.292-.291zM4.209 5.083a.875.875 0 110-1.75.875.875 0 010 1.75z"
      fill="currentColor"
    />
  </svg>
)

AppsMembershipIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsMembershipIcon svgRef={ref} {...props} />)
export default ForwardRef
