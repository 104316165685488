import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Footer from "./Footer"

/**
 * For intention, the main content area takes over everything (without a sidebar).
 */
export const MainLayout = ({ children }) => <main className="min-w-main-content">{children}</main>
MainLayout.propTypes = {
  /** The main content area. */
  children: PropTypes.node.isRequired
}

/**
 * This layout contains a large flexible main area on the left, and a fixed-width sidebar on the right. If the page
 * has a header, it should be nested in the main content so that right sidebar is flush to the top-level navigation.
 */
export const SidebarLayout = ({ children, sidebar }) => (
  <div className="flex">
    <main className="flex-grow min-w-main-content">{children}</main>
    {sidebar && (
      <aside className="flex-none w-76 border-l border-gold-100 min-h-screen-minus-topnav">
        {sidebar}
      </aside>
    )}
  </div>
)
SidebarLayout.propTypes = {
  /** The main content area. */
  children: PropTypes.node.isRequired,
  /** The right sidebar. */
  sidebar: PropTypes.node
}

/**
 * This layout adds a full-width hero section that sits on top of the main content and sidebar (i.e. sidebar is not
 * flush to top).
 */
export const HeroSidebarLayout = ({ children, hero, sidebar }) => (
  <>
    <header>{hero}</header>
    <div className="flex">
      <main className="flex-grow min-w-main-content">{children}</main>
      {sidebar && <aside className="flex-none w-76 border-l border-gold-100">{sidebar}</aside>}
    </div>
  </>
)
HeroSidebarLayout.propTypes = {
  /** The main content area. */
  children: PropTypes.node.isRequired,
  /** The hero / header, sits on top of main and sidebar. */
  hero: PropTypes.node.isRequired,
  /** The right sidebar. */
  sidebar: PropTypes.node.isRequired
}

/**
 * A flexible main area on the left (which may contain a fixed column), and a sidebar that is visible only on large
 * screens.
 */
export const CollapsingSidebarLayout = ({ children, sidebar }) => (
  <div className="flex">
    <main className="flex-grow min-w-main-content">{children}</main>
    {sidebar && (
      <aside className="hidden xl:block flex-none w-76 border-l border-gold-100 min-h-screen-minus-topnav">
        {sidebar}
      </aside>
    )}
  </div>
)
CollapsingSidebarLayout.propTypes = {
  /** The main content area. */
  children: PropTypes.node.isRequired,
  /** The right sidebar. */
  sidebar: PropTypes.node.isRequired
}

export const CallToActionLayout = ({
  children,
  placeholderTitle,
  placeholderClasses,
  placeholderStyles
}) => {
  return (
    <div className="flex min-h-screen">
      <main className="flex-shrink-0 sm:max-w-lg bg-white w-full">{children}</main>
      <aside
        title={placeholderTitle}
        className={classNames("w-full bg-cover bg-center", placeholderClasses)}
        style={placeholderStyles}
      />
    </div>
  )
}

export const CallToAction = ({ header, footer, children }) => {
  return (
    <div className="flex flex-col justify-between h-full xs:mx-auto xs:max-w-sm sm:mx-0 sm:max-w-lg">
      <header className="px-6 py-3 sm:px-8 sm:py-5">{header}</header>
      <section className="flex flex-col px-6 sm:px-16">{children}</section>
      {footer || <Footer />}
    </div>
  )
}

CallToActionLayout.propTypes = {
  /** The main content area. */
  children: PropTypes.node.isRequired,
  /** the placeholder on the right's title **/
  placeholderTitle: PropTypes.string,
  /** Classes applied to the placeholder image **/
  placeholderClasses: PropTypes.string,
  /** Styles applied to the placeholder image **/
  placeholderStyles: PropTypes.object
}

/**
 * Visually centers a child box, intended for empty states. The empty vertical space ratio is 1:3 (above:below).
 */
export const EmptyStateSublayout = ({ children }) => (
  <div className="h-full flex flex-col">
    <div style={{ flex: 1 }} />
    <div className="flex-none flex">
      <div className="mx-auto">{children}</div>
    </div>
    <div style={{ flex: 3 }} />
  </div>
)
EmptyStateSublayout.propTypes = {
  children: PropTypes.node.isRequired
}
