import React from "react"
import DatePicker from "../DatePicker"
import InputWrapper from "./InputWrapper"

const DateInput = ({ error, ...props }) => {
  return (
    <InputWrapper error={error}>
      <DatePicker style={DatePicker.STYLE.floating} {...props} />
    </InputWrapper>
  )
}

export default DateInput
