import React from "react"

import TextInput from "./TextInput"
import { isLongTime } from "../../utils/datetime"

const TimeInput = ({ value, ...props }) => {
  // Keep time in HH:MM format so that the input does not render seconds
  const formattedValue = isLongTime(value) ? value.substring(0, 5) : value
  return <TextInput {...props} value={formattedValue} type="time" />
}

export default TimeInput
