const AUTH_TOKEN_KEY = "authenticationToken"
const IDENTITIES_KEY = "identities"

export const getAuthToken = () => localStorage.getItem(AUTH_TOKEN_KEY)
export const setAuthToken = token => localStorage.setItem(AUTH_TOKEN_KEY, token)
export const clearAuthToken = () => localStorage.removeItem(AUTH_TOKEN_KEY)

export const getIdentities = () => {
  const identities = localStorage.getItem(IDENTITIES_KEY)
  return identities ? JSON.parse(identities) : []
}

export const setIdentities = identities =>
  localStorage.setItem(IDENTITIES_KEY, JSON.stringify(identities))

export const clearIdentities = () => localStorage.removeItem(IDENTITIES_KEY)
