import React, { useState } from "react"
import PropTypes from "prop-types"
import { GoogleMap, LoadScript, Marker, OverlayView } from "@react-google-maps/api"

import Image from "@sonato/core/components/Image"
import Loading from "@sonato/core/components/Loading"
import MapPinBlack from "@sonato/core/assets/MapPinBlack.svg"
import MapPinGold from "@sonato/core/assets/MapPinGold.svg"
import Overline from "@sonato/core/components/Overline"
import { addressShape, coordinatesShape, imageShape } from "@sonato/core/shapes"
import { client } from "@sonato/core/client"
import { Link } from "react-router-dom"
import { Trans } from "react-i18next"
import { locationImageURL } from "../images"

export const mapLocationShape = PropTypes.shape({
  address: addressShape.isRequired,
  coordinates: coordinatesShape,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  images: PropTypes.shape({ square: imageShape.isRequired }).isRequired
})

export const mapStyles = (landColor, waterColor) => [
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [{ color: landColor }]
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [{ color: waterColor }]
  },
  {
    featureType: "water",
    elementType: "labels",
    stylers: [{ visibility: "off" }]
  }
]

const position = location => ({
  lat: location.coordinates.latitude,
  lng: location.coordinates.longitude
})

const LocationOverlay = ({ location }) => {
  const imageUrl = locationImageURL(location, "square", 160)

  return (
    <OverlayView position={position(location)} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      <Link to={`/member/clubs/${location.subdomain}`}>
        <div
          className="flex bg-white p-5 rounded"
          style={{ boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)" }}
        >
          <Image src={imageUrl} aspect="1:1" alt={location.name} className="h-20 w-20" />
          <div className="ml-5">
            <h3>{location.name}</h3>
            <Overline.Small className="text-gray-500">{location.address.locality}</Overline.Small>
            <Overline.Small className="text-gold-900 mt-1">
              <Trans>Request a Visit</Trans>
            </Overline.Small>
          </div>
        </div>
      </Link>
    </OverlayView>
  )
}

LocationOverlay.propTypes = {
  location: mapLocationShape.isRequired
}

const LocationMarker = ({
  location,
  overlayVisible,
  isHighlighted,
  onClick = () => {},
  onMouseOver = () => {},
  onMouseOut = () => {}
}) => {
  const icon = isHighlighted ? MapPinBlack : MapPinGold

  return (
    <>
      <Marker
        key={location.id}
        position={position(location)}
        icon={icon}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      />
      {overlayVisible && <LocationOverlay location={location} />}
    </>
  )
}

LocationMarker.propTypes = {
  location: mapLocationShape.isRequired,
  overlayVisible: PropTypes.bool.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onMouseOut: PropTypes.func,
  onMouseOver: PropTypes.func
}

const LocationMap = ({
  center,
  zoom,
  locations,
  mapContainerClassName,
  mapContainerStyle,
  onLoad,
  waterColor = "#F6F6F3",
  landColor = "#D3CDC1",
  highlightedLocationIds = []
}) => {
  // Skip locations which don't have coordinates defined
  const filteredLocations = locations.filter(l => l.coordinates)
  const styles = mapStyles(landColor, waterColor)
  const [visibleOverlayID, setVisibleOverlayID] = useState()
  const [apiKey, setApiKey] = useState()

  React.useEffect(() => {
    client.connectPromise.then(({ setup }) => setApiKey(setup.googleApiKey))
  }, [])

  if (!apiKey) {
    return <Loading />
  }

  return (
    <LoadScript googleMapsApiKey={apiKey}>
      <GoogleMap
        center={center}
        zoom={zoom}
        mapContainerClassName={mapContainerClassName}
        mapContainerStyle={mapContainerStyle}
        onClick={() => setVisibleOverlayID(null)}
        onLoad={onLoad}
        options={{
          styles: styles,
          disableDefaultUI: true
        }}
      >
        {filteredLocations.map(location => (
          <LocationMarker
            key={location.id}
            location={location}
            isHighlighted={highlightedLocationIds.includes(location.id)}
            overlayVisible={visibleOverlayID === location.id}
            onMouseOver={() => setVisibleOverlayID(location.id)}
            onMouseOut={() => setTimeout(() => setVisibleOverlayID(null), 3000)}
            onClick={() => setVisibleOverlayID(location.id)}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  )
}

LocationMap.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }),
  zoom: PropTypes.number,
  locations: PropTypes.arrayOf(mapLocationShape).isRequired,
  mapContainerClassName: PropTypes.string,
  mapContainerStyle: PropTypes.object,
  onLoad: PropTypes.func,
  landColor: PropTypes.string,
  waterColor: PropTypes.string,
  highlightedLocationIds: PropTypes.arrayOf(PropTypes.string)
}

export default LocationMap
