/** A function which does nothing. */
export const noOp = () => {}

/**
 * Returns an array of `n` elements which are generated by calling `fn`
 * repeatedly. The index of the element is given as argument to `fn`.
 */
export const repeat = (n, fn) =>
  Array(n)
    .fill()
    .map((_, i) => fn(i))

/**
 * Sleep this duration.
 *
 * #### Example
 *
 *     await sleep(1000)
 *     // sleeps 1000ms
 *
 * @param {number} ms milliseconds to sleep
 * @returns a Promise that resolves in `ms` milliseconds
 */
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export const pluralize = (singular, plural, count) => (count === 1 ? singular : plural)
