import React from "react"
import PropTypes from "prop-types"

const ActionsUpdateItemIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.722 2.217A1.522 1.522 0 005.201 3.74a.609.609 0 01-.61.609h-2.13a.243.243 0 00-.244.243v8.948a.244.244 0 00.244.244H5.87a.609.609 0 110 1.217H2.46A1.461 1.461 0 011 13.54V4.59a1.46 1.46 0 011.46-1.46h1.592a2.739 2.739 0 015.34 0h1.591a1.46 1.46 0 011.461 1.46V5.87a.609.609 0 11-1.217 0V4.59a.243.243 0 00-.244-.243h-2.13a.609.609 0 01-.609-.609 1.522 1.522 0 00-1.522-1.522zm-3.165 4.93c0-.335.272-.608.608-.608h5.113a.609.609 0 010 1.218H4.165a.609.609 0 01-.608-.61zm9.629 1.036a.593.593 0 00-.42.174L8.563 12.56l-.211 1.054 1.054-.21L13.61 9.2a.594.594 0 000-.838l-.005-.005a.593.593 0 00-.42-.174zm-1.28-.687a1.81 1.81 0 012.56 0l.005.005a1.811 1.811 0 010 2.56l-4.335 4.335a.609.609 0 01-.311.166l-2.13.426a.609.609 0 01-.717-.716l.426-2.13a.609.609 0 01.167-.312l4.335-4.334zm-8.35 1.782c0-.336.273-.608.61-.608h3.408a.609.609 0 010 1.217H4.165a.609.609 0 01-.608-.609zm0 2.13c0-.335.273-.608.61-.608H5.87a.609.609 0 110 1.217H4.165a.609.609 0 01-.608-.608z"
      fill="currentColor"
    />
  </svg>
)

ActionsUpdateItemIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsUpdateItemIcon svgRef={ref} {...props} />
))
export default ForwardRef
