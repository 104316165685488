import React, { useState } from "react"
import * as dateFns from "date-fns"
import classNames from "classnames"
import PropTypes from "prop-types"

import Icon from "@sonato/core/icons/icon"
import Overline from "@sonato/core/components/Overline"
import { ChevronLeftIcon } from "@sonato/core/icons"
import { noOp } from "@sonato/core/utils"
import { times } from "lodash"
import { useDateFnsLocale } from "@sonato/core/hooks/i18n"

// Returns a function which runs the given callback only if triggered by
// pressing Space or Enter.
const ifActivationKey = callback => event => {
  if (event.keyCode === 13 || event.keyCode === 32) {
    event.preventDefault()
    callback()
  }
}

const STYLE = Object.freeze({
  flat: "flat",
  floating: "floating"
})

const MonthChangeArrow = ({ enabled, icon, onClick }) => {
  const buttonClasses = classNames(
    "h-8 w-8 flex items-center justify-center ml-3 text-xl text-gold-900 group focus:outline-none",
    { "opacity-40": !enabled }
  )
  const iconClasses = classNames("h-5 w-5", {
    "transform group-hover:scale-105 group-active:scale-100 duration-300": enabled
  })

  const handleClick = () => {
    if (enabled) {
      onClick()
    }
  }

  return (
    <button
      className={buttonClasses}
      disabled={!enabled}
      onClick={handleClick}
      onKeyDown={ifActivationKey(onClick)}
    >
      <Icon name={icon} className={iconClasses} />
    </button>
  )
}

MonthChangeArrow.propTypes = {
  enabled: PropTypes.bool.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func
}

/** Determine which month to show initially. */
const getInitialDisplayDate = (initialDisplayDate, selectedDate, minDate, maxDate) => {
  if (initialDisplayDate) {
    return initialDisplayDate
  }

  if (selectedDate) {
    return selectedDate
  }

  const today = dateFns.startOfDay(new Date())

  if (minDate && today < minDate) {
    return minDate
  }

  if (maxDate && today > maxDate) {
    return maxDate
  }

  return today
}

const toDate = value => (value ? dateFns.parseISO(value) : null)

const DatePicker = ({
  initialDisplayDate = null,
  value = null,
  minDate = "1900-01-01",
  maxDate = "2100-12-31",
  showBackLink = false,
  style = STYLE.flat,
  onBack = noOp,
  onChange = noOp,
  isDateEnabled = () => true
}) => {
  initialDisplayDate = toDate(initialDisplayDate)
  value = toDate(value)
  minDate = toDate(minDate)
  maxDate = toDate(maxDate)

  const [displayDate, setDisplayDate] = useState(
    getInitialDisplayDate(initialDisplayDate, value, minDate, maxDate)
  )

  const firstDate = dateFns.startOfMonth(displayDate)
  const lastDate = dateFns.endOfMonth(displayDate)

  // Number of cells to skip to align the first day of month to the appropriate
  // column according to its weekday.
  const paddingCount = dateFns.getDay(firstDate)

  // The absolute picker height must be known in advanced to facilitate height
  // transition animation.
  const cellCount = paddingCount + dateFns.getDaysInMonth(displayDate)
  const rowCount = Math.ceil(cellCount / 7)
  const height = 120 + rowCount * 52

  const daysOfWeek = dateFns.eachDayOfInterval({
    start: dateFns.startOfWeek(displayDate),
    end: dateFns.endOfWeek(displayDate)
  })

  const daysOfMonth = dateFns.eachDayOfInterval({
    start: firstDate,
    end: lastDate
  })

  // Format date with selected locale
  const locale = useDateFnsLocale()
  const lformat = (date, format) => dateFns.format(date, format, { locale: locale })

  const renderDate = date => {
    const isSelected = value && dateFns.isSameDay(date, value)
    const isValidMin = minDate ? date >= minDate : true
    const isValidMax = maxDate ? date <= maxDate : true
    const isEnabled = isValidMin && isValidMax && isDateEnabled(date)

    const classes = classNames(
      "h-10 w-10 font-bold flex items-center justify-center rounded-full focus:outline-none",
      {
        "bg-gold-900 text-white": isSelected,
        "text-gold-500 cursor-default": !isEnabled,
        "hover:bg-gold-300 focus:bg-gold-300": isEnabled && !isSelected,
        "transform hover:scale-105 active:scale-100 duration-300": isEnabled
      }
    )

    const handleClick = () => onChange(dateFns.format(date, "yyyy-MM-dd"))

    return (
      <button
        className={classes}
        key={dateFns.getDate(date)}
        onClick={handleClick}
        onKeyDown={ifActivationKey(handleClick)}
        disabled={!isEnabled}
      >
        {lformat(date, "d")}
      </button>
    )
  }

  const prevMonthEnabled = minDate < dateFns.startOfMonth(displayDate)
  const nextMonthEnabled = maxDate > dateFns.endOfMonth(displayDate)

  const handleNextMonth = () => {
    setDisplayDate(dateFns.addMonths(displayDate, 1))
  }

  const handlePrevMonth = () => {
    setDisplayDate(dateFns.subMonths(displayDate, 1))
  }

  const boxShadow = style === STYLE.floating ? "0px 2px 8px rgba(0, 0, 0, 0.1)" : "none"
  const classes = classNames(
    "px-5 pt-3 rounded select-none transition-all duration-300 overflow-y-hidden",
    {
      "bg-gold-100": style === STYLE.flat
    }
  )

  return (
    <div className={classes} style={{ height, boxShadow }}>
      <div className="flex items-center">
        {showBackLink && (
          <button
            className="mr-3 focus:outline-none"
            onClick={onBack}
            onKeyDown={ifActivationKey(onBack)}
          >
            <ChevronLeftIcon />
          </button>
        )}

        <div className="flex-grow text-left font-bold">{lformat(displayDate, "LLL, yyyy")}</div>

        <MonthChangeArrow
          enabled={prevMonthEnabled}
          onClick={handlePrevMonth}
          icon="ArrowLeftIcon"
        />

        <MonthChangeArrow
          enabled={nextMonthEnabled}
          onClick={handleNextMonth}
          icon="ArrowRightIcon"
        />
      </div>

      <div className="grid grid-cols-7 gap-x-1 gap-y-3 justify-items-center mt-5 -mx-3">
        {daysOfWeek.map((d, key) => (
          <Overline.Small key={key} className="h-10 text-gray-500 flex items-center justify-center">
            {lformat(d, "EEEEEE")}
          </Overline.Small>
        ))}

        {times(paddingCount, key => (
          <div key={key}></div>
        ))}

        {daysOfMonth.map(renderDate)}
      </div>
    </div>
  )
}

DatePicker.STYLE = STYLE

export const dateStringProp = (props, propName, componentName) => {
  const value = props[propName]
  if (value && !/\d{4}-\d{2}-\d{2}/.test(value)) {
    const strValue = JSON.stringify(value)
    return new Error(`Invalid date value ${strValue} supplied to \`${componentName}.${propName}\`.`)
  }
}

DatePicker.propTypes = {
  /**
   * Date which defines the year/month displayed by the datepicker.
   * Any date within the desired month can be given.
   */
  initialDisplayDate: PropTypes.instanceOf(Date),
  /** Currently selected date, as an ISO formatted date. */
  value: dateStringProp,
  /** Minimal allowed date, dates lower than this one will not be selectable */
  minDate: dateStringProp,
  /** Maximal allowed date, dates higher than this one will not be selectable */
  maxDate: dateStringProp,
  /** Whether to show the back link in the top left corner of the picker */
  showBackLink: PropTypes.bool,
  /** Date picker style (flat or floating) */
  style: PropTypes.oneOf(Object.values(STYLE)),
  /** Called when a date is selected. */
  onChange: PropTypes.func,
  /** Called when left arrow is activated. */
  onPrevMonth: PropTypes.func,
  /** Called when right arrow is activated. */
  onNextMonth: PropTypes.func,
  /** Called when back button is activated. */
  onBack: PropTypes.func,
  /** Optional function which takes a date, and returns if the date should be enabled or not. */
  isDateEnabled: PropTypes.func
}

export default DatePicker
