import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { KEY_CODES } from "@sonato/core/enums"

const BaseSwitch = ({ checked, disabled }) => {
  const handleClasses = classNames(
    "absolute w-6 h-6 rounded-full transition-all duration-300",
    checked ? "bg-gold-900" : "bg-gold-500",
    !disabled && "transform group-hover:scale-105 group-active:scale-100"
  )
  const trackClasses = classNames(
    "absolute w-10 h-3 rounded transition-all duration-300",
    checked ? "bg-gold-500" : "bg-gold-100"
  )
  return (
    <div className="relative w-10 h-6">
      <div className={trackClasses} style={{ top: 6 }} />
      <div className={handleClasses} style={{ left: checked ? 16 : 0 }} />
    </div>
  )
}

const Switch = ({
  name,
  label,
  value = label,
  checked = false,
  disabled = false,
  onChange,
  ...rest
}) => {
  function handleChange() {
    if (onChange) {
      onChange(!checked, value)
    }
  }
  function handleKeyUp(e) {
    if (!disabled && e.keyCode === KEY_CODES.space) {
      handleChange()
    }
  }

  const labelClasses = classNames(
    "group flex items-center font-bold text-gray-900 outline-none",
    disabled ? "opacity-40 cursor-not-allowed" : "cursor-pointer"
  )
  return (
    <label tabIndex="0" className={labelClasses} onKeyUp={handleKeyUp}>
      <input
        type="checkbox"
        className="hidden"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
      <BaseSwitch checked={checked} disabled={disabled} {...rest} />
      {label && <p className="ml-3">{label}</p>}
    </label>
  )
}

Switch.propTypes = {
  /** Name of input. */
  name: PropTypes.string,
  /** Visible label. */
  label: PropTypes.string,
  /** Value (defaults to `label`). */
  value: PropTypes.string,
  /** Checked state. Defaults to false. */
  checked: PropTypes.bool,
  /** Disabled state. Defaults to false. */
  disabled: PropTypes.bool,
  /** Invokes onChange handler passing new `checked` state and `value`. */
  onChange: PropTypes.func
}

export default Switch
