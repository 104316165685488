import React from "react"
import PropTypes from "prop-types"

const AppsFinanceIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="currentColor">
      <path d="M7 16a7.008 7.008 0 007-7 .333.333 0 00-.333-.333h-6a.333.333 0 01-.334-.334v-6A.333.333 0 007 2a7 7 0 000 14z" />
      <path d="M9 0a.333.333 0 00-.334.333v6.334a.667.667 0 00.667.666h6.333A.333.333 0 0016 7a7.008 7.008 0 00-7-7z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

AppsFinanceIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsFinanceIcon svgRef={ref} {...props} />)
export default ForwardRef
