import React from "react"
import PropTypes from "prop-types"

const ChevronFilledDownIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M5.057 6.604l2.602 3.2a.398.398 0 00.682 0s2.565-3.14 2.602-3.2A.398.398 0 0010.602 6H5.398a.398.398 0 00-.34.604z"
      fill="currentColor"
    />
  </svg>
)

ChevronFilledDownIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ChevronFilledDownIcon svgRef={ref} {...props} />
))
export default ForwardRef
