import NotFound from "@sonato/core/components/NotFound"
import React from "react"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import ClubDetailPage from "./ClubDetail"
import ClubListPage from "./ClubList"

const Clubs = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <ClubListPage />
      </Route>
      <Route exact path={`${path}/:subdomain`}>
        <ClubDetailPage />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  )
}

export default Clubs
