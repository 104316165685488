import React from "react"
import PropTypes from "prop-types"

const ActionsAddItemIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.646 2.663A1.522 1.522 0 018.243 3.74c0 .336.273.609.61.609h2.13a.244.244 0 01.243.243V5.87a.609.609 0 101.217 0V4.59a1.46 1.46 0 00-1.46-1.46h-1.59a2.74 2.74 0 00-5.342 0h-1.59A1.46 1.46 0 001 4.59v8.948A1.46 1.46 0 002.46 15h4.262a.609.609 0 100-1.217H2.46a.243.243 0 01-.244-.244V4.591a.243.243 0 01.244-.243h2.13a.609.609 0 00.609-.609c0-.403.16-.79.446-1.076zM4.166 6.54a.609.609 0 100 1.218h2.982a.609.609 0 000-1.218H4.165zm0 2.13a.609.609 0 100 1.218H5.87a.609.609 0 000-1.217H4.165zm-.61 2.74c0-.337.273-.609.61-.609H5.87a.609.609 0 110 1.217H4.165a.609.609 0 01-.608-.608zm3.166-8.705a.822.822 0 100 1.644.822.822 0 000-1.644zm1.46 8.279a2.8 2.8 0 115.6 0 2.8 2.8 0 01-5.6 0zm2.8-4.018a4.017 4.017 0 100 8.035 4.017 4.017 0 000-8.035zm0 1.705c.337 0 .61.272.61.608v1.096h1.095a.609.609 0 010 1.217h-1.096v1.096a.609.609 0 01-1.217 0v-1.096H9.278a.609.609 0 110-1.217h1.096V9.278c0-.336.272-.608.609-.608z"
      fill="currentColor"
    />
  </svg>
)

ActionsAddItemIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ActionsAddItemIcon svgRef={ref} {...props} />)
export default ForwardRef
