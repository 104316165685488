import React from "react"
import PropTypes from "prop-types"

const EssentialsContactMessageIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M1.635 7.45a4.604 4.604 0 001.844 3.573c.242.181.32.51.185.78l-.76 1.52 2.37-1.126a.625.625 0 01.453-.033 7.768 7.768 0 002.265.344c3.644 0 6.364-2.393 6.364-5.09 0-2.691-2.707-5.05-6.356-5.05-3.641 0-6.36 2.389-6.365 5.082zm-1.25.007c0-3.609 3.526-6.34 7.615-6.34 4.084 0 7.606 2.69 7.606 6.302 0 3.609-3.527 6.34-7.615 6.34h-.002a9.02 9.02 0 01-2.393-.33L1.802 15.23a.625.625 0 01-.827-.844l1.348-2.697A5.854 5.854 0 01.385 7.47v-.014z" />
      <path d="M5.542 7.767a.623.623 0 00-.58-.859.858.858 0 000 1.717.625.625 0 00.58-.858z" />
      <path d="M4.337 7.533c0-.345.28-.625.625-.625a.858.858 0 110 1.717.625.625 0 01-.58-.858.623.623 0 01-.045-.234zM8.809 7.767a.623.623 0 00-.58-.859.858.858 0 000 1.717.625.625 0 00.58-.858z" />
      <path d="M7.604 7.533c0-.345.28-.625.625-.625a.858.858 0 010 1.717.625.625 0 01-.58-.858.623.623 0 01-.045-.234zm.625.234zM12.066 7.767a.624.624 0 00-.58-.859.858.858 0 100 1.717.625.625 0 00.58-.858z" />
      <path d="M10.86 7.533c0-.345.28-.625.626-.625a.858.858 0 110 1.717.625.625 0 01-.58-.858.622.622 0 01-.045-.234zm.626.234z" />
    </g>
  </svg>
)

EssentialsContactMessageIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsContactMessageIcon svgRef={ref} {...props} />
))
export default ForwardRef
