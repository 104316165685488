import { useRef } from "react"

let counter = 1

/**
 * Provides a unique ID string which can be used on an <input> and does not
 * change on each redraw.
 *
 * @param {string} prefix - Optional ID prefix.
 */
const useUniqueId = (prefix = "component-") => {
  const ref = useRef()

  if (!ref.current) {
    ref.current = `${prefix}${counter}`
    counter += 1
  }

  return ref.current
}

export default useUniqueId
