import React from "react"
import PropTypes from "prop-types"

const Copy = ({ text, className }) => {
  const paragraphs = text.split("\n\n").filter(p => p.trim())

  return (
    <div>
      {paragraphs.map((p, key) => (
        <p key={key} className={`mt-2 ${className}`}>
          {p}
        </p>
      ))}
    </div>
  )
}

Copy.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default Copy
