import NotFound from "@sonato/core/components/NotFound"
import React from "react"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import HotelList from "./HotelList"
import ClubDetail from "../clubs/ClubDetail"

const Clubs = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <HotelList />
      </Route>
      <Route exact path={`${path}/:subdomain`}>
        <ClubDetail />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  )
}

export default Clubs
