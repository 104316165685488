/**
 * Spinkit css loading spinner
 * repository: https://github.com/tobiasahlin/SpinKit
 * demo: https://tobiasahlin.com/spinkit/
 */

import React from "react"
import "./loader.css"
import classNames from "classnames"

const Loader = ({ isFixed = false }) => {
  return (
    <div
      className={classNames(
        "bg-grey-900 fixed w-full h-full flex justify-center items-center z-50",
        {
          fixed: isFixed
        }
      )}
    >
      <div className="sk-fading-circle">
        <div className="sk-circle1 sk-circle"></div>
        <div className="sk-circle2 sk-circle"></div>
        <div className="sk-circle3 sk-circle"></div>
        <div className="sk-circle4 sk-circle"></div>
        <div className="sk-circle5 sk-circle"></div>
        <div className="sk-circle6 sk-circle"></div>
        <div className="sk-circle7 sk-circle"></div>
        <div className="sk-circle8 sk-circle"></div>
        <div className="sk-circle9 sk-circle"></div>
        <div className="sk-circle10 sk-circle"></div>
        <div className="sk-circle11 sk-circle"></div>
        <div className="sk-circle12 sk-circle"></div>
      </div>
    </div>
  )
}

export default Loader
