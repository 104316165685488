import React from "react"
import PropTypes from "prop-types"

const ActionsStarFilledIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M8.453 2.277l2.082 4.125 4.007.397a.501.501 0 01.311.855l-3.298 3.269 1.223 4.441a.506.506 0 01-.716.582l-4.063-2.012-4.057 2.01a.506.506 0 01-.716-.582l1.223-4.442-3.3-3.268a.5.5 0 01.31-.855L5.467 6.4l2.079-4.123a.51.51 0 01.907 0z"
      fill="currentColor"
    />
  </svg>
)

ActionsStarFilledIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsStarFilledIcon svgRef={ref} {...props} />
))
export default ForwardRef
