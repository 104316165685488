import React from "react"
import PropTypes from "prop-types"

import Image from "@sonato/core/components/Image"
import Overline from "@sonato/core/components/Overline"
import ResponsivePanel from "@sonato/core/components/ResponsivePanel"
import { locationImageURL } from "@sonato/core/images"
import { useAlert } from "@sonato/core/components/Alert"
import { useCancelVisitRequestMutation } from "./hooks"
import { useFormatters } from "@sonato/core/format"
import { visitRequestShape } from "@sonato/core/shapes"

const CancelVisitPanel = ({ visit, visible, onDismiss }) => {
  const format = useFormatters()
  const alert = useAlert()
  const { mutate: cancelVisitRequest, isLoading } = useCancelVisitRequestMutation()
  const location = visit.destinationLocation
  const isHotelOnly = visit.isHotel && location.settings.hotelOnly
  const name = isHotelOnly ? "Booking" : "Visit Request"

  const onSuccess = () => {
    alert.success(`Your reservation with ${location.name} has been cancelled.`)
    onDismiss()
  }

  const onError = () => {
    alert.error("Failed cancelling reservation.")
  }

  const submit = () => cancelVisitRequest(visit.id, { onSuccess, onError })
  const imageUrl = locationImageURL(location, "landscape", 600)
  const action = { text: `Yes, Cancel ${name}`, onClick: submit, disabled: isLoading }
  const alt = `Image of ${location.name}`

  return (
    <ResponsivePanel
      title={`Cancel ${name}?`}
      action={action}
      onDismiss={onDismiss}
      visible={visible}
    >
      <div className="p-6 text-center">
        <Image src={imageUrl} aspect="3:2" alt={alt} />
        <h3 className="mt-5">{location.name}</h3>
        <Overline.Small className="text-gray-500">{location.address.locality}</Overline.Small>

        {isHotelOnly ? (
          <>
            <Overline.Small className="text-gray-500">
              Check in: {format.shortDate(visit.hotelStartDate)}
            </Overline.Small>
            <Overline.Small className="text-gray-500">
              Check out: {format.shortDate(visit.hotelEndDate)}
            </Overline.Small>
          </>
        ) : (
          <Overline.Small className="text-gray-500 mt-1">
            {format.longDateTime(visit.scheduledAt, location.timezone)}
          </Overline.Small>
        )}
      </div>
    </ResponsivePanel>
  )
}

CancelVisitPanel.propTypes = {
  visit: visitRequestShape.isRequired,
  visible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired
}

export default CancelVisitPanel
