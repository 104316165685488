import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from "react-router-dom"
import Icon from "@sonato/core/icons/icon"

// For text buttons
const textPropTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  to: PropTypes.string,
  icon: PropTypes.string
}

// For icon buttons
const iconPropTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  to: PropTypes.string
}

const sharedClasses = (disabled = false, secondary = false, dark = false) => [
  "inline-block",
  {
    "cursor-not-allowed": disabled,

    // Colors
    "bg-gold-900 text-white": !disabled && !secondary && !dark,
    "bg-black text-white": !disabled && !secondary && dark,
    "border border-gold-900 text-gold-900": !disabled && secondary && !dark,
    "border border-black text-black": !disabled && secondary && dark,
    "bg-gold-300 text-gold-500": disabled && !secondary && !dark,
    "bg-gray-900 text-gray-500/20": disabled && !secondary && dark,
    "border border-gold-500 text-gold-500": disabled && secondary && !dark,
    "border border-gray-300 text-gray-300": disabled && secondary && dark
  }
]

const transformClasses = (disabled, extraScale) =>
  classNames(
    { transform: !disabled },
    "duration-300",
    "ease-in-out",
    extraScale ? "hover:scale-125" : "hover:scale-103",
    "active:scale-95"
  )

const render = (to, onClick, classes, disabled, children, extraScale = false, type = null) => {
  const innerClasses = "h-full w-full flex items-center justify-center"
  const outerClasses = classNames(
    "overflow-hidden inline-block focus:outline-none",
    transformClasses(disabled, extraScale),
    classes
  )

  // Default button type to "button" so it won't submit forms by deafult
  type = type || "button"

  return to ? (
    <Link to={to} tabIndex={disabled ? "-1" : "0"} className={outerClasses} disabled={disabled}>
      <div className={innerClasses}>{children}</div>
    </Link>
  ) : (
    <button onClick={onClick} className={outerClasses} disabled={disabled} type={type}>
      <div className={innerClasses}>{children}</div>
    </button>
  )
}

/** Same as render, but only button content is scaled, not the button iteslf. */
const renderScaleInner = (to, onClick, classes, disabled, children, extraScale = false) => {
  const innerClasses = classNames(
    "h-full w-full flex items-center justify-center",
    transformClasses(disabled, extraScale)
  )
  const outerClasses = classNames(classes, "overflow-hidden inline-block focus:outline-none")

  return to ? (
    <Link to={to} tabIndex={disabled ? "-1" : "0"} className={outerClasses} disabled={disabled}>
      <div className={innerClasses}>{children}</div>
    </Link>
  ) : (
    <button onClick={onClick} className={outerClasses} disabled={disabled}>
      <div className={innerClasses}>{children}</div>
    </button>
  )
}

export const Button = ({
  disabled,
  secondary,
  dark,
  className,
  to,
  onClick,
  children,
  type,
  tall = false,
  icon = null
}) => {
  const heightClasses = tall ? "h-12" : "h-8"
  const buttonClasses = "font-bold rounded leading-8 px-4"
  const classes = classNames(
    ...sharedClasses(disabled, secondary, dark),
    buttonClasses,
    heightClasses,
    className
  )

  return render(
    to,
    onClick,
    classes,
    disabled,
    <span className="w-full flex items-center justify-center">
      {icon && <Icon name={icon} className="h-4 w-4 mr-2" />}
      {children}
    </span>,
    false,
    type
  )
}

Button.propTypes = textPropTypes

export const IconButton = ({ icon, disabled, secondary, dark, className, to, onClick }) => {
  const iconClasses = "h-6 w-6 font-bold rounded-full"
  const classes = classNames(...sharedClasses(disabled, secondary, dark), iconClasses, className)
  const children = <Icon name={icon} className="h-4 w-4" />

  return render(to, onClick, classes, disabled, children, true)
}

IconButton.propTypes = iconPropTypes

export const LargeIconButton = ({ icon, disabled, secondary, className, to, onClick }) => {
  const classes = classNames(
    "inline-block h-18 w-18 rounded border focus:outline-none",
    disabled ? "text-gold-500 cursor-not-allowed" : "text-gold-900",
    className,
    {
      "bg-gold-100 border-gold-300": !secondary,
      "focus:border-gold-900": !secondary && !disabled,
      "border-gold-900 focus:bg-gold-100": secondary && !disabled,
      "border-gold-500": secondary && disabled
    }
  )

  const children = <Icon name={icon} className="h-8 w-8" />

  return render(to, onClick, classes, disabled, children, true)
}

LargeIconButton.propTypes = iconPropTypes

export const StretchedButton = ({
  disabled,
  secondary,
  dark,
  className,
  to,
  onClick,
  tall,
  children
}) => {
  const heightClasses = tall ? "h-12" : "h-8"
  const stretchedClasses = "font-bold rounded h-8 leading-8 text-center px-4 min-w-full"
  const classes = classNames(
    ...sharedClasses(disabled, secondary, dark),
    stretchedClasses,
    heightClasses,
    className
  )

  return render(to, onClick, classes, disabled, children)
}

StretchedButton.propTypes = textPropTypes

export const PanelWidgetButton = ({
  disabled,
  secondary,
  dark,
  className,
  to,
  onClick,
  children
}) => {
  const panelClasses = "font-bold h-12 leading-12 text-center min-w-full"
  const disabledStyle = "bg-gold-900 text-white/40"
  const classes = classNames(...sharedClasses(disabled, secondary, dark), panelClasses, className, {
    [disabledStyle]: disabled
  })

  return renderScaleInner(to, onClick, classes, disabled, children)
}

PanelWidgetButton.propTypes = textPropTypes

export const TextButton = ({ children, onClick, className }) => {
  return (
    <button
      className={classNames(
        "text-gold-900",
        "transform duration-300 ease-in-out hover:text-gold-500",
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

TextButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
}
