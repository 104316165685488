import React from "react"
import PropTypes from "prop-types"

const ActionsUIPopoutIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="#B3956E">
      <path d="M10.857 2.674c0-.372.301-.674.674-.674h3.795a.672.672 0 01.67.602.146.146 0 01.004.034V6.47a.674.674 0 11-1.348 0V4.3l-7.284 7.284a.674.674 0 01-.953-.953l7.284-7.284H11.53a.674.674 0 01-.675-.674zm.674-.38a.38.38 0 000 .76h2.522a.147.147 0 01.104.251L6.623 10.84a.38.38 0 10.537.538l7.535-7.535a.147.147 0 01.25.104V6.47a.38.38 0 00.761 0V2.657a.378.378 0 00-.38-.363h-3.795zm-8.014 2.53a1.224 1.224 0 00-1.223 1.224v8.434a1.224 1.224 0 001.223 1.224h8.435a1.224 1.224 0 001.224-1.223V9.421a.38.38 0 10-.76 0v5.06a.463.463 0 01-.464.464H3.517a.463.463 0 01-.463-.463V6.047a.463.463 0 01.463-.463h5.061a.38.38 0 100-.761h-5.06zm-1.073.15c.285-.284.671-.444 1.073-.444h5.061a.674.674 0 010 1.348h-5.06a.17.17 0 00-.17.17v8.434a.17.17 0 00.17.17h8.434a.17.17 0 00.17-.17v-5.06a.674.674 0 011.348 0v5.06A1.517 1.517 0 0111.952 16H3.517A1.517 1.517 0 012 14.482V6.049c0-.403.16-.789.444-1.073z" />
      <path d="M11.003 2.674c0-.291.236-.527.527-.527h3.796a.526.526 0 01.526.488l.002.001V6.47a.527.527 0 11-1.055 0V3.947L7.264 11.48a.527.527 0 01-.745-.745L14.053 3.2H11.53a.527.527 0 01-.527-.527zM3.517 4.677a1.37 1.37 0 00-1.37 1.37v8.436a1.37 1.37 0 001.37 1.37h8.435a1.37 1.37 0 001.37-1.37V9.421a.527.527 0 10-1.053 0v5.06a.316.316 0 01-.317.317H3.517a.316.316 0 01-.316-.316V6.047a.316.316 0 01.316-.316h5.061a.527.527 0 000-1.055h-5.06z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

ActionsUIPopoutIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ActionsUIPopoutIcon svgRef={ref} {...props} />)
export default ForwardRef
