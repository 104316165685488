import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from "react-router-dom"
import Image, { ASPECT_RATIOS } from "@sonato/core/components/Image"
import { LinkAction } from "@sonato/core/components/cards/Actions"

const Base = ({ imageUrl, imageAlt, aspect, className, children }) => (
  <Image src={imageUrl} alt={imageAlt} aspect={aspect} className={className}>
    {children}
  </Image>
)

const WithLink = ({ to, className, ...rest }) => (
  <Link
    to={to}
    className={classNames(
      "block transition duration-300 ease-in-out transform hover:scale-102",
      className
    )}
  >
    <Base {...rest} />
  </Link>
)

const WithHoverAction = ({ hoverAction, className, children, ...rest }) => (
  <div
    className={classNames(
      "group transition duration-300 ease-in-out transform hover:scale-102",
      className
    )}
  >
    <Base {...rest}>
      <div className="transition duration-500 ease-in-out absolute inset-0 bg-gray-900 opacity-0 group-hover:opacity-60" />
      <div className="transition duration-500 ease-in-out absolute top-0 right-0 opacity-0 group-hover:opacity-100">
        <LinkAction {...hoverAction} />
      </div>
      {children}
    </Base>
  </div>
)

const ImageCard = ({ to, hoverAction, ...rest }) => {
  if (to) {
    return <WithLink to={to} {...rest} />
  }
  if (hoverAction) {
    return <WithHoverAction hoverAction={hoverAction} {...rest} />
  }
  return <Base {...rest} />
}

ImageCard.propTypes = {
  // required

  /** Image URL. */
  imageUrl: PropTypes.string.isRequired,
  /** Alternate text for image. May be an empty string, depending on context. */
  imageAlt: PropTypes.string.isRequired,

  // optional

  /** The desired aspect ratio: `1:1`, `2:3`, `4:5`, or `21:8`. Defaults to `1:1`. */
  aspect: PropTypes.oneOf(ASPECT_RATIOS),
  /** Clicking image card will navigate to this URL. */
  to: PropTypes.string,
  /** Hover action. Mutually exclusive with `to`. */
  hoverAction: PropTypes.shape(LinkAction.propTypes),
  /** Optional classes. */
  className: PropTypes.string,
  /** Overlay children on top of image. */
  children: PropTypes.node
}

export default ImageCard
export { ASPECT_RATIOS, LinkAction }
