import React from "react"
import PropTypes from "prop-types"

const AppsAllianceIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g>
      <path
        d="M16 8a8 8 0 10-8 8h.083A7.925 7.925 0 0016 8.083v-.042V8zm-1.333 0c0 .593-.081 1.184-.24 1.755a.332.332 0 01-.454.218 2.323 2.323 0 01-1.011-.846L11.483 6.91a1.666 1.666 0 00-1.386-.742h-.289a1.833 1.833 0 110-3.667h1.848c.069 0 .136.021.193.061A6.662 6.662 0 0114.667 8zM1.453 6.77a.333.333 0 01.327-.267h2.891a2.318 2.318 0 011.65.684l.746.746a2.333 2.333 0 01.093 3.2l-.906 1.02c-.271.305-.42.7-.42 1.108v.551a.334.334 0 01-.467.305A6.666 6.666 0 011.333 8c.001-.413.041-.825.12-1.23z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

AppsAllianceIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsAllianceIcon svgRef={ref} {...props} />)
export default ForwardRef
