import React, { useState } from "react"

import { useTranslation, Trans } from "react-i18next"
import { Button } from "@sonato/core/components/Buttons"
import { Form, useServerChangesetForm } from "@sonato/core/form"

const ResendEmailVerification = ({ location, message }) => {
  const { t } = useTranslation()
  const [verificationSentTo, setVerificationSentTo] = useState("")

  const form = useServerChangesetForm("public/signup", "resendEmailVerification", {
    subdomain: location.subdomain
  })

  const handleSubmit = () => {
    form.submit(() => {
      setVerificationSentTo(form.value("emailAddress"))
      form.reset()
    })
  }

  const shouldShowMessage = verificationSentTo !== ""
  message = message || (
    <Trans>
      Before you can use Sonato, you must verify your email. We&apos;ve sent you an email with a
      verification link.
    </Trans>
  )
  return (
    <Form value={form}>
      <section className="flex flex-col space-y-6 w-full">
        <h2 className="text-center">{t("Check your Email")}</h2>

        <div>{message}</div>

        {shouldShowMessage && (
          <div>
            <Trans>
              We have sent another email validation message to{" "}
              <span className="font-bold">{{ verificationSentTo }}</span>. Please allow up to 30
              minutes for it to arrive, and be sure to check your spam folder. If you do not receive
              this message, please contact <span className="font-bold">support@sonato.com</span>.
            </Trans>
          </div>
        )}

        <Form.TextInput name="emailAddress" large={true} placeholder={t("Re-enter Your Email")} />
        <Button onClick={handleSubmit} tall={true}>
          {t("Resend Email Verification")}
        </Button>
      </section>
    </Form>
  )
}

export default ResendEmailVerification
