import React from "react"
import PropTypes from "prop-types"

const EssentialsDoorOpenIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.775 2.398c0-.345.28-.625.625-.625h2.8a1.558 1.558 0 011.558 1.558v9.333a1.558 1.558 0 01-1.558 1.559h-2.8a.625.625 0 010-1.25h2.8a.308.308 0 00.308-.309V3.331a.308.308 0 00-.308-.308h-2.8a.625.625 0 01-.625-.625zM7.908 1.467zm0 .202v12.682l-5.283-.755V2.973l5.283-1.304zM2.625 13.734zm-.137-.158zm5.424 2.038l-5.599-.8a1.093 1.093 0 01-.938-1.08V2.848a1.092 1.092 0 01.852-1.065L7.819.404a1.092 1.092 0 011.34 1.064v13.066a1.094 1.094 0 01-1.247 1.08z"
      />
      <path d="M7 8a1 1 0 11-2 0 1 1 0 012 0z" />
    </g>
  </svg>
)

EssentialsDoorOpenIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsDoorOpenIcon svgRef={ref} {...props} />
))
export default ForwardRef
