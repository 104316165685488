import React from "react"
import PropTypes from "prop-types"

const AppsWellnessIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="currentColor">
      <path d="M9.333 12.595a.168.168 0 00-.089.07.19.19 0 00.017.225c.027.03.061.05.1.054a7.23 7.23 0 001.028.072 5.484 5.484 0 002.459-.542c1.362-.785 2.446-2.031 3.085-3.546a.73.73 0 00.004-.584.68.68 0 00-.17-.238.62.62 0 00-.246-.137 7.78 7.78 0 00-.986-.207.314.314 0 00-.217.05.354.354 0 00-.14.187 6.898 6.898 0 01-.837 1.742c-1.025 1.38-2.427 2.38-4.008 2.854zM3.311 12.477c.774.37 1.613.556 2.46.542.267 0 .536-.015.802-.046a.162.162 0 00.101-.053.19.19 0 00.019-.226.169.169 0 00-.09-.071C5.007 12.139 3.59 11.13 2.549 9.74a6.862 6.862 0 01-.823-1.7.354.354 0 00-.144-.188.313.313 0 00-.223-.045 7.945 7.945 0 00-.725.161.622.622 0 00-.246.137.682.682 0 00-.17.238.73.73 0 00.003.583c.64 1.517 1.726 2.764 3.09 3.55zM5.985 11.232a.156.156 0 00.103.006.166.166 0 00.087-.06.188.188 0 00.01-.212A9.263 9.263 0 014.71 6.208c.002-.468.05-.934.14-1.39a.384.384 0 00-.027-.23.347.347 0 00-.151-.164 8.06 8.06 0 00-1.642-.66.592.592 0 00-.532.112.673.673 0 00-.182.226.725.725 0 00-.076.287 8.034 8.034 0 001.114 4.715 6.32 6.32 0 002.631 2.128zM11.447 6.208a9.134 9.134 0 01-1.368 4.575.19.19 0 00.015.217c.024.03.057.05.093.057a.156.156 0 00.105-.015 6.126 6.126 0 002.245-1.939 8.031 8.031 0 001.115-4.714.727.727 0 00-.076-.288.674.674 0 00-.183-.225.593.593 0 00-.532-.112c-.475.133-.938.31-1.385.53a.346.346 0 00-.16.165.384.384 0 00-.028.238c.104.496.157 1.002.16 1.511zM7.579 11.31v1.888c0 .143.052.28.146.38a.483.483 0 00.354.159c.132 0 .26-.057.353-.158a.56.56 0 00.147-.38v-1.89c0-.083.026-.162.074-.226 1.079-1.37 1.71-3.082 1.794-4.875-.096-1.826-.748-3.567-1.855-4.954a.694.694 0 00-.232-.183.648.648 0 00-.564 0 .694.694 0 00-.232.183C6.457 2.64 5.806 4.382 5.71 6.208c.085 1.793.716 3.506 1.795 4.876a.377.377 0 01.074.225z" />
    </g>
  </svg>
)

AppsWellnessIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsWellnessIcon svgRef={ref} {...props} />)
export default ForwardRef
