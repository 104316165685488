import React from "react"
import PropTypes from "prop-types"

import {
  EssentialsBarIcon,
  EssentialsDiningOutlineIcon,
  EssentialsGymIcon,
  EssentialsHotelOutlineIcon,
  EssentialsSpaIcon,
  EssentialsWorkspaceIcon
} from "@sonato/core/icons"
import CircleIcon from "../CircleIcon"

const AmentityTypeToIcon = {
  bar: EssentialsBarIcon,
  hotel: EssentialsHotelOutlineIcon,
  dining: EssentialsDiningOutlineIcon,
  gym: EssentialsGymIcon,
  spa: EssentialsSpaIcon,
  workspace: EssentialsWorkspaceIcon
}

const AmenityTypeIcon = ({ type, onClick, ...props }) => {
  const Icon = AmentityTypeToIcon[type]
  return onClick ? (
    <button className="bg-red" onClick={onClick}>
      <CircleIcon icon={Icon} {...props} />
    </button>
  ) : (
    <CircleIcon icon={Icon} {...props} />
  )
}

AmenityTypeIcon.propTypes = {
  type: PropTypes.oneOf(Object.keys(AmentityTypeToIcon)),
  onClick: PropTypes.func
}

export default AmenityTypeIcon
