import React from "react"
import PropTypes from "prop-types"

const ActionsHeartFilledIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M12.987 2.644a3.813 3.813 0 00-4.8 1.076L8 3.937l-.164-.186A3.827 3.827 0 003.01 2.644a3.92 3.92 0 00-.659 6.388l4.8 4.71a1.183 1.183 0 001.687.007l4.768-4.682a3.92 3.92 0 001.39-2.997 3.877 3.877 0 00-2.01-3.426z"
      fill="currentColor"
    />
  </svg>
)

ActionsHeartFilledIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsHeartFilledIcon svgRef={ref} {...props} />
))
export default ForwardRef
