import React from "react"
import PropTypes from "prop-types"

const ActionsUIReturnIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M5.627 11.822a.568.568 0 000-.857L3.609 9.131l2.018-1.834a.568.568 0 000-.857.715.715 0 00-.943 0L2.195 8.703a.568.568 0 000 .857l2.49 2.262c.26.237.682.237.942 0z" />
      <path d="M2 9.131c0 .335.298.606.667.606h9.955c.761 0 1.378-.56 1.378-1.252V4.606c0-.335-.299-.606-.667-.606-.368 0-.666.271-.666.606v3.879c0 .022-.02.04-.045.04H2.667c-.369 0-.667.272-.667.606z" />
    </g>
  </svg>
)

ActionsUIReturnIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ActionsUIReturnIcon svgRef={ref} {...props} />)
export default ForwardRef
