import React from "react"
import PropTypes from "prop-types"

const EssentialsHotelOutlineIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M3.383 8.442a.47.47 0 00-.469.47v1.812h9.972V8.912a.47.47 0 00-.47-.47H3.383zm0-1.014h9.033A1.484 1.484 0 0113.9 8.912v2.319c0 .28-.227.507-.507.507H2.407a.507.507 0 01-.507-.507V8.91a1.484 1.484 0 011.483-1.483z" />
      <path d="M2.407 10.724c.28 0 .507.227.507.507v1.465a.507.507 0 11-1.014 0V11.23c0-.28.227-.507.507-.507zM13.393 10.724c.28 0 .507.227.507.507v1.465a.507.507 0 11-1.014 0V11.23c0-.28.227-.507.507-.507zM3.362 3.763a1.24 1.24 0 01.876-.363h7.324a1.24 1.24 0 011.24 1.24v3.295a.507.507 0 01-1.015 0V4.64a.226.226 0 00-.225-.225H4.238a.225.225 0 00-.225.225v3.296a.507.507 0 01-1.014 0V4.64c0-.328.13-.644.363-.876z" />
      <path d="M6.191 5.597H9.61a.995.995 0 01.995.996v1.342c0 .28-.227.507-.507.507H5.703a.507.507 0 01-.507-.507V6.593a.995.995 0 01.995-.996zm.019 1.014h3.38v.817H6.21v-.817z" />
    </g>
  </svg>
)

EssentialsHotelOutlineIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsHotelOutlineIcon svgRef={ref} {...props} />
))
export default ForwardRef
