import React, { useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import InputWrapper from "./InputWrapper"
import Label from "./Label"
import Overline from "@sonato/core/components/Overline"
import useUniqueId from "@sonato/core/hooks/useUniqueId"
import { refShape } from "@sonato/core/shapes"

const BULLET = "\u2022"

const TextDisplay = ({ value }) => <p className="font-bold h-5 line-clamp-1 flex-grow">{value}</p>
const PasswordDisplay = ({ value }) => (
  <p className="font-bold text-2xl flex-grow">{BULLET.repeat(value.length)}</p>
)

const TextInput = ({
  label,
  name,
  value,
  defaultValue,
  placeholder,
  leftFrame,
  rightFrame,
  error,
  display = false,
  disabled = false,
  maxLength,
  min,
  max,
  step,
  large,
  largeFont,
  frameClasses = "h-4 w-4",
  type = "text",
  onChange = () => {},
  inputRef,
  onFocus = () => {},
  onBlur = () => {},
  onKeyPress = () => {},
  disableForceFocus = false
}) => {
  const boxClasses = classNames(
    "flex flex-col justify-center px-3 rounded",
    large ? "h-16" : "h-11"
  )

  const inputClasses = classNames("flex-grow focus:outline-none min-w-0", {
    "text-lg": largeFont,
    "text-base": !largeFont,
    "h-10": !large,
    "font-bold": value,
    "text-gold-500 placeholder-gold-500 cursor-not-allowed": disabled
  })

  const handleChange = event => {
    onChange(event.target.value)
  }

  const localRef = useRef()
  const myInputRef = inputRef ? inputRef : localRef

  const focusInput = () => {
    !disableForceFocus && myInputRef.current && myInputRef.current.focus()
  }

  const inputId = useUniqueId()
  const counter = !display && maxLength && `${value.length}/${maxLength}`
  const showLabel = large && label

  const Display = type === "password" ? PasswordDisplay : TextDisplay

  // Make sure the value is defined so we don't create an uncontrolled input
  if (value === null || value === undefined) value = ""

  return (
    <InputWrapper error={error} disabled={disabled} display={display} onClick={focusInput}>
      <div className={boxClasses}>
        {showLabel && <Label text={label} error={error} htmlFor={inputId} />}
        <div className={classNames("flex items-center", { "mt-1": showLabel })}>
          {leftFrame && (
            <div className={classNames(frameClasses, "mr-3 flex items-center")}>{leftFrame}</div>
          )}
          {display && <Display value={value} />}
          {!display && (
            <input
              id={inputId}
              type={type}
              min={min}
              max={max}
              step={step}
              ref={myInputRef}
              className={inputClasses}
              name={name}
              value={value}
              placeholder={placeholder}
              maxLength={maxLength}
              disabled={disabled}
              style={{ caretColor: "#b3956e", backgroundColor: "transparent" }}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
              defaultValue={defaultValue}
            />
          )}
          {rightFrame && <div className={classNames(frameClasses, "ml-3")}>{rightFrame}</div>}
          {counter && (
            <Overline.Small className="ml-3 text-gray-900 opacity-40">{counter}</Overline.Small>
          )}
        </div>
      </div>
    </InputWrapper>
  )
}

TextInput.propTypes = {
  /** Input label. */
  label: PropTypes.string,
  /** Name of input field.  */
  name: PropTypes.string,
  /** Value of input. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Placeholder text. */
  placeholder: PropTypes.string,
  /** Classes added to frame containers **/
  frameClasses: PropTypes.string,
  /** Left frame. */
  leftFrame: PropTypes.node,
  /** Right frame. */
  rightFrame: PropTypes.node,
  /** Error message. */
  error: PropTypes.string,
  /** Display-only mode, if true. */
  display: PropTypes.bool,
  /** Disabled if true. */
  disabled: PropTypes.bool,
  /** Large if true. */
  large: PropTypes.bool,
  /** Limit max length and show counter. */
  maxLength: PropTypes.number,
  /** Minimal allowed value. */
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Maximal allowed value. */
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Specifies the granularity that the value must adhere to. */
  step: PropTypes.number,
  /** Input type, defaults to `text`. */
  type: PropTypes.string,
  /** Input reference **/
  inputRef: refShape,
  /** Change handler, invoked with new value. */
  onChange: PropTypes.func,
  /** Focus callback */
  onFocus: PropTypes.func,
  /** Blur callback */
  onBlur: PropTypes.func,
  /** KeyPress callback **/
  onKeyPress: PropTypes.func,
  /** Disable zoom on input focus for safari
   * by setting font size to 16px or more
   * this props apply font-lg class
   * in tailwind.config.js file, values is set to 16px
   */
  largeFont: PropTypes.bool,
  /** Disable force focus on input */
  disableForceFocus: PropTypes.bool,
  /** Default value for uncontrolled inputs */
  defaultValue: PropTypes.string
}

export default React.forwardRef((props, ref) => <TextInput inputRef={ref} {...props} />)
