import React from "react"
import PropTypes from "prop-types"

const ThirdPartyTwitterIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.669 2a3.04 3.04 0 011.845.656l1.176-.412a.548.548 0 01.587.15c.149.163.188.4.1.604l-.414.952.71.32a.563.563 0 01.107.958l-.992.758v.226c0 2.403-1.157 4.967-3.32 6.475-2.19 1.528-5.324 1.914-9.148.121a.562.562 0 01.246-1.067c.743.018 1.48-.11 2.174-.37a3.585 3.585 0 01-.328-.12c-.631-.264-1.143-.71-1.492-1.448a.566.566 0 01.302-.766l.187-.071a3.205 3.205 0 01-.436-.363 3.39 3.39 0 01-.97-2.022.564.564 0 01.167-.468.548.548 0 01.47-.149l.615.099c-.36-.841-.416-1.855-.213-2.994a.549.549 0 01.968-.26c1.484 1.802 2.943 2.758 4.504 2.619v-.13a3.388 3.388 0 01.916-2.322A3.084 3.084 0 0110.66 2h.008zm-.009 1.12a1.986 1.986 0 00-1.433.631A2.26 2.26 0 008.62 5.3v.608a.558.558 0 01-.437.547c-1.963.427-3.66-.466-5.149-1.96.035.85.319 1.47.793 1.916a.565.565 0 01.122.655.551.551 0 01-.584.308L2.32 7.206c.109.223.253.426.427.598.355.353.813.56 1.292.59A.56.56 0 014.2 9.475l-.938.36c.165.17.355.29.572.382.42.176.953.249 1.657.307a.563.563 0 01.275 1.014 7.05 7.05 0 01-1.989 1c2.577.713 4.61.24 6.063-.773 1.839-1.282 2.842-3.487 2.842-5.554v-.505c0-.176.082-.341.22-.447l.464-.354-.357-.161a.562.562 0 01-.28-.737l.125-.287-.28.098a.548.548 0 01-.567-.127 1.96 1.96 0 00-1.348-.573z"
      fill="currentColor"
    />
  </svg>
)

ThirdPartyTwitterIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ThirdPartyTwitterIcon svgRef={ref} {...props} />
))
export default ForwardRef
