import React from "react"
import PropTypes from "prop-types"

const EssentialsLockFilledIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M12.375 6.542h-.437V4.938a3.938 3.938 0 00-7.875 0v1.604h-.438a1.167 1.167 0 00-1.167 1.166v6.125A1.167 1.167 0 003.625 15h8.75a1.167 1.167 0 001.167-1.167V7.708a1.167 1.167 0 00-1.167-1.166zm-5.542 3.5a1.167 1.167 0 111.75 1.005v1.328a.583.583 0 01-1.166 0v-1.328a1.163 1.163 0 01-.583-1.005zM5.521 4.938a2.48 2.48 0 114.958 0V6.25a.292.292 0 01-.291.292H5.813a.292.292 0 01-.292-.292V4.937z"
      fill="currentColor"
    />
  </svg>
)

EssentialsLockFilledIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsLockFilledIcon svgRef={ref} {...props} />
))
export default ForwardRef
