import React from "react"
import PropTypes from "prop-types"

const ActionsShareSimpleIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.167 3.95c0-1.629 1.306-2.95 2.917-2.95C13.694 1 15 2.321 15 3.95c0 1.63-1.306 2.952-2.916 2.952-.7 0-1.342-.25-1.845-.665a.653.653 0 01-.036.015L6.789 7.548a3.004 3.004 0 01-.013 1.1l3.076 1.501a2.897 2.897 0 012.232-1.05c1.61 0 2.916 1.32 2.916 2.95C15 13.68 13.694 15 12.084 15c-1.611 0-2.917-1.321-2.917-2.95 0-.267.035-.526.1-.771L6.265 9.814a2.902 2.902 0 01-2.348 1.2C2.306 11.014 1 9.694 1 8.064c0-1.63 1.306-2.951 2.917-2.951.989 0 1.863.498 2.39 1.26l3.127-1.187a2.969 2.969 0 01-.267-1.235zM5.474 8.517a.633.633 0 00-.175.404c-.285.47-.798.783-1.382.783a1.63 1.63 0 01-1.62-1.64c0-.905.725-1.639 1.62-1.639.895 0 1.62.734 1.62 1.64 0 .157-.022.308-.063.452zm6.61-6.205a1.63 1.63 0 00-1.62 1.64c0 .905.725 1.64 1.62 1.64a1.63 1.63 0 001.62-1.64 1.63 1.63 0 00-1.62-1.64zm-1.62 9.738c0-.905.725-1.64 1.62-1.64.895 0 1.62.735 1.62 1.64a1.63 1.63 0 01-1.62 1.64 1.63 1.63 0 01-1.62-1.64z"
      fill="currentColor"
    />
  </svg>
)

ActionsShareSimpleIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsShareSimpleIcon svgRef={ref} {...props} />
))
export default ForwardRef
