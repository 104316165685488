import React from "react"
import PropTypes from "prop-types"

const EssentialsNoteIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M2.313 2.167a.146.146 0 00-.146.146v11.374a.146.146 0 00.146.146h6.27v-3.646a1.604 1.604 0 011.605-1.604h3.646v-6.27a.146.146 0 00-.146-.146H2.313zm12.839 7.439c.091-.205.14-.428.14-.656V2.313A1.604 1.604 0 0013.688.708H2.313A1.604 1.604 0 00.708 2.313v11.374a1.604 1.604 0 001.605 1.605H8.95a1.604 1.604 0 001.134-.47l4.738-4.738a1.605 1.605 0 00.33-.478zm-2.35.436l-2.76 2.76v-2.614a.146.146 0 01.146-.146h2.614z" />
      <path d="M4.208 4.938a.73.73 0 01.73-.73h7a.73.73 0 010 1.459h-7a.73.73 0 01-.73-.73zM4.208 7.563a.73.73 0 01.73-.73H8a.73.73 0 110 1.459H4.938a.73.73 0 01-.73-.73z" />
    </g>
  </svg>
)

EssentialsNoteIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <EssentialsNoteIcon svgRef={ref} {...props} />)
export default ForwardRef
