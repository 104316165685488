import React from "react"
import { Redirect, Route, useLocation } from "react-router-dom"
import { useAuthentication } from "@sonato/core/hooks/authentication"
import SignIn from "@sonato/core/pages/authentication/SignIn"
import * as Urls from "@sonato/core/urls"

function ProtectedRoute({ redirectTo, children, ...rest }) {
  const { auth } = useAuthentication()
  const location = useLocation()

  const renderFunction = () => {
    if (auth.isPending) {
      throw Promise.resolve(true)
    }

    if (auth.isAuthenticated) {
      return children
    }

    if (redirectTo) {
      return <Redirect to={{ pathname: redirectTo, state: { from: location } }} />
    }

    const subdomain = Urls.currentLocationSubdomain()
    window.location = SignIn.url(subdomain)
    return null
  }

  return <Route {...rest} render={renderFunction} />
}

export default ProtectedRoute
