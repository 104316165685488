import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Image from "@sonato/core/components/Image"
import { noOp, repeat } from "@sonato/core/utils"

const IndicatorIcon = ({ filled, onClick }) => (
  <button onClick={onClick}>
    <svg
      height="8"
      width="8"
      viewBox="0 0 10 10"
      strokeWidth="1"
      stroke="currentColor"
      fill={filled ? "currentColor" : "none"}
      className="cursor-pointer"
    >
      <circle cx="5" cy="5" r="4" />
    </svg>
  </button>
)

export const CarouselIndicator = ({ count, selectedIndex, className, onClick = noOp }) => {
  const classes = classNames("grid grid-flow-col gap-5 justify-center text-gold-900", className)

  return (
    <div className={classes}>
      {repeat(count, index => (
        <IndicatorIcon
          key={index}
          filled={index === selectedIndex}
          onClick={() => onClick(index)}
        />
      ))}
    </div>
  )
}

/**
 * Creates an illusion of an infinitely looping carousel.
 *
 * Done by appending copies of the first two images to the end of the list of
 * images, then scrolling through each image up to the copy of the first image,
 * then instantly skipping back to the first image (sans animation). Since the
 * last and first state look the same this skip is invisible to the user.
 * Carousel then starts from the beginning, thus creating an illusion of an
 * endless loop.
 *
 * Requires at least two images because two images are visible at the same time
 * on the screen (current image + part of the next image).
 */
const LoopingCarousel = ({ imageUrls, alt, aspect, gap, delay, showIndicator }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const loopedImages = [...imageUrls.slice(-1), ...imageUrls, ...imageUrls.slice(0, 2)]
  const count = loopedImages.length

  const left = `calc(${-selectedIndex - 1} * (100% + ${gap}))`
  const width = `calc(${count} * 100% + ${count - 1} * ${gap})`

  // Don't animate transition back to first slide
  const animationClasses = selectedIndex === 0 ? "" : "transition-all duration-500"

  // Prevent waiting another full interval after switching back to the first
  // slide. 500ms is still required for the animation to fully play out.
  const adjustedDelay = selectedIndex === loopedImages.length - 3 ? 500 : delay

  useEffect(() => {
    const timer = setTimeout(() => {
      setSelectedIndex((selectedIndex + 1) % (imageUrls.length + 1))
    }, adjustedDelay)

    return () => clearTimeout(timer)
  }, [loopedImages, imageUrls, adjustedDelay, selectedIndex])

  return (
    <>
      <div className="overflow-x-visible">
        <div
          className={classNames("grid grid-flow-col relative", animationClasses)}
          style={{ gap, left, width }}
        >
          {loopedImages.map((url, idx) => (
            <div key={idx}>
              <Image src={url} alt={alt} aspect={aspect} />
            </div>
          ))}
        </div>
      </div>
      {showIndicator && (
        <CarouselIndicator
          className="mt-10"
          count={imageUrls.length}
          selectedIndex={selectedIndex >= imageUrls.length ? 0 : selectedIndex}
          onClick={index => setSelectedIndex(index === 0 ? imageUrls.length : index)}
        />
      )}
    </>
  )
}

LoopingCarousel.propTypes = {
  imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  alt: PropTypes.string.isRequired,
  aspect: PropTypes.string.isRequired,
  gap: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired,
  showIndicator: PropTypes.bool
}

const Carousel = ({ imageUrls, alt, aspect, gap = "40px", delay = 4000, showIndicator = true }) => {
  if (imageUrls.length > 1) {
    return (
      <LoopingCarousel imageUrls={imageUrls} alt={alt} aspect={aspect} gap={gap} delay={delay} />
    )
  } else {
    return <Image alt={alt} src={imageUrls[0]} aspect={aspect} />
  }
}

Carousel.propTypes = {
  imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  alt: PropTypes.string.isRequired,
  aspect: PropTypes.string.isRequired,
  gap: PropTypes.string,
  delay: PropTypes.number,
  showIndicator: PropTypes.bool
}

export default Carousel
