import React from "react"
import PropTypes from "prop-types"

const ActionsUploadFileIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path d="M2.434 1.05A1.383 1.383 0 0 0 1.05 2.433v7.734a1.383 1.383 0 0 0 1.384 1.383h2.9a.55.55 0 1 1 0 1.1h-2.9A2.483 2.483 0 0 1-.05 10.167V2.433A2.483 2.483 0 0 1 2.434-.05h7.733a2.483 2.483 0 0 1 2.483 2.483v2.9a.55.55 0 1 1-1.1 0v-2.9a1.383 1.383 0 0 0-1.383-1.383H2.434Z" />
    <path d="M4.367-.05a.55.55 0 0 1 .55.55v11.6a.55.55 0 0 1-1.1 0V.5a.55.55 0 0 1 .55-.55ZM8.234-.05a.55.55 0 0 1 .55.55v5.8a.55.55 0 1 1-1.1 0V.5a.55.55 0 0 1 .55-.55Z" />
    <path d="M-.05 4.367a.55.55 0 0 1 .55-.55h11.6a.55.55 0 1 1 0 1.1H.5a.55.55 0 0 1-.55-.55ZM-.05 8.233a.55.55 0 0 1 .55-.55h5.8a.55.55 0 0 1 0 1.1H.5a.55.55 0 0 1-.55-.55ZM11.133 7.817a3.317 3.317 0 1 0 0 6.633 3.317 3.317 0 0 0 0-6.633Zm-4.416 3.316a4.417 4.417 0 1 1 8.833 0 4.417 4.417 0 0 1-8.833 0Z" />
    <path d="M11.133 8.65a.55.55 0 0 1 .55.55v3.867a.55.55 0 0 1-1.1 0V9.2a.55.55 0 0 1 .55-.55Z" />
    <path d="M11.522 8.811a.55.55 0 0 1 0 .778l-1.45 1.45a.55.55 0 1 1-.778-.778l1.45-1.45a.55.55 0 0 1 .778 0Z" />
    <path d="M10.745 8.811a.55.55 0 0 1 .777 0l1.45 1.45a.55.55 0 1 1-.777.778l-1.45-1.45a.55.55 0 0 1 0-.778Z" />
  </svg>
)
ActionsUploadFileIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsUploadFileIcon svgRef={ref} {...props} />
))
export default ForwardRef
