import Alert from "@sonato/core/components/Alert"
import AppDownloadButtons from "@sonato/core/components/AppDownloadButtons"
import IdentitySwitcher from "@sonato/core/components/authentication/IdentitySwitcher"
import CobrandedLogos from "@sonato/core/components/CobrandedLogos"
import ColorMode from "@sonato/core/components/ColorMode"
import Image from "@sonato/core/components/Image"
import Overline from "@sonato/core/components/Overline"
import { useAuthentication } from "@sonato/core/hooks/authentication"
import { ScrollToAnchor } from "@sonato/core/hooks/useScrollOnNavigate"
import Icon from "@sonato/core/icons/icon"
import sonatoLogoDark from "@sonato/core/static/images/logos/sonato.svg"
import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom"
import Clubs from "./clubs"
import Hotels from "./hotels"
import AccountPanel from "./header/AccountPanel"
import MemberNotification from "./header/MemberNotification"
import Inbox from "./messages/Inbox"
import Thread from "./messages/Thread"
import VisitsPage from "./visits"
import { privacyUrl } from "@sonato/core/urls"
import { userAvatarURL } from "@sonato/core/images"

const ActiveIndicator = () => (
  <svg
    viewBox="0 0 10 10"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    className="w-3 h-3 absolute"
    style={{
      left: "calc(50% - 0.375rem)",
      top: "3.75rem"
    }}
  >
    <circle cx="5" cy="5" r="4" />
  </svg>
)

ActiveIndicator.propTypes = {
  className: PropTypes.string
}

const TopNavLink = ({ to, text, exact }) => {
  const { pathname } = useLocation()
  const isActive = exact ? pathname === to : pathname.startsWith(to)
  const classes = classNames(
    "font-bold relative",
    isActive ? "text-gold-900" : "text-gray-500 hover:text-gold-900"
  )

  return (
    <Link to={to} className={classes} exact={exact}>
      <Overline.Medium>{text}</Overline.Medium>
      {isActive && <ActiveIndicator />}
    </Link>
  )
}

TopNavLink.propTypes = {
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  text: PropTypes.string.isRequired
}

const TopNavBar = () => {
  return (
    <nav className="hidden sm:grid grid-flow-col gap-10">
      <TopNavLink to="/member/clubs" text="Clubs" />
      <TopNavLink to="/member/hotels" text="Hotels" />
      <TopNavLink to="/member/visits" text="Visits" />
      <TopNavLink to="/member/inbox" text="Inbox" />
    </nav>
  )
}

const MobileNavLink = ({ to, text, exact, icon }) => {
  const { pathname } = useLocation()
  const isActive = exact ? pathname === to : pathname.startsWith(to)
  const classes = classNames("font-bold text-white flex flex-col items-center", {
    "opacity-40": !isActive
  })

  return (
    <Link to={to} className={classes} exact={exact}>
      <Icon name={icon} className="h-5 w-5" />
      <span>{text}</span>
    </Link>
  )
}

MobileNavLink.propTypes = {
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  text: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired
}

const MobileNavBar = () => {
  return (
    <nav className="h-16 flex items-center justify-around bg-brand-500">
      <MobileNavLink to="/member/clubs" text="Clubs" icon="AppsClubsMobileIcon" />
      <MobileNavLink to="/member/hotels" text="Hotels" icon="AppsClubsMobileIcon" />
      <MobileNavLink to="/member/visits" text="Visits" icon="AppsVisitsMobileIcon" />
      <MobileNavLink to="/member/inbox" text="Inbox" icon="AppsInboxMobileIcon" />
    </nav>
  )
}

const Header = () => {
  const { t } = useTranslation()
  const { auth } = useAuthentication()
  const [isIdentitySwitcherVisible, setIdentitySwitcherVisible] = useState(false)
  const [isAccountPanelVisible, setAccountPanelVisible] = useState(false)
  const hideAccountPanel = () => setAccountPanelVisible(false)
  const toggleAccountPanel = () => setAccountPanelVisible(!isAccountPanelVisible)
  const avatarURL = userAvatarURL(auth.identity.subject, 100, 100)

  return (
    <header className="h-28 px-8 border-b border-gold-300 flex items-center justify-between">
      <Link to={`/member/clubs`}>
        <img className="w-40" src={sonatoLogoDark} alt={t("Sonato")} />
      </Link>
      <TopNavBar />
      <div className="w-40 flex justify-end">
        <div>
          <MemberNotification />
        </div>
        <div className="ml-3 relative flex flex-col">
          <button
            key={isIdentitySwitcherVisible}
            onClick={() => setIdentitySwitcherVisible(prev => !prev)}
          >
            <Image className="h-8 w-8" circular={true} src={avatarURL} alt={t("Profile image")} />
          </button>
          <div className="absolute top-10 right-3 z-50">
            <IdentitySwitcher
              accountOnClick={toggleAccountPanel}
              visible={isIdentitySwitcherVisible}
              setVisible={setIdentitySwitcherVisible}
            />
          </div>
        </div>
      </div>
      <AccountPanel visible={isAccountPanelVisible} onDismiss={hideAccountPanel} />
    </header>
  )
}

const Footer = () => {
  const { t } = useTranslation()
  const { auth } = useAuthentication()

  return (
    <footer className="h-72 p-16 bg-brand-500 text-white">
      <div className="flex flex-row place-content-between space-x-5">
        <CobrandedLogos colorMode={ColorMode.dark} location={auth.location} />
        <section>
          <div className="hidden md:flex space-x-3">
            <AppDownloadButtons buttonStyle="clear" />
          </div>
        </section>
      </div>
      <div className="flex flex-row place-content-between mt-12">
        <section className="text-white">
          <p>245 5th Street, San Francisco, CA 94103</p>
          <p>
            <a href="mailto:hello@sonato.com">hello@sonato.com</a>
          </p>
        </section>
        <section className="text-white opacity-40">
          <a href={privacyUrl()}>
            <Overline.Small>{t("Privacy Policy")}</Overline.Small>
          </a>
        </section>
      </div>
    </footer>
  )
}

const Layout = ({ children }) => (
  <Alert.Context className="fixed inset-x-0 z-10">
    <div className="absolute inset-0 mb-16 sm:mb-0 bg-gold-100 overflow-y-scroll">{children}</div>
    <div className="fixed sm:hidden inset-x-0 bottom-0">
      <MobileNavBar />
    </div>
  </Alert.Context>
)

const App = () => {
  const { path } = useRouteMatch()

  return (
    <Layout>
      <ScrollToAnchor />
      <Header />
      <main className="mb-10 sm:mb-16" style={{ minHeight: "calc(100vh - 464px)" }}>
        <Switch>
          <Route exact={true} path={path}>
            <Redirect to={`${path}/clubs`} />
          </Route>
          <Route path={`${path}/clubs`}>
            <Clubs />
          </Route>
          <Route path={`${path}/hotels`}>
            <Hotels />
          </Route>
          <Route exact={true} path={`${path}/inbox`}>
            <Inbox />
          </Route>
          <Route path={Thread.path}>
            <Thread />
          </Route>
          <Route path={`${path}/visits`}>
            <VisitsPage />
          </Route>
        </Switch>
      </main>
      <div className="hidden sm:block">
        <Footer />
      </div>
    </Layout>
  )
}

export default App
