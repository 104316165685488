import React from "react"
import PropTypes from "prop-types"

const AppsDiningIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="currentColor">
      <path d="M10.334 0a.5.5 0 00-.5.5v14.833a.666.666 0 101.333 0V10a.333.333 0 01.333-.333h1.167a.83.83 0 00.833-.834V8.83C13.457 4.037 11.888 0 10.334 0zM7.167 0A.667.667 0 006.5.667V4c0 .349-.136.684-.38.933a.167.167 0 01-.287-.116V.667a.667.667 0 10-1.333 0v4.148a.167.167 0 01-.286.117A1.329 1.329 0 013.834 4V.667a.667.667 0 10-1.334 0V4a2.673 2.673 0 001.778 2.515.333.333 0 01.222.314v8.504a.667.667 0 101.333 0V6.83a.333.333 0 01.222-.314A2.673 2.673 0 007.833 4V.667A.667.667 0 007.167 0z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

AppsDiningIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsDiningIcon svgRef={ref} {...props} />)
export default ForwardRef
