import React from "react"
import PropTypes from "prop-types"

const ActionsDownloadIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M8 2.642c.345 0 .625.28.625.625v7.466a.625.625 0 11-1.25 0V3.267c0-.345.28-.625.625-.625z" />
      <path d="M4.758 7.492a.625.625 0 01.884 0L8 9.85l2.358-2.358a.625.625 0 11.884.883l-2.8 2.8a.625.625 0 01-.884 0l-2.8-2.8a.625.625 0 010-.883z" />
      <path d="M1 10.108c.345 0 .625.28.625.625v.934a1.242 1.242 0 001.242 1.241h10.266a1.241 1.241 0 001.242-1.241v-.934a.625.625 0 111.25 0v.934a2.492 2.492 0 01-2.492 2.491H2.867a2.492 2.492 0 01-2.492-2.491v-.934c0-.345.28-.625.625-.625z" />
    </g>
  </svg>
)

ActionsDownloadIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ActionsDownloadIcon svgRef={ref} {...props} />)
export default ForwardRef
