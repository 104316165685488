import React, { useState, useCallback } from "react"
import { useParams } from "react-router-dom"

import { useTranslation, Trans } from "react-i18next"
import DefaultBackground from "@sonato/core/assets/home_background.jpg"
import CobrandedLogos from "@sonato/core/components/CobrandedLogos"
import { Button } from "@sonato/core/components/Buttons"
import { Form, useServerChangesetForm } from "@sonato/core/form"
import { CallToActionLayout, CallToAction } from "@sonato/core/components/Layouts"
import { useApiCall } from "@sonato/core/client"
import SignIn from "@sonato/core/pages/authentication/SignIn"
import ForgotPassword from "@sonato/core/pages/authentication//ForgotPassword"
import Footer from "@sonato/core/components/Footer"

const ResetPassword = () => {
  const params = useParams()
  const subdomain = params.location

  let { token } = useParams()
  const { t } = useTranslation()
  const [isFormSubmitted, setFormSubmitted] = useState(false)
  const [isPasswordReset, setPasswordReset] = useState(false)
  const [isCodeValid, setCodeValid] = useState(true)
  const [currentLocation, error, isLoading] = useApiCall("public/locations", "current", {
    subdomain
  })

  const markCodeInvalid = useCallback(() => {
    setCodeValid(false)
  }, [setCodeValid])

  const form = useServerChangesetForm(
    "public/authentication",
    "resetPassword",
    { token, subdomain },
    { ajax: true, onFetchFailure: markCodeInvalid }
  )

  let backgroundImage
  if (isLoading) {
    backgroundImage = ""
  } else if (currentLocation) {
    backgroundImage = currentLocation.images.landscape.uri
  } else if (error) {
    backgroundImage = DefaultBackground
  } else {
    backgroundImage = DefaultBackground
  }

  const placeholderStyles = {
    backgroundImage: `url("${backgroundImage}")`,
    backgroundSize: "cover",
    backgroundPosition: "center center"
  }

  const resetPassword = () => {
    setFormSubmitted(true)
    form.submit(() => setPasswordReset(true))
  }

  const submitOnEnter = e => {
    if (e.key === "Enter") {
      resetPassword()
    }
  }

  if (isPasswordReset) {
    const alertMessage = (
      <Trans>
        <span className="inline-block mr-1 font-bold">Success!</span> Your password has been saved
      </Trans>
    )

    return <SignIn alertMessage={alertMessage} />
  }

  if (!isFormSubmitted && !isCodeValid) {
    const alertMessage = (
      <Trans>
        <span className="inline-block font-bold mr-1">Your reset link is invalid.</span>Please
        create another
      </Trans>
    )
    return <ForgotPassword alertMessage={alertMessage} alertStyle="error" />
  }

  return (
    <CallToActionLayout
      placeholderClasses="bg-corrected-brand-500"
      placeholderStyles={placeholderStyles}
    >
      <CallToAction header={<CobrandedLogos />} footer={<Footer />}>
        <h2 className="text-center">{t("Reset Password")}</h2>
        <Form value={form}>
          <fieldset className="flex flex-col w-full gap-3 mt-6">
            <Form.GlobalErrors />
            <Form.PasswordInput
              name="password"
              placeholder={t("New Password")}
              large={true}
              onKeyPress={submitOnEnter}
            />
            <Form.PasswordInput
              name="passwordConfirmation"
              placeholder={t("New Password (Again)")}
              large={true}
              onKeyPress={submitOnEnter}
            />
          </fieldset>

          <Button className="mt-6" tall={true} onClick={() => resetPassword()}>
            {t("Reset Password")}
          </Button>

          <div className="text-center mt-3">
            <Trans>
              Or go to{" "}
              <a href={SignIn.url(subdomain)} className="text-gold-900">
                login
              </a>
            </Trans>
          </div>
        </Form>
      </CallToAction>
    </CallToActionLayout>
  )
}

ResetPassword.path = "/account/reset-password/:token"

export default ResetPassword
