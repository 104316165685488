import React from "react"
import PropTypes from "prop-types"

const AppsVisitsMobileIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="currentColor">
      <path d="M5.652 6.478h-.521a.522.522 0 100 1.044h.521a.522.522 0 100-1.044zM8.261 6.478H7.74a.522.522 0 100 1.044h.521a.522.522 0 000-1.044zM10.87 6.478h-.523a.522.522 0 100 1.044h.522a.522.522 0 000-1.044zM5.652 8.565h-.521a.522.522 0 100 1.044h.521a.522.522 0 100-1.044zM8.261 8.565H7.74a.522.522 0 100 1.044h.521a.522.522 0 100-1.044zM10.87 8.565h-.523a.522.522 0 100 1.044h.522a.522.522 0 000-1.044zM5.652 10.652h-.521a.522.522 0 000 1.044h.521a.522.522 0 000-1.044zM8.261 10.652H7.74a.522.522 0 000 1.044h.521a.522.522 0 000-1.044zM10.87 10.652h-.523a.522.522 0 000 1.044h.522a.522.522 0 000-1.044z" />
      <path d="M12.957 2.565h-1.435a.13.13 0 01-.13-.13v-.913a.522.522 0 00-1.044 0V4a.391.391 0 11-.783 0V2.826a.26.26 0 00-.26-.26H6.042a.13.13 0 01-.13-.13v-.914a.522.522 0 00-1.043 0V4a.391.391 0 01-.783 0V2.826a.26.26 0 00-.26-.26h-.784A1.043 1.043 0 002 3.608v8.87a1.043 1.043 0 001.043 1.043h9.913A1.043 1.043 0 0014 12.478v-8.87a1.043 1.043 0 00-1.043-1.043zm0 9.652a.261.261 0 01-.261.261H3.304a.26.26 0 01-.26-.26V5.956a.26.26 0 01.26-.261h9.392a.26.26 0 01.26.26v6.261z" />
    </g>
  </svg>
)

AppsVisitsMobileIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <AppsVisitsMobileIcon svgRef={ref} {...props} />
))
export default ForwardRef
