import { useTranslation } from "react-i18next"
import * as dateFnsLocales from "date-fns/locale"

/**
 * React hook which returns the date-fns locale object matching the currently
 * selected i18next language. Defaults to "en-US".
 */
export const useDateFnsLocale = () => {
  const { i18n } = useTranslation()

  // Locale modules are named without a dash (enUS instead of en-US)
  const language = i18n.language.replace("-", "")

  return dateFnsLocales[language] || dateFnsLocales.enUS
}

/**
 * React hook which returns the currently active locale.
 */
export const useLocale = () => {
  const { i18n } = useTranslation()

  return i18n.language
}
