import React from "react"
import PropTypes from "prop-types"
import Overline from "@sonato/core/components/Overline"
import { Button } from "@sonato/core/components/Buttons"

const LinkAction = ({ text, icon, onClick }) => (
  <button className="p-5 flex items-center focus:outline-none" onClick={onClick}>
    <Overline.Small className="text-white">{text}</Overline.Small>
    {icon && <p className="text-white ml-2">{icon}</p>}
  </button>
)
LinkAction.propTypes = {
  /** Click handler. */
  onClick: PropTypes.func.isRequired,
  /** Link text. */
  text: PropTypes.string.isRequired,
  /** Optional icon. */
  icon: PropTypes.element
}

const ButtonAction = ({ text, onClick, secondary, disabled }) => (
  <Button secondary={secondary} disabled={disabled} onClick={onClick}>
    {text}
  </Button>
)
ButtonAction.propTypes = {
  /** Click handler. */
  onClick: PropTypes.func.isRequired,
  /** Button text. */
  text: PropTypes.string.isRequired,
  /** True if secondary. */
  secondary: PropTypes.bool,
  /** True if disabled. */
  disabled: PropTypes.bool
}

export { ButtonAction, LinkAction }
