import { useAlert } from "@sonato/core/components/Alert"
import ErrorFallback from "@sonato/core/components/ErrorFallback"
import Overline from "@sonato/core/components/Overline"
import { AbsoluteDate } from "@sonato/core/components/Timestamps"
import { useAuthentication } from "@sonato/core/hooks/authentication"
import { ArrowLeftIcon, ArrowRightIcon } from "@sonato/core/icons"
import { coerceDate } from "@sonato/core/utils/date"
import * as DateFns from "date-fns"
import * as React from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import FeedItem from "./FeedItem"
import { useLocationMessages, useSendMessageMutation } from "./hooks"
import { useAcknowledgeMessagesMutation } from "../header/hooks"
import { usePublicLocation } from "pages/hooks"
import NotFound from "@sonato/core/components/NotFound"

const Page = () => {
  const { subdomain } = useParams()

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <React.Suspense fallback={<ThreadSkeleton />}>
        <Thread subdomain={subdomain} />
      </React.Suspense>
    </ErrorBoundary>
  )
}

const Thread = ({ subdomain }) => {
  const { data: location } = usePublicLocation(subdomain, { suspense: true })
  const { mutate: messageRead } = useAcknowledgeMessagesMutation(location?.id)
  const inputRef = React.useRef()

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
    if (location) {
      messageRead()
    }
  }, [messageRead, location])

  if (!location) {
    return <NotFound />
  }

  return (
    <div className="-mb-10 sm:-mb-16">
      <div className="px-5">
        <div className="flex my-10 space-x-5 items-center">
          <Link to="/member/inbox" className="block">
            <ArrowLeftIcon className="h-6 w-6" />
          </Link>
          <h2>{location.name}</h2>
        </div>
        <Feed location={location} />
      </div>
      <MessageInput inputRef={inputRef} locationId={location.id} />
    </div>
  )
}

const ThreadSkeleton = () => (
  <div className="px-5">
    <div className="flex my-10 space-x-5 items-center">
      <Link to="/member/inbox" className="block">
        <ArrowLeftIcon className="h-6 w-6" />
      </Link>
      <div className="w-3/5 h-10 bg-gray-100 rounded-sm skeleton" />
    </div>
  </div>
)

const Feed = ({ location }) => {
  const { auth } = useAuthentication()
  const userId = auth.identity.subject.id
  const { data: messages } = useLocationMessages(location.id)

  let currentDayStartsAt = null
  const components = [...messages].reverse().reduce((acc, { eventData, id, recipientId }) => {
    const timestamp = coerceDate(eventData.eventAt)
    const dayStartsAt = DateFns.startOfDay(timestamp)
    if (!currentDayStartsAt || currentDayStartsAt < dayStartsAt) {
      currentDayStartsAt = dayStartsAt
      acc.push(<DateSeparator date={currentDayStartsAt} key={currentDayStartsAt} />)
    }
    acc.push(
      <FeedItem key={id} id={id} eventData={eventData} isRecipient={userId === recipientId} />
    )
    return acc
  }, [])

  return components
}

const DateSeparator = ({ date }) => (
  <div className="flex justify-center relative my-5">
    <div className="absolute border-b border-gold-300 top-3 w-full h-0"></div>
    <div className="z-0 rounded-full text-gray-500 bg-gold-100 border border-gold-300 h-6 px-6 flex items-center">
      <Overline.Small>
        <AbsoluteDate date={date} />
      </Overline.Small>
    </div>
  </div>
)

const MessageInput = ({ inputRef, locationId }) => {
  const { t } = useTranslation()
  const { mutate: sendMessage, isLoading } = useSendMessageMutation(locationId)
  const alert = useAlert()

  function onSubmit(e) {
    e.preventDefault()
    const text = inputRef.current.value
    sendMessage(text, {
      onSuccess() {
        inputRef.current.value = ""
      },
      onError() {
        alert.error(t("Message failed to send."))
      }
    })
  }

  return (
    <form className="p-4 bg-gold-900" onSubmit={onSubmit}>
      <div className="h-11 flex bg-white rounded overflow-hidden">
        <div className="flex-grow">
          <input
            ref={inputRef}
            type="text"
            placeholder={t("Type a message")}
            className="w-full h-full pl-3 text-gray-900 font-bold focus:outline-none"
          />
        </div>
        <button
          type="submit"
          className="flex-shrink-0 px-2.5 text-gold-900 transform hover:scale-110 transition duration-300 focus:outline-none"
          disabled={isLoading}
        >
          <ArrowRightIcon className="h-6 w-6" />
        </button>
      </div>
    </form>
  )
}

Page.path = "/member/inbox/:subdomain"
Page.pathFor = subdomain => Page.path.replace(":subdomain", subdomain)

export default Page
