import { IconButton } from "@sonato/core/components/Buttons"
import ErrorHeader from "@sonato/core/components/form/ErrorHeader"
import Image from "@sonato/core/components/Image"
import Overline from "@sonato/core/components/Overline"
import classNames from "classnames"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { ACCEPT_TYPES, uploadcareURL, userAvatarURL, useValidateImage } from "@sonato/core/images"
import { useUploadAvatarMutation } from "pages/member/header/hooks"

const CIRCLE_CLASSES = classNames(
  "text-gold-900 border border-gold-900 rounded-full w-34 h-34",
  "inline-block cursor-pointer flex items-center justify-center"
)

const AvatarImageField = ({ user, onChange }) => {
  const { t } = useTranslation()
  const [error, setError] = useState(null)
  const validate = useValidateImage()
  const uploadMutation = useUploadAvatarMutation(user)
  const { mutate, isLoading } = uploadMutation

  const hasImage = !user.avatar.isFallback
  const initialImageURL = hasImage ? userAvatarURL(user) : null
  const [imageURL, setImageURL] = useState(initialImageURL)

  const onFileSelected = e => {
    setError(null)

    const file = e.target.files[0]
    if (!file) {
      return
    }

    const error = validate(file)
    if (error) {
      setError(error)
      return
    }

    const onSuccess = uuid => {
      onChange()
      setImageURL(uploadcareURL(uuid))
    }

    const onError = () => {
      setError(t("Photo upload failed"))
    }

    mutate(file, { onSuccess, onError })
  }

  const fileInputRef = useRef()
  const openFilePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const showImage = imageURL && !isLoading
  const showUploadButton = !imageURL && !isLoading

  return (
    <div>
      <h3 className="mt-10">{t("Add a photo")}</h3>
      <p className="mt-5">
        {t("When you check in to the club, this photo will help the reception identify you.")}
      </p>

      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        accept={ACCEPT_TYPES}
        onChange={onFileSelected}
      />

      <div className="mt-5 flex justify-center">
        {showImage && <Avatar imageURL={imageURL} onClick={openFilePicker} />}
        {showUploadButton && <UploadButton onClick={openFilePicker} />}
        {isLoading && <Uploading />}
      </div>

      {error && (
        <div className="mt-3">
          <ErrorHeader titleText={t("We had the following problems:")}>{error}</ErrorHeader>
        </div>
      )}
    </div>
  )
}

const Avatar = ({ imageURL, onClick }) => (
  <div className="w-34 h-34 relative">
    <Image
      circular={true}
      className="h-full w-full"
      aspect="1:1"
      src={imageURL}
      alt="Selected avatar image"
    />
    <IconButton
      className="w-10 h-10 absolute bottom-0 right-0"
      icon="ActionsUpdateSimpleIcon"
      onClick={onClick}
    />
  </div>
)

const UploadButton = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <button className={CIRCLE_CLASSES} onClick={onClick}>
      <Overline.Medium>{t("Upload")}</Overline.Medium>
    </button>
  )
}

const Uploading = () => {
  const { t } = useTranslation()

  return (
    <div className={CIRCLE_CLASSES}>
      <div className="grid grid-flow-rows gap-1">
        <Overline.Medium className="text-center">{t("Uploading")}</Overline.Medium>
      </div>
    </div>
  )
}

export default AvatarImageField
