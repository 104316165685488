import { useApiCall } from "@sonato/core/client"
import { Button } from "@sonato/core/components/Buttons"
import CobrandedLogos from "@sonato/core/components/CobrandedLogos"
import Footer from "@sonato/core/components/Footer"
import Checkbox from "@sonato/core/components/form/Checkbox"
import { CallToAction, CallToActionLayout } from "@sonato/core/components/Layouts"
import Overline from "@sonato/core/components/Overline"
import { Form, useServerChangesetForm } from "@sonato/core/form"
import ResendEmailVerification from "@sonato/core/pages/authentication/ResendEmailVerification"
import SignIn from "@sonato/core/pages/authentication/SignIn"
import { authDomain, marketingUrl, termsUrl } from "@sonato/core/urls"
import i18next from "i18next"
import React, { useEffect, useMemo, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useLocation, useParams } from "react-router-dom"
import { locationImageURL } from "../../images"

const redirectToLogin = location => {
  window.location = SignIn.url(location.subdomain)
}

const needsConfirmation = ({ __schema__ }) => __schema__?.endsWith(".UnverifiedMemberSignup")

const AcceptTerms = ({ onChange, value }) => {
  const toggleTerms = hasAccepted => {
    onChange(hasAccepted)
  }

  return (
    <Checkbox
      onChange={toggleTerms}
      value={value}
      label={
        <Trans>
          <span className="inline-block ml-2 font-normal">
            I agree to the
            <a
              className="inline-block ml-1 text-gold-900"
              target="_blank"
              rel="noopener noreferrer"
              href={termsUrl()}
            >
              terms of service
            </a>
          </span>
        </Trans>
      }
    />
  )
}

function useSearchParams() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const SignupViaEmail = ({ location, onSignupReview, onSignupSuccess }) => {
  const { t } = useTranslation()
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false)

  // Initial value for the email address field can be given via query string
  const searchParams = useSearchParams()
  const defaultEmailAddress = searchParams.get("emailAddress") || ""

  const payload = {
    subdomain: location.subdomain,
    locale: i18next.language
  }

  const form = useServerChangesetForm("public/authentication", "signUp", payload, { ajax: true })
  const isLoaded = form.isLoaded()

  // This is a hacky way of setting an initial form value once, after form is
  // loaded. Forms don't support initial values and `form` cannot be used in
  // `useEffect` deps because it's not stable.
  useEffect(() => {
    if (isLoaded) {
      form.setValue("emailAddress", defaultEmailAddress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded])

  const signUp = () => {
    if (hasAcceptedTerms) {
      form.submit(({ reply }) => {
        if (needsConfirmation(reply)) {
          onSignupReview(reply)
        } else {
          onSignupSuccess(reply)
        }
      })
    }
  }

  return (
    <section>
      <h2 className="text-center">{t("Sign Up")}</h2>
      <Form value={form}>
        <fieldset className="flex flex-col space-y-3 mt-6">
          <Form.GlobalErrors />

          <Form.TextInput
            large={true}
            name="fullName"
            placeholder={t("Full Name")}
            largeFont={true}
          />
          <Form.TextInput
            large={true}
            name="emailAddress"
            placeholder={t("Email (The email you use at {{locationName}})", {
              locationName: location.name
            })}
            largeFont={true}
          />
          <Form.HiddenInput name="emailAddressConfirmation" />
          <Form.PasswordInput
            large={true}
            name="password"
            placeholder={t("Create Password")}
            largeFont={true}
          />
          <Form.PasswordInput
            large={true}
            name="passwordConfirmation"
            placeholder={t("Confirm Password")}
            largeFont={true}
          />
          {location.settings.hasMemberRef && (
            <Form.TextInput
              large={true}
              name="memberNumber"
              placeholder={t("{{locationName}} Member Number / I.D.", {
                locationName: location.name
              })}
              largeFont={true}
            />
          )}
        </fieldset>
        <fieldset className="flex flex-row items-center mt-6">
          <AcceptTerms value={hasAcceptedTerms} onChange={setHasAcceptedTerms} />
        </fieldset>
        <fieldset className="w-full mt-6">
          <Button disabled={!hasAcceptedTerms} className="w-full" tall={true} onClick={signUp}>
            {form.value("emailAddress").trim() !== "" &&
            form.value("emailAddressConfirmation") === form.value("emailAddress")
              ? t("I'm sure this information is correct")
              : t("Sign Up with Email")}
          </Button>
        </fieldset>
      </Form>
    </section>
  )
}

const ReviewQueue = ({ location }) => {
  const locationName = location.name
  return (
    <div className="space-y-6">
      <h2 className="text-center">
        <Trans>Thanks, almost done...</Trans>
      </h2>
      <p>
        <Trans>
          {{ locationName }} is confirming your membership. Once that is done, we will send you an
          email and you will be able to start using Sonato.
        </Trans>
      </p>
    </div>
  )
}

export const SignupFooter = () => {
  const { location } = useParams()
  return (
    <Footer>
      <Overline.Small>
        <a href={SignIn.url(location)}>
          <Trans>
            Already have an account? <strong className="text-gray-900">Login</strong>
          </Trans>
        </a>
      </Overline.Small>
    </Footer>
  )
}

const Signup = () => {
  const params = useParams()
  const subdomain = params.location
  const [createdUser, setCreatedUser] = useState()
  const [reviewSignup, setReviewSignup] = useState()

  const [location, error, isLoading] = useApiCall("public/locations", "current", { subdomain })

  if (isLoading) {
    return null
  } else if (error) {
    window.location = marketingUrl()
    return null
  }

  if (createdUser && createdUser.flags.emailAddressVerified) {
    redirectToLogin(location)
    return null
  }

  const imageUrl = locationImageURL(location, "landscape", 1200)
  const backgroundImage = `url("${imageUrl}")`

  return (
    <CallToActionLayout placeholderStyles={{ backgroundImage }}>
      <CallToAction footer={<SignupFooter />} header={<CobrandedLogos location={location} />}>
        {!createdUser && !reviewSignup && (
          <SignupViaEmail
            location={location}
            onSignupSuccess={setCreatedUser}
            onSignupReview={setReviewSignup}
          />
        )}
        {createdUser && !reviewSignup && <ResendEmailVerification location={location} />}
        {reviewSignup && <ReviewQueue location={location} />}
      </CallToAction>
    </CallToActionLayout>
  )
}

Signup.path = "/:location/sign-up"
Signup.url = subdomain => {
  const path = Signup.path.replace(":location", subdomain)
  return `https://${authDomain()}${path}`
}

export default Signup
