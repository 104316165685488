import React from "react"
import PropTypes from "prop-types"

const AppsGivingIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M15.525 3.385a4.377 4.377 0 00-6.953-1.14l-.347.316a.333.333 0 01-.45 0l-.326-.298a4.323 4.323 0 00-3.782-1.204A4.32 4.32 0 00.474 3.385a4.267 4.267 0 00.81 4.978L7.521 14.8a.666.666 0 00.957 0l6.228-6.427a4.275 4.275 0 00.818-4.988zM8.833 8.609a.333.333 0 00-.166.29V10.5a.667.667 0 11-1.333 0V8.898a.333.333 0 00-.167-.289 1.667 1.667 0 111.667 0z"
      fill="currentColor"
    />
  </svg>
)

AppsGivingIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsGivingIcon svgRef={ref} {...props} />)
export default ForwardRef
