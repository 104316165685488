import React from "react"
import PropTypes from "prop-types"

const AppsClubsMobileIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M13.25 1.876H8.875v-.583a.292.292 0 00-.355-.285L3.27 2.175a.292.292 0 00-.228.284v11.084a.292.292 0 00.228.285l5.25 1.166a.292.292 0 00.355-.284v-.584h4.375a.583.583 0 00.583-.583V2.459a.583.583 0 00-.583-.583zM7.417 8.293a.875.875 0 11-1.75 0 .875.875 0 011.75 0zm4.958 4.667H9.167a.292.292 0 01-.292-.292V3.334a.292.292 0 01.292-.291h3.208a.292.292 0 01.292.291v9.334a.292.292 0 01-.292.292z"
      fill="currentColor"
    />
  </svg>
)

AppsClubsMobileIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsClubsMobileIcon svgRef={ref} {...props} />)
export default ForwardRef
