import React from "react"
import PropTypes from "prop-types"

const EssentialsPeopleGuestIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="currentColor">
      <path d="M8.186 5.5c0 .663.275 1.299.766 1.768.49.469 1.155.732 1.848.732.694 0 1.359-.263 1.849-.732.49-.47.766-1.105.766-1.768s-.276-1.299-.766-1.768A2.676 2.676 0 0010.8 3c-.693 0-1.358.263-1.848.732A2.446 2.446 0 008.186 5.5zM9.32 9.096a.37.37 0 00-.134.098.352.352 0 00-.075.307.352.352 0 00.074.146 5.18 5.18 0 011.234 2.996v.018a.352.352 0 00.115.24c.07.064.161.1.257.1h4.56a.289.289 0 00.204-.085.268.268 0 00.075-.202 4.229 4.229 0 00-1.415-2.848 4.58 4.58 0 00-3.06-1.152c-.633 0-1.259.13-1.835.382zM9.228 12.915a.268.268 0 00.074-.2A4.233 4.233 0 007.928 9.89a4.582 4.582 0 00-3-1.194A4.598 4.598 0 001.875 9.76a4.251 4.251 0 00-1.503 2.764.413.413 0 00.112.334.447.447 0 00.334.142h8.204a.291.291 0 00.206-.085zM1.86 5.5c0 .663.276 1.299.766 1.768.49.469 1.155.732 1.849.732.693 0 1.358-.263 1.848-.732.49-.47.766-1.105.766-1.768s-.275-1.299-.766-1.768A2.676 2.676 0 004.475 3c-.694 0-1.359.263-1.849.732A2.446 2.446 0 001.86 5.5z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" transform="translate(0 3)" d="M0 0h16v10H0z" />
      </clipPath>
    </defs>
  </svg>
)

EssentialsPeopleGuestIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsPeopleGuestIcon svgRef={ref} {...props} />
))
export default ForwardRef
