import React from "react"
import PropTypes from "prop-types"

const ChevronUpIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M8.012 5c.136 0 .27.028.395.081a.957.957 0 01.325.23l4.088 4.452a.717.717 0 01-.081 1.028.79.79 0 01-1.074-.054L8.09 6.844a.104.104 0 00-.078-.034.108.108 0 00-.079.034L4.36 10.737a.765.765 0 01-.531.26.801.801 0 01-.567-.18.713.713 0 01-.058-1.053L7.29 5.312a.96.96 0 01.326-.23.998.998 0 01.396-.083z"
      fill="currentColor"
    />
  </svg>
)

ChevronUpIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ChevronUpIcon svgRef={ref} {...props} />)
export default ForwardRef
