import React from "react"
import PropTypes from "prop-types"

const ActionsSendIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.385 3.424L8.267 8.542l1.23 5.11a.292.292 0 00.554.007l3.334-10.235zM7.455 7.73l5.113-5.113L2.345 5.952a.292.292 0 00.007.555l5.103 1.224zm6.768-6.703a1.011 1.011 0 00-.548.022L1.987 4.862l-.005.002a1.44 1.44 0 00.089 2.756l5.087 1.22 1.226 5.093a1.44 1.44 0 002.757.088v-.004l3.81-11.695V2.32a1.012 1.012 0 00-.728-1.291z"
      fill="currentColor"
    />
  </svg>
)

ActionsSendIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ActionsSendIcon svgRef={ref} {...props} />)
export default ForwardRef
