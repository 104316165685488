import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import Overline from "@sonato/core/components/Overline"
import { ACCEPT_TYPES, userAvatarURL } from "@sonato/core/images"
import { noOp } from "@sonato/core/utils"

export default function EditableAvatar({
  user,
  uploadMutation,
  width = 400,
  height = 400,
  enabled = true,
  onSuccess = noOp
}) {
  const [result, setResult] = useState(null)
  const { mutate, isLoading } = uploadMutation

  const onFileSelected = e => {
    const file = e.target.files[0]

    const handleSuccess = () => {
      setResult(true)
      setTimeout(() => setResult(null), 5000)
      onSuccess()
    }

    const handleError = () => {
      setResult(false)
      setTimeout(() => setResult(null), 5000)
    }

    mutate(file, { onSuccess: handleSuccess, onError: handleError })
  }

  const fileInputRef = useRef()
  const openFilePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const avatarURL = userAvatarURL(user, width, height)

  return (
    <>
      <img className="w-full h-auto rounded" src={avatarURL} alt={user.fullName} />

      {enabled && (
        <>
          <input
            ref={fileInputRef}
            type="file"
            className="hidden"
            accept={ACCEPT_TYPES}
            onChange={onFileSelected}
          />

          <div className="mt-3">
            <EditButton onClick={openFilePicker} isLoading={isLoading} result={result} />
          </div>
        </>
      )}
    </>
  )
}

function EditButton({ onClick, isLoading, result }) {
  const { t } = useTranslation()

  if (isLoading) {
    return <Overline.Small className="text-gold-900">{t("Uploading...")}</Overline.Small>
  }

  if (result === true) {
    return <Overline.Small className="text-green-900">{t("Photo changed")}</Overline.Small>
  }

  if (result === false) {
    return (
      <div className="text-red-900">
        <Overline.Small>{t("Upload failed")}</Overline.Small>
        <Overline.Small>{t("Please contact support")}</Overline.Small>
      </div>
    )
  }

  return (
    <button className="text-gold-900" onClick={onClick}>
      <Overline.Small>{t("Edit Photo")}</Overline.Small>
    </button>
  )
}
