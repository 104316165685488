import React, { useRef, useState } from "react"
import { format, parse } from "date-fns"
import { useTranslation } from "react-i18next"

import Checkbox from "@sonato/core/components/form/Checkbox"
import TextInput from "@sonato/core/components/form/TextInput"
import { useFormatters } from "@sonato/core/format"

/**
 * Adds seconds to a time string. Value read from input[type=time] is hh:mm and
 * it needs to be converted to hh:mm:ss before sending it on.
 */
const addSeconds = timeString => `${timeString}:00`

/**
 * Formats a time value either for display (using the user's locale) or for
 * usage in an input[type=time] which expects `hh:mm` format.
 */
const formatTime = (date, display, Format) =>
  display ? Format.shortTime(date) : format(date, "HH:mm")

const TimeRange = ({ label, value, error, display, onChange }) => {
  const { t } = useTranslation()
  const Format = useFormatters()
  const [prevValue, setPrevValue] = useState({ start: "10:00:00", stop: "22:00:00" })

  const parsedStart = value && parse(value.start, "HH:mm:ss", new Date())
  const parsedStop = value && parse(value.stop, "HH:mm:ss", new Date())

  const start = parsedStart ? formatTime(parsedStart, display, Format) : ""
  const stop = parsedStop ? formatTime(parsedStop, display, Format) : ""

  const startRef = useRef()
  const stopRef = useRef()

  const currentValue = () => ({
    start: addSeconds(startRef.current.value),
    stop: addSeconds(stopRef.current.value)
  })

  const handleChangeEnabled = isChecked => {
    if (isChecked) {
      onChange(prevValue)
    } else {
      setPrevValue(currentValue())
      onChange(null)
    }
  }

  const handleChangeStart = value => {
    onChange({ ...currentValue(), start: addSeconds(value) })
  }

  const handleChangeStop = value => {
    onChange({ ...currentValue(), stop: addSeconds(value) })
  }

  return (
    <div className="grid grid-cols-3 gap-3 items-center">
      {display ? (
        <div className="font-bold ml-4">{label}</div>
      ) : (
        <Checkbox label={label} value={!!value} onChange={handleChangeEnabled} />
      )}
      <TextInput
        large
        type="time"
        name="start"
        value={start}
        label={t("From")}
        disabled={!value}
        ref={startRef}
        onChange={handleChangeStart}
        display={display}
        error={error}
      />
      <TextInput
        large
        type="time"
        name="stop"
        value={stop}
        label={t("To")}
        disabled={!value}
        ref={stopRef}
        onChange={handleChangeStop}
        display={display}
        error={error}
      />
    </div>
  )
}

export default TimeRange
