import classNames from "classnames"
import PropTypes from "prop-types"
import * as React from "react"
import { useTranslation } from "react-i18next"
import ColorMode from "./ColorMode"
import { uploadcareImageShape } from "../shapes"
import sonatoLogoDark from "../static/images/logos/sonato.svg"
import sonatoLogoLight from "../static/images/logos/sonato-white.svg"

export const SonatoOnlyLogo = ({ colorMode }) => {
  const { t } = useTranslation()
  const sonatoLogo = colorMode === ColorMode.dark ? sonatoLogoLight : sonatoLogoDark
  return (
    <div className="flex-grow flex justify-center">
      <img className="w-32 sm:w-40" alt={t("Sonato Alliance")} src={sonatoLogo} />
    </div>
  )
}

const CobrandedLogos = ({ colorMode = ColorMode.light, location }) => {
  const { t } = useTranslation()

  if (!location) {
    return <SonatoOnlyLogo colorMode={colorMode} />
  }

  const mode =
    colorMode === ColorMode.dark
      ? {
          locationLogo: location.uploadcareImages.logoFullLight.url,
          sonatoLogo: sonatoLogoLight,
          separatorClasses: "border-white/20"
        }
      : {
          locationLogo: location.uploadcareImages.logoFullDark.url,
          sonatoLogo: sonatoLogoDark,
          separatorClasses: "border-gold-300"
        }

  return (
    <div className="flex-grow flex max-w-lg">
      <div className="flex items-center">
        <img className="max-h-14 sm:max-h-18" alt={location.name} src={mode.locationLogo} />
      </div>
      <div
        className={classNames(
          "flex-none flex items-center h-14 ml-6 pl-6 sm:h-18 sm:ml-8 sm:pl-8 border-l",
          mode.separatorClasses
        )}
      >
        <img className="w-32 sm:w-40" alt={t("Sonato Alliance")} src={mode.sonatoLogo} />
      </div>
    </div>
  )
}
CobrandedLogos.propTypes = {
  colorMode: PropTypes.oneOf(Object.values(ColorMode)),
  // Sanitized location object, does not contain all fields defined in locationShape
  location: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uploadcareImages: PropTypes.shape({
      logoFullDark: uploadcareImageShape.isRequired,
      logoFullLight: uploadcareImageShape.isRequired
    }).isRequired
  })
}

export default CobrandedLogos
