import React from "react"
import PropTypes from "prop-types"

const ActionsDuplicateIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.042 2.28a.25.25 0 01.172-.069h4.54a.25.25 0 01.173.07l1.709 1.631c.048.046.076.11.076.178v6.042a.246.246 0 01-.249.247h-1.14V7.5c0-.383-.16-.753-.447-1.027L9.166 4.84a1.58 1.58 0 00-1.089-.43H5.965V2.458c0-.068.029-.132.077-.178zM4.678 4.41V2.458c0-.384.16-.754.446-1.028A1.58 1.58 0 016.214 1h4.54c.411 0 .803.156 1.09.43l1.71 1.633c.286.274.446.643.446 1.027v6.042c0 .383-.16.753-.447 1.027a1.58 1.58 0 01-1.09.43h-1.14v1.953c0 .384-.16.754-.447 1.028-.288.274-.68.43-1.09.43h-6.25c-.41 0-.802-.156-1.09-.43A1.421 1.421 0 012 13.542V5.869c0-.384.16-.754.447-1.028a1.58 1.58 0 011.09-.43h1.14zm5.357 6.55a.641.641 0 000 .048v2.534a.246.246 0 01-.25.247H3.538a.25.25 0 01-.25-.247V5.869a.246.246 0 01.25-.248h4.54a.25.25 0 01.172.07l1.71 1.632c.047.045.076.11.076.178v3.459z"
      fill="currentColor"
    />
  </svg>
)

ActionsDuplicateIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsDuplicateIcon svgRef={ref} {...props} />
))
export default ForwardRef
