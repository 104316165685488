const rule = (type, args, message) => ({ type, args, message })

const format = (format, message) => rule("format", format, message)
const required = () => rule("required", [])
const number = opts => rule("number", opts)
const excludes = exclusions => rule("exclusion", exclusions)
const isOneOf = includes => rule("inclusion", includes)
const length = opts => rule("length", opts)
const confirmation = () => rule("confirmation", [])

const NUMBER_OPERATORS = ["gt", "ge", "lt", "le", "eq", "ne"]

const LENGTH_OPERATORS = ["min", "max", "is"]
const parseOptsFormat = (opts, validOpts) => {
  return Object.entries(opts).filter(([op]) => validOpts.includes(op))
}

const definitionToData = ({ type, args }) => {
  switch (type) {
    case "format":
      return ["format", [args.toString()]]

    case "number":
      return ["number", parseOptsFormat(args, NUMBER_OPERATORS)]

    case "length":
      return ["length", parseOptsFormat(args, LENGTH_OPERATORS)]

    case "exclusion":
    case "inclusion":
      return [type, args]

    case "confirmation":
      return ["confirmation"]

    default:
      return null
  }
}

const toValidationSchema = opts => {
  const requiredFields = Object.entries(opts).reduce((acc, [fieldName, rules]) => {
    if (rules.find(item => item.type === "required")) {
      acc.push(fieldName)
    }
    return acc
  }, [])

  const fieldRules = Object.entries(opts).reduce((fieldRules, [fieldName, ruleDefinitions]) => {
    fieldRules[fieldName] = ruleDefinitions
      .map(definition => definitionToData(definition))
      .filter(rule => rule !== null)
    return fieldRules
  }, {})

  return { required: requiredFields, ...fieldRules }
}

export { confirmation, toValidationSchema, excludes, isOneOf, format, length, number, required }
