import React, { Suspense } from "react"
import Loading from "@sonato/core/components/Loading"

const withSuspense = (Component, fallback = <Loading />) => props => (
  <Suspense fallback={fallback}>
    <Component {...props} />
  </Suspense>
)

export default withSuspense
