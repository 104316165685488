import React from "react"
import { Button } from "@sonato/core/components/Buttons"
import { useFormatters } from "@sonato/core/format"

const BookRoomButton = ({ location, onClick }) => {
  const format = useFormatters()

  if (!location.profile.visitRequestsEnabled) {
    return (
      <div className="inline-block bg-gold-900 text-white px-5 py-3 font-bold rounded mt-3 opacity-80">
        Available {format.longDate(location.profile.visitRequestsEnabledAt)}
      </div>
    )
  }

  return (
    <Button className="mt-3 px-10 hidden md:block" tall={true} onClick={onClick}>
      Book Room
    </Button>
  )
}

export default BookRoomButton
