import LocationMap from "@sonato/core/components/LocationMap"
import Overline from "@sonato/core/components/Overline"
import classNames from "classnames"
import Loader from "components/loader"
import React, { Suspense, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useAcknowledgeVisitRequestsMutation } from "../header/hooks"
import { useLocationMap, useVisitsStats } from "./hooks"
import PastVisitsSection from "./PastVisitsSection"
import UpcomingVisitsSection from "./UpcomingVisitsSection"

export default function VisitsPage() {
  const { t } = useTranslation()
  const { mutate: acknowledgeVisits } = useAcknowledgeVisitRequestsMutation()

  useEffect(() => {
    acknowledgeVisits()
  }, [acknowledgeVisits])

  return (
    <section className="mt-9 px-6 overflow-x-hidden">
      <h1 className="md:text-center">{t("Visits")}</h1>
      <Suspense fallback={<Loader />}>
        <UpcomingVisitsSection />
      </Suspense>
      <hr className="border-gold-300 mt-15 max-w-4xl mx-auto" />
      <Suspense fallback={<Loader />}>
        <StatsSection />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <LocationMapSection />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <PastVisitsSection />
      </Suspense>
    </section>
  )
}

function StatsSection() {
  const { t } = useTranslation()
  const { data: stats } = useVisitsStats()

  return (
    <div className="mt-10 grid md:grid-flow-col gap-8 md:gap-20 justify-center">
      <Stat number={stats.partnerCount} caption={t("Clubs in your network")} />
      <Stat number={stats.visitedCount} caption={t("Sonato clubs you’ve visited")} />
      <Stat number={stats.favoritedCount} caption={t("Clubs you’ve favorited")} />
    </div>
  )
}

const Stat = ({ number, caption }) => (
  <div className="flex">
    <h1 className="text-gold-900">{number}</h1>
    <Overline.Medium className="text-gray-500 font-bold ml-5 w-30 flex items-center">
      {caption}
    </Overline.Medium>
  </div>
)

function LocationMapSection() {
  const { data: map } = useLocationMap()

  const aspectRatioClasses = classNames(
    "aspect-w-5 aspect-h-4",
    "md:aspect-w-3 md:aspect-h-2",
    "lg:aspect-w-2 lg:aspect-h-1"
  )

  return (
    <div className="mt-8 max-w-7xl mx-auto">
      <LocationMap
        center={{ lat: 30, lng: 0 }}
        zoom={2}
        mapContainerClassName={aspectRatioClasses}
        locations={map.partnerLocations}
        highlightedLocationIds={map.visitedLocationIds}
        onLoad={m => {
          if (map.mapBounds) {
            m.fitBounds(map.mapBounds)
          }
        }}
      />
    </div>
  )
}
