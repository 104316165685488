import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { KEY_CODES } from "@sonato/core/enums"

const BaseRadio = ({ checked, disabled }) => {
  const ringClasses = classNames(
    "w-6 h-6 min-w-6 flex items-center justify-center",
    "border-2 rounded-full bg-gold-100 transition duration-300",
    checked ? "border-gold-900" : "border-gray-500/40",
    !disabled && !checked && "transform group-hover:scale-105 group-active:scale-100"
  )
  const dotClasses = classNames(
    "w-4 h-4 bg-gold-900 rounded-full transition duration-300",
    checked ? "opacity-100" : "opacity-0"
  )
  return (
    <div className={ringClasses}>
      <div className={dotClasses} />
    </div>
  )
}

BaseRadio.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired
}

const RadioButton = ({
  name,
  label,
  text = null,
  value = label,
  checked = false,
  disabled = false,
  onChange = () => {},
  ...rest
}) => {
  const handleChange = () => onChange(value)

  const handleKeyUp = e => {
    if (!disabled && e.keyCode === KEY_CODES.space) {
      handleChange()
    }
  }

  const labelClasses = classNames(
    "group flex items-start outline-none",
    disabled ? "opacity-40 cursor-not-allowed" : "cursor-pointer"
  )
  return (
    <label tabIndex="0" className={labelClasses} onKeyUp={handleKeyUp}>
      <input
        type="radio"
        className="hidden"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />

      <BaseRadio checked={checked} disabled={disabled} {...rest} />
      {(label || text) && (
        <div className="flex flex-col ml-3">
          {label && <span className="font-bold">{label}</span>}
          {text && <span className="mt-1">{text}</span>}
        </div>
      )}
    </label>
  )
}

RadioButton.propTypes = {
  /** Name of input. */
  name: PropTypes.string,
  /** Visible label. */
  label: PropTypes.string,
  /** Additional text to render under the label. */
  text: PropTypes.string,
  /** Value (defaults to `label`). */
  value: PropTypes.string,
  /** Checked state. Defaults to false. */
  checked: PropTypes.bool,
  /** Disabled state. Defaults to false. */
  disabled: PropTypes.bool,
  /** Invokes onChange handler passing new `checked` state and `value`. */
  onChange: PropTypes.func
}

export default RadioButton
