import React, { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"
import { Transition } from "@headlessui/react"

const Loading = ({ text = null }) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setVisible(true)
    }, 250)
  }, [setVisible])

  text = text || t("Loading...")
  return (
    <Transition
      show={visible}
      enter="transition ease-in-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in-out duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <h3>{text}</h3>
    </Transition>
  )
}
export default Loading
