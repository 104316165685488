import React from "react"
import PropTypes from "prop-types"

const EssentialsGenericImageIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M3.519 4.371A1.875 1.875 0 116.17 7.023a1.875 1.875 0 01-2.652-2.652zm1.326.701a.625.625 0 100 1.25.625.625 0 000-1.25zM9.667 6.22a1.188 1.188 0 011.53.397l2.915 4.37a.625.625 0 11-1.04.694l-2.864-4.294-1.444 2.068a.625.625 0 01-.904.13l-.872-.702-1.872 2.81A.625.625 0 114.076 11l1.91-2.866a1.19 1.19 0 011.73-.27l.406.327 1.114-1.595a1.19 1.19 0 01.43-.376z" />
      <path d="M12.703 1.42a1.625 1.625 0 011.163.49l1.922 1.97c.296.304.462.711.462 1.135v8a1.625 1.625 0 01-1.625 1.625h-13A1.625 1.625 0 010 13.015v-9.97a1.625 1.625 0 011.625-1.624h11.078zm.146 1.28a.375.375 0 00-.146-.03H1.625a.375.375 0 00-.375.376v9.97a.375.375 0 00.375.374h13a.375.375 0 00.375-.375v-8a.375.375 0 00-.106-.261m0 0l-1.922-1.97a.374.374 0 00-.123-.084" />
    </g>
  </svg>
)

EssentialsGenericImageIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsGenericImageIcon svgRef={ref} {...props} />
))
export default ForwardRef
