import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Icon from "@sonato/core/icons/icon"
import InputWrapper from "./InputWrapper"
import Label from "./Label"
import TextInput from "./TextInput"
import { useDocumentKeyDown, useDocumentMouseDown } from "@sonato/core/hooks/document"

const scalarValuePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.object
])

const containerPropType = PropTypes.oneOfType([
  scalarValuePropType,
  PropTypes.arrayOf(scalarValuePropType)
])

const optionPropShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: scalarValuePropType
})

const Option = ({ label, value, selected, onChange, onHover, isFocused }) => {
  const classes = classNames("flex px-5 font-bold z-50 h-12 flex items-center", {
    "bg-gold-100": isFocused
  })

  return (
    <li className={classes} onClick={() => onChange(value)} onMouseEnter={onHover}>
      <div className="flex-grow z-50">{label}</div>
      {selected && <Icon name="ActionsCheckIcon" />}
    </li>
  )
}

Option.propTypes = {
  label: PropTypes.string,
  value: containerPropType,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  onHover: PropTypes.func,
  isFocused: PropTypes.bool
}

const OptionList = ({
  options,
  values,
  multiple,
  onChange,
  onHover,
  focusedOptionIndex,
  maxVisibleOptions,
  optionListRef
}) => {
  const handleSelect = newValue => {
    if (!multiple) {
      onChange(newValue)
      return
    }

    if (values.includes(newValue)) {
      // remove from multi
      onChange(values.filter(v => v !== newValue))
    } else {
      // add to multi, order
      const selectedOptions = options.filter(
        ({ value }) => value === newValue || values.includes(value)
      )
      onChange(selectedOptions.map(({ value }) => value))
    }
  }

  const wrapperClasses = classNames(
    "absolute z-50 bg-white divide-y divide-gold-300 rounded cursor-pointer",
    {
      "h-64 overflow-y-auto": options.length >= 5
    }
  )

  const optionHeight = 48
  const containerHeight = Math.min(options.length, maxVisibleOptions) * optionHeight

  return (
    <ul
      ref={optionListRef}
      className={wrapperClasses}
      style={{
        height: containerHeight,
        top: "90%",
        left: "0.75rem",
        right: "0.75rem",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)"
      }}
    >
      {options.map(({ label, value }, index) => (
        <Option
          key={index}
          value={value}
          selected={values.includes(value)}
          label={label}
          onChange={handleSelect}
          onHover={() => onHover(index)}
          isFocused={index === focusedOptionIndex}
        />
      ))}
    </ul>
  )
}

OptionList.propTypes = {
  options: PropTypes.arrayOf(optionPropShape).isRequired,
  values: PropTypes.arrayOf(scalarValuePropType),
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onHover: PropTypes.func,
  focusedOptionIndex: PropTypes.number,
  maxVisibleOptions: PropTypes.number,
  optionListRef: PropTypes.any
}

const Options = ({
  isOpen,
  setOpen,
  options,
  multiple,
  maxVisibleOptions,
  selectedValues,
  onChange,
  focused
}) => {
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null)
  const [filterText, setFilterText] = useState("")
  const ref = useRef()
  const optionListRef = useRef()

  if (!isOpen && filterText.length > 0) {
    setFilterText("")
  }

  const scrollOptionIntoView = index => {
    const option = optionListRef?.current?.children[index]
    if (option) {
      option.scrollIntoView({ block: "nearest" })
    }
  }

  // Scroll options list into view when opened
  useEffect(() => {
    if (isOpen) {
      optionListRef.current.scrollIntoView({ block: "nearest", behavior: "smooth" })
    }
  }, [isOpen])

  const minIndex = 0
  const maxIndex = options.length - 1
  const changeFocusedIndex = delta => {
    setOpen(true)

    if (isOpen) {
      const nextIndex =
        focusedOptionIndex === null
          ? 0
          : Math.min(Math.max(focusedOptionIndex + delta, minIndex), maxIndex)

      scrollOptionIntoView(nextIndex)
      setFocusedOptionIndex(nextIndex)
    }
  }

  if (isOpen) {
    if (filterText.length > 0) {
      const scrolledOptionIndex = options.findIndex(o =>
        o.label.toLowerCase().startsWith(filterText)
      )
      scrollOptionIntoView(scrolledOptionIndex)
    }
  }

  // Close when Escape is pressed
  useDocumentKeyDown(e => {
    if (e.code === "Escape") {
      setOpen(false)
    }
    handleKeyDown(e)
  })

  // Close when mouse is clicks outside the component
  useDocumentMouseDown(e => {
    if (ref.current && !ref.current.contains(e.target)) {
      setOpen(false)
    }
  })

  // Toggle when Space is pressed while component is focused
  const handleKeyDown = e => {
    if (!focused) {
      return
    }

    // Space
    if (e.key === "Space") {
      e.preventDefault()
      setOpen(false)
    }

    if (e.key === "ArrowUp") {
      e.preventDefault()
      changeFocusedIndex(-1)
    }

    if (e.key === "ArrowDown") {
      e.preventDefault()
      changeFocusedIndex(1)
    }

    if (e.key === "PageUp") {
      e.preventDefault()
      changeFocusedIndex(-5)
    }

    if (e.key === "PageDown") {
      e.preventDefault()
      changeFocusedIndex(5)
    }

    if (e.key === "Enter" && isOpen && focusedOptionIndex !== null) {
      e.preventDefault()
      setOpen(false)
      onChange(options[focusedOptionIndex].value)
    }

    if (e.key === "Enter" && !isOpen) {
      e.preventDefault()
      setOpen(true)
      const optionIndex = options.findIndex(o => selectedValues.includes(o.value))
      setFocusedOptionIndex(optionIndex === -1 ? 0 : optionIndex)
    }

    if (e.key === "Escape" || e.key === "Backspace") {
      setFilterText("")
    }

    if (e.key.match(/^[a-zA-Z0-9_/ ]$/)) {
      setOpen(true)
      setFilterText(oldText => oldText + e.key.toLowerCase())
    }
  }

  return (
    <div onKeyDown={handleKeyDown} ref={ref}>
      {isOpen && (
        <OptionList
          multiple={multiple}
          options={options}
          values={selectedValues}
          onChange={onChange}
          onHover={setFocusedOptionIndex}
          optionListRef={optionListRef}
          focusedOptionIndex={focusedOptionIndex}
          maxVisibleOptions={maxVisibleOptions}
        />
      )}
    </div>
  )
}

const getSelectedValues = (value, multiple) => {
  if (value === null || value === undefined) {
    return []
  }

  if (multiple) {
    return value
  }

  return [value]
}

const SelectInput = ({
  multiple,
  value,
  error,
  label,
  options,
  icon,
  compact,
  disabled = false,
  placeholder,
  display = false,
  onChange = () => {},
  maxVisibleOptions = 5
}) => {
  const ref = useRef()
  const [isOpen, setOpen] = useState(false)
  const [focused, setFocused] = useState(false)
  const selectedValues = getSelectedValues(value, multiple)

  const toggleOpen = () => {
    if (!disabled && !display) {
      setOpen(!isOpen)
    }
  }

  // Close when Escape is pressed
  useDocumentKeyDown(e => {
    if (e.code === "Escape") {
      setOpen(false)
    }
  })

  // Close when mouse is clicks outside the component
  useDocumentMouseDown(e => {
    if (ref.current && !ref.current.contains(e.target)) {
      setOpen(false)
    }
  })

  const handleOnFocus = () => {
    setFocused(true)
  }

  const handleOnBlur = () => {
    setFocused(false)
    setOpen(false)
  }

  const classes = classNames(
    "flex flex-col justify-center px-3 relative select-none focus:outline-none",
    compact ? "h-11" : "h-16",
    {
      "cursor-pointer": !disabled
    }
  )

  const selectedLabels = options
    .filter(({ _label, value }) => selectedValues.includes(value))
    .map(({ label, _value }) => label)
    .join(", ")

  const showLabel = !compact && label

  if (display) {
    return (
      <TextInput
        label={label}
        large={!compact}
        value={selectedLabels}
        display
        onChange={() => {}}
      />
    )
  }

  return (
    <InputWrapper error={error} disabled={disabled} display={display}>
      <div
        className={classes}
        onBlur={handleOnBlur}
        onClick={toggleOpen}
        onFocus={handleOnFocus}
        ref={ref}
        tabIndex="0"
      >
        {showLabel && <Label text={label} error={error} />}
        <div className={classNames("flex", { "mt-1": showLabel })}>
          <div className="flex-grow">
            {selectedLabels && <span className="font-bold">{selectedLabels}</span>}
            {!selectedLabels && <span className="text-gray-500 opacity-40">{placeholder}</span>}
          </div>
          {icon && (
            <div className="h-4 w-4 ml-3">
              <Icon name={icon} />
            </div>
          )}
          <div className="h-4 w-4 ml-3">
            <Icon name="ChevronDownIcon" />
          </div>
        </div>
        <Options
          isOpen={isOpen}
          setOpen={setOpen}
          options={options}
          multiple={multiple}
          maxVisibleOptions={maxVisibleOptions}
          selectedValues={selectedValues}
          onChange={onChange}
          focused={focused}
        />
      </div>
    </InputWrapper>
  )
}

SelectInput.propTypes = {
  /** Multiple select. */
  multiple: PropTypes.bool,
  /** Currently selected value (or array of values if multiple). */
  value: containerPropType,
  /** Error message shown under the input. */
  error: PropTypes.string,
  /** Input label, shown on non-compact variant. */
  label: PropTypes.string,
  /** OptionList as a map of value to label.  */
  options: PropTypes.arrayOf(optionPropShape).isRequired,
  /** Name of the icon to show next to the downward chevron. */
  icon: PropTypes.string,
  /** Set to true to render a compact input, or false to render a medium input. */
  compact: PropTypes.bool,
  /** Display-only mode. */
  display: PropTypes.bool,
  /** Whether to disable the input. */
  disabled: PropTypes.bool,
  /** Placeholder text to show if no value is selected. */
  placeholder: PropTypes.any,
  /** Called when an option is selected, the selected value is passed as a parameter. */
  onChange: PropTypes.any,
  /** Number of options to display in the dropdown list before requiring a scroll bar. */
  maxVisibleOptions: PropTypes.number
}

export { Options }
export default SelectInput
