import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Trans } from "react-i18next"

import { ReactComponent as AppStoreLight } from "assets/buttons/appStoreLight.svg"
import { ReactComponent as GooglePlayLight } from "assets/buttons/googlePlayLight.svg"
import { ReactComponent as AppStoreDark } from "assets/buttons/appStoreDark.svg"
import { ReactComponent as GooglePlayDark } from "assets/buttons/googlePlayDark.svg"

const STYLE_DARK = "dark"
const STYLE_LIGHT = "light"
const STYLE_CLEAR = "clear"

const defaultShowAppStore = !/android/i.test(navigator.platform)
const defaultShowGooglePlay = !/iphone|ipod/i.test(navigator.platform)

export const AppDownloadButtons = ({
  buttonStyle,
  buttonWidth = "w-28",
  showAppStore = defaultShowAppStore,
  showGooglePlay = defaultShowGooglePlay
}) => {
  return (
    <>
      {showAppStore && (
        <ExternalButton
          buttonStyle={buttonStyle}
          to="https://apps.apple.com/us/app/sonato-alliance/id1553756187"
        >
          {buttonStyle === STYLE_LIGHT && <AppStoreLight className={buttonWidth} />}
          {buttonStyle === STYLE_DARK && <AppStoreDark className={buttonWidth} />}
          {buttonStyle === STYLE_CLEAR && <AppStoreDark className={buttonWidth} />}
          <span className="sr-only">
            <Trans>Download on the App Store</Trans>
          </span>
        </ExternalButton>
      )}

      {showGooglePlay && (
        <ExternalButton
          buttonStyle={buttonStyle}
          to="https://play.google.com/store/apps/details?id=com.sonato.members"
        >
          {buttonStyle === STYLE_LIGHT && <GooglePlayLight className={buttonWidth} />}
          {buttonStyle === STYLE_DARK && <GooglePlayDark className={buttonWidth} />}
          {buttonStyle === STYLE_CLEAR && <GooglePlayDark className={buttonWidth} />}
          <span className="sr-only">
            <Trans>Get it on Google Play</Trans>
          </span>
        </ExternalButton>
      )}
    </>
  )
}

AppDownloadButtons.propTypes = {
  buttonStyle: PropTypes.oneOf([STYLE_LIGHT, STYLE_DARK, STYLE_CLEAR]).isRequired,
  buttonWidth: PropTypes.string,
  showAppStore: PropTypes.bool,
  showGooglePlay: PropTypes.bool
}

const ExternalButton = ({ children, buttonStyle, to }) => {
  const classes = classNames(
    "block px-3 py-2 rounded shadow",
    "transform active:scale-100 hover:scale-102 transition ease-in-out duration-300",
    {
      "text-white bg-gray-900": buttonStyle === STYLE_DARK,
      "text-gray-900 bg-white": buttonStyle === STYLE_LIGHT,
      "text-white border border-white": buttonStyle === STYLE_CLEAR
    }
  )

  return (
    <a className={classes} href={to} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  )
}

export default AppDownloadButtons
