import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const ASPECT_RATIO_CLASSES = {
  "1:1": "aspect-w-1 aspect-h-1",
  "2:3": "aspect-w-2 aspect-h-3",
  "3:2": "aspect-w-3 aspect-h-2",
  "4:5": "aspect-w-4 aspect-h-5",
  "5:4": "aspect-w-5 aspect-h-4",
  "21:8": "aspect-w-21 aspect-h-8"
}

const ASPECT_RATIOS = Object.keys(ASPECT_RATIO_CLASSES)
ASPECT_RATIOS.push("original")

const aspectShape = PropTypes.oneOf(ASPECT_RATIOS)

/**
 * The image component constrains its source image to the given aspect ratio without distortion.
 */
const Image = ({ src, alt, aspect = "1:1", circular = false, className, style, children }) => {
  const rounded = circular ? "rounded-full" : "rounded"
  const paddingBottom = ASPECT_RATIO_CLASSES[aspect]

  if (aspect === "original") {
    return (
      <div className={classNames("overflow-hidden relative", rounded, className)} style={style}>
        <img className="w-full h-auto" src={src} alt={alt} />
        {children && <div className="absolute inset-0">{children}</div>}
      </div>
    )
  }

  return (
    <div className={classNames("overflow-hidden", rounded, className)} style={style}>
      <div className={classNames("relative", paddingBottom)}>
        <img className="absolute object-cover w-full h-full" src={src} alt={alt} />
        {children && <div className="absolute inset-0">{children}</div>}
      </div>
    </div>
  )
}

Image.propTypes = {
  // required

  /** Image source. */
  src: PropTypes.string.isRequired,
  /** Alternate text for image. May be an empty string, depending on context. */
  alt: PropTypes.string.isRequired,

  // optional

  /** The desired aspect ratio: `1:1`, `2:3`, `3:2`, `4:5`, `21:8`, or `default` to preserve the original aspect ratio. Defaults to `1:1`. */
  aspect: aspectShape,
  /** Circular/elliptic if true, otherwise a rounded rectangle. Defaults to false. */
  circular: PropTypes.bool,
  /** Optional classes. */
  className: PropTypes.string,
  /** Optional styling. */
  style: PropTypes.object,
  /** Optional children (overlayed on top of image). */
  children: PropTypes.node
}

export default Image
export { ASPECT_RATIOS, aspectShape }
