import React from "react"
import PropTypes from "prop-types"

const AppsClubsIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.09 0a1.212 1.212 0 100 2.424A1.212 1.212 0 005.09 0zm5.82 0a1.212 1.212 0 10-.001 2.424 1.212 1.212 0 000-2.424zM6.383 3.11a1.212 1.212 0 100 2.424 1.212 1.212 0 000-2.424zm2.909 0a1.212 1.212 0 100 2.424 1.212 1.212 0 000-2.424zm-8.08.769a1.212 1.212 0 100 2.424 1.212 1.212 0 000-2.424zm13.575 0a1.212 1.212 0 100 2.424 1.212 1.212 0 000-2.424zM4.283 5.21a1.212 1.212 0 100 2.424 1.212 1.212 0 000-2.424zm7.11 0a1.212 1.212 0 10.001 2.424 1.212 1.212 0 000-2.424zM4.284 8.12a1.212 1.212 0 100 2.424 1.212 1.212 0 000-2.424zm7.11 0a1.212 1.212 0 10.001 2.424 1.212 1.212 0 000-2.424zM1.214 9.697a1.212 1.212 0 100 2.424 1.212 1.212 0 000-2.424zm13.575 0a1.212 1.212 0 100 2.424 1.212 1.212 0 000-2.424zm-8.404.524a1.212 1.212 0 100 2.424 1.212 1.212 0 000-2.424zm2.909 0a1.212 1.212 0 100 2.424 1.212 1.212 0 000-2.424zM5.09 13.576a1.212 1.212 0 100 2.424 1.212 1.212 0 000-2.424zm5.818 0a1.212 1.212 0 100 2.424 1.212 1.212 0 000-2.424z"
      fill="currentColor"
    />
  </svg>
)

AppsClubsIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsClubsIcon svgRef={ref} {...props} />)
export default ForwardRef
