import CobrandedLogos from "@sonato/core/components/CobrandedLogos"
import Footer from "@sonato/core/components/Footer"
import { CallToAction, CallToActionLayout } from "@sonato/core/components/Layouts"
import { locationImageURL } from "@sonato/core/images"
import ResendEmailVerification from "@sonato/core/pages/authentication/ResendEmailVerification"
import SignIn from "@sonato/core/pages/authentication/SignIn"
import * as URLs from "@sonato/core/urls"
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useCurrentLocation, useVerifyEmailMutation } from "./hooks"

const ResendVerification = () => {
  const { data: location } = useCurrentLocation()
  const imageUrl = locationImageURL(location, "landscape", 1200)

  const placeholderStyles = location
    ? {
        backgroundImage: `url("${imageUrl}")`,
        backgroundSize: "cover",
        backgroundPosition: "center center"
      }
    : {}

  const message = (
    <Trans>
      We could not verify that email address. If you would like us to send you a new verification
      code, please enter your email address below
    </Trans>
  )

  return (
    <CallToActionLayout placeholderStyles={placeholderStyles}>
      <CallToAction header={<CobrandedLogos location={location} />} footer={<Footer />}>
        {location && <ResendEmailVerification message={message} location={location} />}
      </CallToAction>
    </CallToActionLayout>
  )
}

const VerifyEmail = () => {
  const { t } = useTranslation()
  const { token } = useParams()
  const { mutate: verifyEmail, data: user, isError } = useVerifyEmailMutation()
  React.useEffect(() => {
    verifyEmail(token)
  }, [verifyEmail, token])

  if (isError) {
    return (
      <ResendVerification
        errorMessage={t(
          "Your email address could not be verified. Please contact support@sonato.com for assistance."
        )}
      />
    )
  }
  if (user) {
    const message = t("Success! Your email has been verified. Please login below.")
    const url = SignIn.url(URLs.currentSubdomain(), "/member/")
    window.location = URLs.addMessage(url, message)
    return null
  }
  return null // loading
}

VerifyEmail.path = "/account/verify/:token"

export default VerifyEmail
