import React from "react"
import PropTypes from "prop-types"

const EssentialsPasswordHideIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M14.381 1.567a.625.625 0 01-.018.884L2.697 13.65a.625.625 0 11-.866-.902l11.667-11.2a.625.625 0 01.883.018zM12.317 5.29a.625.625 0 01.873-.136c.727.53 1.4 1.134 2.006 1.8l.002.002a1.66 1.66 0 010 2.222l-.001.002c-1.641 1.806-4.385 3.756-7.2 3.712a6.84 6.84 0 01-2.072-.308.625.625 0 11.37-1.193c.55.17 1.124.255 1.7.25h.015c2.283.04 4.71-1.595 6.26-3.3a.41.41 0 000-.547 12.177 12.177 0 00-1.817-1.63.625.625 0 01-.136-.874zM8 3.242c.557-.006 1.112.06 1.651.194a.625.625 0 11-.301 1.213c-.44-.11-.891-.162-1.344-.157H7.99c-2.243-.038-4.677 1.56-6.26 3.3a.41.41 0 000 .546c.472.516.986.991 1.538 1.42a.625.625 0 11-.767.987A13.567 13.567 0 01.804 9.18l-.002-.004a1.66 1.66 0 010-2.221l.001-.002C2.481 5.107 5.231 3.2 8.001 3.242z" />
      <path d="M7.999 6.358A1.708 1.708 0 006.29 8.067a.625.625 0 01-1.25 0 2.958 2.958 0 012.958-2.959.625.625 0 010 1.25zM10.332 7.441c.345 0 .625.28.625.625A2.959 2.959 0 018 11.026a.625.625 0 110-1.25 1.708 1.708 0 001.708-1.71c0-.345.28-.625.625-.625z" />
    </g>
  </svg>
)

EssentialsPasswordHideIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsPasswordHideIcon svgRef={ref} {...props} />
))
export default ForwardRef
