import React from "react"
import PropTypes from "prop-types"

const AppsApplyIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="currentColor">
      <path d="M10.33 11.289a3.119 3.119 0 01-4.66 0c-.162.01-.325.006-.486-.011a.17.17 0 00-.13.042.168.168 0 00-.054.124v3.89a.667.667 0 001.02.565L8 14.66 9.98 15.9a.667.667 0 001.02-.566v-3.887a.167.167 0 00-.183-.166c-.162.017-.325.02-.488.009z" />
      <path d="M12.528 3.791A2.113 2.113 0 009.875 1.14a2.113 2.113 0 00-3.75 0 2.113 2.113 0 00-2.652 2.652 2.113 2.113 0 000 3.75 2.113 2.113 0 002.652 2.653 2.113 2.113 0 003.751 0 2.113 2.113 0 002.652-2.652 2.113 2.113 0 000-3.75zm-5.879 4a.306.306 0 01-.435-.382l.52-1.183a.167.167 0 00-.037-.185l-.933-.916a.285.285 0 01.2-.499h1.06a.167.167 0 00.152-.096l.557-1.197a.309.309 0 01.55 0l.552 1.2a.166.166 0 00.151.096h1.06a.286.286 0 01.2.499l-.933.916a.167.167 0 00-.036.185l.516 1.184a.306.306 0 01-.435.382L8.084 7.08a.167.167 0 00-.164 0l-1.27.712z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

AppsApplyIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsApplyIcon svgRef={ref} {...props} />)
export default ForwardRef
