import React from "react"
import PropTypes from "prop-types"

const AppsGroupsIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="currentColor">
      <path d="M13.333 12.5a.333.333 0 00.333.333h1.881a.333.333 0 00.309-.458 2.966 2.966 0 00-2.4-1.844.334.334 0 00-.358.431c.156.498.235 1.016.235 1.538zM3.244 10.933a.333.333 0 00-.316-.435H2.92a2.994 2.994 0 00-2.776 1.877.333.333 0 00.31.458h2.212A.333.333 0 003 12.5c0-.532.082-1.06.244-1.567zM6.553 8.372a.167.167 0 01-.02.295A4.171 4.171 0 004 12.5a.333.333 0 00.333.333H12a.333.333 0 00.333-.333A4.17 4.17 0 009.79 8.667a.167.167 0 01-.02-.297 3.156 3.156 0 10-3.216.004v-.002zm-.22-2.714c0-.126.014-.252.042-.375a.333.333 0 01.497-.212 4.1 4.1 0 002.7.544.333.333 0 01.377.385 1.824 1.824 0 01-3.616-.342z" />
      <path d="M13.08 9.833a1.844 1.844 0 10-1.788-1.438 1.855 1.855 0 001.788 1.438zM2.92 9.833a1.833 1.833 0 100-3.666 1.833 1.833 0 000 3.666z" />
    </g>
  </svg>
)

AppsGroupsIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsGroupsIcon svgRef={ref} {...props} />)
export default ForwardRef
