import React from "react"
import PropTypes from "prop-types"

import * as exports from "@sonato/core/icons"

export default function Icon({ name, ...rest }) {
  const IconComponent = exports[name]

  if (IconComponent === undefined) {
    console.error(`Icon named '${name}' does not exist.`)
    return null
  }

  return <IconComponent className="text-lg" {...rest} />
}

Icon.propTypes = {
  name: PropTypes.string.isRequired
}
