import Overline from "@sonato/core/components/Overline"
import { AbsoluteTime } from "@sonato/core/components/Timestamps"
import { placeholderAvatarURL } from "@sonato/core/images"
import classNames from "classnames"
import * as React from "react"
import { useTranslation } from "react-i18next"

// DJB2 string hash returns a signed integer
const hashCode = s => s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0)

// This adds some randomness to the max-width of 80% so it's not completely uniform (which can look
// a little unnatural). It uses a hash of the ID so it is consistent between renders.
const maxWidthFor = id => `calc(80% - ${hashCode(id) % 41}px)`

const Wrapper = ({ children, id, rightAlign = false }) => (
  <div className={classNames("flex my-2", rightAlign && "justify-end")}>
    <div style={{ maxWidth: maxWidthFor(id) }}>{children}</div>
  </div>
)

const FeedItem = ({ eventData, id, isRecipient }) => (
  <Wrapper id={id} rightAlign={!isRecipient}>
    {isRecipient ? <MessageIn eventData={eventData} /> : <MessageOut eventData={eventData} />}
  </Wrapper>
)

const MessageIn = ({ eventData }) => {
  const { t } = useTranslation()
  const { fromName, fromShortName, fromImageUrl, eventAt, text } = eventData
  const imageURL = fromImageUrl || placeholderAvatarURL()

  return (
    <div className="flex">
      <div className="flex-none flex items-end">
        <img className="h-8 w-8 rounded-full" src={imageURL} alt={fromName} />
      </div>
      <div className="ml-3 px-5 py-4 bg-gold-300 rounded-lg rounded-bl-none">
        <p>{text}</p>
        <div className="opacity-40">
          <Overline.Small>
            <span title={t("Received from {{name}}", { name: fromName })}>
              {fromShortName}, <AbsoluteTime date={eventAt} />
            </span>
          </Overline.Small>
        </div>
      </div>
    </div>
  )
}
const MessageOut = ({ eventData }) => {
  const { t } = useTranslation()
  const { fromName, fromShortName, fromImageUrl, eventAt, text } = eventData
  const imageURL = fromImageUrl || placeholderAvatarURL()

  return (
    <div className="flex">
      <div className="px-5 py-4 bg-teal-900 text-white rounded-lg rounded-br-none">
        <p>{text}</p>
        <div className="opacity-40">
          <Overline.Small>
            <span title={t("Sent by {{name}}", { name: fromName })}>
              {fromShortName}, <AbsoluteTime date={eventAt} />
            </span>
          </Overline.Small>
        </div>
      </div>
      <div className="ml-3 flex-none flex items-end">
        <img className="h-8 w-8 rounded-full" src={imageURL} alt={fromName} />
      </div>
    </div>
  )
}

export default FeedItem
