import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"
import { EssentialsErrorIcon } from "../../icons"

const ErrorHeader = ({ titleText, children }) => {
  const { t } = useTranslation()
  const title = titleText || t("We had the following problems:")
  const errorArray = React.Children.toArray(children)
  return (
    <section className="p-3 bg-red-100 w-full">
      <div className="flex flex-row items-start gap-2">
        <div className="min-w-4">
          <EssentialsErrorIcon className=" w-4 h-4 mt-1" />
        </div>
        <div>
          <div className="font-bold">{title}</div>
          <ul className="mt-2">
            {errorArray.map((error, idx) => (
              <li key={idx}>{error}</li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

ErrorHeader.propTypes = {
  titleText: PropTypes.string,
  children: PropTypes.node
}

export default ErrorHeader
