import React from "react"
import PropTypes from "prop-types"

const AppsEventsIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.109 1.826h1.674c.672 0 1.217.545 1.217 1.217v10.348c0 .673-.545 1.218-1.217 1.218H2.217A1.217 1.217 0 011 13.39V3.043c0-.672.545-1.217 1.217-1.217h.913c.169 0 .305.136.305.304V3.5a.457.457 0 10.913 0V.609a.609.609 0 111.217 0v1.066c0 .083.069.151.152.151h3.805c.168 0 .304.136.304.304V3.5a.457.457 0 00.913 0V.609a.609.609 0 011.217 0v1.065c0 .084.069.152.153.152zM2.52 13.391h10.957a.304.304 0 00.304-.304V5.783a.304.304 0 00-.304-.305H2.521a.304.304 0 00-.304.305v7.304c0 .168.136.304.304.304zm2.436-6.087a.609.609 0 100 1.218h6.087a.609.609 0 100-1.218H4.957zm-.61 3.652c0-.336.273-.608.61-.608h6.087a.609.609 0 010 1.217H4.957a.609.609 0 01-.61-.609z"
      fill="currentColor"
    />
  </svg>
)

AppsEventsIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsEventsIcon svgRef={ref} {...props} />)
export default ForwardRef
