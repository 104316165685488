import React from "react"
import PropTypes from "prop-types"

const AppsInboxMobileIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M3.26 3.063a.197.197 0 00-.197.198v7.29a.197.197 0 00.198.198h1.458c.294 0 .532.238.532.532v1.124l2.065-1.55a.532.532 0 01.32-.106h5.103a.197.197 0 00.198-.197V3.26a.198.198 0 00-.198-.198H3.261zm-.89-.694A1.26 1.26 0 013.26 2h9.48A1.26 1.26 0 0114 3.26v7.292a1.26 1.26 0 01-1.26 1.26H7.812l-2.775 2.082a.532.532 0 01-.85-.426v-1.655H3.26A1.26 1.26 0 012 10.552V3.26c0-.335.133-.655.37-.892z" />
      <path d="M4.187 5.813c0-.294.238-.532.531-.532h6.562a.532.532 0 010 1.063H4.718a.532.532 0 01-.531-.531zM4.187 8c0-.294.238-.532.531-.532h5.104a.532.532 0 110 1.064H4.718A.532.532 0 014.187 8z" />
    </g>
  </svg>
)

AppsInboxMobileIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsInboxMobileIcon svgRef={ref} {...props} />)
export default ForwardRef
