import React from "react"
import classNames from "classnames"

import PropTypes from "prop-types"
import ColorMode from "./ColorMode"

const sizes = {
  sm: ["w-8 h-8", "w-5 h-5"],
  md: ["w-10 h-10", "w-7 h-7"],
  lg: ["w-12 h-12", "w-8 h-8"],
  responsive: [
    "w-8 h-8 xs:w-12 xs:h-12 md:w-10 md:h-10 lg:w-12 lg:h-12",
    "w-5 h-5 xs:w-8 xs:h-8 md:w-6 md:h-6 lg:w-8 lg:h-8"
  ]
}

const CircleIcon = ({
  icon,
  size = "md",
  colorMode = ColorMode.dark,
  hasBorder = false,
  title = "",
  ...props
}) => {
  const Icon = icon
  let backgroundColor
  let foregroundColor
  let borderColor

  switch (colorMode) {
    case ColorMode.light:
      backgroundColor = "bg-transparent"
      foregroundColor = "text-gold-300"
      borderColor = "border-gold-300"
      break

    case ColorMode.medium:
      backgroundColor = "bg-transparent"
      foregroundColor = "text-gold-900"
      borderColor = "border-gold-900"
      break
    case ColorMode.dark:
    default:
      backgroundColor = "bg-gold-900"
      foregroundColor = "text-white"
      borderColor = "border-gold-900"
      break
  }

  const border = hasBorder ? "border-2" : ""
  const [containerSize, iconSize] = sizes[size]

  return (
    <span
      title={title}
      className={classNames(
        "flex items-center justify-center rounded-full",
        border,
        borderColor,
        containerSize,
        backgroundColor
      )}
    >
      <Icon className={classNames(iconSize, foregroundColor)} />
    </span>
  )
}

CircleIcon.propTypes = {
  icon: PropTypes.elementType,
  size: PropTypes.oneOf(Object.keys(sizes)),
  colorMode: PropTypes.oneOf(Object.values(ColorMode)),
  hasBorder: PropTypes.bool,
  title: PropTypes.string
}

export default CircleIcon
