import React from "react"
import PropTypes from "prop-types"

const EssentialsPeopleEmployeeIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g>
      <path
        d="M8 1a7 7 0 107 7 7.007 7.007 0 00-7-7zm0 1.458a5.531 5.531 0 014.258 9.078.292.292 0 01-.343.079c-.514-.233-1.135-.46-1.844-.722l-.434-.16a.467.467 0 01-.189-.322 1.161 1.161 0 01.11-.894c.623-.686 1.009-1.424 1.009-2.975A2.505 2.505 0 008 3.819a2.505 2.505 0 00-2.567 2.723c0 1.553.386 2.29 1.01 2.975a1.16 1.16 0 01.112.894.466.466 0 01-.19.322l-.434.16c-.708.26-1.33.49-1.843.721a.292.292 0 01-.343-.079A5.53 5.53 0 018 2.458z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" transform="translate(1 1)" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
)

EssentialsPeopleEmployeeIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsPeopleEmployeeIcon svgRef={ref} {...props} />
))
export default ForwardRef
