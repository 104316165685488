import React from "react"
import PropTypes from "prop-types"

const ThirdPartyAppleOfficialIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.477 0a3.894 3.894 0 01-.97 2.885c-.34.4-.769.721-1.257.94-.488.22-1.022.33-1.562.325a3.651 3.651 0 01.206-1.483c.171-.477.44-.917.79-1.295A4.397 4.397 0 0112.477 0zm1.97 7.606a3.932 3.932 0 00-.56 1.966c0 .776.242 1.535.694 2.182a4.122 4.122 0 001.848 1.455 8.88 8.88 0 01-1.323 2.556c-.778 1.106-1.595 2.186-2.891 2.206-.617.014-1.033-.154-1.466-.33-.453-.183-.924-.373-1.662-.373-.782 0-1.274.196-1.75.386-.41.164-.807.323-1.367.345-1.234.043-2.178-1.18-2.985-2.276C1.372 13.486.115 9.418 1.8 6.65a4.505 4.505 0 011.634-1.615 4.81 4.81 0 012.261-.64c.7-.013 1.372.243 1.961.467.45.172.852.325 1.182.325.289 0 .68-.147 1.135-.319.718-.27 1.595-.6 2.49-.511.686.02 1.357.192 1.96.501.603.31 1.122.749 1.516 1.282a4.195 4.195 0 00-1.491 1.466z"
      fill="currentColor"
    />
  </svg>
)

ThirdPartyAppleOfficialIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ThirdPartyAppleOfficialIcon svgRef={ref} {...props} />
))
export default ForwardRef
