import * as React from "react"

function useHover() {
  const [isHovered, setIsHovered] = React.useState(false)
  const ref = React.useRef()

  React.useEffect(() => {
    const node = ref.current
    if (node) {
      const handleMouseEnter = () => setIsHovered(true)
      const handleMouseLeave = () => setIsHovered(false)
      node.addEventListener("mouseenter", handleMouseEnter)
      node.addEventListener("mouseleave", handleMouseLeave)
      return () => {
        node.removeEventListener("mouseenter", handleMouseEnter)
        node.removeEventListener("mouseleave", handleMouseLeave)
      }
    }
  }, [])

  return { ref, isHovered }
}

export default useHover
