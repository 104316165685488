import React from "react"
import PropTypes from "prop-types"

const EssentialsFileFilledIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M13.491 3.866l-2.524-2.524A1.167 1.167 0 0010.142 1H3.333a1.167 1.167 0 00-1.167 1.167v11.666A1.167 1.167 0 003.333 15h9.334a1.167 1.167 0 001.166-1.167V4.691c0-.31-.123-.606-.342-.825zm-6.074 8.509H4.792a.583.583 0 010-1.167h2.625a.583.583 0 010 1.167zm2.624-2.333h-5.25a.583.583 0 010-1.167h5.25a.583.583 0 010 1.167zm0-2.334h-5.25a.583.583 0 010-1.166h5.25a.583.583 0 010 1.166z"
      fill="currentColor"
    />
  </svg>
)

EssentialsFileFilledIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsFileFilledIcon svgRef={ref} {...props} />
))
export default ForwardRef
