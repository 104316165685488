import React from "react"
import { Parser, HtmlRenderer } from "commonmark"
import PropTypes from "prop-types"

const reader = new Parser()

const writer = new HtmlRenderer({ safe: true })

const Markdown = ({ markdown }) => {
  const parsed = reader.parse(markdown)
  const renderedHTML = writer.render(parsed)

  return <div dangerouslySetInnerHTML={{ __html: renderedHTML }}></div>
}

Markdown.propTypes = {
  markdown: PropTypes.string.isRequired
}

export default Markdown
