import React from "react"
import PropTypes from "prop-types"

const ActionsAddPersonIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.443 2.217a1.735 1.735 0 100 3.47 1.735 1.735 0 000-3.47zM2.491 3.952a2.952 2.952 0 114.075 2.732c.211.055.42.125.622.212a.609.609 0 01-.478 1.12 3.228 3.228 0 00-4.493 2.967.609.609 0 11-1.217 0 4.445 4.445 0 013.322-4.299 2.953 2.953 0 01-1.83-2.732zm8.492 4.23a2.8 2.8 0 100 5.6 2.8 2.8 0 000-5.6zm-4.018 2.8a4.017 4.017 0 118.035 0 4.017 4.017 0 01-8.035 0zm4.018-2.312c.336 0 .608.272.608.608v1.096h1.096a.609.609 0 010 1.217h-1.096v1.096a.609.609 0 01-1.217 0v-1.096H9.278a.609.609 0 110-1.217h1.096V9.278c0-.336.272-.608.609-.608z"
      fill="currentColor"
    />
  </svg>
)

ActionsAddPersonIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsAddPersonIcon svgRef={ref} {...props} />
))
export default ForwardRef
