import { useState, useCallback, useEffect } from "react"

/**
 * Allows objects to be set as state. Every call to setState will result in
 * the underlying state object changing.
 */
export const useObjectState = initialState => {
  const [[reactState], setReactState] = useState([initialState])

  const setObjectState = stateOrCallback => {
    if (typeof stateOrCallback === "function") {
      setReactState(([oldState]) => {
        const rv = stateOrCallback(oldState)
        // the undefined check is to ensure that a call to something
        // that modifies the underlying object, but doesn't return a value
        // doesn't make the state [undefined]
        return rv === undefined ? [oldState] : [rv]
      })
    } else {
      setReactState([stateOrCallback])
    }
  }

  const setState = useCallback(setObjectState, [setReactState])

  return [reactState, setState]
}

/**
 * Limits how often a value can change by a given delay.
 */
export function useDebouncedValue(value, delay_ms) {
  const [debounced, setDebounced] = useState(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebounced(value), delay_ms)
    return () => clearTimeout(timer)
  }, [value, delay_ms])

  return debounced
}
