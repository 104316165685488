import React from "react"
import PropTypes from "prop-types"

const EssentialsErrorIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M7.019 1.522a2 2 0 012.691.706l.002.003 5.647 9.426.005.01a2 2 0 01-1.71 3H2.347a2 2 0 01-1.71-3l.005-.01 5.646-9.426.572.342-.57-.345a2 2 0 01.729-.706zm.412 1.396l-5.642 9.42a.667.667 0 00.569.995h11.285a.667.667 0 00.569-.996L8.57 2.92v-.001a.667.667 0 00-1.139 0z" />
      <path d="M8.037 4.982c.368 0 .667.298.667.667v4a.667.667 0 11-1.333 0v-4c0-.369.298-.667.666-.667zM7.333 12c0-.368.299-.667.667-.667h.007a.667.667 0 010 1.334H8A.667.667 0 017.333 12z" />
    </g>
  </svg>
)

EssentialsErrorIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <EssentialsErrorIcon svgRef={ref} {...props} />)
export default ForwardRef
