import React from "react"
import PropTypes from "prop-types"

const ThirdPartyGooglePlayIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.346 1.117l6.29 6.621L10.68 5.59 3.315 1.185c-.383-.23-.727-.238-.969-.068zM2 2.203l5.946 6.26-5.856 6.152A1.401 1.401 0 012 14.09V2.203zM3.103 15c.07-.028.143-.064.218-.109l6.842-4.094-1.528-1.609L3.103 15zm7.938-4.729l2.413-1.444c.728-.438.728-1.15 0-1.582l-1.892-1.132-2.238 2.351 1.717 1.807z"
      fill="currentColor"
    />
  </svg>
)

ThirdPartyGooglePlayIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ThirdPartyGooglePlayIcon svgRef={ref} {...props} />
))
export default ForwardRef
