import { useAlert } from "@sonato/core/components/Alert"
import Loading from "@sonato/core/components/Loading"
import ExtendedNetworkNote from "@sonato/core/components/ExtendedNetworkNote"
import ResponsivePanel from "@sonato/core/components/ResponsivePanel"
import { Form } from "@sonato/core/form"
import { useFormatters } from "@sonato/core/format"
import { useAuthentication } from "@sonato/core/hooks/authentication"
import { locationShape } from "@sonato/core/shapes"
import { noOp } from "@sonato/core/utils"
import { parseLocalDateTime } from "@sonato/core/utils/datetime"
import {
  getMinDateTimeForVisit,
  guestCountOptions,
  isDateEnabled,
  validateRequestForm
} from "@sonato/core/utils/visitRequest"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useBlackoutDateRanges, useCreateVisitRequestForm } from "./hooks"
import TextInput from "@sonato/core/components/form/TextInput"
import AvatarImageField from "components/AvatarImageField"

const CreateForm = ({ location, form }) => {
  const { t } = useTranslation()
  const { auth } = useAuthentication()
  const { data: blackoutDateRanges } = useBlackoutDateRanges(location.id)

  const minDate = getMinDateTimeForVisit(location).toISODate()
  const hasCellPhone = !!auth.identity.subject.cellPhone

  // Hotel option only shown for full clubs, we don't want full clubs having to
  // forward hotel reservations to legacy clubs
  const showHotel = location.amenities.some(a => a.type === "hotel") && location.kind === "full"
  const showDining = location.amenities.some(a => a.type === "dining")
  const hotelDiscount = location.profile?.hotelDiscount
  const hotelLabel = hotelDiscount ? `Hotel (${hotelDiscount}% discount)` : "Hotel"

  const [showHotelDates, setShowHotelDates] = useState(false)
  const onHotelChange = v => setShowHotelDates(v)
  const hotelStartDate = form.value("hotelStartDate")
  const minHotelEndDate = hotelStartDate || minDate

  return (
    <fieldset className="grid gap-3 mt-5">
      <Form value={form}>
        <Form.GlobalErrors />
        <TextInput label={t("Club")} value={location.name} display={true} large={true} />
        <div className="grid grid-cols-2 gap-3">
          <Form.DateInput.Dropdown
            name="date"
            label="Date"
            minDate={minDate}
            isDateEnabled={date => isDateEnabled(location, blackoutDateRanges, date)}
          />
          <Form.TimeInput
            large={true}
            name="time"
            label="Time"
            step={600}
            disableForceFocus={true}
          />
        </div>
        <Form.Select
          options={guestCountOptions(location)}
          large={true}
          name="guestCount"
          label={t("Guests")}
        />
        {!hasCellPhone && (
          <Form.PhoneNumberInput large={true} name="cellPhone" label={t("Your mobile no.")} />
        )}

        {(showDining || showHotel) && (
          <div className="my-3">
            <p className="text-gray-500">Also interested in:</p>
            {showDining && (
              <div className="mt-3">
                <Form.Checkbox name="isDining" label="Dining" />
              </div>
            )}
            {showHotel && (
              <div className="mt-3">
                <Form.Checkbox name="isHotel" label={hotelLabel} onChange={onHotelChange} />
                {showHotelDates && (
                  <div className="grid grid-cols-2 gap-3 mt-5">
                    <Form.DateInput.Dropdown
                      name="hotelStartDate"
                      label="Check In Date"
                      minDate={minDate}
                    />
                    <Form.DateInput.Dropdown
                      name="hotelEndDate"
                      label="Check Out Date"
                      dropdownOffset={{ right: 4, top: 44 }}
                      minDate={minHotelEndDate}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <Form.TextArea
          large={true}
          rows={3}
          name="message"
          label={t("Optional note or request")}
          placeholder={t(
            "Would you like to request a reservation for drinks or dining? Leave a note here..."
          )}
          maxLength={180}
        />
      </Form>
    </fieldset>
  )
}

const RequestVisitPanel = ({ location, visible, onSuccess = noOp, onDismiss = noOp }) => {
  const { t } = useTranslation()
  const { auth, resendAuthentication } = useAuthentication()
  const { form, submit } = useCreateVisitRequestForm(location.id)

  const alert = useAlert()
  const format = useFormatters()
  const topRef = useRef()

  const user = auth.identity.subject
  const showImageUpload = user.avatar.isFallback
  const [hasImage, setHasImage] = useState(!user.avatar.isFallback)

  const onImageChange = () => {
    setHasImage(true)
  }

  function onCreateSuccess(visitRequest) {
    const locationName = location.name
    alert.success(
      <Trans>
        <p>
          <b className="mr-1">Success!</b>
          {{ locationName }} has received your visit request! Please note: they will need to approve
          your visit before you go.
        </p>
      </Trans>
    )
    onSuccess(visitRequest)
    resendAuthentication()
  }

  const action = {
    text: t("Book Your Visit"),
    onClick() {
      submit({
        onSuccess: onCreateSuccess,
        onError() {
          alert.error(t("There was a problem creating your visit request."))
        },
        onValidate(form) {
          const [date, time] = form.values("date", "time")
          const earliestVisitDate = getMinDateTimeForVisit(location)
          const scheduledAt = parseLocalDateTime(date, time, location.timezone)
          const isValid = validateRequestForm(
            form,
            hasImage,
            t,
            format,
            scheduledAt,
            earliestVisitDate
          )
          if (!isValid && !hasImage) {
            topRef.current.scrollIntoView({ behavior: "smooth" })
          }
          return isValid
        }
      })
    }
  }

  if (!form.isLoaded()) {
    return <Loading />
  }

  return (
    <ResponsivePanel
      title={t("Visit details")}
      action={action}
      onDismiss={onDismiss}
      visible={visible}
      centerContent={false}
    >
      <ExtendedNetworkNote location={location} />
      <p ref={topRef} className="text-gray-500">
        {t("When would you like to go?")}
      </p>
      <CreateForm location={location} form={form} />

      {showImageUpload && <AvatarImageField user={user} onChange={onImageChange} />}
    </ResponsivePanel>
  )
}

RequestVisitPanel.propTypes = {
  location: locationShape.isRequired,
  visible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func,
  onSuccess: PropTypes.func
}

export default RequestVisitPanel
