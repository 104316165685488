import Overline from "@sonato/core/components/Overline"
import { useAuthentication } from "@sonato/core/hooks/authentication"
import { useMouseClickOutside } from "@sonato/core/hooks/document"
import { ActionsCheckIcon } from "@sonato/core/icons"
import { contactUrl } from "@sonato/core/urls"
import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const IdentitySummary = ({ summary }) => {
  const { changeIdentity, isCurrentIdentity } = useAuthentication()
  const summaryType = summary.identityType === "member" ? "Member" : "Staff"
  const locationName = summary.location.name

  return (
    <li>
      <button
        className="flex flex-row justify-between w-full transition duration-300 hover:text-gray-300"
        onClick={() => changeIdentity(summary)}
      >
        <Trans>
          {{ summaryType }} - {{ locationName }}
        </Trans>
        {isCurrentIdentity(summary) && <ActionsCheckIcon />}
      </button>
    </li>
  )
}

const IdentitySwitcher = ({ accountLink, accountOnClick, visible = false, setVisible }) => {
  const { auth, signOut } = useAuthentication()
  const { t } = useTranslation()
  const ref = useMouseClickOutside(() => setVisible(false))

  if (!auth?.identitySummaries) {
    return null
  }

  const classes = classNames(
    "w-60 shadow-md rounded bg-white text-black z-50 transition ease-in-out duration-300 z-50",
    visible ? "opacity-100" : "opacity-0 invisible h-0"
  )

  const AccountLink = () => (
    <Link
      onClick={() => setVisible(false)}
      to={accountLink}
      className="transition duration-300 hover:text-gray-300 w-full text-left inline-block"
    >
      {t("My Account")}
    </Link>
  )

  const AccountButton = () => (
    <button
      onClick={() => {
        setVisible(false)
        accountOnClick()
      }}
      className="transition duration-300 hover:text-gray-300 w-full text-left"
    >
      {t("My Account")}
    </button>
  )

  const showAccountLink = !!accountLink
  const showAccountButton = !showAccountLink && !!accountOnClick
  const showAccountSection = showAccountLink || showAccountButton

  return (
    <div className={classes} ref={ref}>
      {showAccountSection && (
        <section className="border-b border-gold-300 px-5 py-3">
          {showAccountLink && <AccountLink />}
          {showAccountButton && <AccountButton />}
        </section>
      )}
      {auth.identitySummaries.length > 1 && (
        <section className="px-5 py-3 border-b border-gold-300">
          <Overline.Small className="mb-3">
            <span className="text-gray-300">{t("Switch Account")}</span>
          </Overline.Small>
          <ul className="ml-3 space-y-3">
            {auth.identitySummaries.map((summary, idx) => (
              <IdentitySummary summary={summary} key={idx} />
            ))}
          </ul>
        </section>
      )}
      <section className="px-5 py-3 border-b border-gold-300 transition duration-300 hover:text-gray-300">
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block text-left w-full"
          href={contactUrl()}
        >
          {t("Contact Us")}
        </a>
      </section>
      <section className="px-5 py-3 transition duration-300 hover:text-gray-300">
        <button className="w-full text-left" onClick={() => signOut()}>
          {t("Log Out")}
        </button>
      </section>
    </div>
  )
}

IdentitySwitcher.propTypes = {
  accountLink: PropTypes.string,
  accountOnClick: PropTypes.func,
  visible: PropTypes.bool,
  setVisible: PropTypes.func.isRequired
}

export default IdentitySwitcher
