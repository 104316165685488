import React from "react"
import PropTypes from "prop-types"

const EssentialsMembershipIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.958 5.333a4.042 4.042 0 116.284 3.364.618.618 0 00-.105.068 4.042 4.042 0 01-6.178-3.431zm6.067 4.89A5.276 5.276 0 018 10.626c-.717 0-1.4-.143-2.024-.401l-.52 3.906 2.222-1.333a.625.625 0 01.643 0l2.222 1.333-.518-3.906zm-5.221-.672a5.292 5.292 0 116.392 0l.757 5.7a.625.625 0 01-.941.618L8 14.062 4.988 15.87a.625.625 0 01-.941-.618l.757-5.7z"
      fill="currentColor"
    />
  </svg>
)

EssentialsMembershipIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsMembershipIcon svgRef={ref} {...props} />
))
export default ForwardRef
