import React from "react"
import PropTypes from "prop-types"

const AppsAccountMobileIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="currentColor">
      <path d="M7.705 7.025l-.367-.135a.342.342 0 01-.08-.18 1.504 1.504 0 01.034-.759 3.277 3.277 0 00.854-2.516C8.146 2.027 7.226 1 5.958 1 4.691 1 3.766 2.027 3.766 3.437a3.266 3.266 0 00.847 2.508c.083.247.098.513.042.768a.354.354 0 01-.079.18l-.368.135c-1.427.525-2.457.904-2.772 1.531A5.345 5.345 0 001 10.625a.291.291 0 00.292.292h5.493a.145.145 0 00.13-.08 3.477 3.477 0 013.503-1.87.146.146 0 00.133-.059.145.145 0 00.02-.141 2.44 2.44 0 00-.092-.212c-.317-.626-1.347-1.005-2.774-1.53z" />
      <path d="M15 7.784a.292.292 0 00-.291-.291h-1.154a.291.291 0 00-.2.08L10.912 9.88a.147.147 0 01-.143.033 2.598 2.598 0 10-1.044 5.068 2.596 2.596 0 002.78-3.325.147.147 0 01.038-.145l2.372-2.366A.292.292 0 0015 8.938V7.784zm-4.177 5.243a1.012 1.012 0 11-1.432-1.432 1.012 1.012 0 011.432 1.432z" />
    </g>
  </svg>
)

AppsAccountMobileIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <AppsAccountMobileIcon svgRef={ref} {...props} />
))
export default ForwardRef
