import React from "react"
import PropTypes from "prop-types"

const EssentialsCompassIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M.575 7.262l4.381-1.094-2.09-2.603a.495.495 0 01.034-.666.495.495 0 01.665-.034l2.607 2.084L7.265.574c.191-.762 1.282-.762 1.475 0l1.094 4.381 2.603-2.093a.491.491 0 01.666.034.498.498 0 01.034.669L11.053 6.17l4.375 1.094a.75.75 0 01.575.734.753.753 0 01-.575.738L11.047 9.83l2.09 2.604a.497.497 0 01-.7.7l-2.606-2.085-1.094 4.375A.753.753 0 018 16c-.14 0-.606-.065-.738-.575L6.17 11.043l-2.604 2.094a.493.493 0 01-.665-.035.494.494 0 01-.035-.665L4.95 9.83.578 8.737a.76.76 0 01-.003-1.475zm6.178 1.987l1.25 5 1.25-5 5-1.25-5-1.25-1.25-5-1.25 5-5 1.25 5 1.25z"
      fill="currentColor"
    />
  </svg>
)

EssentialsCompassIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsCompassIcon svgRef={ref} {...props} />
))
export default ForwardRef
