import React from "react"
import PropTypes from "prop-types"

const EssentialsUnlockFilledIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M12.377 6.541h-.438V4.937a3.938 3.938 0 00-7.71-1.131.73.73 0 001.397.418 2.48 2.48 0 014.855.713V6.25a.292.292 0 01-.292.291H3.627A1.167 1.167 0 002.46 7.708v6.125A1.167 1.167 0 003.627 15h8.75a1.167 1.167 0 001.167-1.167V7.708a1.167 1.167 0 00-1.167-1.167zm-5.542 3.5a1.167 1.167 0 111.75 1.006v1.328a.583.583 0 01-1.167 0v-1.328a1.164 1.164 0 01-.583-1.006z"
      fill="currentColor"
    />
  </svg>
)

EssentialsUnlockFilledIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsUnlockFilledIcon svgRef={ref} {...props} />
))
export default ForwardRef
