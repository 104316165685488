import React from "react"
import { Trans, useTranslation } from "react-i18next"

import EditableAvatar from "@sonato/core/components/EditableAvatar"
import Overline from "@sonato/core/components/Overline"
import ResponsivePanel from "@sonato/core/components/ResponsivePanel"
import { EssentialsLockFilledIcon, EssentialsPeoplePersonIcon } from "@sonato/core/icons"
import { Form } from "@sonato/core/form"
import { useAlert } from "@sonato/core/components/Alert"
import { useAuthentication } from "@sonato/core/hooks/authentication"
import { useFormatters } from "@sonato/core/format"
import { useUpdateAccountForm, useUploadAvatarMutation } from "./hooks"

const AccountPanel = ({ visible, onDismiss }) => {
  const { auth, resendAuthentication } = useAuthentication()
  const { t } = useTranslation()
  const alert = useAlert()
  const format = useFormatters()
  const { form, submit, reset, isLoading } = useUpdateAccountForm()
  const user = auth.identity.subject
  const uploadMutation = useUploadAvatarMutation(user)

  if (isLoading) {
    return null
  }

  function onSave() {
    submit({
      onSuccess() {
        alert.success(
          <Trans>
            <b>Success!</b>
            <span className="ml-1">Your account changes have been saved.</span>
          </Trans>
        )
        onDismiss()
      }
    })
  }

  const usesMemberRef = auth.identity.location.settings.hasMemberRef
  const memberRef = auth.identity.membership.memberRef
  const memberSince = format.longDate(auth.identity.membership.startDate)

  const leftContent = (
    <>
      <EditableAvatar
        uploadMutation={uploadMutation}
        user={user}
        width={400}
        height={500}
        onSuccess={resendAuthentication}
      />
      <hr className="mt-7 mb-4" />
      <Overline.Small className="text-gray-500">Alliance Membership</Overline.Small>
      {usesMemberRef && <h4 className="mt-3">{memberRef}</h4>}
      <p className="font-bold">{t("Member since {{ memberSince }}", { memberSince })}</p>
    </>
  )

  const rightContent = (
    <>
      <Form value={form}>
        <Section title={t("Personal")} icon={<EssentialsPeoplePersonIcon />}>
          <div className="grid grid-flow-row gap-3 my-3">
            <Form.TextInput large={true} name="fullName" label={t("Full name")} />
            <Form.TextInput large={true} name="shortName" label={t("Short name")} />
            <Form.TextInput large={true} name="emailAddress" label={t("Email")} type="email" />
            <Form.PhoneNumberInput large={true} name="cellPhone" label={t("Mobile phone")} />
          </div>
        </Section>

        <div className="mt-5"></div>

        <Section title={t("Password")} icon={<EssentialsLockFilledIcon />}>
          <div className="grid grid-flow-row gap-3 my-3">
            <Form.PasswordInput
              large={true}
              name="currentPassword"
              placeholder={t("Current password")}
            />
            <Form.PasswordInput large={true} name="newPassword" placeholder={t("New password")} />
            <Form.PasswordInput
              large={true}
              name="newPasswordConfirmation"
              placeholder={t("Confirm new password")}
            />
          </div>
        </Section>
        <Form.GlobalErrors />
      </Form>
    </>
  )

  return (
    <ResponsivePanel.Wide
      centerContent={false}
      visible={visible}
      title={user.fullName}
      onDismiss={() => {
        reset()
        onDismiss()
      }}
      leftContent={leftContent}
      rightContent={rightContent}
      action={{
        text: t("Save Changes"),
        onClick: onSave
      }}
    />
  )
}

const Section = ({ icon, title, children }) => (
  <section className="bg-gold-300 p-5 rounded">
    <div className="flex items-center">
      <span className="h-6 w-6 rounded-full text-white bg-gold-900 flex items-center justify-center">
        {icon}
      </span>
      <h3 className="ml-5">{title}</h3>
    </div>
    {children}
  </section>
)

export default AccountPanel
