import * as DateFns from "date-fns"

const DAY_OFFSET_IN_HOURS = 4

/**
 * Returns the beginning of the check-in day (which starts at 4:00am and goes to 3:59am of the
 * following day).
 *
 * @param {Object} [date] - optional Date object, defaults to the current time
 * @returns a Date object
 */
const beginningOfDay = (date = new Date()) => {
  let cutover = DateFns.addHours(DateFns.startOfDay(date), DAY_OFFSET_IN_HOURS)
  return date < cutover
    ? DateFns.addHours(DateFns.startOfDay(DateFns.addHours(date, -24)), DAY_OFFSET_IN_HOURS)
    : cutover
}

const coerceDate = value => (typeof value === "string" ? DateFns.parseISO(value) : value)

export { beginningOfDay, coerceDate }
