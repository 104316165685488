import i18n from "i18next"

import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

const resources = {
  en: { translation: require("./i18n/en/translation.json") },
  en_YELL: { translation: require("./i18n/en_YELL/translation.json") },
  ru_FAKE: { translation: require("./i18n/ru_FAKE/translation.json") }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    debug: true,
    keySeparator: "::",
    nsSeparator: ":::",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

i18n.addResources("en", "translation", require("@sonato/core/i18n/en/translation.json"))

export default i18n
