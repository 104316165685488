import React from "react"
import PropTypes from "prop-types"
import { groupBy } from "lodash"
import { useTranslation } from "react-i18next"

import AmenityTypeIcon from "./AmenityTypeIcon"
import CircleIcon from "@sonato/core/components/CircleIcon"
import Markdown from "@sonato/core/components/Markdown"
import OpeningHours from "./OpeningHours"
import ResponsivePanel from "@sonato/core/components/ResponsivePanel"
import { amenityShape, locationShape } from "@sonato/core/shapes"
import { EssentialsDoorOpenIcon } from "@sonato/core/icons"

const AmenityDetail = ({ amenity }) => {
  return (
    <ul className="space-y-2">
      <li>
        <h6 className="font-bold">{amenity.name}</h6>
        {amenity.description}
      </li>
      <li>
        <Markdown markdown={amenity.hoursMarkdown} />
      </li>
    </ul>
  )
}

AmenityDetail.propTypes = {
  amenity: amenityShape
}

const GeneralHours = ({ location }) => {
  const { t } = useTranslation()
  return (
    <div className="bg-gold-300 rounded-md p-5">
      <h5 className="flex flex-row items-center">
        <span className="inline-block mr-3">
          <CircleIcon icon={EssentialsDoorOpenIcon} />
        </span>
        {t("General Hours")}
      </h5>
      <div className="mt-5 flex flex-col">
        <OpeningHours location={location} />
      </div>
    </div>
  )
}

GeneralHours.propTypes = {
  location: locationShape
}

const AmenityGroup = ({ amenityType, amenities }) => {
  const { t } = useTranslation()
  const titles = {
    bar: t("Bar"),
    dining: t("Dining"),
    hotel: t("Hotel"),
    gym: t("Gym"),
    spa: t("Spa"),
    workspace: t("Workspace")
  }

  const title = titles[amenityType]

  return (
    <div className="bg-gold-300 rounded-md p-5">
      <h5 className="flex flex-row items-center">
        <span className="inline-block mr-3">
          <AmenityTypeIcon title={title} type={amenityType} />
        </span>
        {title}
      </h5>
      <div className="mt-5 flex flex-col space-y-5">
        {amenities.map((amenity, index) => (
          <AmenityDetail key={index} amenity={amenity} />
        ))}
      </div>
    </div>
  )
}

AmenityGroup.propTypes = {
  amenityType: PropTypes.string.isRequired,
  amenities: PropTypes.arrayOf(amenityShape)
}

const AmenitiesPanel = ({ location, visible, setVisible }) => {
  const { t } = useTranslation()

  const closePanel = () => {
    setVisible(false)
  }

  const amenitiesByType = groupBy(location.amenities, "type")

  const action = {
    onClick: closePanel,
    text: t("Close")
  }

  return (
    <ResponsivePanel
      title={t("Hours & Amenities")}
      action={action}
      visible={visible}
      onDismiss={closePanel}
    >
      <section className="flex flex-col space-y-3">
        <GeneralHours location={location} />
        {Object.entries(amenitiesByType).map(([type, amenities], index) => (
          <AmenityGroup key={index} amenityType={type} amenities={amenities} />
        ))}
      </section>
    </ResponsivePanel>
  )
}

AmenitiesPanel.propTypes = {
  location: locationShape.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func
}

export default AmenitiesPanel
