import React from "react"
import PropTypes from "prop-types"

const EssentialsSpaIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M8.017 3.5a.102.102 0 00-.071.029l-.007.007a5.98 5.98 0 00-1.877 4.173 6.13 6.13 0 001.955 4.252 6.13 6.13 0 001.955-4.252 5.976 5.976 0 00-1.877-4.173l-.007-.007a.102.102 0 00-.071-.029zm0 9.122l-.314.389a7.128 7.128 0 01-2.64-5.314A6.98 6.98 0 017.25 2.811a1.102 1.102 0 011.534 0 6.976 6.976 0 012.188 4.918 7.13 7.13 0 01-2.64 5.282l-.315-.39z" />
      <path d="M14.382 6.142a6.504 6.504 0 00-3.694 1.123.5.5 0 11-.561-.827 7.503 7.503 0 014.261-1.296 1.113 1.113 0 011.11 1.1 6.972 6.972 0 01-1.931 5.01 7.129 7.129 0 01-5.602 1.867.5.5 0 11.105-.994 6.13 6.13 0 004.804-1.595 5.974 5.974 0 001.624-4.265v-.01a.113.113 0 00-.114-.113h-.002z" />
      <path d="M1.612 5.142A7.448 7.448 0 015.91 6.45a.5.5 0 11-.566.824 6.448 6.448 0 00-3.722-1.13h-.005a.113.113 0 00-.113.111v.011a5.976 5.976 0 001.623 4.265 6.195 6.195 0 004.84 1.595.5.5 0 01.105.994 7.197 7.197 0 01-5.66-1.89A6.976 6.976 0 01.504 6.243a1.113 1.113 0 011.11-1.1z" />
    </g>
  </svg>
)

EssentialsSpaIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <EssentialsSpaIcon svgRef={ref} {...props} />)
export default ForwardRef
