import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import RadioButton from "@sonato/core/components/form/RadioButton"

const valueType = PropTypes.oneOfType([PropTypes.string, PropTypes.number])

let nameID = 1

const optionShape = PropTypes.shape({
  value: valueType.isRequired,
  label: PropTypes.string.isRequired,
  text: PropTypes.string,
  disabled: PropTypes.bool
})

const RadioButtonGroup = ({ options, value, onChange, disabled, horizontal = false }) => {
  const uniqueName = `radio_group_${nameID++}`

  return (
    <div className={classNames("mt-4", horizontal ? "flex space-x-4" : "space-y-2")}>
      {options.map((option, idx) => (
        <RadioButton
          key={idx}
          name={uniqueName}
          value={option.value}
          label={option.label}
          text={horizontal ? null : option.text}
          disabled={disabled || option.disabled}
          onChange={onChange}
          checked={value === option.value}
        />
      ))}
    </div>
  )
}

RadioButtonGroup.propTypes = {
  /** List of possible values with their labels. */
  options: PropTypes.arrayOf(optionShape),
  /** Currently selected value. */
  value: valueType,
  /** Invokes onChange handler passing new `checked` state and `value`. */
  onChange: PropTypes.func,
  /** If true, options will be displayed in a row, otherwise in a column. */
  horizontal: PropTypes.bool,
  /** If true, all radio buttons will be disabled. */
  disabled: PropTypes.bool
}

export default RadioButtonGroup
