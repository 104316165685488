import React from "react"
import PropTypes from "prop-types"

const ArrowLeftIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M8.88 13.756a.727.727 0 00-.063-1.015L4.585 8.95a.128.128 0 01-.034-.141.127.127 0 01.117-.083h8.625a.7.7 0 00.5-.21.725.725 0 000-1.017.7.7 0 00-.5-.21H4.668a.124.124 0 01-.117-.083.13.13 0 01.034-.14l4.232-3.792a.717.717 0 00.256-.498.731.731 0 00-.174-.534A.708.708 0 008.401 2a.695.695 0 00-.518.197L2.4 7.109a1.196 1.196 0 00-.4.9 1.215 1.215 0 00.4.899l5.484 4.912a.703.703 0 00.51.178.696.696 0 00.486-.242z"
      fill="currentColor"
    />
  </svg>
)

ArrowLeftIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ArrowLeftIcon svgRef={ref} {...props} />)
export default ForwardRef
