import React from "react"
import PropTypes from "prop-types"

const ActionsDeleteObjectIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.871 0A3.871 3.871 0 000 3.871v8.258A3.871 3.871 0 003.871 16h8.258A3.871 3.871 0 0016 12.129V3.871A3.871 3.871 0 0012.129 0H3.871zM1.548 3.871a2.323 2.323 0 012.323-2.323h8.258a2.323 2.323 0 012.323 2.323v8.258a2.323 2.323 0 01-2.323 2.323H3.871a2.323 2.323 0 01-2.323-2.323V3.871zM5.11 7.226a.774.774 0 100 1.548h5.78a.774.774 0 100-1.548H5.11z"
      fill="currentColor"
    />
  </svg>
)

ActionsDeleteObjectIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsDeleteObjectIcon svgRef={ref} {...props} />
))
export default ForwardRef
