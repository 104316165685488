import React from "react"
import PropTypes from "prop-types"

const ThirdPartyMessengerIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M8 1c-.923 0-1.821.119-2.673.447a6.754 6.754 0 00-4.326 6.361 6.656 6.656 0 002.172 5.005l.033 1.076a1.145 1.145 0 001.607 1.013l1.222-.54c.64.17 1.302.256 1.965.255a6.754 6.754 0 007-6.809 6.753 6.753 0 00-4.327-6.361C9.822 1.119 8.901 1 8 1zm0 12.344a6.276 6.276 0 01-1.677-.223 1.145 1.145 0 00-.76.059l-1.091.481-.03-.95a1.151 1.151 0 00-.381-.811 5.4 5.4 0 01-1.788-4.092 5.51 5.51 0 015.726-5.535 5.539 5.539 0 110 11.071zm2.376-7.518L8.905 7.58l-2.13-1.308a.635.635 0 00-.82.134L4.013 8.718a.636.636 0 10.974.818l1.59-1.893 2.13 1.308a.635.635 0 00.82-.133l1.824-2.173a.636.636 0 10-.975-.819z"
      fill="currentColor"
    />
  </svg>
)

ThirdPartyMessengerIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ThirdPartyMessengerIcon svgRef={ref} {...props} />
))
export default ForwardRef
