import { useMutation, useQuery } from "react-query"

import { useServerChangesetForm } from "@sonato/core/form"
import { useAjax } from "@sonato/core/hooks/ajax"
import { uploadImage } from "@sonato/core/images"

// mutations

export function useAcknowledgeMessagesMutation(locationId) {
  const ajax = useAjax()
  return useMutation(() => ajax.post("/member/account/acknowledgeMessages", { locationId }))
}

export function useAcknowledgeVisitRequestsMutation() {
  const ajax = useAjax()
  return useMutation(() => ajax.post("/member/account/acknowledgeVisitRequests"))
}

// `{ form, submit(), reset(), isLoading }`
export function useUpdateAccountForm() {
  const form = useServerChangesetForm("member/account", "update", {}, { ajax: true })
  return {
    form,
    submit({ onSuccess }) {
      form.submit(() => {
        // trigger invalidation of authenticated user account (after useAuthentication is rewritten)
        onSuccess()
      })
    },
    reset: form.reset,
    isLoading: !form.isLoaded()
  }
}

export function useUploadParameters(options) {
  const ajax = useAjax()
  const queryKey = "uploadSignature"
  const queryFn = () => ajax.get("/member/account/uploadcareParams")

  // The signature is valid for 30 minutes
  const staleTime = 30 * 60 * 1000

  return useQuery(queryKey, queryFn, { staleTime, ...options })
}

export function useUploadAvatarMutation(user) {
  const ajax = useAjax()
  const { data: params } = useUploadParameters({ suspense: true })
  const { signature, publicKey, expiresAt } = params
  const metadata = { schema: user.__schema__, id: user.id }

  const mutate = async file => {
    const uuid = await uploadImage(file, metadata, signature, publicKey, expiresAt)
    await ajax.post("/member/account/storeAvatar", { user_id: user.id, uuid })
    return uuid
  }

  return useMutation(mutate)
}
