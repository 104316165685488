import React from "react"
import PropTypes from "prop-types"

const EssentialsPeoplePersonIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.884 7.121A3.039 3.039 0 015.027 5c0-.796.309-1.559.857-2.121A2.89 2.89 0 017.954 2c.776 0 1.52.316 2.069.879.549.562.857 1.325.857 2.121s-.308 1.559-.857 2.121A2.89 2.89 0 017.953 8a2.89 2.89 0 01-2.069-.879zm7.098 6.665a.314.314 0 01-.297.214H3.503a.488.488 0 01-.375-.17.518.518 0 01-.125-.402 5.213 5.213 0 011.683-3.316 4.935 4.935 0 013.417-1.276 4.948 4.948 0 013.359 1.432 5.228 5.228 0 011.537 3.39.332.332 0 01-.017.129z"
      fill="currentColor"
    />
  </svg>
)

EssentialsPeoplePersonIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsPeoplePersonIcon svgRef={ref} {...props} />
))
export default ForwardRef
