import DefaultBackground from "@sonato/core/assets/home_background.jpg"
import { useApiCall } from "@sonato/core/client"
import Alert from "@sonato/core/components/Alert"
import { Button } from "@sonato/core/components/Buttons"
import CobrandedLogos from "@sonato/core/components/CobrandedLogos"
import Footer from "@sonato/core/components/Footer"
import { CallToAction, CallToActionLayout } from "@sonato/core/components/Layouts"
import Loading from "@sonato/core/components/Loading"
import Modal from "@sonato/core/components/Modal"
import NotFound from "@sonato/core/components/NotFound"
import { Form, useServerChangesetForm } from "@sonato/core/form"
import ForgotPassword from "@sonato/core/pages/authentication/ForgotPassword"
import * as Urls from "@sonato/core/urls"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { locationImageURL } from "../../images"

const IdentitySummary = ({ summary, onSelect }) => {
  const { t } = useTranslation()
  let title = summary.identityType === "member" ? t("Member") : t("Employee")
  const location = summary.location
  const imageUrl = locationImageURL(location, "logoFullDark", 96)

  return (
    <div className="flex flex-col bg-gold-300 w-72 items-center space-y-5 p-5 rounded">
      <h5>{title}</h5>
      <img className="h-12" src={imageUrl} alt={location.name} />
      <div className="text-lg font-bold">{location.name}</div>
      <div>{location.address.locality}</div>
      <Button className="w-3/4" onClick={() => onSelect(summary)}>
        {t("Select")}
      </Button>
    </div>
  )
}

const IdentityIntersitial = ({ summaries, onSelect }) => {
  const { t } = useTranslation()
  return (
    <Modal visible={summaries.length > 0} width="w-3/4">
      <section className="flex flex-col items-center w-full">
        <h2>{t("Welcome Back")}</h2>
        <h5>{t("Where would you like to go?")}</h5>
        <section className="flex flex-row w-full justify-evenly mt-10">
          {summaries.map((summary, index) => (
            <IdentitySummary key={index} summary={summary} onSelect={onSelect} />
          ))}
        </section>
      </section>
    </Modal>
  )
}

const ViaEmail = ({ subdomain, onSuccess }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const form = useServerChangesetForm(
    "public/authentication",
    "signIn",
    { subdomain },
    { ajax: true }
  )

  const params = useParams()
  const signIn = () => {
    setIsLoading(true)
    form.submit(
      ({ reply }) => {
        setIsLoading(false)
        onSuccess(reply)
      },
      () => setIsLoading(false),
      () => true,
      () => setIsLoading(false)
    )
  }

  const signInOnEnter = e => {
    if (e.key === "Enter") {
      signIn()
    }
  }

  return (
    <section className="flex flex-col w-full items-center">
      <Form value={form}>
        <fieldset className="flex flex-col w-full gap-3">
          <Form.GlobalErrors />
          <Form.TextInput
            type="email"
            name="emailAddress"
            placeholder={t("Email Address")}
            large={true}
            onKeyPress={signInOnEnter}
            largeFont={true}
          />
          <Form.PasswordInput
            placeholder={t("Password")}
            name="password"
            large={true}
            onKeyPress={signInOnEnter}
            largeFont={true}
          />
          <Button tall={true} onClick={() => signIn()} disabled={isLoading}>
            {isLoading ? t("Logging in...") : t("Login with Email")}
          </Button>
        </fieldset>
      </Form>
      <a href={ForgotPassword.url(params.location)} className="inline-block mt-5 text-gold-900">
        {t("Forgot Password")}
      </a>
    </section>
  )
}

const SignIn = ({ alertMessage, alertStyle = Alert.STYLE.success }) => {
  const { t } = useTranslation()

  // The subdomain of the location to log into is read from the URL:
  //   https://auth.sonato.com/<subdomain>/sign-in
  // But it can also be undefined for:
  //   https://auth.sonato.com/sign-in
  const params = useParams()
  const subdomain = params.location

  // Alert message can be passed via props or via the URL
  alertMessage = alertMessage || Urls.getMessage(window.location)

  const [authResults, setAuthResults] = useState()
  const [showAlert, setShowAlert] = useState(!!alertMessage)

  const [location, error, isLoading] = useApiCall("public/locations", "current", { subdomain })

  if (isLoading) {
    return <Loading />
  }

  // Show an error if a subdomain is given but a location with the given
  // subdomain does not exist.
  if (subdomain && error) {
    return (
      <div className="h-screen">
        <NotFound />
      </div>
    )
  }

  const imageUrl = location ? locationImageURL(location, "landscape", 1200) : DefaultBackground
  const placeholderStyles = {
    backgroundImage: `url("${imageUrl}")`,
    backgroundSize: "cover",
    backgroundPosition: "center center"
  }

  // If user has authenticated successfully and is a member of only one
  // location, redirect them to that location.
  if (authResults && authResults.identitySummaries?.length === 1) {
    const identitySummary = authResults.identitySummaries[0]
    const redirectURL = new URL(identitySummary.url)
    redirectURL.searchParams.set("t", identitySummary.token)
    window.location = redirectURL.toString()
    return null
  }

  const onSummarySelected = summary => {
    const url = new URL(summary.url)
    url.searchParams.set("t", summary.token)
    window.location = url.toString()
  }

  const showInterstitial = authResults?.identitySummaries?.length > 1

  const footer = (
    <Footer>
      {subdomain && (
        <span>
          {t("Don't have an account?")}{" "}
          <a className="text-gray-900" href={Urls.signupUrl(subdomain)}>
            {t("Sign Up")}
          </a>
        </span>
      )}
    </Footer>
  )

  return (
    <div>
      {showAlert && (
        <Alert style={alertStyle} onDismiss={() => setShowAlert(false)}>
          {alertMessage}
        </Alert>
      )}
      {showInterstitial && (
        <IdentityIntersitial
          summaries={authResults.identitySummaries}
          onSelect={onSummarySelected}
        />
      )}
      <CallToActionLayout placeholderClasses="bg-brand-500" placeholderStyles={placeholderStyles}>
        <CallToAction header={<CobrandedLogos location={location} />} footer={footer}>
          <h2 className="mb-12 text-center">{t("Login")}</h2>
          <ViaEmail subdomain={subdomain} onSuccess={setAuthResults} />
        </CallToAction>
      </CallToActionLayout>
    </div>
  )
}

SignIn.path = "/:location/sign-in"
SignIn.globalPath = "/sign-in"

SignIn.url = (subdomain, to) => {
  const path = subdomain ? SignIn.path.replace(":location", subdomain) : SignIn.globalPath
  const baseUrl = new URL(`https://${Urls.authDomain()}${path}`)
  const params = new URLSearchParams(window.location.search)

  to = to || params.get("to")
  if (to) {
    baseUrl.searchParams.set("to", to)
  }

  return baseUrl.toString()
}

SignIn.propTypes = {
  alertMessage: PropTypes.node,
  alertStyle: PropTypes.oneOf(Object.keys(Alert.STYLE))
}

export default SignIn
