import React from "react"
import PropTypes from "prop-types"

const EssentialsAnnounceFilledIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="currentColor">
      <path d="M14 2.081a.583.583 0 00-1.167 0v.452L2.243 5.952a.292.292 0 00-.201.277v3.475a.292.292 0 00.204.279l.758.237a.292.292 0 01.204.279v1.511a2.628 2.628 0 002.625 2.614h.012a2.628 2.628 0 002.613-2.635v-.059l4.375 1.371v.447a.583.583 0 001.167 0V2.08zm-6.709 9.91a1.458 1.458 0 01-1.45 1.466h-.008a1.458 1.458 0 01-1.458-1.45v-.96a.292.292 0 01.379-.278l2.333.731a.292.292 0 01.204.279v.213zM1.167 5.873a.583.583 0 00-1.167 0l.003 4.083a.583.583 0 00.583.584H.583a.583.583 0 00.584-.57V5.873z" />
    </g>
  </svg>
)

EssentialsAnnounceFilledIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsAnnounceFilledIcon svgRef={ref} {...props} />
))
export default ForwardRef
