import React from "react"
import PropTypes from "prop-types"

const AppsSettingsIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M15.829 2.282a.333.333 0 00-.553-.133l-1.632 1.635a.334.334 0 01-.236.098h-.941a.333.333 0 01-.334-.333v-.943c0-.089.036-.173.099-.236L13.867.732a.333.333 0 00-.127-.553 3.329 3.329 0 00-4.333 3.84.33.33 0 01-.086.306L4.325 9.323a.333.333 0 01-.306.085 3.328 3.328 0 00-3.84 4.334.333.333 0 00.553.124l1.64-1.64a.333.333 0 01.234-.093h.943c.184 0 .333.15.333.333v.943a.333.333 0 01-.097.235l-1.633 1.633a.333.333 0 00.133.552 3.33 3.33 0 004.306-3.846.333.333 0 01.086-.306l5-5a.333.333 0 01.306-.086 3.329 3.329 0 003.846-4.31v.001z"
      fill="currentColor"
    />
  </svg>
)

AppsSettingsIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsSettingsIcon svgRef={ref} {...props} />)
export default ForwardRef
