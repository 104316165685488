import React from "react"
import PropTypes from "prop-types"

const ActionsVisitCancelVisitIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M11.063 8.188a2.875 2.875 0 100 5.75 2.875 2.875 0 000-5.75zm-4.126 2.874a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0z" />
      <path d="M13.979 8.146a.625.625 0 010 .884l-4.95 4.949a.625.625 0 11-.883-.884l4.949-4.95a.625.625 0 01.884 0zM2.313 2.946a.25.25 0 00-.25.25v6.992a.25.25 0 00.25.25h3.5a.625.625 0 010 1.25h-3.5a1.5 1.5 0 01-1.5-1.5V3.196a1.5 1.5 0 011.5-1.5h7.874a1.5 1.5 0 011.5 1.5v2.617a.625.625 0 11-1.25 0V3.196a.25.25 0 00-.25-.25H2.314z" />
      <path d="M11.063 5.563H1.437v-1.25h9.626v1.25zM4.058.813c.345 0 .625.28.625.625v1.75a.625.625 0 01-1.25 0v-1.75c0-.346.28-.625.625-.625zM8.433.813c.345 0 .625.28.625.625v1.75a.625.625 0 01-1.25 0v-1.75c0-.346.28-.625.625-.625z" />
    </g>
  </svg>
)

ActionsVisitCancelVisitIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsVisitCancelVisitIcon svgRef={ref} {...props} />
))
export default ForwardRef
