import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/** Shows a floating tooltip bubble when a trigger element is hovered. */
const Tooltip = ({
  trigger,
  children,
  widthClass = "w-80",
  offset = "0px",
  position = "right"
}) => {
  const shadow = { boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)" }
  const commonClasses = "absolute bg-white invisible group-hover:visible"

  const arrowClasses = classNames(commonClasses, "h-4 w-4 transform rotate-45 z-50")
  const arrowStyle =
    position === "right"
      ? { left: `calc(100% + .5rem + ${offset})`, top: "15%" }
      : { right: `calc(100% + .5rem + ${offset})`, top: "15%" }

  const baloonClasses = classNames(
    commonClasses,
    widthClass,
    "rounded-lg px-6 py-5 shadow z-50 text-gray-500"
  )

  const baloonStyle =
    position === "right"
      ? { left: `calc(100% + 1rem + ${offset})`, top: "-100%", ...shadow }
      : { right: `calc(100% + 1rem + ${offset})`, top: "-100%", ...shadow }

  return (
    <span>
      <span className="relative inline-block group">
        {trigger}
        <span className={arrowClasses} style={{ ...arrowStyle, ...shadow }}></span>
        <span className={baloonClasses} style={baloonStyle}>
          {children}
        </span>
        <span className={arrowClasses} style={arrowStyle}></span>
      </span>
    </span>
  )
}

Tooltip.propTypes = {
  trigger: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  widthClass: PropTypes.string,
  offset: PropTypes.string,
  position: PropTypes.oneOf(["left", "right"])
}

export default Tooltip
