import React from "react"
import PropTypes from "prop-types"

const AppsCheckinIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g>
      <path
        d="M8 0a6.34 6.34 0 00-6.333 6.333c0 4.365 4.358 8.326 5.694 9.436a1 1 0 001.278 0c1.333-1.11 5.694-5.072 5.694-9.436A6.34 6.34 0 008 0zm0 9.667A3.333 3.333 0 118 3a3.333 3.333 0 010 6.667z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

AppsCheckinIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsCheckinIcon svgRef={ref} {...props} />)
export default ForwardRef
