import React from "react"
import PropTypes from "prop-types"

const AppsConnectIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="currentColor">
      <path d="M6.833 9.037A1.667 1.667 0 018.5 7.371h4.833a.166.166 0 00.167-.167V3.037a1 1 0 00-1-1H1.166a1 1 0 00-1 1v6.667a1 1 0 001 1h1v2.333a.334.334 0 00.57.236l2.568-2.569h1.362a.166.166 0 00.167-.167v-1.5z" />
      <path d="M15.167 8.37H8.5a.667.667 0 00-.667.667v4a.667.667 0 00.667.667h3l2.445 2.173a.333.333 0 00.555-.248v-1.925h.667a.667.667 0 00.667-.667v-4a.666.666 0 00-.667-.666z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

AppsConnectIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsConnectIcon svgRef={ref} {...props} />)
export default ForwardRef
