import React from "react"
import PropTypes from "prop-types"

const ActionsUpdateObjectIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M13.515 2.263a.688.688 0 00-.972 0l-5.42 5.422-.163 1.134 1.134-.162 5.421-5.421a.687.687 0 000-.973zM11.66 1.38a1.937 1.937 0 112.74 2.74L8.831 9.689a.625.625 0 01-.354.177l-2.165.31a.625.625 0 01-.707-.707l.31-2.166a.625.625 0 01.176-.354l5.568-5.569z" />
      <path d="M2.53 4.688a.25.25 0 00-.25.25v8.75c0 .138.111.25.25.25h8.75a.25.25 0 00.25-.25V9.311a.625.625 0 111.25 0v4.376a1.5 1.5 0 01-1.5 1.5H2.53a1.5 1.5 0 01-1.5-1.5v-8.75a1.5 1.5 0 011.5-1.5h4.374a.625.625 0 010 1.25H2.53z" />
    </g>
  </svg>
)

ActionsUpdateObjectIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsUpdateObjectIcon svgRef={ref} {...props} />
))
export default ForwardRef
