import React from "react"
import PropTypes from "prop-types"

const EssentialsInfoIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6 7.138c0-.297.241-.538.539-.538h.43a.97.97 0 01.97.97v2.153a.323.323 0 00.323.323h.43a.538.538 0 010 1.077h-.43a1.4 1.4 0 01-1.4-1.4V7.677h-.323a.538.538 0 01-.539-.539z"
      />
      <path d="M7.57 4.123a.862.862 0 100 1.723.862.862 0 000-1.723z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.05 3.05a7 7 0 119.9 9.9 7 7 0 01-9.9-9.9zM8 2.077a5.923 5.923 0 100 11.846A5.923 5.923 0 008 2.077z"
      />
    </g>
  </svg>
)

EssentialsInfoIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <EssentialsInfoIcon svgRef={ref} {...props} />)
export default ForwardRef
