import ForgotPassword from "@sonato/core/pages/authentication/ForgotPassword"
import ResetPassword from "@sonato/core/pages/authentication/ResetPassword"
import SignIn from "@sonato/core/pages/authentication/SignIn"
import SignupMobile from "@sonato/core/pages/authentication/SignupMobile"
import * as URLs from "@sonato/core/urls"
import React from "react"
import { Route, Switch } from "react-router-dom"

function ReservedRoutes({ children }) {
  const hostname = window.location.hostname
  if (hostname === URLs.authDomain()) {
    return (
      <Switch>
        <Route
          exact
          path="/:subdomain/sign-up"
          render={({ match }) => window.location.replace(URLs.signupUrl(match.params.subdomain))}
        />
        <Route exact path={SignupMobile.path}>
          <SignupMobile />
        </Route>
        <Route exact path={SignIn.path}>
          <SignIn />
        </Route>
        <Route exact path={SignIn.globalPath}>
          <SignIn />
        </Route>
        <Route exact path={ForgotPassword.path}>
          <ForgotPassword />
        </Route>
        <Route exact path={ForgotPassword.globalPath}>
          <ForgotPassword />
        </Route>
        <Route exact path={ResetPassword.path}>
          <ResetPassword />
        </Route>
      </Switch>
    )
  }
  return children
}

export default ReservedRoutes
