import Image from "@sonato/core/components/Image"
import { useFormatters } from "@sonato/core/format"
import { locationImageURL } from "@sonato/core/images"
import * as React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import ClubDetail from "../clubs/ClubDetail"
import { usePastVisits } from "./hooks"

export default function PastVisitsSection() {
  const { t } = useTranslation()
  const { data: visits } = usePastVisits()

  if (visits.length === 0) {
    return null
  }

  return (
    <section className="max-w-7xl mx-auto mt-8">
      <h2 className="text-center">{t("Past visits")}</h2>
      <div className="grid gap-10 md:grid-cols-2 mt-8 md:mt-12">
        {visits.map(visit => (
          <PastVisitCard key={visit.id} visit={visit} />
        ))}
      </div>
    </section>
  )
}

function PastVisitCard({ visit }) {
  const { t } = useTranslation()
  const format = useFormatters()
  const location = visit.destinationLocation
  const imageUrl = locationImageURL(location, "square", 192)
  const guestCountText =
    visit.guestCount > 0 ? t("+ {{ count }} guests", { count: visit.guestCount }) : null

  return (
    <div className="flex border-b border-gold-300 pb-8">
      <Link to={ClubDetail.path(location.subdomain)}>
        <Image className="w-24" src={imageUrl} aspect="1:1" alt={location.name} />
      </Link>
      <div className="ml-5 flex flex-col justify-center">
        <h3>{format.longDate(visit.scheduledAt, location.timezone)}</h3>
        <p className="text-gray-500">
          {location.name}, {location.address.locality}
          <span className="ml-1">{guestCountText}</span>
        </p>
      </div>
    </div>
  )
}
