import React, { Suspense } from "react"
import classNames from "classnames"

import ClubCard from "components/ClubCard"
import Loader from "components/loader"
import { useAuthentication } from "@sonato/core/hooks/authentication"
import { useFavoriteLocationIds, useHotelPartnerLocations } from "../clubs/hooks"

const HotelList = () => {
  const { data: favoriteLocationIDs } = useFavoriteLocationIds()
  const { data: locations } = useHotelPartnerLocations()

  return (
    <div
      className={classNames(
        "grid gap-10 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
        "sm:px-10 max-w-8xl mx-auto"
      )}
    >
      {locations.map(location => (
        <ClubCard
          to={`/member/hotels/${location.subdomain}`}
          key={location.id}
          location={location}
          isFavorite={favoriteLocationIDs.includes(location.id)}
        />
      ))}
    </div>
  )
}

const Header = () => {
  const { auth } = useAuthentication()
  const location = auth.location.name

  return (
    <div className="md:text-center">
      <h1>Hotels</h1>
      <p className="mt-5 md:mt-8">
        Welcome to Sonato! Below are the list of private clubs who are partnered with {location}.
      </p>
      <p>We hope you enjoy all the amazing amenities these clubs offer.</p>
    </div>
  )
}

const Clubs = () => {
  return (
    <>
      <section className="mt-9 px-4 md:px-0">
        <Header />
      </section>

      <section className="mt-10">
        <Suspense fallback={<Loader />}>
          <HotelList />
        </Suspense>
      </section>
    </>
  )
}

export default Clubs
