import { buildFieldValidators } from "./validators"
import { useTranslation } from "react-i18next"
import { useState, useLayoutEffect, useRef } from "react"
import { client } from "@sonato/core/client"
import { toValidationSchema } from "./validation"
import { isEmpty, isObject } from "lodash"
import { useAjax } from "./hooks/ajax"

const splitOnce = str => {
  const index = str.indexOf(".")
  if (index < 0) {
    return [str, null]
  }
  return [str.substring(0, index), str.substring(index + 1)]
}

const parseArrayAccess = str => {
  const arrayAccessRegex = /^\[(\d*)\]\.?(.*)/
  const arrayMatch = str.match(arrayAccessRegex)

  if (!arrayMatch) {
    return null
  }

  const [_, indexStr, remaining] = arrayMatch
  const rest = remaining.trim().length === 0 ? null : remaining.trim()

  if (indexStr === "") {
    return { type: "array-indication", name: "[]", rest }
  } else {
    return { type: "array", name: parseInt(indexStr), rest }
  }
}

const parseFieldAccess = str => {
  const fieldAccessRegex = /^([a-zA-Z_?][a-zA-Z0-9_-]+)\.?(.*)/
  const fieldMatch = str.match(fieldAccessRegex)

  if (!fieldMatch) {
    return null
  }

  const [_, name, remaining] = fieldMatch
  const rest = remaining.trim().length === 0 ? null : remaining.trim()
  return { type: "field", name, rest }
}

const parseAccess = str => {
  const fieldAccess = parseFieldAccess(str)

  if (fieldAccess) {
    return fieldAccess
  }
  return parseArrayAccess(str)
}

const toValidatorPath = str => {
  let current = str

  const accessPath = []

  while (current) {
    const { type, name, rest } = parseAccess(current)
    if (type === "array" || type === "array-indication") {
      accessPath.push("[]")
    } else {
      if (accessPath.length > 0) {
        accessPath.push(".")
      }

      accessPath.push(name)
    }
    current = rest
  }

  return accessPath.join("")
}

/**
 * Provides a simulacrum of an Ecto changeset.
 * Changesets have a backing store of data, validations and keep track of changes and
 * errors.
 **/
class Changeset {
  constructor(
    model = {},
    translationInstance,
    requiredFields = [],
    validations = {},
    errors = {},
    types = {}
  ) {
    this.setData(model)
    this.validations = validations
    this.required = requiredFields
    this.fieldValidators = buildFieldValidators(requiredFields, validations, translationInstance)
    this.types = types
    this.errors = errors
    this.loaded = false
  }

  static fromModel(model, translationInstance, validations = {}, types = {}) {
    const { required, ...rest } = toValidationSchema(validations)
    const requiredFields = required || []
    const validators = rest || {}

    const changeset = new Changeset(
      model,
      translationInstance,
      requiredFields,
      validators,
      {},
      types
    )
    changeset.loaded = true
    return changeset
  }

  static empty() {
    return new Changeset()
  }

  static fromApi(apiResponse, translationInstance) {
    const { required, errors, validations, types } = apiResponse

    const changeset = new Changeset(
      apiResponse.data,
      translationInstance,
      required,
      validations,
      errors,
      types
    )
    changeset.setChanges(apiResponse.changes, translationInstance)
    changeset.errors = Changeset.applyServerErrorTemplates(errors)
    changeset.loaded = true
    return changeset
  }

  static applyServerErrorTemplates(errors) {
    const applyTemplate = (message, validations) =>
      validations.reduce((message, [label, value]) => {
        const regex = new RegExp("%{" + label + "}", "g")
        return message.replace(regex, value)
      }, message)

    const newErrors = Object.entries(errors).reduce((acc, [fieldName, error]) => {
      const message = applyTemplate(error.message, error.validations)

      acc[fieldName] = { ...error, message }
      return acc
    }, {})
    return newErrors
  }

  setData(model) {
    this.data = Object.freeze(model)
    this.changes = {}
  }

  setChanges(changes, translationInstance) {
    const reducer = (acc, [key, value]) => {
      if (Array.isArray(value)) {
        acc[key] = value.map(elem => {
          if (elem.type === "changeset") {
            return Changeset.fromApi(elem, translationInstance)
          } else {
            return elem
          }
        })
      } else if (value?.type === "changeset") {
        const changeset = Changeset.fromApi(value, translationInstance)
        acc[key] = changeset
      } else {
        acc[key] = value
      }
      return acc
    }
    this.changes = Object.entries(changes).reduce(reducer, {})
  }

  applyChanges() {
    const doApply = value => {
      if (Array.isArray(value)) {
        return value.map(doApply)
      } else if (value instanceof Changeset) {
        return value.applyChanges()
      }

      return value
    }

    return this.fieldNames().reduce((changes, name) => {
      if (name.indexOf(".") < 0 && name.indexOf("[") < 0) {
        const value = this.getValue(name)
        changes[name] = doApply(value)
      }
      return changes
    }, {})
  }

  clearError(fieldName) {
    this.setError(fieldName, null)
  }

  clearErrors() {
    Object.keys(this.errors).forEach(k => this.clearError(k))
    Object.keys(this.changes).forEach(k => this.clearError(k))
  }

  setError(fieldName, message) {
    const putError = (fullName, error, errors) => {
      const { name, rest, type } = parseAccess(fullName)
      let currentError
      if (errors) {
        currentError = errors
      } else {
        currentError = type === "array" ? [] : {}
      }

      if (rest === null) {
        currentError[name] = error
        return currentError
      }
      currentError[name] = putError(rest, error, currentError[name])
      return currentError
    }

    let error = message
    if (message && !message.hasOwnProperty("message")) {
      error = { message, validations: [] }
    }

    putError(fieldName, error, this.errors)
  }

  getErrorObject(fieldName) {
    const fetchErrorInChanges = (fullName, changes) => {
      const { name, rest } = parseAccess(fullName)
      const changeValue = changes[name]
      if (changeValue && changeValue instanceof Changeset) {
        return changeValue.getErrorObject(rest)
      }
      return false
    }

    const fetchErrorInErrors = (fullName, errors) => {
      if (!errors) {
        return null
      }

      const { name, rest } = parseAccess(fullName)
      const errorValue = errors[name]

      return rest === null ? errorValue : fetchErrorInErrors(rest, errorValue)
    }

    return (
      fetchErrorInChanges(fieldName, this.changes) || fetchErrorInErrors(fieldName, this.errors)
    )
  }

  getError(fieldName) {
    const error = this.getErrorObject(fieldName)

    if (error && error.message) {
      return error.message
    }
    return null
  }

  error(fieldName) {
    return this.getError(fieldName)
  }

  hasErrors() {
    return Object.values(this.errors).includes(e => e !== null)
  }

  validate() {
    return this.fieldNames()
      .map(fieldName => this.validateField(fieldName))
      .every(x => x === true)
  }

  validateField(fieldName) {
    const validatorPath = toValidatorPath(fieldName)

    const fieldValidator = this.fieldValidators[validatorPath]
    if (fieldValidator) {
      return fieldValidator(this, fieldName)
    }
    return true
  }

  fieldNames() {
    if (isEmpty(this.types)) {
      const dataKeys = new Set(Object.keys(this.data))
      const changesKeys = new Set(Object.keys(this.changes))
      dataKeys.forEach(k => changesKeys.add(k))

      return Array.from(changesKeys).sort()
    } else {
      const extractKeys = (fieldName, fieldType, acc) => {
        acc.push(fieldName)
        if (Array.isArray(fieldType)) {
          // it's an embedded list
          const subKeys = Object.keys(fieldType[0])
          const count = this.getValue(fieldName).length || 0
          for (let i = 0; i < count; i++) {
            for (let key of subKeys) {
              acc.push(`${fieldName}[${i}].${key}`)
            }
          }
        } else if (isObject(fieldType)) {
          const subKeys = Object.keys(fieldType)
          for (let key of subKeys) {
            acc.push(`${fieldName}.${key}`)
          }
        }
        return acc
      }

      return Object.entries(this.types)
        .reduce((acc, [fieldName, fieldType]) => {
          return extractKeys(fieldName, fieldType, acc)
        }, [])
        .sort()
    }
  }

  /**
   * Returns the value set as the data in the changeset.
   * The data value is the initial value of a field, defined when the changeset
   * is created, and comes from the model parameter.
   **/
  getDataValue(fieldName) {
    const coallesce = (value, defaultValue) =>
      value === null || value === undefined ? defaultValue : value

    const fetchInData = (fullName, data) => {
      const { name, rest } = parseAccess(fullName)
      if (data === null || !data.hasOwnProperty(name)) {
        return ""
      }
      const dataValue = data[name]
      if (rest === null) {
        return coallesce(dataValue, "")
      }
      return fetchInData(rest, dataValue)
    }
    return fetchInData(fieldName, this.data)
  }

  hasField(fieldName, isStrict = true) {
    const findFieldInTypes = (fullName, types) => {
      const { type, name, rest } = parseAccess(fullName)
      // the server sends arrays of embedded or associated types as arrays with a single
      // object. Callers of this function might call it with a non-zero index, so the name
      // here might be that non-zero index. Clamping it to zero ensures the array access
      // will work
      const nameOrIndex = type === "array" ? 0 : name

      if (types.hasOwnProperty(nameOrIndex)) {
        return rest === null ? true : findFieldInTypes(rest, types[nameOrIndex])
      }
      return false
    }

    const findFieldInDataOrChanges = (fullName, changes, data) => {
      if (changes instanceof Changeset) {
        return changes.hasField(fullName)
      }

      const { name, rest } = parseAccess(fullName)

      if (changes && name in changes) {
        return rest === null ? true : findFieldInDataOrChanges(rest, changes[name], {})
      } else if (data && name in data) {
        return rest === null ? true : findFieldInDataOrChanges(rest, {}, data[name])
      }
      return false
    }

    const [name] = isStrict ? [fieldName] : splitOnce(fieldName)

    return Object.keys(this.types).length > 0
      ? findFieldInTypes(name, this.types)
      : findFieldInDataOrChanges(name, this.changes, this.data)
  }

  getValidations(fieldName) {
    return this.validations[fieldName] || []
  }

  getValues(...fieldNames) {
    return fieldNames.map(fieldName => this.getValue(fieldName))
  }

  getValue(fieldName) {
    const coallesce = (value, defaultValue) =>
      value === null || value === undefined ? defaultValue : value

    const fetchInData = (fullName, data) => {
      const { name, rest } = parseAccess(fullName)
      if (data === null || !data.hasOwnProperty(name)) {
        return ""
      }
      const dataValue = data[name]
      if (rest === null) {
        return coallesce(dataValue, "")
      }
      return fetchInData(rest, dataValue)
    }

    const fetchInChanges = (fullName, changes) => {
      const { name, rest } = parseAccess(fullName)
      if (!changes.hasOwnProperty(name)) {
        return fetchInData(fieldName, this.data)
      }
      const changeValue = changes[name]
      if (changeValue instanceof Changeset) {
        if (rest) {
          return changeValue.getValue(rest)
        } else {
          return changeValue.applyChanges()
        }
      }
      if (rest === null) {
        return coallesce(changeValue, "")
      }
      return fetchInChanges(rest, changeValue)
    }

    return fetchInChanges(fieldName, this.changes)
  }

  setValue(fieldName, value) {
    const putValueInChangeset = (fullName, value, changes) => {
      if (!fullName || !changes) {
        return false
      }

      const { name, rest } = parseAccess(fullName)

      if (!changes.hasOwnProperty(name)) {
        return false
      }

      let changeField = changes[name]
      if (changeField instanceof Changeset) {
        changeField.setValue(rest, value)
        return true
      }

      return putValueInChangeset(rest, value, changeField)
    }

    const putValue = (fullName, value, changes) => {
      const { name, rest, type } = parseAccess(fullName)
      let currentChanges

      if (changes) {
        currentChanges = changes
      } else {
        currentChanges = type === "array" ? [] : {}
      }

      if (rest === null) {
        currentChanges[name] = value
        return currentChanges
      }

      currentChanges[name] = putValue(rest, value, currentChanges[name])
      return currentChanges
    }

    this.clearError(fieldName)
    putValueInChangeset(fieldName, value, this.changes) || putValue(fieldName, value, this.changes)
    this.validateField(fieldName)
  }

  reset() {
    this.setData(this.data)
    this.clearErrors()
  }

  resetField(fieldName) {
    delete this.changes[fieldName]
  }
}

const noOpCallback = () => {}
const emptyObject = {}

const useServerChangeset = (
  path,
  event,
  payload = emptyObject,
  version = 0,
  { onFetchSuccess = noOpCallback, onFetchError = noOpCallback, ajax = false } = {}
) => {
  const ajaxHook = useAjax()
  const { t } = useTranslation()
  const [changeset, setChangeset] = useState(Changeset.empty())

  // Using a ref here prevents an infinite loop when a payload is given
  const payloadRef = useRef(payload)

  useLayoutEffect(() => {
    const changesetEvent = `${event}.changeset`

    if (ajax) {
      const payload = { ...payloadRef.current, version }

      const onSuccess = response => {
        if (response["valid?"]) {
          handleFetchSuccess(response, setChangeset, t, onFetchSuccess)
        } else {
          onFetchError({ error: response })
        }
      }

      const onError = response => {
        onFetchError({ error: response })
      }

      ajaxHook.post(`/${path}/${changesetEvent}`, payload).then(onSuccess, onError)
    } else {
      client.call(
        path,
        changesetEvent,
        { ...payloadRef.current, version },
        ({ reply: changeset }) => handleFetchSuccess(changeset, setChangeset, t, onFetchSuccess),
        error => onFetchError && onFetchError(error)
      )
    }
  }, [path, event, version, onFetchSuccess, onFetchError, t, ajax, ajaxHook])
  return changeset
}

const handleFetchSuccess = (changeset, setChangeset, t, onFetchSuccess) => {
  const fetchedChangeset = Changeset.fromApi(changeset, t)
  setChangeset(fetchedChangeset)
  onFetchSuccess(fetchedChangeset)
}

export { Changeset, useServerChangeset }
