// WARNING: this file is automatically generated by the `mix sonato.countries_js` task
// Do not modify this file because it will get overwritten next time the task is run.

/* eslint-disable */

export const AD = {
  "alpha2": "AD",
  "countryCode": "376",
  "name": "Andorra"
}

export const AE = {
  "alpha2": "AE",
  "countryCode": "971",
  "name": "United Arab Emirates"
}

export const AF = {
  "alpha2": "AF",
  "countryCode": "93",
  "name": "Afghanistan"
}

export const AG = {
  "alpha2": "AG",
  "countryCode": "1",
  "name": "Antigua and Barbuda"
}

export const AI = {
  "alpha2": "AI",
  "countryCode": "1",
  "name": "Anguilla"
}

export const AL = {
  "alpha2": "AL",
  "countryCode": "355",
  "name": "Albania"
}

export const AM = {
  "alpha2": "AM",
  "countryCode": "374",
  "name": "Armenia"
}

export const AN = {
  "alpha2": "AN",
  "countryCode": "599",
  "name": "Netherlands Antilles"
}

export const AO = {
  "alpha2": "AO",
  "countryCode": "244",
  "name": "Angola"
}

export const AQ = {
  "alpha2": "AQ",
  "countryCode": "672",
  "name": "Antarctica"
}

export const AR = {
  "alpha2": "AR",
  "countryCode": "54",
  "name": "Argentina"
}

export const AS = {
  "alpha2": "AS",
  "countryCode": "1",
  "name": "American Samoa"
}

export const AT = {
  "alpha2": "AT",
  "countryCode": "43",
  "name": "Austria"
}

export const AU = {
  "alpha2": "AU",
  "countryCode": "61",
  "name": "Australia"
}

export const AW = {
  "alpha2": "AW",
  "countryCode": "297",
  "name": "Aruba"
}

export const AX = {
  "alpha2": "AX",
  "countryCode": "358",
  "name": "Åland Islands"
}

export const AZ = {
  "alpha2": "AZ",
  "countryCode": "994",
  "name": "Azerbaijan"
}

export const BA = {
  "alpha2": "BA",
  "countryCode": "387",
  "name": "Bosnia and Herzegovina"
}

export const BB = {
  "alpha2": "BB",
  "countryCode": "1",
  "name": "Barbados"
}

export const BD = {
  "alpha2": "BD",
  "countryCode": "880",
  "name": "Bangladesh"
}

export const BE = {
  "alpha2": "BE",
  "countryCode": "32",
  "name": "Belgium"
}

export const BF = {
  "alpha2": "BF",
  "countryCode": "226",
  "name": "Burkina Faso"
}

export const BG = {
  "alpha2": "BG",
  "countryCode": "359",
  "name": "Bulgaria"
}

export const BH = {
  "alpha2": "BH",
  "countryCode": "973",
  "name": "Bahrain"
}

export const BI = {
  "alpha2": "BI",
  "countryCode": "257",
  "name": "Burundi"
}

export const BJ = {
  "alpha2": "BJ",
  "countryCode": "229",
  "name": "Benin"
}

export const BL = {
  "alpha2": "BL",
  "countryCode": "590",
  "name": "Saint Barthélemy"
}

export const BM = {
  "alpha2": "BM",
  "countryCode": "1",
  "name": "Bermuda"
}

export const BN = {
  "alpha2": "BN",
  "countryCode": "673",
  "name": "Brunei Darussalam"
}

export const BO = {
  "alpha2": "BO",
  "countryCode": "591",
  "name": "Bolivia (Plurinational State of)"
}

export const BQ = {
  "alpha2": "BQ",
  "countryCode": "599",
  "name": "Bonaire, Sint Eustatius and Saba"
}

export const BR = {
  "alpha2": "BR",
  "countryCode": "55",
  "name": "Brazil"
}

export const BS = {
  "alpha2": "BS",
  "countryCode": "1",
  "name": "Bahamas"
}

export const BT = {
  "alpha2": "BT",
  "countryCode": "975",
  "name": "Bhutan"
}

export const BV = {
  "alpha2": "BV",
  "countryCode": "47",
  "name": "Bouvet Island"
}

export const BW = {
  "alpha2": "BW",
  "countryCode": "267",
  "name": "Botswana"
}

export const BY = {
  "alpha2": "BY",
  "countryCode": "375",
  "name": "Belarus"
}

export const BZ = {
  "alpha2": "BZ",
  "countryCode": "501",
  "name": "Belize"
}

export const CA = {
  "alpha2": "CA",
  "countryCode": "1",
  "name": "Canada"
}

export const CC = {
  "alpha2": "CC",
  "countryCode": "61",
  "name": "Cocos (Keeling) Islands"
}

export const CD = {
  "alpha2": "CD",
  "countryCode": "243",
  "name": "Congo (Democratic Republic of the)"
}

export const CF = {
  "alpha2": "CF",
  "countryCode": "236",
  "name": "Central African Republic"
}

export const CG = {
  "alpha2": "CG",
  "countryCode": "242",
  "name": "Congo"
}

export const CH = {
  "alpha2": "CH",
  "countryCode": "41",
  "name": "Switzerland"
}

export const CI = {
  "alpha2": "CI",
  "countryCode": "225",
  "name": "Côte d'Ivoire"
}

export const CK = {
  "alpha2": "CK",
  "countryCode": "682",
  "name": "Cook Islands"
}

export const CL = {
  "alpha2": "CL",
  "countryCode": "56",
  "name": "Chile"
}

export const CM = {
  "alpha2": "CM",
  "countryCode": "237",
  "name": "Cameroon"
}

export const CN = {
  "alpha2": "CN",
  "countryCode": "86",
  "name": "China"
}

export const CO = {
  "alpha2": "CO",
  "countryCode": "57",
  "name": "Colombia"
}

export const CR = {
  "alpha2": "CR",
  "countryCode": "506",
  "name": "Costa Rica"
}

export const CU = {
  "alpha2": "CU",
  "countryCode": "53",
  "name": "Cuba"
}

export const CV = {
  "alpha2": "CV",
  "countryCode": "238",
  "name": "Cabo Verde"
}

export const CW = {
  "alpha2": "CW",
  "countryCode": "599",
  "name": "Curaçao"
}

export const CX = {
  "alpha2": "CX",
  "countryCode": "61",
  "name": "Christmas Island"
}

export const CY = {
  "alpha2": "CY",
  "countryCode": "357",
  "name": "Cyprus"
}

export const CZ = {
  "alpha2": "CZ",
  "countryCode": "420",
  "name": "Czech Republic"
}

export const DE = {
  "alpha2": "DE",
  "countryCode": "49",
  "name": "Germany"
}

export const DJ = {
  "alpha2": "DJ",
  "countryCode": "253",
  "name": "Djibouti"
}

export const DK = {
  "alpha2": "DK",
  "countryCode": "45",
  "name": "Denmark"
}

export const DM = {
  "alpha2": "DM",
  "countryCode": "1",
  "name": "Dominica"
}

export const DO = {
  "alpha2": "DO",
  "countryCode": "1",
  "name": "Dominican Republic"
}

export const DZ = {
  "alpha2": "DZ",
  "countryCode": "213",
  "name": "Algeria"
}

export const EC = {
  "alpha2": "EC",
  "countryCode": "593",
  "name": "Ecuador"
}

export const EE = {
  "alpha2": "EE",
  "countryCode": "372",
  "name": "Estonia"
}

export const EG = {
  "alpha2": "EG",
  "countryCode": "20",
  "name": "Egypt"
}

export const EH = {
  "alpha2": "EH",
  "countryCode": "212",
  "name": "Western Sahara"
}

export const ER = {
  "alpha2": "ER",
  "countryCode": "291",
  "name": "Eritrea"
}

export const ES = {
  "alpha2": "ES",
  "countryCode": "34",
  "name": "Spain"
}

export const ET = {
  "alpha2": "ET",
  "countryCode": "251",
  "name": "Ethiopia"
}

export const FI = {
  "alpha2": "FI",
  "countryCode": "358",
  "name": "Finland"
}

export const FJ = {
  "alpha2": "FJ",
  "countryCode": "679",
  "name": "Fiji"
}

export const FK = {
  "alpha2": "FK",
  "countryCode": "500",
  "name": "Falkland Islands (Malvinas)"
}

export const FM = {
  "alpha2": "FM",
  "countryCode": "691",
  "name": "Micronesia (Federated States of)"
}

export const FO = {
  "alpha2": "FO",
  "countryCode": "298",
  "name": "Faroe Islands"
}

export const FR = {
  "alpha2": "FR",
  "countryCode": "33",
  "name": "France"
}

export const GA = {
  "alpha2": "GA",
  "countryCode": "241",
  "name": "Gabon"
}

export const GB = {
  "alpha2": "GB",
  "countryCode": "44",
  "name": "United Kingdom of Great Britain and Northern Ireland"
}

export const GD = {
  "alpha2": "GD",
  "countryCode": "1",
  "name": "Grenada"
}

export const GE = {
  "alpha2": "GE",
  "countryCode": "995",
  "name": "Georgia"
}

export const GF = {
  "alpha2": "GF",
  "countryCode": "594",
  "name": "French Guiana"
}

export const GG = {
  "alpha2": "GG",
  "countryCode": "44",
  "name": "Guernsey"
}

export const GH = {
  "alpha2": "GH",
  "countryCode": "233",
  "name": "Ghana"
}

export const GI = {
  "alpha2": "GI",
  "countryCode": "350",
  "name": "Gibraltar"
}

export const GL = {
  "alpha2": "GL",
  "countryCode": "299",
  "name": "Greenland"
}

export const GM = {
  "alpha2": "GM",
  "countryCode": "220",
  "name": "Gambia"
}

export const GN = {
  "alpha2": "GN",
  "countryCode": "224",
  "name": "Guinea"
}

export const GP = {
  "alpha2": "GP",
  "countryCode": "590",
  "name": "Guadeloupe"
}

export const GQ = {
  "alpha2": "GQ",
  "countryCode": "240",
  "name": "Equatorial Guinea"
}

export const GR = {
  "alpha2": "GR",
  "countryCode": "30",
  "name": "Greece"
}

export const GS = {
  "alpha2": "GS",
  "countryCode": "500",
  "name": "South Georgia and the South Sandwich Islands"
}

export const GT = {
  "alpha2": "GT",
  "countryCode": "502",
  "name": "Guatemala"
}

export const GU = {
  "alpha2": "GU",
  "countryCode": "1",
  "name": "Guam"
}

export const GW = {
  "alpha2": "GW",
  "countryCode": "245",
  "name": "Guinea-Bissau"
}

export const GY = {
  "alpha2": "GY",
  "countryCode": "592",
  "name": "Guyana"
}

export const HK = {
  "alpha2": "HK",
  "countryCode": "852",
  "name": "Hong Kong"
}

export const HM = {
  "alpha2": "HM",
  "countryCode": "",
  "name": "Heard Island and McDonald Islands"
}

export const HN = {
  "alpha2": "HN",
  "countryCode": "504",
  "name": "Honduras"
}

export const HR = {
  "alpha2": "HR",
  "countryCode": "385",
  "name": "Croatia"
}

export const HT = {
  "alpha2": "HT",
  "countryCode": "509",
  "name": "Haiti"
}

export const HU = {
  "alpha2": "HU",
  "countryCode": "36",
  "name": "Hungary"
}

export const ID = {
  "alpha2": "ID",
  "countryCode": "62",
  "name": "Indonesia"
}

export const IE = {
  "alpha2": "IE",
  "countryCode": "353",
  "name": "Ireland"
}

export const IL = {
  "alpha2": "IL",
  "countryCode": "972",
  "name": "Israel"
}

export const IM = {
  "alpha2": "IM",
  "countryCode": "44",
  "name": "Isle of Man"
}

export const IN = {
  "alpha2": "IN",
  "countryCode": "91",
  "name": "India"
}

export const IO = {
  "alpha2": "IO",
  "countryCode": "246",
  "name": "British Indian Ocean Territory"
}

export const IQ = {
  "alpha2": "IQ",
  "countryCode": "964",
  "name": "Iraq"
}

export const IR = {
  "alpha2": "IR",
  "countryCode": "98",
  "name": "Iran (Islamic Republic of)"
}

export const IS = {
  "alpha2": "IS",
  "countryCode": "354",
  "name": "Iceland"
}

export const IT = {
  "alpha2": "IT",
  "countryCode": "39",
  "name": "Italy"
}

export const JE = {
  "alpha2": "JE",
  "countryCode": "44",
  "name": "Jersey"
}

export const JM = {
  "alpha2": "JM",
  "countryCode": "1",
  "name": "Jamaica"
}

export const JO = {
  "alpha2": "JO",
  "countryCode": "962",
  "name": "Jordan"
}

export const JP = {
  "alpha2": "JP",
  "countryCode": "81",
  "name": "Japan"
}

export const KE = {
  "alpha2": "KE",
  "countryCode": "254",
  "name": "Kenya"
}

export const KG = {
  "alpha2": "KG",
  "countryCode": "996",
  "name": "Kyrgyzstan"
}

export const KH = {
  "alpha2": "KH",
  "countryCode": "855",
  "name": "Cambodia"
}

export const KI = {
  "alpha2": "KI",
  "countryCode": "686",
  "name": "Kiribati"
}

export const KM = {
  "alpha2": "KM",
  "countryCode": "269",
  "name": "Comoros"
}

export const KN = {
  "alpha2": "KN",
  "countryCode": "1",
  "name": "Saint Kitts and Nevis"
}

export const KP = {
  "alpha2": "KP",
  "countryCode": "850",
  "name": "Korea (Democratic People's Republic of)"
}

export const KR = {
  "alpha2": "KR",
  "countryCode": "82",
  "name": "Korea (Republic of)"
}

export const KW = {
  "alpha2": "KW",
  "countryCode": "965",
  "name": "Kuwait"
}

export const KY = {
  "alpha2": "KY",
  "countryCode": "1",
  "name": "Cayman Islands"
}

export const KZ = {
  "alpha2": "KZ",
  "countryCode": "7",
  "name": "Kazakhstan"
}

export const LA = {
  "alpha2": "LA",
  "countryCode": "856",
  "name": "Lao People's Democratic Republic"
}

export const LB = {
  "alpha2": "LB",
  "countryCode": "961",
  "name": "Lebanon"
}

export const LC = {
  "alpha2": "LC",
  "countryCode": "1",
  "name": "Saint Lucia"
}

export const LI = {
  "alpha2": "LI",
  "countryCode": "423",
  "name": "Liechtenstein"
}

export const LK = {
  "alpha2": "LK",
  "countryCode": "94",
  "name": "Sri Lanka"
}

export const LR = {
  "alpha2": "LR",
  "countryCode": "231",
  "name": "Liberia"
}

export const LS = {
  "alpha2": "LS",
  "countryCode": "266",
  "name": "Lesotho"
}

export const LT = {
  "alpha2": "LT",
  "countryCode": "370",
  "name": "Lithuania"
}

export const LU = {
  "alpha2": "LU",
  "countryCode": "352",
  "name": "Luxembourg"
}

export const LV = {
  "alpha2": "LV",
  "countryCode": "371",
  "name": "Latvia"
}

export const LY = {
  "alpha2": "LY",
  "countryCode": "218",
  "name": "Libya"
}

export const MA = {
  "alpha2": "MA",
  "countryCode": "212",
  "name": "Morocco"
}

export const MC = {
  "alpha2": "MC",
  "countryCode": "377",
  "name": "Monaco"
}

export const MD = {
  "alpha2": "MD",
  "countryCode": "373",
  "name": "Moldova (Republic of)"
}

export const ME = {
  "alpha2": "ME",
  "countryCode": "382",
  "name": "Montenegro"
}

export const MF = {
  "alpha2": "MF",
  "countryCode": "590",
  "name": "Saint Martin (French part)"
}

export const MG = {
  "alpha2": "MG",
  "countryCode": "261",
  "name": "Madagascar"
}

export const MH = {
  "alpha2": "MH",
  "countryCode": "692",
  "name": "Marshall Islands"
}

export const MK = {
  "alpha2": "MK",
  "countryCode": "389",
  "name": "Macedonia (the former Yugoslav Republic of)"
}

export const ML = {
  "alpha2": "ML",
  "countryCode": "223",
  "name": "Mali"
}

export const MM = {
  "alpha2": "MM",
  "countryCode": "95",
  "name": "Myanmar"
}

export const MN = {
  "alpha2": "MN",
  "countryCode": "976",
  "name": "Mongolia"
}

export const MO = {
  "alpha2": "MO",
  "countryCode": "853",
  "name": "Macao"
}

export const MP = {
  "alpha2": "MP",
  "countryCode": "1",
  "name": "Northern Mariana Islands"
}

export const MQ = {
  "alpha2": "MQ",
  "countryCode": "596",
  "name": "Martinique"
}

export const MR = {
  "alpha2": "MR",
  "countryCode": "222",
  "name": "Mauritania"
}

export const MS = {
  "alpha2": "MS",
  "countryCode": "1",
  "name": "Montserrat"
}

export const MT = {
  "alpha2": "MT",
  "countryCode": "356",
  "name": "Malta"
}

export const MU = {
  "alpha2": "MU",
  "countryCode": "230",
  "name": "Mauritius"
}

export const MV = {
  "alpha2": "MV",
  "countryCode": "960",
  "name": "Maldives"
}

export const MW = {
  "alpha2": "MW",
  "countryCode": "265",
  "name": "Malawi"
}

export const MX = {
  "alpha2": "MX",
  "countryCode": "52",
  "name": "Mexico"
}

export const MY = {
  "alpha2": "MY",
  "countryCode": "60",
  "name": "Malaysia"
}

export const MZ = {
  "alpha2": "MZ",
  "countryCode": "258",
  "name": "Mozambique"
}

export const NA = {
  "alpha2": "NA",
  "countryCode": "264",
  "name": "Namibia"
}

export const NC = {
  "alpha2": "NC",
  "countryCode": "687",
  "name": "New Caledonia"
}

export const NE = {
  "alpha2": "NE",
  "countryCode": "227",
  "name": "Niger"
}

export const NF = {
  "alpha2": "NF",
  "countryCode": "672",
  "name": "Norfolk Island"
}

export const NG = {
  "alpha2": "NG",
  "countryCode": "234",
  "name": "Nigeria"
}

export const NI = {
  "alpha2": "NI",
  "countryCode": "505",
  "name": "Nicaragua"
}

export const NL = {
  "alpha2": "NL",
  "countryCode": "31",
  "name": "Netherlands"
}

export const NO = {
  "alpha2": "NO",
  "countryCode": "47",
  "name": "Norway"
}

export const NP = {
  "alpha2": "NP",
  "countryCode": "977",
  "name": "Nepal"
}

export const NR = {
  "alpha2": "NR",
  "countryCode": "674",
  "name": "Nauru"
}

export const NU = {
  "alpha2": "NU",
  "countryCode": "683",
  "name": "Niue"
}

export const NZ = {
  "alpha2": "NZ",
  "countryCode": "64",
  "name": "New Zealand"
}

export const OM = {
  "alpha2": "OM",
  "countryCode": "968",
  "name": "Oman"
}

export const PA = {
  "alpha2": "PA",
  "countryCode": "507",
  "name": "Panama"
}

export const PE = {
  "alpha2": "PE",
  "countryCode": "51",
  "name": "Peru"
}

export const PF = {
  "alpha2": "PF",
  "countryCode": "689",
  "name": "French Polynesia"
}

export const PG = {
  "alpha2": "PG",
  "countryCode": "675",
  "name": "Papua New Guinea"
}

export const PH = {
  "alpha2": "PH",
  "countryCode": "63",
  "name": "Philippines"
}

export const PK = {
  "alpha2": "PK",
  "countryCode": "92",
  "name": "Pakistan"
}

export const PL = {
  "alpha2": "PL",
  "countryCode": "48",
  "name": "Poland"
}

export const PM = {
  "alpha2": "PM",
  "countryCode": "508",
  "name": "Saint Pierre and Miquelon"
}

export const PN = {
  "alpha2": "PN",
  "countryCode": "64",
  "name": "Pitcairn"
}

export const PR = {
  "alpha2": "PR",
  "countryCode": "1",
  "name": "Puerto Rico"
}

export const PS = {
  "alpha2": "PS",
  "countryCode": "970",
  "name": "Palestine, State of"
}

export const PT = {
  "alpha2": "PT",
  "countryCode": "351",
  "name": "Portugal"
}

export const PW = {
  "alpha2": "PW",
  "countryCode": "680",
  "name": "Palau"
}

export const PY = {
  "alpha2": "PY",
  "countryCode": "595",
  "name": "Paraguay"
}

export const QA = {
  "alpha2": "QA",
  "countryCode": "974",
  "name": "Qatar"
}

export const RE = {
  "alpha2": "RE",
  "countryCode": "262",
  "name": "Réunion"
}

export const RO = {
  "alpha2": "RO",
  "countryCode": "40",
  "name": "Romania"
}

export const RS = {
  "alpha2": "RS",
  "countryCode": "381",
  "name": "Serbia"
}

export const RU = {
  "alpha2": "RU",
  "countryCode": "7",
  "name": "Russian Federation"
}

export const RW = {
  "alpha2": "RW",
  "countryCode": "250",
  "name": "Rwanda"
}

export const SA = {
  "alpha2": "SA",
  "countryCode": "966",
  "name": "Saudi Arabia"
}

export const SB = {
  "alpha2": "SB",
  "countryCode": "677",
  "name": "Solomon Islands"
}

export const SC = {
  "alpha2": "SC",
  "countryCode": "248",
  "name": "Seychelles"
}

export const SD = {
  "alpha2": "SD",
  "countryCode": "249",
  "name": "Sudan"
}

export const SE = {
  "alpha2": "SE",
  "countryCode": "46",
  "name": "Sweden"
}

export const SG = {
  "alpha2": "SG",
  "countryCode": "65",
  "name": "Singapore"
}

export const SH = {
  "alpha2": "SH",
  "countryCode": "290",
  "name": "Saint Helena, Ascension and Tristan da Cunha"
}

export const SI = {
  "alpha2": "SI",
  "countryCode": "386",
  "name": "Slovenia"
}

export const SJ = {
  "alpha2": "SJ",
  "countryCode": "47",
  "name": "Svalbard and Jan Mayen"
}

export const SK = {
  "alpha2": "SK",
  "countryCode": "421",
  "name": "Slovakia"
}

export const SL = {
  "alpha2": "SL",
  "countryCode": "232",
  "name": "Sierra Leone"
}

export const SM = {
  "alpha2": "SM",
  "countryCode": "378",
  "name": "San Marino"
}

export const SN = {
  "alpha2": "SN",
  "countryCode": "221",
  "name": "Senegal"
}

export const SO = {
  "alpha2": "SO",
  "countryCode": "252",
  "name": "Somalia"
}

export const SR = {
  "alpha2": "SR",
  "countryCode": "597",
  "name": "Suriname"
}

export const SS = {
  "alpha2": "SS",
  "countryCode": "211",
  "name": "South Sudan"
}

export const ST = {
  "alpha2": "ST",
  "countryCode": "239",
  "name": "Sao Tome and Principe"
}

export const SV = {
  "alpha2": "SV",
  "countryCode": "503",
  "name": "El Salvador"
}

export const SX = {
  "alpha2": "SX",
  "countryCode": "1",
  "name": "Sint Maarten (Dutch part)"
}

export const SY = {
  "alpha2": "SY",
  "countryCode": "963",
  "name": "Syrian Arab Republic"
}

export const SZ = {
  "alpha2": "SZ",
  "countryCode": "268",
  "name": "eSwatini"
}

export const TC = {
  "alpha2": "TC",
  "countryCode": "1",
  "name": "Turks and Caicos Islands"
}

export const TD = {
  "alpha2": "TD",
  "countryCode": "235",
  "name": "Chad"
}

export const TF = {
  "alpha2": "TF",
  "countryCode": "262",
  "name": "French Southern Territories"
}

export const TG = {
  "alpha2": "TG",
  "countryCode": "228",
  "name": "Togo"
}

export const TH = {
  "alpha2": "TH",
  "countryCode": "66",
  "name": "Thailand"
}

export const TJ = {
  "alpha2": "TJ",
  "countryCode": "992",
  "name": "Tajikistan"
}

export const TK = {
  "alpha2": "TK",
  "countryCode": "690",
  "name": "Tokelau"
}

export const TL = {
  "alpha2": "TL",
  "countryCode": "670",
  "name": "Timor-Leste"
}

export const TM = {
  "alpha2": "TM",
  "countryCode": "993",
  "name": "Turkmenistan"
}

export const TN = {
  "alpha2": "TN",
  "countryCode": "216",
  "name": "Tunisia"
}

export const TO = {
  "alpha2": "TO",
  "countryCode": "676",
  "name": "Tonga"
}

export const TR = {
  "alpha2": "TR",
  "countryCode": "90",
  "name": "Turkey"
}

export const TT = {
  "alpha2": "TT",
  "countryCode": "1",
  "name": "Trinidad and Tobago"
}

export const TV = {
  "alpha2": "TV",
  "countryCode": "688",
  "name": "Tuvalu"
}

export const TW = {
  "alpha2": "TW",
  "countryCode": "886",
  "name": "Taiwan, Province of China"
}

export const TZ = {
  "alpha2": "TZ",
  "countryCode": "255",
  "name": "Tanzania, United Republic of"
}

export const UA = {
  "alpha2": "UA",
  "countryCode": "380",
  "name": "Ukraine"
}

export const UG = {
  "alpha2": "UG",
  "countryCode": "256",
  "name": "Uganda"
}

export const UM = {
  "alpha2": "UM",
  "countryCode": "1",
  "name": "United States Minor Outlying Islands"
}

export const US = {
  "alpha2": "US",
  "countryCode": "1",
  "name": "United States of America"
}

export const UY = {
  "alpha2": "UY",
  "countryCode": "598",
  "name": "Uruguay"
}

export const UZ = {
  "alpha2": "UZ",
  "countryCode": "998",
  "name": "Uzbekistan"
}

export const VA = {
  "alpha2": "VA",
  "countryCode": "39",
  "name": "Holy See"
}

export const VC = {
  "alpha2": "VC",
  "countryCode": "1",
  "name": "Saint Vincent and the Grenadines"
}

export const VE = {
  "alpha2": "VE",
  "countryCode": "58",
  "name": "Venezuela (Bolivarian Republic of)"
}

export const VG = {
  "alpha2": "VG",
  "countryCode": "1",
  "name": "Virgin Islands (British)"
}

export const VI = {
  "alpha2": "VI",
  "countryCode": "1",
  "name": "Virgin Islands (U.S.)"
}

export const VN = {
  "alpha2": "VN",
  "countryCode": "84",
  "name": "Viet Nam"
}

export const VU = {
  "alpha2": "VU",
  "countryCode": "678",
  "name": "Vanuatu"
}

export const WF = {
  "alpha2": "WF",
  "countryCode": "681",
  "name": "Wallis and Futuna"
}

export const WS = {
  "alpha2": "WS",
  "countryCode": "685",
  "name": "Samoa"
}

export const YE = {
  "alpha2": "YE",
  "countryCode": "967",
  "name": "Yemen"
}

export const YT = {
  "alpha2": "YT",
  "countryCode": "262",
  "name": "Mayotte"
}

export const ZA = {
  "alpha2": "ZA",
  "countryCode": "27",
  "name": "South Africa"
}

export const ZM = {
  "alpha2": "ZM",
  "countryCode": "260",
  "name": "Zambia"
}

export const ZW = {
  "alpha2": "ZW",
  "countryCode": "263",
  "name": "Zimbabwe"
}

const Countries = { AD, AE, AF, AG, AI, AL, AM, AN, AO, AQ, AR, AS, AT, AU, AW, AX, AZ, BA, BB, BD, BE, BF, BG, BH, BI, BJ, BL, BM, BN, BO, BQ, BR, BS, BT, BV, BW, BY, BZ, CA, CC, CD, CF, CG, CH, CI, CK, CL, CM, CN, CO, CR, CU, CV, CW, CX, CY, CZ, DE, DJ, DK, DM, DO, DZ, EC, EE, EG, EH, ER, ES, ET, FI, FJ, FK, FM, FO, FR, GA, GB, GD, GE, GF, GG, GH, GI, GL, GM, GN, GP, GQ, GR, GS, GT, GU, GW, GY, HK, HM, HN, HR, HT, HU, ID, IE, IL, IM, IN, IO, IQ, IR, IS, IT, JE, JM, JO, JP, KE, KG, KH, KI, KM, KN, KP, KR, KW, KY, KZ, LA, LB, LC, LI, LK, LR, LS, LT, LU, LV, LY, MA, MC, MD, ME, MF, MG, MH, MK, ML, MM, MN, MO, MP, MQ, MR, MS, MT, MU, MV, MW, MX, MY, MZ, NA, NC, NE, NF, NG, NI, NL, NO, NP, NR, NU, NZ, OM, PA, PE, PF, PG, PH, PK, PL, PM, PN, PR, PS, PT, PW, PY, QA, RE, RO, RS, RU, RW, SA, SB, SC, SD, SE, SG, SH, SI, SJ, SK, SL, SM, SN, SO, SR, SS, ST, SV, SX, SY, SZ, TC, TD, TF, TG, TH, TJ, TK, TL, TM, TN, TO, TR, TT, TV, TW, TZ, UA, UG, UM, US, UY, UZ, VA, VC, VE, VG, VI, VN, VU, WF, WS, YE, YT, ZA, ZM, ZW }

/** Country options which can be given to a Select component. */
export const CountryOptions = Object.values(Countries)
  .sort((a, b) => a.name.localeCompare(b.name))
  .map(c => ({
    label: c.name,
    value: c.alpha2
  }))

export default Countries
