import { useState, useEffect } from "react"

// Tailwind breakpoints
// It would be good to read these from tailwind config, this would require
// dumping the compiled config somewhere from where it can be imported.
const SCREENS = [
  ["2xl", 1536],
  ["xl", 1280],
  ["lg", 1024],
  ["md", 768],
  ["sm", 640],
  ["xs", 0]
]

const getBreakpoint = screenWidth => {
  for (const [breakpoint, breakpointWidth] of SCREENS) {
    if (screenWidth >= breakpointWidth) {
      return breakpoint
    }
  }
}

/**
 * React hook which returns current window height and width.
 */
export const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return { width, height }
}

/**
 * React hook which returns the tailwind breakpoint based on the current window
 * width. Breakpoints are one of: 2xl, xl, lg, md, sm and xs.
 */
export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(getBreakpoint(window.innerWidth))

  useEffect(() => {
    const handleResize = () => {
      setBreakpoint(getBreakpoint(window.innerWidth))
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return breakpoint
}
