import AppDownloadButtons from "@sonato/core/components/AppDownloadButtons"
import CobrandedLogos from "@sonato/core/components/CobrandedLogos"
import ColorMode from "@sonato/core/components/ColorMode"
import Overline from "@sonato/core/components/Overline"
import ArrowDownIcon from "@sonato/core/icons/ArrowDownIcon"
import SignIn from "@sonato/core/pages/authentication/SignIn"
import { marketingUrl, privacyUrl, signupUrl } from "@sonato/core/urls"
import accessIllustration from "assets/illustrations/accessIllustration.svg"
import downloadIllustration from "assets/illustrations/downloadIllustration.svg"
import joinIllustration from "assets/illustrations/joinIllustration.svg"
import appTwoPhonesImage from "assets/images/appTwoPhones.jpg"
import marketingBottomImage from "assets/images/homepage/marketingBottom.jpg"
import marketingTopImage from "assets/images/homepage/marketingTop.jpg"
import classNames from "classnames"
import * as React from "react"
import { Trans, useTranslation } from "react-i18next"
import { useCurrentLocation } from "./hooks"

const Home = () => {
  const ref = React.useRef()

  const { data: location } = useCurrentLocation({ suspense: true })

  if (!location) {
    window.location = marketingUrl()
    return null
  }

  const onClickLearnHow = () => {
    const top = ref.current.offsetTop
    window.scrollTo({ top, behavior: "smooth" })
  }

  return (
    <div>
      <Splash location={location} onClickLearnHow={onClickLearnHow} />
      <HowItWorks ref={ref} />
      <GetTheApp />
      <StartExploring location={location} />
      <Footer location={location} />
    </div>
  )
}

const Header = ({ location }) => {
  return (
    <div className="flex justify-between">
      <CobrandedLogos colorMode={ColorMode.dark} location={location} />
      <div className="hidden md:flex items-center space-x-8">
        <Overline.Medium>
          <a href={SignIn.url(location.subdomain)}>
            <Trans>Login</Trans>
          </a>
        </Overline.Medium>
        <Overline.Medium>
          <a href={signupUrl(location.subdomain)}>
            <Trans>Sign Up</Trans>
          </a>
        </Overline.Medium>
      </div>
    </div>
  )
}

const Splash = ({ location, onClickLearnHow }) => {
  const { t } = useTranslation()
  const name = location.name
  const backgroundImage = `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${marketingTopImage})`

  return (
    <div
      className="flex flex-col justify-between h-screen bg-cover bg-center text-white"
      style={{ backgroundImage }}
    >
      <div className="px-6 py-3 sm:px-8 sm:py-5">
        <Header location={location} />
      </div>
      <div className="px-8 sm:px-10">
        <div className="space-y-6 sm:space-y-8 text-center">
          <p className="font-bold sm:text-xl">{name} + Sonato Alliance</p>
          <h3 className="text-3xl leading-h3 sm:text-5xl sm:leading-h1 max-w-4xl mx-auto">
            <Trans>Access the best private clubs around the world.</Trans>
          </h3>
          <p className="font-bold sm:text-xl sm:leading-h5 max-w-xl mx-auto">
            <Trans>
              {{ name }} has partnered with Sonato Alliance to upgrade reciprocity and get you into
              clubs around the world.
            </Trans>
          </p>
          <div className="flex sm:hidden justify-center space-x-3">
            <AppDownloadButtons buttonStyle="light" />
          </div>
          <div>
            <div className="sm:hidden">
              <Trans>
                <a className="hover:text-gray-300" href={signupUrl(location.subdomain)}>
                  or <span className="font-bold">sign up</span> on the web
                </a>
              </Trans>
            </div>
            <div className="hidden sm:flex">
              <a
                className="block px-7 py-3.5 mx-auto bg-white text-gray-900 font-bold rounded shadow transform active:scale-100 hover:scale-102 transition ease-in-out duration-300"
                href={signupUrl(location.subdomain)}
              >
                <Trans>Sign Up for Sonato</Trans>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="my-10 flex">
        <button
          className="mx-auto focus:outline-none transform hover:text-gray-300"
          onClick={onClickLearnHow}
        >
          <span className="hidden sm:block font-bold">
            <Trans>Learn How</Trans>
          </span>
          <ArrowDownIcon
            className="mx-auto mt-5 w-6 h-6 sm:w-8 sm:h-8 animate-bounce"
            title={t("Learn How")}
          />
        </button>
      </div>
    </div>
  )
}

const ExternalLink = ({ children, to }) => {
  return (
    <a
      className="font-bold text-gold-900 hover:text-gold-700"
      href={to}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </a>
  )
}

const Card = ({ children, illustration, number, right = false, transform = "", title }) => (
  <div
    className={classNames(
      "bg-white rounded shadow lg:flex lg:h-96 items-center",
      right ? "ml-auto" : "mr-auto"
    )}
    style={{ maxWidth: 928 }}
  >
    {right && (
      <div className="flex-none hidden lg:block w-144 h-96">
        <img alt={title} className="object-cover object-right h-full w-full" src={illustration} />
      </div>
    )}
    <div className="p-8 lg:px-15">
      <div className="w-12 h-12 rounded-full bg-gold-900 flex justify-center items-center">
        <Overline.Medium className="font-bold text-white">{number}</Overline.Medium>
      </div>
      <h3 className="my-5">{title}</h3>
      <p className="text-gray-500">{children}</p>
    </div>
    <img
      alt={title}
      className="object-cover object-top h-56 sm:h-80 w-full lg:hidden"
      src={illustration}
    />
    {!right && (
      <div className="flex-none hidden lg:block w-144 h-96">
        <img
          alt={title}
          className={`object-cover object-center h-full w-full ${transform}`}
          src={illustration}
        />
      </div>
    )}
  </div>
)

/* eslint-disable react/display-name */
const HowItWorks = React.forwardRef((_props, ref) => {
  const { t } = useTranslation()
  return (
    <div ref={ref} className="flex bg-gold-100">
      <div className="px-5 py-5 sm:px-12 sm:py-10 w-full max-w-screen-xl mx-auto">
        <h2 className="text-center sm:text-h1 lg:text-left">
          <Trans>How It Works</Trans>
        </h2>
        <div className="my-3 space-y-8 sm:my-10 sm:space-y-12 max-w-screen-xl">
          <Card
            illustration={joinIllustration}
            number="01"
            title={t("Join Sonato Alliance")}
            transform="transform scale-105 origin-bottom"
          >
            <Trans>
              Your home club has invited you to join the Sonato Alliance. You can sign up from this
              page or download our app.
            </Trans>
          </Card>
          <Card
            illustration={downloadIllustration}
            number="02"
            right={true}
            title={t("Download the App")}
          >
            <Trans>
              Download on{" "}
              <ExternalLink to="https://apps.apple.com/us/app/sonato-alliance/id1553756187">
                iOS
              </ExternalLink>
              {" or "}
              <ExternalLink to="https://play.google.com/store/apps/details?id=com.sonato.members">
                Android
              </ExternalLink>{" "}
              to get the full experience and communicate with the clubs you want to visit.
            </Trans>
          </Card>
          <Card illustration={accessIllustration} number="03" title={t("Access premier clubs")}>
            <Trans>
              When you travel, for work or play, enjoy clubs around the world as well as added
              benefits: hotel discounts, coworking, fitness, and spas.
            </Trans>
          </Card>
        </div>
      </div>
    </div>
  )
})

const GetTheApp = () => {
  return (
    <div className="flex bg-white">
      <div className="hidden sm:block mt-8 mb-16 mx-auto max-w-screen-xl lg:flex lg:px-15 lg:space-x-15 xl:space-x-24">
        <div className="flex items-start">
          <img
            alt="mobile app"
            className="w-full max-w-md xl:max-w-lg mx-auto"
            src={appTwoPhonesImage}
          />
        </div>
        <div className="sm:flex items-center justify-center">
          <div className="space-y-8 text-center lg:text-left">
            <h1>
              <Trans>Get the app</Trans>
            </h1>
            <p className="w-96 mx-auto">
              <Trans>
                The Sonato mobile app is the easiest way to find partner clubs and book visits on
                the go. Download it now to get started.
              </Trans>
            </p>
            <div className="flex justify-center space-x-5 lg:ml-0">
              <AppDownloadButtons
                buttonStyle="dark"
                buttonWidth="w-36"
                showAppStore={true}
                showGooglePlay={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StartExploring = ({ location }) => {
  const name = location.name
  const backgroundImage = `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${marketingBottomImage})`

  return (
    <div
      className="flex flex-col py-40 sm:py-72 bg-cover bg-center text-white"
      style={{ backgroundImage }}
    >
      <div className="mx-auto max-w-xs space-y-6 sm:max-w-md sm:space-y-8 lg:max-w-xl text-center">
        <h1>
          <Trans>Start Exploring</Trans>
        </h1>
        <p className="font-bold sm:text-xl sm:leading-h5">
          <Trans>
            {{ name }} has partnered with Sonato Alliance to grant you access to similar clubs
            around the world. Once you join, a whole new world opens up to you.
          </Trans>
        </p>
        <div className="flex sm:hidden justify-center space-x-3">
          <AppDownloadButtons buttonStyle="light" />
        </div>
        <div>
          <div className="sm:hidden">
            <Trans>
              <a className="hover:text-gray-300" href={signupUrl(location.subdomain)}>
                or <span className="font-bold">sign up</span> on the web
              </a>
            </Trans>
          </div>
          <div className="hidden sm:flex">
            <a
              className="block px-7 py-3.5 mx-auto bg-white text-gray-900 font-bold rounded shadow transform active:scale-100 hover:scale-102 transition ease-in-out duration-300"
              href={signupUrl(location.subdomain)}
            >
              <Trans>Sign Up for Sonato</Trans>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const Footer = ({ location }) => {
  return (
    <div
      className="px-8 py-8 lg:px-12 lg:py-12 text-white"
      style={{ backgroundColor: location.brandColor }}
    >
      <div className="flex items-center justify-between">
        <CobrandedLogos colorMode={ColorMode.dark} location={location} />
        <div className="hidden lg:flex space-x-3">
          <AppDownloadButtons buttonStyle="clear" />
        </div>
      </div>
      <div className="mt-10 sm:flex sm:justify-between">
        <div>
          <p>245 5th Street, San Francisco, CA 94103</p>
          <p>
            <a href="mailto:hello@sonato.com">hello@sonato.com</a>
          </p>
        </div>
        <div className="flex mt-6 space-x-4 opacity-40">
          <Overline.Small>
            <a href={privacyUrl()}>
              <Trans>Privacy Policy</Trans>
            </a>
          </Overline.Small>
          <Overline.Small>© 2022</Overline.Small>
        </div>
      </div>
    </div>
  )
}

export default Home
