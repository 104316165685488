import React from "react"
import PropTypes from "prop-types"

const ChevronLeftIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M5 7.988c0-.136.028-.27.081-.395a.957.957 0 01.23-.325L9.763 3.18a.717.717 0 011.028.081.79.79 0 01-.054 1.074L6.844 7.91a.104.104 0 00-.034.078.108.108 0 00.034.079l3.893 3.574a.766.766 0 01.26.531.8.8 0 01-.18.567.712.712 0 01-1.053.058L5.312 8.71a.96.96 0 01-.23-.326.998.998 0 01-.083-.396z"
      fill="currentColor"
    />
  </svg>
)

ChevronLeftIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ChevronLeftIcon svgRef={ref} {...props} />)
export default ForwardRef
