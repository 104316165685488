import React from "react"
import PropTypes from "prop-types"

const ActionsUpdateSimpleIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.14 3.859a.88.88 0 00-1.245 0L3.95 10.803l-.207 1.453 1.453-.208 6.943-6.944a.88.88 0 000-1.245zM9.763 2.727a2.482 2.482 0 113.51 3.51L6.14 13.368a.8.8 0 01-.453.226l-2.773.397a.8.8 0 01-.906-.906l.396-2.774a.8.8 0 01.226-.453l7.133-7.132z"
      fill="currentColor"
    />
  </svg>
)

ActionsUpdateSimpleIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsUpdateSimpleIcon svgRef={ref} {...props} />
))
export default ForwardRef
