import React from "react"
import PropTypes from "prop-types"

const EssentialsContactEmailIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.245 2a2.14 2.14 0 00-1.587.729A2.63 2.63 0 001 4.488v7.024c0 .66.237 1.293.658 1.76A2.14 2.14 0 003.245 14h9.51a2.14 2.14 0 001.587-.729A2.63 2.63 0 0015 11.512V4.488a2.63 2.63 0 00-.658-1.76A2.14 2.14 0 0012.755 2h-9.51zM13.68 5.807L9.19 8.913c-.357.247-.77.378-1.19.378-.42 0-.833-.13-1.19-.378L2.32 5.807v5.705c0 .272.098.532.272.725.173.192.408.3.653.3h9.51c.245 0 .48-.108.653-.3.174-.193.271-.453.271-.725V5.807zM2.592 3.763a.881.881 0 01.653-.3h9.51c.245 0 .48.108.653.3.094.104.165.227.21.36L8.49 7.674a.86.86 0 01-.49.155.86.86 0 01-.49-.156L2.38 4.124c.046-.134.117-.257.21-.36z"
      fill="currentColor"
    />
  </svg>
)

EssentialsContactEmailIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsContactEmailIcon svgRef={ref} {...props} />
))
export default ForwardRef
