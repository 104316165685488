import i18n from "i18next"
import { Client } from "@sonato/halo"
import { useEffect, useState } from "react"
import * as Storage from "@sonato/core/storage"
import * as URLs from "./urls"

const apiUrl = URLs.apiURL()
const client = new Client({ debug: true })
const language = i18n.language

function connect() {
  // When the authentication token is passed as a query parameter after signing
  // in, store it locally and remove it from the URL to prevent it leaking if a
  // user shares the URL.
  const queryParams = new URLSearchParams(window.location.search)
  const urlToken = queryParams.get("t")

  if (urlToken) {
    Storage.setAuthToken(urlToken)
    queryParams.delete("t")
    window.location.search = queryParams.toString()
    return
  }

  const token = Storage.getAuthToken()
  const subdomain = URLs.currentSubdomain()
  client.connect(apiUrl, { token, language, subdomain })
}

// Avoid automatically connecting on mobile which handles connection differently
if (!window.avoidClientAutoConnect) {
  connect()
}

/**
 * The callback function for useSubscription.
 *
 * @callback subscriptionCallback
 * @param {Object} payload - The payload of the event.
 * @param {string} eventName - The name of the event.
 */

/**
 * Subscribe to a topic.
 *
 * @param {string} topicName - The subscription topic.
 * @param {subscriptionCallback} callback - The callback to invoke when a matching topic arrives.
 * @param {string|string[]} [eventName] - If specified, only invoke the callback if the event name
 *   matches this string or array of strings.
 */
const useSubscription = (topicName, callback, eventName) => {
  useEffect(() => {
    return client.subscribe(topicName, callback, eventName)
  }, [topicName, callback, eventName])
}

/**
 * Make an API call to fetch data.
 *
 * Returns a list of:
 *
 * - data - data returned by the API call if successful, null while loading or on error
 * - error - error returned by the API call if failed, null while loading or on success
 * - isLoading - boolean flag which is true until the call is completed
 *
 * @param {string} namespace - The namespace of the event.
 * @param {string} event - The event name.
 * @param {object|null} payload - Payload to pass to the event handler.
 */
const useApiCall = (namespace, event, payload = null) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setLoading] = useState(true)

  // Used to forca a reload
  const [reloadCounter, setReloadCounter] = useState(0)
  const reload = () => setReloadCounter(reloadCounter + 1)

  // This is an ugly hack that prevents endless loops when payload is not a
  // primitive.
  //
  // `useEffect` uses `Object.is()` to check if a dependency changed. However,
  // non-primitives such as objects and lists cannot be compared that way, the
  // comparison always returns `false`. Therefore, if payload is passed as a
  // dependency for `useEffect` and a non-primitive payload is passed into the
  // hook, that will cause an infinite re-render loop.acc
  //
  // This workaround serializes the payload to JSON and uses that as a
  // dependency instead.
  const serialized = JSON.stringify(payload)

  useEffect(() => {
    const onSuccess = response => {
      setData(response.reply)
      setLoading(false)
    }

    const onError = response => {
      setError(response.error || true)
      setLoading(false)
    }

    const deserialized = JSON.parse(serialized)

    client.call(namespace, event, deserialized, onSuccess, onError)
  }, [namespace, event, serialized, reloadCounter])

  return [data, error, isLoading, reload]
}

// async/await call helper: await call("members/notifications/refresh")
const call = (name, params = {}) => {
  const pieces = name.split("/")
  const eventName = pieces.pop()
  const namespace = pieces.join("/")
  return new Promise((resolve, reject) => {
    client.call(
      namespace,
      eventName,
      params,
      ({ reply }) => resolve(reply),
      ({ error }) => {
        console.error(`${name} unexpected error`, error)
        reject(error)
      }
    )
  })
}

let ajaxRequestId = 0

const ajaxLogger = (method, path) => {
  const requestId = ++ajaxRequestId
  const startTime = new Date()
  const duration = () => `${new Date() - startTime}ms`

  const decodingError = response => {
    console.log(
      `%c[AJAX:${requestId}] <- ${method} ${path} FAILED DECODING JSON`,
      "color: red",
      duration(),
      response
    )
  }

  // Called when fetch fails
  const error = error => {
    console.log(
      `%c[AJAX:${requestId}] <- ${method} ${path} FETCH ERROR`,
      "color: red",
      duration(),
      error
    )
  }

  return { decodingError, error }
}

const ajaxReq = (
  domain,
  method,
  path,
  { payload = {}, query = {}, token = null, headers = {} } = {}
) => {
  const url = new URL(`https://${domain}${path}`)
  method = method.toUpperCase()

  headers["Accept"] = "application/json"
  headers["Content-Type"] = "application/json"

  if (token) {
    headers["Authorization"] = `Bearer ${token}`
  }

  const fetchParams = { method, headers }

  if (method === "GET") {
    for (const key of Object.keys(query)) {
      const value = query[key] || ""

      if (Array.isArray(value)) {
        for (const item of value) {
          url.searchParams.append(`${key}[]`, item)
        }
      } else {
        url.searchParams.append(key, value)
      }
    }
  }

  if (method === "POST") {
    fetchParams.body = JSON.stringify(payload)
  }

  return new Promise((resolve, reject) => {
    const logger = ajaxLogger(method, path)

    fetch(url, fetchParams)
      .then(response => {
        response
          .json()
          .then(decoded => {
            if (response.ok) {
              resolve(decoded)
            } else {
              reject(decoded)
            }
          })
          .catch(() => {
            logger.decodingError(response)
            reject("An unexpected error occured. If it persists please contact support@sonato.com")
          })
      })
      .catch(error => {
        logger.error(error)
        reject(error)
      })
  })
}

export { ajaxReq, call, client, useSubscription, useApiCall }
