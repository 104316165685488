import React from "react"
import PropTypes from "prop-types"

const ChevronFilledRightIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M6.607 10.94l3.199-2.602a.398.398 0 000-.68s-3.139-2.566-3.2-2.603a.398.398 0 00-.604.34V10.6a.398.398 0 00.605.34z"
      fill="currentColor"
    />
  </svg>
)

ChevronFilledRightIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ChevronFilledRightIcon svgRef={ref} {...props} />
))
export default ForwardRef
