import React from "react"
import PropTypes from "prop-types"

const ChevronFilledLeftIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M9.393 5.055L6.194 7.657a.398.398 0 000 .681s3.139 2.566 3.2 2.602a.397.397 0 00.604-.34V5.396a.398.398 0 00-.605-.34z"
      fill="currentColor"
    />
  </svg>
)

ChevronFilledLeftIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ChevronFilledLeftIcon svgRef={ref} {...props} />
))
export default ForwardRef
