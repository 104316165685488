import React from "react"
import Badge from "@sonato/core/components/Badge"
import Tooltip from "@sonato/core/components/Tooltip"
import { extendedNetworkCopy } from "@sonato/core/utils/location"
import { locationShape } from "@sonato/core/shapes"

const ExtendedNetworkBadge = ({ location = null, useTooltip = true }) => {
  const badge = (
    <Badge color="orange" icon="EssentialsUnlockFilledIcon">
      Legacy
    </Badge>
  )
  if (useTooltip && location) {
    const tooltipText = extendedNetworkCopy(location)
    return <Tooltip trigger={badge}>{tooltipText}</Tooltip>
  }
  return badge
}

ExtendedNetworkBadge.propTypes = {
  location: locationShape
}

export default ExtendedNetworkBadge
