// Validation for image uploads

import { useTranslation } from "react-i18next"

const SUPPORTED_TYPES = ["image/bmp", "image/gif", "image/jpeg", "image/png", "image/webp"]

/** Can be given to `accept` param on a file input element */
export const ACCEPT_TYPES = SUPPORTED_TYPES.join(",")

const MAX_SIZE_MB = 10

export function useValidateImage() {
  const { t } = useTranslation()

  /**
   * Validates image for upload. Returns null on success, or an error on failure.
   * @param {File} image
   */
  return file => {
    if (!SUPPORTED_TYPES.includes(file.type)) {
      return t("Unsupported file type. Please choose an image.")
    }

    const size = file.size / Math.pow(1024, 2)
    if (size > MAX_SIZE_MB) {
      return t("Image is too large. Max allowed size is {{ max }}MB.", { max: MAX_SIZE_MB })
    }

    return null
  }
}

/**
 * Returns the URL to the user's avatar image.
 *
 * The image is cropped and scaled to given size and uses Uploadcare's smart
 * algorithm to focus on faces, or other objects if no faces are found.
 *
 * https://uploadcare.com/docs/transformations/image/resize-crop/#operation-smart-crop
 */
export function userAvatarURL(user, width = 400, height = 400) {
  return user.avatar.url + `-/scale_crop/${width}x${height}/smart/`
}

export function uploadcareURL(uuid, width = 400, height = 400) {
  return `https://ucarecdn.com/${uuid}/-/scale_crop/${width}x${height}/smart/`
}

export function locationImageURL(location, imageKey, height = 800) {
  const image = location?.uploadcareImages[imageKey]

  if (!image) {
    console.error(`Image key '${imageKey}' not found.`)
    return null
  }

  // For images with a defined aspect ratio, enforce that ratio, and for images
  // without keep the original aspect ratio.
  if (image.aspect) {
    const [w, h] = image.aspect
    const width = Math.round((w * height) / h)
    return `${image.url}-/scale_crop/${width}x${height}/center/`
  } else {
    return `${image.url}-/resize/x${height}/`
  }
}

/**
 * Returns a list of hero images with URL adjusted to the desired image size.
 */
export function locationHeroImages(location, width = 2100, height = 800) {
  return location.uploadcareHeroImages.map(image => {
    const url = `${image.url}-/scale_crop/${width}x${height}/center/`
    return { ...image, url: url }
  })
}

/**
 * Returns a list of hotel images with URL adjusted to the desired image size.
 */
export function locationHotelImages(location, width = 2100, height = 800) {
  return location.uploadcareHotelImages.map(image => {
    const url = `${image.url}-/scale_crop/${width}x${height}/center/`
    return { ...image, url: url }
  })
}

export function locationHeroImageURLs(location, width = 2100, height = 800) {
  return location.uploadcareHeroImages.map(
    image => `${image.url}-/scale_crop/${width}x${height}/center/`
  )
}

export function locationHotelImageURLs(location, width = 2100, height = 800) {
  return location.uploadcareHotelImages.map(
    image => `${image.url}-/scale_crop/${width}x${height}/center/`
  )
}

/**
 * Returns the URL to a placeholder avatar image, used when user has no avatar.
 *
 * This is usually not needed since User's avatar is always populated, and a
 * placeholder is served when user has no avatar. Used in places the User
 * object is not available.
 *
 * TODO: Replace hardcoded value with placeholder hosted on Uploadcare. Requires
 * propagating the placeholder image URL from serverside.
 */
export function placeholderAvatarURL() {
  return "/avatar_placeholder.svg"
}

/**
 * Uploads an image to Uploadcare.
 */
export async function uploadImage(file, metadata, signature, publicKey, expiresAt) {
  const body = new FormData()
  body.append(file.name, file)
  body.append("UPLOADCARE_PUB_KEY", publicKey)
  body.append("UPLOADCARE_STORE", "0")
  body.append("signature", signature)
  body.append("expire", expiresAt)

  for (const key of Object.keys(metadata)) {
    body.append(`metadata[${key}]`, metadata[key])
  }

  const response = await fetch("https://upload.uploadcare.com/base/", { method: "POST", body })
  const responseData = await response.json()
  return responseData[file.name]
}
