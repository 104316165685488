import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Overline from "@sonato/core/components/Overline"

const Label = ({ text, error, htmlFor }) => {
  const classes = classNames("cursor-inherit", error ? "text-red-900" : "text-gray-900 opacity-40")

  return (
    <label htmlFor={htmlFor} className={classes}>
      <Overline.Small>{text}</Overline.Small>
    </label>
  )
}

Label.propTypes = {
  text: PropTypes.string.isRequired,
  error: PropTypes.string,
  htmlFor: PropTypes.string
}

export default Label
