const MESSAGE_KEY = "msg"

export const RESERVED_SUBDOMAINS = ["www", "api", "auth"]

const splitHostname = () => {
  return window.location.hostname.split(".")
}

export const isReservedSubdomain = subdomain => RESERVED_SUBDOMAINS.includes(subdomain)

export const currentSubdomain = () => {
  const current = splitHostname()
  return current.length === 3 ? current[0] : null
}

export const currentLocationSubdomain = () => {
  const subdomain = currentSubdomain()

  if (subdomain && !isReservedSubdomain(subdomain)) {
    return subdomain
  }

  return null
}

export const currentDomain = () => {
  return window.location.hostname
}

const domain = subdomain => {
  const current = splitHostname()
  if (current.length === 3) {
    current.splice(0, 1, subdomain)
  } else {
    current.splice(0, 0, subdomain)
  }

  return current.join(".")
}

export const authDomain = () => domain("auth")
export const isAuthDomain = () => currentDomain() === authDomain()

export const apiDomain = () => domain("api")
export const isApiDomain = () => currentDomain() === apiDomain()

export const marketingDomain = () =>
  process.env.NODE_ENV === "production" ? rootDomain() : domain("apex")
export const isMarketingDomain = () => currentDomain() === rootDomain()

export const apiURL = () => `wss://${apiDomain()}/v1`
export const socketURL = () => `wss://${apiDomain()}/socket`
export const locationDomain = subdomain => domain(subdomain)
export const locationUrl = subdomain => `https://${locationDomain(subdomain)}`

export const rootDomain = () => {
  const hostname = splitHostname()
  return hostname.slice(-2).join(".")
}

export const addMessage = (baseUrl, message) => {
  const url = baseUrl instanceof URL ? baseUrl : new URL(baseUrl)
  url.searchParams.set(MESSAGE_KEY, message)
  return url.toString()
}

export const getMessage = baseUrl => {
  const url = baseUrl instanceof URL ? baseUrl : new URL(baseUrl)

  return url.searchParams.get(MESSAGE_KEY)
}

export const aboutUrl = () => `https://${marketingDomain()}/about`
export const contactUrl = () => `https://${marketingDomain()}/contact`
export const marketingUrl = () => `https://${marketingDomain()}/`
export const privacyUrl = () => `https://${marketingDomain()}/privacy`
export const signupUrl = subdomain =>
  subdomain
    ? `https://${marketingDomain()}/signup/${subdomain}`
    : `https://${marketingDomain()}/signup`
export const termsUrl = () => `https://${marketingDomain()}/terms`
