import React, { useEffect, useState } from "react"
import { client } from "../client"

/**
 * Sets up Google Analytics tracking.
 * https://developers.google.com/tag-platform/gtagjs
 *
 * After the script is loaded it tracks page views automatically, so there is no
 * need to manually send page view events.
 */
export default function GoogleAnalytics({ children }) {
  const [measurementId, setMeasurementId] = useState(null)

  useEffect(() => {
    client.connectPromise.then(({ setup }) => {
      setMeasurementId(setup.googleAnalyticsMeasurementId)
    })
  }, [])

  if (!measurementId) {
    return children
  }

  return <GoogleAnalyticsInner measurementId={measurementId}>{children}</GoogleAnalyticsInner>
}

function GoogleAnalyticsInner({ measurementId, children }) {
  useEffect(() => {
    const script = document.createElement("script")
    script.defer = true
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`
    document.head.appendChild(script)

    window.dataLayer = window.dataLayer || []

    function gtag() {
      window.dataLayer.push(arguments)
    }

    gtag("js", new Date())
    gtag("config", measurementId)
  }, [measurementId])

  return children
}
