import { CarouselIndicator } from "@sonato/core/components/Carousel"
import Image from "@sonato/core/components/Image"
import Overline from "@sonato/core/components/Overline"
import { useFormatters } from "@sonato/core/format"
import classNames from "classnames"
import { take } from "lodash"
import * as React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import ClubDetail from "../clubs/ClubDetail"
import { useFavoriteLocationIds, useSuggestedLocations } from "../clubs/hooks"
import CancelVisitPanel from "./CancelVisitPanel"
import { useUpcomingVisits } from "./hooks"
import RequestVisitPanel from "./RequestVisitPanel"
import Thread from "@sonato/member/src/pages/member/messages/Thread"
import { locationImageURL } from "@sonato/core/images"
import FavoriteButton from "components/FavoriteButton"

const UpcomingVisits = () => {
  const { data: visits } = useUpcomingVisits()
  const { data: favoriteLocationIDs } = useFavoriteLocationIds()
  const { data: suggestedLocations } = useSuggestedLocations()

  const checkIfFavoriteLocation = location => !!favoriteLocationIDs.includes(location.id)

  const [index, setIndex] = React.useState(0)
  const containerRef = React.useRef()

  const [isDragging, setIsDragging] = React.useState(false)
  const [draggedDistance, setDraggedDistance] = React.useState(0)

  // If there are fewer than 3 visit requests, add suggested locations required
  // to reach 3 cards in the carousel.
  const suggestedCount = Math.max(0, 3 - visits.length)
  const cardCount = Math.max(visits.length, 3)
  const gapCount = visits.length
  const gap = "2.5rem"
  const width = `calc(${cardCount} * 100% + ${gapCount} * ${gap})`
  const left = `calc(-${index} * (100% + ${gap}) + ${draggedDistance}px)`
  const gridTemplateColumns = `repeat(${cardCount}, 1fr)`
  const cursor = isDragging ? "grabbing" : "grab"
  const animationClasses = isDragging ? "" : "transition-all duration-500 ease-in-out"

  const onPointerDown = e => {
    e.target.setPointerCapture(e.pointerId)
  }

  const onGotPointerCapture = _e => {
    setDraggedDistance(0)
    setIsDragging(true)
  }

  const onPointerMove = e => {
    if (isDragging) {
      setDraggedDistance(draggedDistance + e.movementX)
    }
  }

  const onLostPointerCapture = _e => {
    // Decide which card to focus depending on how far the user dragged
    const cardWidth = containerRef.current.clientWidth
    const magnitude = Math.ceil(
      Math.max(Math.abs(Math.abs(draggedDistance) - cardWidth / 2), 0) / cardWidth
    )
    const deltaIndex = draggedDistance < 0 ? magnitude : -magnitude
    const newIndex = Math.min(Math.max(0, index + deltaIndex), cardCount - 1)

    setIsDragging(false)
    setDraggedDistance(0)
    setIndex(newIndex)
  }

  const visitCards = visits.map(visit => (
    <LocationCard
      key={visit.id}
      visit={visit}
      location={visit.destinationLocation}
      favorite={checkIfFavoriteLocation(visit.destinationLocation)}
    />
  ))

  const suggestedCards = take(suggestedLocations, suggestedCount).map(location => (
    <LocationCard
      key={location.id}
      location={location}
      favorite={checkIfFavoriteLocation(location)}
    />
  ))

  const cards = visitCards.concat(suggestedCards)

  return (
    <section className="mt-8 max-w-4xl mx-auto">
      <div className="overflow-x-visible" ref={containerRef}>
        <div
          className={classNames("grid relative", animationClasses)}
          style={{ width, gridTemplateColumns, gap, left, cursor, touchAction: "pan-x" }}
          onPointerDown={onPointerDown}
          onPointerMove={onPointerMove}
          onGotPointerCapture={onGotPointerCapture}
          onLostPointerCapture={onLostPointerCapture}
        >
          {cards}
        </div>
      </div>

      <CarouselIndicator
        className="mt-8"
        count={cardCount}
        selectedIndex={index}
        onClick={index => setIndex(index)}
      />
    </section>
  )
}

const LocationCard = ({ location, visit, favorite }) => {
  const { t } = useTranslation()
  const imageUrl = locationImageURL(location, "landscape", 600)

  const classes = classNames(
    "bg-white rounded border border-gold-300 px-5 py-6",
    "grid gap-5 md:grid-cols-2"
  )

  return (
    <div className={classes}>
      <div className="flex flex-col md:justify-between">
        {visit ? <VisitHeader visit={visit} /> : <ClubToTryHeader />}
        <LocationInfo location={location} visit={visit} className="hidden md:block" />
      </div>
      <Link to={ClubDetail.path(location.subdomain)}>
        <Image
          src={imageUrl}
          aspect={"3:2"}
          alt={t("Image of {{ location }}", { location: location.name })}
        >
          <div className="flex justify-end p-5">
            <FavoriteButton locationId={location.id} favorite={favorite} />
          </div>
        </Image>
      </Link>
      <LocationInfo location={location} visit={visit} className="md:hidden" />
    </div>
  )
}

const LocationInfo = ({ location, className, visit = null }) => {
  const format = useFormatters()

  const [requestVisible, setRequestVisible] = React.useState(false)
  const [cancelVisible, setCancelVisible] = React.useState(false)
  const showRequestPanel = () => setRequestVisible(true)
  const hideRequestPanel = () => setRequestVisible(false)

  const showSendMessageLink = location.kind === "full"

  const isHotelOnly = visit?.isHotel && location.settings.hotelOnly
  const cancelText = isHotelOnly ? "Cancel booking" : "Cancel visit"

  return (
    <div className={className}>
      <Link to={ClubDetail.path(location.subdomain)}>
        <h3>{location.name}</h3>
      </Link>

      {visit && isHotelOnly && (
        <p className="text-gray-500 mt-1">
          {format.shortDate(visit.hotelStartDate, location.timezone)} -{" "}
          {format.shortDate(visit.hotelEndDate, location.timezone)}
          <Guests visit={visit} className="ml-1" />
        </p>
      )}

      {visit && !isHotelOnly && (
        <p className="text-gray-500 mt-1">
          {format.shortDateTimeWithWeekday(visit.scheduledAt, location.timezone)}
          <Guests visit={visit} className="ml-1" />
        </p>
      )}

      {!visit && <p className="text-gray-500 mt-1">{location.address.locality}</p>}

      <div className="flex items-center space-x-2">
        {showSendMessageLink && (
          <Link to={Thread.pathFor(location.subdomain)}>
            <Overline.Small className="text-gold-900 whitespace-nowrap">
              Send Message
            </Overline.Small>
          </Link>
        )}

        {visit && visit.status !== "canceled" && (
          <>
            {showSendMessageLink && <span className="text-gray-300">&bull;</span>}
            <button onClick={() => setCancelVisible(true)} className="inline-block">
              <Overline.Small className="text-gold-900 whitespace-nowrap">
                {cancelText}
              </Overline.Small>
            </button>
            <CancelVisitPanel
              visit={visit}
              visible={cancelVisible}
              onDismiss={() => setCancelVisible(false)}
            />
          </>
        )}

        {!visit && location.profile.visitRequestsEnabled && (
          <>
            <RequestVisitPanel
              location={location}
              visible={requestVisible}
              onSuccess={hideRequestPanel}
              onDismiss={hideRequestPanel}
            />
            <span className="text-gray-300">&bull;</span>
            <button className="text-gold-900" onClick={showRequestPanel}>
              <Overline.Small>Request a visit</Overline.Small>
            </button>
          </>
        )}
      </div>
    </div>
  )
}

const VisitHeader = ({ visit }) => {
  const format = useFormatters()

  return (
    <div>
      <h2 className="whitespace-nowrap">
        {format.longDateNoYear(visit.scheduledAt, visit.destinationLocation.timezone)}
      </h2>
      <Overline.Medium className="text-gray-500 whitespace-nowrap inline-block">
        <Status visit={visit} />
      </Overline.Medium>
    </div>
  )
}

const ClubToTryHeader = () => {
  const { t } = useTranslation()

  return (
    <div>
      <h2 className="whitespace-nowrap">{t("Club to Try")}</h2>
      <Link to="/member/clubs">
        <Overline.Medium className="text-gray-500 whitespace-nowrap">
          {t("View all clubs")}
        </Overline.Medium>
      </Link>
    </div>
  )
}

const Status = ({ visit }) => {
  const { t } = useTranslation()

  const translations = {
    approved: t("Approved"),
    pending: t("Awaiting approval"),
    canceled: t("Canceled")
  }

  const classes = classNames(
    "font-bold",
    visit.status === "approved" ? "text-green-900" : "text-red-900"
  )

  return <span className={classes}>{translations[visit.status]}</span>
}

const Guests = ({ visit, className }) => {
  const { t } = useTranslation()

  if (visit.guestCount > 0) {
    const label = t("+ {{ count }} guests", { count: visit.guestCount })
    return <span className={className}>{label}</span>
  }

  return null
}

export default UpcomingVisits
