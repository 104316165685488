import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { StretchedButton } from "@sonato/core/components/Buttons"

const actionShape = PropTypes.shape({
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  text: PropTypes.string.isRequired
})

export const Overlay = ({ visible, onDismiss, children }) => (
  <div
    className={classNames(
      "fixed inset-0 bg-gray-900/40 flex items-center justify-center z-20",
      "transition-all duration-300",
      visible ? "visible opacity-100" : "invisible opacity-0"
    )}
    onClick={onDismiss}
  >
    {children}
  </div>
)

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
  onDismiss: PropTypes.func,
  visible: PropTypes.bool.isRequired
}

const Contents = ({ text, content, actions, width }) => (
  <div
    className={classNames("bg-white text-gray-900 p-10 rounded", width)}
    style={{ boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)" }}
    onClick={e => e.stopPropagation()} // Prevents click from bubbling up to Overlay
  >
    <h5 className="text-center">{text}</h5>
    {content && content}
    {actions && (
      <div className="mt-6 grid grid-flow-row gap-3">
        {actions.map(({ text, secondary, onClick, disabled }, key) => (
          <StretchedButton key={key} secondary={secondary} onClick={onClick} disabled={disabled}>
            {text}
          </StretchedButton>
        ))}
      </div>
    )}
  </div>
)

Contents.propTypes = {
  text: PropTypes.string,
  content: PropTypes.node,
  actions: PropTypes.arrayOf(actionShape),
  width: PropTypes.string
}

const ModalDialog = ({
  text,
  onDismiss,
  visible,
  width = "w-modal",
  confirmText = null,
  cancelText = null,
  onConfirm = null,
  onCancel = null,
  showConfirm = true,
  showCancel = false
}) => {
  const { t } = useTranslation()

  const actions = []

  if (showCancel) {
    actions.push({
      text: cancelText || t("Cancel"),
      onClick: onCancel || onDismiss,
      secondary: true
    })
  }

  if (showConfirm) {
    actions.push({
      text: confirmText || t("OK"),
      onClick: onConfirm || onDismiss
    })
  }

  return (
    <Overlay visible={visible} onDismiss={onDismiss}>
      <Contents width={width} text={text} actions={actions} />
    </Overlay>
  )
}

ModalDialog.propTypes = {
  /** Whether to display the modal. */
  visible: PropTypes.bool,

  /** Text shown in the modal above the actions. */
  text: PropTypes.string.isRequired,

  /** Text shown on the confirmation button (deafult "ok"). */
  confirmText: PropTypes.string,

  /** Text shown on the cancel button (default "Cancel"). */
  cancelText: PropTypes.string,

  /** Function to call to dismiss the modal. */
  onDismiss: PropTypes.func.isRequired,

  /** Function to call when confirm button is pressed, if not given defaults to `onDismiss`. */
  onConfirm: PropTypes.func,

  /** Function to call when cancel button is pressed, if not given defaults to `onDismiss`. */
  onCancel: PropTypes.func,

  /** Whether to show the confirm button, defaults to true. */
  showConfirm: PropTypes.bool,

  /** Whether to show the cancel button, defaults to false. */
  showCancel: PropTypes.bool,

  /** Tailwind class name which defines the width of the modal, defaults to `w-modal`. */
  width: PropTypes.string
}

const Modal = ({ text, onDismiss, visible, children, width = "w-modal" }) => {
  return (
    <Overlay visible={visible} onDismiss={onDismiss}>
      <Contents content={children} width={width} text={text} />
    </Overlay>
  )
}

export { ModalDialog }

export default Modal
