import React from "react"
import classNames from "classnames"
import { ActionsHeartIcon } from "@sonato/core/icons"
import {
  useAddFavoriteLocationMutation,
  useRemoveFavoriteLocationMutation
} from "pages/member/clubs/hooks"

export default function FavoriteButton({ locationId, favorite }) {
  const addFavorite = useAddFavoriteLocationMutation()
  const removeFavorite = useRemoveFavoriteLocationMutation()

  function toggleFavorite(e) {
    e.preventDefault()
    if (!addFavorite.isLoading && !removeFavorite.isLoading) {
      favorite ? removeFavorite.mutate(locationId) : addFavorite.mutate(locationId)
    }
  }

  const buttonClasses = classNames(
    "h-8 w-8 rounded-full text-white focus:outline-none",
    "flex items-center justify-center",
    favorite ? "bg-red-900" : "bg-black/40"
  )

  return (
    <button className={buttonClasses} onClick={toggleFavorite}>
      <ActionsHeartIcon className="text-xl transform duration-300 ease-in-out hover:scale-110 active:scale-95" />
    </button>
  )
}
