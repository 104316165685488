import React from "react"
import PropTypes from "prop-types"

const ActionsUITypeIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M1 6.626a2.245 2.245 0 012.245-2.245h9.51A2.245 2.245 0 0115 6.626v2.748a2.245 2.245 0 01-2.245 2.245h-9.51A2.245 2.245 0 011 9.374V6.626zm2.245-.924a.925.925 0 00-.924.924v2.748c0 .51.414.924.924.924h9.51c.51 0 .924-.414.924-.924V6.626a.925.925 0 00-.924-.924h-9.51z" />
      <path d="M10.958 3.536a.66.66 0 01.66.66v7.608a.66.66 0 01-1.32 0V4.196a.66.66 0 01.66-.66z" />
      <path d="M10.959 11.143a.66.66 0 01.66.66c0 1.036.84 1.876 1.876 1.876a.66.66 0 110 1.32 3.19 3.19 0 01-2.536-1.25A3.19 3.19 0 018.423 15a.66.66 0 110-1.32c1.036 0 1.876-.84 1.876-1.875a.66.66 0 01.66-.66zM7.763 1.66a.66.66 0 01.66-.66c1.033 0 1.952.49 2.536 1.25A3.191 3.191 0 0113.495 1a.66.66 0 010 1.32c-1.036 0-1.876.84-1.876 1.876a.66.66 0 01-1.32 0c0-1.036-.84-1.875-1.876-1.875a.66.66 0 01-.66-.66z" />
    </g>
  </svg>
)

ActionsUITypeIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ActionsUITypeIcon svgRef={ref} {...props} />)
export default ForwardRef
