import React from "react"
import PropTypes from "prop-types"

const ActionsAddSimpleIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M14 8a.702.702 0 01-.702.702H2.703a.702.702 0 110-1.404h10.595c.388 0 .703.314.703.702z" />
      <path d="M8 2c.388 0 .703.314.703.702v10.596a.702.702 0 11-1.405 0V2.702C7.298 2.314 7.613 2 8 2z" />
    </g>
  </svg>
)

ActionsAddSimpleIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsAddSimpleIcon svgRef={ref} {...props} />
))
export default ForwardRef
