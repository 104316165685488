import React from "react"
import PropTypes from "prop-types"

const ActionsMigrateIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g>
      <path
        d="M12.064 9.936L12 9.873l-.064.063-.705.705-.063.064.063.064 1.637 1.641H5a1.91 1.91 0 110-3.82h6a3.09 3.09 0 000-6.18H3.132L4.769.769l.063-.064L4.77.64l-.705-.705L4-.127l-.064.063-3 3L.873 3l.063.064 3 3L4 6.127l.064-.063.705-.705.063-.064-.063-.064L3.132 3.59H11a1.91 1.91 0 110 3.82H5a3.09 3.09 0 100 6.18h7.868l-1.637 1.641-.063.064.063.064.705.705.064.063.064-.063 3-3 .063-.064-.063-.064-3-3z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={0.18}
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

ActionsMigrateIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ActionsMigrateIcon svgRef={ref} {...props} />)
export default ForwardRef
