import React from "react"
import PropTypes from "prop-types"

const EssentialsCalendarIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M.375 2.867c0-.345.28-.625.625-.625h14c.345 0 .625.28.625.625V15c0 .345-.28.625-.625.625H1A.625.625 0 01.375 15V2.867zm1.25.625v10.883h12.75V3.492H1.625z" />
      <path d="M.375 6.6c0-.345.28-.625.625-.625h14a.625.625 0 110 1.25H1A.625.625 0 01.375 6.6zM4.733.375c.346 0 .625.28.625.625v3.267a.625.625 0 11-1.25 0V1c0-.345.28-.625.625-.625zM11.267.375c.345 0 .625.28.625.625v3.267a.625.625 0 11-1.25 0V1c0-.345.28-.625.625-.625z" />
    </g>
  </svg>
)

EssentialsCalendarIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsCalendarIcon svgRef={ref} {...props} />
))
export default ForwardRef
