import React from "react"

import FavoriteButton from "./FavoriteButton"
import ImageCard from "@sonato/core/components/cards/ImageCard"
import LocationBadge from "components/LocationBadge"
import Overline from "@sonato/core/components/Overline"
import { locationImageURL } from "@sonato/core/images"
import { useLocationPath } from "pages/member/hotels/hooks"

const ClubCard = ({ location, isFavorite, to }) => {
  const [wideImageUrl, tallImageUrl] = useLocationCardImages(location)
  const imageAlt = `Image of ${location}`

  const children = (
    <div className="flex justify-end p-5">
      <FavoriteButton locationId={location.id} favorite={isFavorite} />
    </div>
  )

  return (
    <div>
      {/* Image in 5:4, shown on mobile */}
      <ImageCard
        className="md:hidden"
        to={to}
        imageUrl={wideImageUrl}
        imageAlt={imageAlt}
        aspect="5:4"
      >
        {children}
      </ImageCard>

      {/* Image in 2:3, shown on wider screens */}
      <ImageCard
        className="hidden md:block"
        to={to}
        imageUrl={tallImageUrl}
        imageAlt={imageAlt}
        aspect="2:3"
      >
        {children}
      </ImageCard>

      <div className="px-5 md:px-0">
        <span className="inline-block mt-3 font-serif text-h3">
          <span className="inline-block mr-3">{location.name}</span>
          <LocationBadge location={location} />
        </span>

        <Overline.Small className="mt-1 text-gray-500">{location.address.locality}</Overline.Small>
      </div>
    </div>
  )
}

function useLocationCardImages(location) {
  const { isHotelPath } = useLocationPath()
  const hotelImages = location.uploadcareHotelImages

  // For hotel paths, show hotel images instead
  if (isHotelPath && hotelImages.length > 0) {
    const image = hotelImages[0]
    const wide = `${image.url}-/scale_crop/750x600/center/`
    const tall = `${image.url}-/scale_crop/533x800/center/`
    return [wide, tall]
  }

  const wide = locationImageURL(location, "landscapeNarrow", 600)
  const tall = locationImageURL(location, "portraitTall", 800)
  return [wide, tall]
}

export default ClubCard
