import React from "react"
import PropTypes from "prop-types"

const AppsPeopleIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g>
      <path
        d="M13.433 2.693a.333.333 0 01-.266-.326V1.333A1.333 1.333 0 0011.833 0H3.5a2 2 0 00-2 2v12a2 2 0 002 2h9.667a1.334 1.334 0 001.333-1.333V4a1.333 1.333 0 00-1.067-1.307zM8.161 9.667a3.34 3.34 0 013.338 3.111.203.203 0 01-.056.155.209.209 0 01-.152.067H5.167a.334.334 0 01-.333-.37 3.341 3.341 0 013.327-2.963zM6.153 7a2.008 2.008 0 114.016-.016A2.008 2.008 0 016.153 7zm-3.32-5a.667.667 0 01.667-.667h8.167a.166.166 0 01.166.167v1a.166.166 0 01-.166.167H3.5A.667.667 0 012.833 2z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

AppsPeopleIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsPeopleIcon svgRef={ref} {...props} />)
export default ForwardRef
