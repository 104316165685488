import { useCallback, useEffect, useState } from "react"
import i18n from "i18next"
import * as Storage from "@sonato/core/storage"
import * as URLs from "./urls"
import { Socket } from "phoenix"

/**
 * Socket and channel initialization
 */

const params = { token: Storage.getAuthToken(), language: i18n.language }
const logger = (kind, msg, data) => console.log(`[SOCKET]`, kind, msg, data)

const socket = new Socket(URLs.socketURL(), { params, logger })
socket.connect()

const channel = socket.channel("update:notifications", {})
channel.join()

const serverSubscribe = path => {
  channel.push("subscribe", { path: path }).receive("error", error => {
    console.error("subscription error: ", error)
  })
}

const serverUnsubscribe = path => channel.push("unsubscribe", { path: path })

socket.onOpen(() => {
  // resubscribe to all known subscriptions following a netsplit
  for (const path of subscriptions.keys()) serverSubscribe(path)
})

/**
 * Client-side subscription state
 */

const subscriptions = new Map()

const subscribe = (path, callback) => {
  const numSubscriptions = subscriptions.get(path) || 0
  if (numSubscriptions === 0) serverSubscribe(path)

  subscriptions.set(path, numSubscriptions + 1)

  return channel.on(`changed ${path}`, callback)
}

const unsubscribe = (path, ref) => {
  channel.off(`changed ${path}`, ref)

  const numSubscriptions = Math.max((subscriptions.get(path) || 0) - 1, 0)
  if (numSubscriptions === 0) serverUnsubscribe(path)
  subscriptions.set(path, numSubscriptions)
}

export const useSubscription = (path, callback) => {
  // Memoizing the callback to avoid infinite loops which can happen if the callback closes on objects and arrays.
  const memoizedCallback = useCallback(callback, [])

  useEffect(() => {
    const ref = subscribe(path, memoizedCallback)
    return () => unsubscribe(path, ref)
  }, [path, memoizedCallback])
}

/**
 * Feature flags
 */

// Globally caching feature flags so they are immediately available anywhere in the UI.
const cachedFeatureFlags = { flags: {}, isLoading: true }

subscribe("ux/feature_flags", flags => {
  cachedFeatureFlags.flags = flags
  cachedFeatureFlags.isLoading = false
})

export const useFeatureFlags = () => {
  const [featureFlags, setFeatureFlags] = useState(cachedFeatureFlags)
  useSubscription("ux/feature_flags", flags => setFeatureFlags({ flags, isLoading: false }))
  return featureFlags
}
