import React from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import Thread from "@sonato/member/src/pages/member/messages/Thread"
import { Button } from "@sonato/core/components/Buttons"
import { repeat } from "@sonato/core/utils"
import { useInboxMessages } from "./hooks"
import { locationImageURL } from "@sonato/core/images"

const Inbox = () => (
  <div className="px-5">
    <h1 className="my-8">Inbox</h1>
    <React.Suspense fallback={<MessageListSkeleton />}>
      <MessageList />
    </React.Suspense>
  </div>
)

const MessageList = () => {
  const { data: messages } = useInboxMessages()
  if (messages.length === 0) {
    return <MessageListEmpty />
  }
  return (
    <div className="divide-y divide-gold-300">
      {messages.map(m => (
        <Message key={m.id} message={m} />
      ))}
    </div>
  )
}

const MessageListSkeleton = () => (
  <div className="divide-y divide-gold-300">
    {repeat(3, i => (
      <MessageSkeleton key={i} />
    ))}
  </div>
)

const MessageListEmpty = () => {
  const { t } = useTranslation()

  return (
    <div className="p-10 max-w-8xl mx-auto text-center">
      <div className="p-24 bg-gold-300 rounded">
        <p>{t("You have no messages.")}</p>
        <Button tall={true} className="mt-8 px-8" to="/member/clubs">
          {t("View Clubs")}
        </Button>
      </div>
    </div>
  )
}

const Message = ({ message }) => {
  const { t } = useTranslation()
  const to = Thread.pathFor(message.location.subdomain)
  const imageUrl = locationImageURL(message.location, "square", 192)

  return (
    <Link to={to} className="h-32 py-5 flex space-x-5 items-center">
      <img
        alt={t("Title image for club {{ name }}", { name: message.location.name })}
        src={imageUrl}
        className="flex-shrink-0 w-24 h-24 rounded"
      />
      <div className="flex-grow">
        <h4>{message.location.name}</h4>
        <p className="mt-2 text-gray-500 line-clamp-3">{message.eventData.text}</p>
      </div>
    </Link>
  )
}

const MessageSkeleton = () => (
  <div className="h-32 py-5 flex space-x-5 items-center">
    <div className="flex-shrink-0 w-24 h-24 bg-gray-100 rounded skeleton" />
    <div className="flex-grow">
      <div className="w-40 h-6 bg-gray-100 rounded-sm skeleton" />
      <div className="mt-2 space-y-1">
        <div className="w-full h-4 bg-gray-100 rounded-sm skeleton" />
        <div className="w-4/5 h-4 bg-gray-100 rounded-sm skeleton" />
        <div className="w-3/5 h-4 bg-gray-100 rounded-sm skeleton" />
      </div>
    </div>
  </div>
)

export default Inbox
