import { StretchedButton } from "@sonato/core/components/Buttons"
import Carousel from "@sonato/core/components/Carousel"
import Copy from "@sonato/core/components/Copy"
import ExtendedNetworkBadge from "@sonato/core/components/ExtendedNetworkBadge"
import AmenitiesPanel from "@sonato/core/components/locations/AmenitiesPanel"
import AmenitiesStrip from "@sonato/core/components/locations/AmenitiesStrip"
import NotFound from "@sonato/core/components/NotFound"
import Overline from "@sonato/core/components/Overline"
import { useFormatters } from "@sonato/core/format"
import { useBreakpoint } from "@sonato/core/hooks/viewport"
import { ArrowLeftIcon } from "@sonato/core/icons"
import {
  isExtendedNetwork,
  localityWithPostalCode,
  streetAddress
} from "@sonato/core/utils/location"
import classNames from "classnames"
import Loader from "components/loader"
import PropTypes from "prop-types"
import React, { Suspense, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useRouteMatch } from "react-router-dom"
import RequestVisitPanel from "../visits/RequestVisitPanel"
import { usePartnerLocation } from "./hooks"
import Thread from "@sonato/member/src/pages/member/messages/Thread"
import { locationHeroImageURLs, locationHotelImageURLs } from "@sonato/core/images"
import RequestVisitButton from "components/RequestVisitButton"
import { useLocationPath } from "../hotels/hooks"
import BookRoomPanel from "../hotels/BookRoomPanel"
import BookRoomButton from "components/BookRoomButton"

const ClubInfoSection = ({ title, children, first = false }) => {
  // converting to array removes falsy children
  const kids = React.Children.toArray(children)

  return (
    <>
      <Overline.Small className={classNames("text-gray-500", first ? "" : "mt-5")}>
        {title}
      </Overline.Small>
      <div className="mt-5">{kids}</div>
    </>
  )
}

ClubInfoSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  first: PropTypes.bool
}

const ClubInfo = ({ location, showAmenitiesPanel, showRequestPanel, className }) => {
  const { t } = useTranslation()
  const format = useFormatters()
  const hours = location.profile.visitAdvanceNoticeHours
  const hotelDiscount = location.profile.hotelDiscount

  let advanceNotice = hours
  if (advanceNotice > 72) {
    advanceNotice = Math.floor(hours / 24)
  }

  return (
    <div className={classNames("flex-none flex flex-col divide-y divide-gold-100", className)}>
      <ClubInfoSection first={true} title={t("Club info")}>
        {isExtendedNetwork(location) && (
          <div className="mb-3">
            <ExtendedNetworkBadge location={location} />
          </div>
        )}

        <ul>
          <li className="font-bold">{location.name}</li>
          <li>{streetAddress(location)}</li>
          <li>{localityWithPostalCode(location)}</li>
          {location.address.region && <li>{location.address.region}</li>}
          {location.businessPhone && (
            <li>
              {t("Phone: ")}
              <a href={`tel:${location.businessPhone}`} className="text-gold-900">
                {format.phoneNumberInternational(location.businessPhone)}
              </a>
            </li>
          )}
          <li>
            <a className="text-gold-900" target="_new" href={location.websiteUrl}>
              {t("Visit Website")}
            </a>
          </li>
          {location.kind === "full" && (
            <li>
              <Link className="text-gold-900" to={Thread.pathFor(location.subdomain)}>
                {t("Send a Message")}
              </Link>
            </li>
          )}
        </ul>
      </ClubInfoSection>

      <ClubInfoSection title={t("Visiting rules")}>
        {hours > 0 &&
          (hours > 72 ? (
            <p>{t("Requires {{ advanceNotice }} days advance notice", { advanceNotice })}</p>
          ) : (
            <p>{t("Requires {{ advanceNotice }} hours advance notice", { advanceNotice })}</p>
          ))}

        <p>{t("Pre-book your visit")}</p>

        <button className="text-gold-900" onClick={showAmenitiesPanel}>
          {t("Club Hours & Amenities")}
        </button>

        {location.settings.hotelOnly ? (
          <BookRoomButton location={location} onClick={showRequestPanel} />
        ) : (
          <RequestVisitButton location={location} onClick={showRequestPanel} />
        )}
      </ClubInfoSection>

      {hotelDiscount > 0 && (
        <ClubInfoSection title={t("Hotel discount")}>
          {t("{{ hotelDiscount }}% discount", { hotelDiscount })}
        </ClubInfoSection>
      )}
    </div>
  )
}

const ClubBase = () => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()

  const [amenitiesVisible, setAmenitiesVisible] = useState(false)
  const [requestVisible, setRequestVisible] = useState(false)
  const match = useRouteMatch()
  const { data: location } = usePartnerLocation(match.params.subdomain)
  const { isHotelPath } = useLocationPath()

  if (!location) {
    return <NotFound />
  }

  const small = ["xs", "sm", "md"].includes(breakpoint)
  const carouselAspect = small ? "5:4" : "21:8"
  const [width, height] = small ? [1000, 800] : [2100, 800]

  const heroImageUrls = isHotelPath
    ? locationHotelImageURLs(location, width, height)
    : locationHeroImageURLs(location, width, height)

  const imageAlt = t("Image of {{ clubName }}", { clubName: location.name })

  const showAmenitiesPanel = () => setAmenitiesVisible(true)
  const showRequestPanel = () => setRequestVisible(true)
  const hideRequestPanel = () => setRequestVisible(false)
  const onVisitRequestSent = () => hideRequestPanel()

  return (
    <div className="overflow-x-hidden">
      {location.settings.hotelOnly ? (
        <BookRoomPanel
          location={location}
          visible={requestVisible}
          onSuccess={onVisitRequestSent}
          onDismiss={hideRequestPanel}
        />
      ) : (
        <RequestVisitPanel
          location={location}
          visible={requestVisible}
          onSuccess={onVisitRequestSent}
          onDismiss={hideRequestPanel}
        />
      )}
      <AmenitiesPanel
        location={location}
        visible={amenitiesVisible}
        setVisible={setAmenitiesVisible}
      />
      <section className="max-w-8xl md:px-20 m-auto">
        <div className="mt-9 flex flex-col px-5 md:px-0 md:flex-row md:items-center">
          <Link to="/member/clubs">
            <ArrowLeftIcon className="h-6 w-6" />
          </Link>
          <h1 className="flex-grow mt-6 md:mt-0 md:ml-12">{location.name}</h1>
          {location.settings.hotelOnly ? (
            <BookRoomButton location={location} onClick={showRequestPanel} />
          ) : (
            <RequestVisitButton location={location} onClick={showRequestPanel} />
          )}
        </div>
        <div className="mt-8 ml-18 hidden md:block">
          <p>{streetAddress(location)}</p>
          <p>{localityWithPostalCode(location)}</p>
        </div>
      </section>

      <section className="max-w-8xl m-auto mt-6 md:mt-15 md:px-20">
        <Carousel imageUrls={heroImageUrls} alt={imageAlt} aspect={carouselAspect} />
      </section>

      <section className="max-w-8xl m-auto md:flex mt-15 px-5 md:px-20">
        <ClubInfo
          className="hidden md:block w-76"
          location={location}
          showRequestPanel={showRequestPanel}
          showAmenitiesPanel={showAmenitiesPanel}
        />
        <div>
          <div className="flex justify-center md:block">
            <AmenitiesStrip location={location} onIconClick={showAmenitiesPanel} />
          </div>

          <ClubInfo
            className="md:hidden mt-10 pb-5 mb-5 border-b border-gold-300"
            location={location}
            showRequestPanel={showRequestPanel}
            showAmenitiesPanel={showAmenitiesPanel}
          />

          <h3 className="mt-6">{t("The Club")}</h3>
          <Copy className="mt-5" text={location.profile.description} />

          {location.profile.houseRules && (
            <>
              <h3 className="mt-6">{t("House Rules")}</h3>
              <Copy className="mt-5" text={location.profile.houseRules} />
            </>
          )}

          {location.profile.dressCodePolicy && (
            <>
              <h3 className="mt-6">{t("Dress Code")}</h3>
              <Copy className="mt-5" text={location.profile.dressCodePolicy} />
            </>
          )}
        </div>
      </section>

      <StretchedButton className="mt-5 md:hidden" tall={true} onClick={showRequestPanel}>
        {t("Request a Visit")}
      </StretchedButton>
    </div>
  )
}

const ClubDetail = () => (
  <Suspense fallback={<Loader isFixed={true} />}>
    <ClubBase />
  </Suspense>
)

ClubDetail.path = subdomain => `/member/clubs/${subdomain}`

export default ClubDetail
