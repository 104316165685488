import React from "react"
import PropTypes from "prop-types"

const EssentialsLoadingIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M4.085 2.768a.577.577 0 01.806.127L5.977 4.39a.577.577 0 01-.934.678L3.958 3.574a.577.577 0 01.127-.806zM1.528 6.287a.577.577 0 01.727-.37l1.756.57a.577.577 0 11-.357 1.097L1.9 7.014a.577.577 0 01-.37-.727zM4.381 9.71a.577.577 0 01-.37.727l-1.756.57A.577.577 0 111.9 9.91l1.755-.57a.577.577 0 01.727.37zM5.85 11.73a.577.577 0 01.127.805L4.89 14.028a.577.577 0 01-.933-.678l1.085-1.493a.577.577 0 01.806-.128zM8.222 12.5c.32 0 .577.258.577.577v1.846a.577.577 0 01-1.153 0v-1.846c0-.319.258-.577.576-.577zM10.596 11.73a.577.577 0 01.806.127l1.085 1.493a.577.577 0 01-.933.678l-1.086-1.493a.577.577 0 01.128-.806zM12.063 9.71a.577.577 0 01.727-.37l1.756.57a.577.577 0 11-.356 1.097l-1.756-.57a.577.577 0 01-.37-.727zM14.917 6.287a.577.577 0 01-.37.727l-1.757.57a.577.577 0 01-.356-1.097l1.756-.57a.577.577 0 01.727.37zM12.36 2.768a.577.577 0 01.127.806l-1.085 1.493a.577.577 0 01-.934-.678l1.086-1.494a.577.577 0 01.806-.127zM8.222.5c.32 0 .577.258.577.577v3.692a.577.577 0 11-1.153 0V1.077c0-.319.258-.577.576-.577z" />
    </g>
  </svg>
)

EssentialsLoadingIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsLoadingIcon svgRef={ref} {...props} />
))
export default ForwardRef
