import React from "react"
import PropTypes from "prop-types"

const ChevronDownIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M7.988 11a.993.993 0 01-.395-.081.957.957 0 01-.325-.23L3.18 6.237a.717.717 0 01.081-1.028.79.79 0 011.074.054L7.91 9.156a.104.104 0 00.078.034.108.108 0 00.079-.034l3.574-3.893a.765.765 0 01.531-.26.8.8 0 01.567.18c.077.066.14.145.184.233a.712.712 0 01-.127.821l-4.086 4.45a.96.96 0 01-.326.23.997.997 0 01-.396.083z"
      fill="currentColor"
    />
  </svg>
)

ChevronDownIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ChevronDownIcon svgRef={ref} {...props} />)
export default ForwardRef
