import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Icon from "@sonato/core/icons/icon"
import Overline from "@sonato/core/components/Overline"

const Color = Object.freeze({
  green: "green",
  orange: "orange",
  purple: "purple",
  red: "red",
  teal: "teal"
})

const badgeClasses = (className, color) =>
  classNames(className, "inline-block whitespace-nowrap rounded-full cursor-default", {
    "bg-green-100 text-green-900": color === Color.green,
    "bg-orange-100 text-orange-900": color === Color.orange,
    "bg-purple-100 text-purple-900": color === Color.purple,
    "bg-red-100 text-red-900": color === Color.red,
    "bg-teal-100 text-teal-900": color === Color.teal
  })

const iconClasses = color =>
  classNames("h-6 w-6 rounded-full text-white text-center inline-block", {
    "bg-green-900": color === Color.green,
    "bg-orange-900": color === Color.orange,
    "bg-purple-900": color === Color.purple,
    "bg-red-900": color === Color.red,
    "bg-teal-900": color === Color.teal
  })

const Badge = ({ icon, color = "green", className, children }) => (
  <div className={badgeClasses(className, color)}>
    <div className={iconClasses(color)}>
      <Icon name={icon} className="inline" />
    </div>
    <Overline.Small className="inline-block pl-2 pr-3">{children}</Overline.Small>
  </div>
)

Badge.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.oneOf(Object.values(Color)),
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

Badge.Color = Color

export default Badge
