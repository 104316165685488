import React from "react"
import PropTypes from "prop-types"

const EssentialsBarIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M5.857 14.5a.5.5 0 01.5-.5h3.467a.5.5 0 010 1H6.357a.5.5 0 01-.5-.5z" />
      <path d="M8.09 8.98a.5.5 0 01.5.5v5.02a.5.5 0 11-1 0V9.48a.5.5 0 01.5-.5z" />
      <path d="M5.051 2l-.446 2.908a3.527 3.527 0 005.688 3.29M5.051 2h6.077l.447 2.908a3.526 3.526 0 01-1.282 3.29M4.996 1a.933.933 0 00-.924.791l-.455 2.965a4.527 4.527 0 108.947 0l-.456-2.965A.934.934 0 0011.186 1h-6.19z" />
      <path d="M3.593 4.967a.5.5 0 01.5-.5h7.995a.5.5 0 010 1H4.093a.5.5 0 01-.5-.5z" />
    </g>
  </svg>
)

EssentialsBarIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <EssentialsBarIcon svgRef={ref} {...props} />)
export default ForwardRef
