import React from "react"
import { extendedNetworkCopy, isExtendedNetwork } from "@sonato/core/utils/location"
import Badge from "./Badge"

export default function ExtendedNetworkNote({ location }) {
  if (!isExtendedNetwork(location)) {
    return null
  }
  return (
    <div className="flex flex-col mt-5 mb-10">
      <Badge
        className="mb-4 flex-grow-0 self-start"
        color="orange"
        icon="EssentialsUnlockFilledIcon"
      >
        Extended
      </Badge>
      <p>{extendedNetworkCopy(location)}</p>
    </div>
  )
}
