import React, { useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { ActionsCrossIcon, ChevronLeftIcon } from "@sonato/core/icons"
import { PanelWidgetButton } from "@sonato/core/components/Buttons"

/**
 * A simpler version of Panel which works well on narrow screens.
 */
const ResponsivePanel = ({
  title,
  intro,
  action,
  onDismiss,
  onBack,
  visible,
  children,
  centerContent = true,
  contentWidth = "w-full sm:w-panel-right",
  containerWidth = "w-full sm:w-auto"
}) => {
  useEffect(() => {
    document.body.style.overflow = visible ? "hidden" : "visible"

    return () => {
      document.body.style.overflow = "visible"
    }
  }, [visible])

  const containerClasses = classNames(
    "fixed top-0 bottom-16 md:bottom-0 md:h-screen z-20 p-3 sm:p-5 transition-right duration-300",
    containerWidth,
    visible ? "right-0" : "-right-100"
  )

  // NB: `my-auto` is used to center the content here since using a flex box
  // with `justify-center` will cause the panel content not to be scrollable to
  // the top on smaller screens.
  const contentClasses = classNames("flex flex-col px-5 pb-5", {
    "my-auto": centerContent
  })

  const contentWrapperClasses = classNames(
    "flex-grow flex flex-col overflow-auto overscroll-none bg-gold-100",
    {
      "rounded-b-lg": !action
    }
  )

  return (
    <>
      <Overlay visible={visible} onDismiss={onDismiss} />
      <div className={containerClasses}>
        <div className={classNames("h-full flex flex-col", contentWidth)}>
          <Header title={title} onBack={onBack} onDismiss={onDismiss} />
          {intro && (
            <div className="px-5 bg-gold-100">
              <p>{intro}</p>
            </div>
          )}
          <div className={contentWrapperClasses}>
            <div className={contentClasses}>{children}</div>
          </div>
          {action && <ActionButton {...action} />}
        </div>
      </div>
    </>
  )
}

ResponsivePanel.propTypes = {
  action: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  }),
  onDismiss: PropTypes.func,
  onBack: PropTypes.func,
  visible: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  centerContent: PropTypes.bool,
  containerWidth: PropTypes.string,
  contentWidth: PropTypes.string,
  intro: PropTypes.string
}

export const Overlay = ({ visible, onDismiss }) => {
  const classes = classNames("fixed inset-0 z-10 bg-gray-900 transition-all duration-300", {
    "opacity-40": visible,
    "invisible opacity-0": !visible
  })

  return <div className={classes} onClick={onDismiss}></div>
}

const Header = ({ title, onDismiss, onBack }) => (
  <div className="flex-none flex justify-between bg-gold-100 rounded-t-lg">
    <div className="flex flex-row">
      {onBack && (
        <div className="p-5 pr-0">
          <BackButton onBack={onBack} />
        </div>
      )}
      <h3 className="p-5">{title || " "}</h3>
    </div>
    {onDismiss && (
      <div className="p-5">
        <DismissButton onDismiss={onDismiss} />
      </div>
    )}
  </div>
)

Header.propTypes = {
  title: PropTypes.string,
  onDismiss: PropTypes.func,
  onBack: PropTypes.func
}

const BackButton = ({ onBack }) => (
  <button
    type="button"
    className="w-8 h-8 flex items-center justify-center rounded-full text-gray-900 cursor-pointer"
    onClick={onBack}
  >
    <ChevronLeftIcon className="w-5 h-5" />
  </button>
)

const DismissButton = ({ onDismiss }) => (
  <button
    type="button"
    className="w-8 h-8 flex items-center justify-center rounded-full bg-gold-300 cursor-pointer text-gold-500"
    onClick={onDismiss}
  >
    <ActionsCrossIcon className="w-5 h-5" />
  </button>
)

const ActionButton = ({ text, onClick, disabled }) => (
  <PanelWidgetButton className="flex-none rounded-b-lg" onClick={onClick} disabled={disabled}>
    {text}
  </PanelWidgetButton>
)

const WideResponsivePanel = ({ leftContent, rightContent, ...props }) => (
  <ResponsivePanel
    {...props}
    contentWidth="w-full md:w-panel-wide"
    containerWidth="w-full md:w-auto"
  >
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-panel-left md:mr-5">{leftContent}</div>
      <div className="w-full md:w-panel-right">{rightContent}</div>
    </div>
  </ResponsivePanel>
)

ResponsivePanel.Wide = WideResponsivePanel

export default ResponsivePanel
