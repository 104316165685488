import React from "react"
import PropTypes from "prop-types"

const ActionsShareItemIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.646 2.663A1.522 1.522 0 018.243 3.74c0 .336.273.609.61.609h2.13a.244.244 0 01.243.243V5.87a.609.609 0 101.217 0V4.59a1.46 1.46 0 00-1.46-1.46h-1.59a2.74 2.74 0 00-5.342 0h-1.59A1.46 1.46 0 001 4.59v8.948A1.46 1.46 0 002.46 15H8a.609.609 0 100-1.217H2.46a.243.243 0 01-.243-.244V4.591a.243.243 0 01.244-.243h2.13a.609.609 0 00.609-.609c0-.403.16-.79.446-1.076zm-2.09 4.485c0-.336.273-.609.61-.609h4.26a.609.609 0 010 1.218h-4.26a.609.609 0 01-.61-.61zm8.888 1.704a.67.67 0 11.119.382.636.636 0 00-.046-.076.667.667 0 01-.073-.306zm.669 1.887a1.88 1.88 0 01-1.218-.446l-1.198.719a1.893 1.893 0 01.04.495l.944.378a1.887 1.887 0 11-.452 1.13l-.945-.378a1.887 1.887 0 11-.214-2.67l1.198-.717a1.887 1.887 0 111.845 1.49zm-3.63.896a.612.612 0 00-.018.044.67.67 0 11-.062-.65.62.62 0 00.045.073.666.666 0 01.034.533zm3 1.252a.668.668 0 00.63.896.67.67 0 10-.63-.896zM4.165 8.67a.609.609 0 100 1.217H5.87a.609.609 0 000-1.217H4.165zm-.608 2.739c0-.337.272-.609.608-.609H5.87a.609.609 0 110 1.217H4.165a.609.609 0 01-.608-.608z"
      fill="currentColor"
    />
  </svg>
)

ActionsShareItemIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsShareItemIcon svgRef={ref} {...props} />
))
export default ForwardRef
