import React from "react"
import { useTranslation } from "react-i18next"

const ErrorFallback = ({ error }) => {
  const { t } = useTranslation()

  return (
    <div className="flex m-10 items-center justify-center">
      <div>
        <h3>{t("Oops, something went wrong.")}</h3>
        <p className="mt-4">
          {t("If the problem persists, please contact support at support@sonato.com")}
        </p>
        {error?.message && (
          <pre className="mt-4">
            <code>{error?.message}</code>
          </pre>
        )}
      </div>
    </div>
  )
}

export default ErrorFallback
