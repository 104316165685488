import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ActionsCheckIcon } from "@sonato/core/icons"
import { KEY_CODES } from "@sonato/core/enums"

const Checkbox = ({
  name,
  label,
  value,
  error,
  large = false,
  disabled = false,
  imageUrl = null, // TODO: is this used?
  onChange = () => {}
}) => {
  const handleChange = () => onChange(!value)

  function handleKeyUp(e) {
    if (!disabled && e.keyCode === KEY_CODES.space) {
      handleChange()
    }
  }

  const boxClasses = classNames("absolute inset-0 border rounded transition-all duration-300", {
    "bg-white border-red-900": error,
    "bg-gold-900 border-gold-900": !error && value,
    "bg-white border-gray-500/40": !error && !value,
    "transform group-hover:scale-105 group-active:scale-100": !disabled
  })

  const labelClasses = classNames(
    "group flex items-center font-bold text-gray-900 outline-none",
    disabled ? "opacity-40 cursor-not-allowed" : "cursor-pointer"
  )

  const iconClasses = large ? "h-6 w-6" : "h-4 w-4"

  // Show image when checkbox is unchecked, if one was given
  const boxStyle =
    imageUrl && !value
      ? {
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover"
        }
      : {}

  return (
    <>
      <label tabIndex="0" className={labelClasses} onKeyUp={handleKeyUp}>
        <input
          type="checkbox"
          className="hidden"
          name={name}
          checked={value}
          disabled={disabled}
          onChange={handleChange}
        />
        <div className={classNames("relative", large ? "h-8 w-8" : "h-6 w-6")}>
          <div className={boxClasses} style={boxStyle} />
          {value && (
            <div className="absolute w-full h-full flex items-center justify-center text-white">
              <ActionsCheckIcon className={iconClasses} />
            </div>
          )}
        </div>
        {label && <span className="ml-3">{label}</span>}
      </label>
      {error && (
        <div className="mt-3 px-3 bg-red-900 rounded rounded-t-none text-white ">{error}</div>
      )}
    </>
  )
}

Checkbox.propTypes = {
  /** Name of input. */
  name: PropTypes.string,
  /** Input label. */
  label: PropTypes.string,
  /** Value (defaults to the value of the `label` property). */
  value: PropTypes.bool,
  /** Show large version. Defaults to false. */
  large: PropTypes.bool,
  /** Disabled state. Defaults to false. */
  disabled: PropTypes.bool,
  /** Optional URL to the image to display when unchecked. */
  imageUrl: PropTypes.string,
  /** Invokes onChange handler passing new `checked` state and `value`. */
  onChange: PropTypes.func,
  /** An error message **/
  error: PropTypes.string
}

export default Checkbox
