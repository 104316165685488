import { useAjax } from "@sonato/core/hooks/ajax"
import { useMutation, useQuery, useQueryClient } from "react-query"

const queryKeys = {
  messages: () => ["messages"],
  location: locationId => ["location", { locationId }],
  locationMessages: locationId => ["messages", { locationId }]
}

// queries

export function useInboxMessages() {
  const ajax = useAjax()
  const queryKey = queryKeys.messages()
  const queryFn = () => ajax.get("/member/messaging/listInbox")

  return useQuery(queryKey, queryFn, {
    refetchInterval: 20 * 1000, // until we can push server-side invalidate
    suspense: true
  })
}

export function useLocationMessages(locationId) {
  const ajax = useAjax()
  const queryKey = queryKeys.locationMessages(locationId)
  const queryFn = () => ajax.get("/member/messaging/locationMessages", { locationId })

  return useQuery(queryKey, queryFn, {
    refetchInterval: 20 * 1000, // until we can push server-side invalidate
    suspense: true
  })
}

// mutations

export function useSendMessageMutation(locationId) {
  const ajax = useAjax()
  const queryClient = useQueryClient()
  const mutationFn = body => ajax.post("/member/messaging/sendToLocation", { locationId, body })
  const onSuccess = () => queryClient.invalidateQueries(queryKeys.messages)
  return useMutation(mutationFn, { onSuccess })
}
