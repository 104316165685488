import React, { useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import InputWrapper from "./InputWrapper"
import Label from "./Label"
import Overline from "@sonato/core/components/Overline"
import useUniqueId from "@sonato/core/hooks/useUniqueId"

const TextArea = ({
  label,
  name,
  value,
  placeholder,
  error,
  display,
  disabled,
  maxLength,
  large,
  inputRef,
  rows = 5,
  insetTop = null,
  insetBottom = null,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {}
}) => {
  const inputClasses = classNames(
    "w-full focus:outline-none bg-transparent",
    value && "font-bold",
    disabled && "text-gold-500 placeholder-gold-500 cursor-not-allowed"
  )

  const handleChange = event => {
    onChange(event.target.value)
  }

  const localRef = useRef()
  const myInputRef = inputRef ? inputRef : localRef

  const focusInput = () => {
    myInputRef.current && myInputRef.current.focus()
  }

  const inputId = useUniqueId()
  const counter = !display && maxLength && `${value.length}/${maxLength}`
  const showLabel = large && label

  // Add ellipsis to displayed value if it overflows
  const displayStyle = {
    height: `${rows * 1.25}rem`,
    display: "-webkit-box",
    WebkitLineClamp: rows,
    WebkitBoxOrient: "vertical"
  }

  return (
    <InputWrapper error={error} disabled={disabled} display={display} onClick={focusInput}>
      <div className="flex flex-col justify-center p-3 rounded">
        {showLabel && <Label text={label} error={error} htmlFor={inputId} />}
        <div className={classNames("flex flex-col", { "mt-1": showLabel })}>
          {insetTop}
          {display ? (
            <div className="font-bold overflow-hidden whitespace-pre-wrap" style={displayStyle}>
              {value}
            </div>
          ) : (
            <textarea
              id={inputId}
              rows={rows}
              ref={myInputRef}
              className={inputClasses}
              name={name}
              value={value}
              placeholder={placeholder}
              maxLength={maxLength}
              disabled={disabled}
              style={{ caretColor: "#b3956e" }}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          )}
          {insetBottom}
        </div>

        {counter && (
          <Overline.Small className="ml-3 text-gray-900 opacity-40 text-right">
            {counter}
          </Overline.Small>
        )}
      </div>
    </InputWrapper>
  )
}

TextArea.propTypes = {
  /** Input label. */
  label: PropTypes.string,
  /** Name of input field.  */
  name: PropTypes.string,
  /** Value of input. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Placeholder text. */
  placeholder: PropTypes.string,
  /** Error message. */
  error: PropTypes.string,
  /** Display-only mode, if true. */
  display: PropTypes.bool,
  /** Disabled if true. */
  disabled: PropTypes.bool,
  /** Large if true. */
  large: PropTypes.bool,
  /** Limit max length and show counter. */
  maxLength: PropTypes.number,
  /** Change handler, invoked with new value. */
  onChange: PropTypes.func,
  /** Focus callback */
  onFocus: PropTypes.func,
  /** Blur callback */
  onBlur: PropTypes.func,
  /** Number of visible text lines for the text area input. */
  rows: PropTypes.number,
  /** An element to insert above the text editing area. */
  insetTop: PropTypes.node,
  /** An element to insert below the text editing area. */
  insetBottom: PropTypes.node,
  /** Used for passing the reference, use the `ref` prop instead. */
  inputRef: PropTypes.any
}

export default React.forwardRef((props, ref) => <TextArea inputRef={ref} {...props} />)
