import backgroundImage from "@sonato/core/static/images/backgrounds/home.jpg"
import logo from "@sonato/core/static/images/logos/sonato-white.svg"
import { privacyUrl } from "@sonato/core/urls"
import React from "react"
import { useTranslation } from "react-i18next"

function NotFound() {
  const { t } = useTranslation()
  return (
    <div
      className="h-full bg-center bg-cover"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="flex flex-col items-center justify-center h-full bg-black/60">
        <img className="w-1/5 mb-20" alt="Sonato - Hospitality Simplified" src={logo} />
        <h2 className="text-6xl text-white">{t("404 Not Found")}</h2>
        <p className="my-4 text-white underline">
          <a href={privacyUrl()}>{t("Privacy Policy")}</a>
        </p>
      </div>
    </div>
  )
}

export default NotFound
