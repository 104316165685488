import "delayed-scroll-restoration-polyfill"
import "@sonato/core/css/tailwind.generated.css"
import "./i18n"
import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { AuthenticationContextProvider } from "@sonato/core/hooks/authentication"
import App from "./pages"
import { RecoilRoot } from "recoil"
import ReservedRoutes from "@sonato/core/pages/ReservedRoutes"
import { QueryClient, QueryClientProvider } from "react-query"
import Loader from "components/loader"
import { ReactQueryDevtools } from "react-query/devtools"
import GoogleAnalytics from "@sonato/core/components/GoogleAnalytics"

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loader isFixed={true} />}>
        <RecoilRoot>
          <BrowserRouter>
            <GoogleAnalytics>
              <AuthenticationContextProvider>
                <ReservedRoutes>
                  <App />
                </ReservedRoutes>
              </AuthenticationContextProvider>
            </GoogleAnalytics>
          </BrowserRouter>
        </RecoilRoot>
      </Suspense>
      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
)
