import NotFound from "@sonato/core/components/NotFound"
import ProtectedRoute from "@sonato/core/components/ProtectedRoute"
import React from "react"
import { Route, Switch } from "react-router-dom"
import Home from "./Homepage"
import MemberRoutes from "./member"
import VerifyEmail from "./VerifyEmail"

function Router() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path={VerifyEmail.path}>
        <VerifyEmail />
      </Route>
      <ProtectedRoute path="/member">
        <MemberRoutes />
      </ProtectedRoute>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  )
}

export default Router
