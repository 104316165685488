import React, { useState } from "react"
import { AsYouType, parseIncompletePhoneNumber, parsePhoneNumber } from "libphonenumber-js"
import { noOp } from "@sonato/core/utils"
import Countries from "@sonato/core/countries"
import { Options } from "@sonato/core/components/form/Select"
import TextInput from "@sonato/core/components/form/TextInput"

const COUNTRY_OPTIONS = Object.values(Countries)
  .sort((a, b) => a.name.localeCompare(b.name))
  .map(c => ({
    label: `${c.name} (+${c.countryCode})`,
    value: `${c.alpha2}`
  }))

const CountrySelector = ({ country, isEditable, onChange }) => {
  const [isOpen, setOpen] = useState(false)
  const countryCode = `+${Countries[country].countryCode}`

  const toggleOpen = () => {
    if (isEditable) {
      setOpen(oldValue => !oldValue)
    }
  }

  const editorClasses = "w-full font-bold text-right"

  return (
    <div className="text-right w-full">
      {isEditable && (
        <button type="button" className={editorClasses} onClick={toggleOpen}>
          {countryCode}
        </button>
      )}
      {!isEditable && <span className={editorClasses}>{countryCode}</span>}
      <div className="w-full text-left font-bold">
        <Options
          isOpen={isOpen}
          setOpen={setOpen}
          options={COUNTRY_OPTIONS}
          multiple={false}
          maxVisibleOptions={5}
          selectedValues={[country]}
          focused={isOpen}
          onChange={val => {
            setTimeout(() => setOpen(false), 20)
            onChange(val)
          }}
        />
      </div>
    </div>
  )
}

const PhoneNumberInput = ({
  value,
  label,
  large,
  display,
  disabled,
  error,
  onChange = noOp,
  name,
  onBlur,
  inputRef
}) => {
  const [selectedCountry, setSelectedCountry] = useState()
  const [guessedCountry, number] = extractCountryAndNumber(value, selectedCountry)
  const country = selectedCountry || guessedCountry

  // Workaround for the issue when a number is formatted as `(555) 123-4567`, it
  // is impossible to delete the right paren since it would just be re-inserted
  // by the formatter.
  let formattedNumber = new AsYouType(country).input(number)
  if (formattedNumber.endsWith(")")) {
    formattedNumber = formattedNumber.substring(0, formattedNumber.length - 1)
  }

  const handleChange = (country, number) => {
    const countryCode = Countries[country].countryCode
    const parsedNumber = parseIncompletePhoneNumber(number)

    if (parsedNumber.length > 0) {
      onChange(`+${countryCode}${parsedNumber}`)
    } else {
      onChange("")
    }
  }

  const onCountryChanged = newCountry => {
    setSelectedCountry(newCountry)
    handleChange(newCountry, number)
  }

  const onNumberChanged = newNumber => {
    handleChange(country, newNumber)
  }

  const isEditable = !display && !disabled

  return (
    <div className="relative">
      <TextInput
        frameClasses="h-6 w-10 border-r pr-3 mr-2 text-right"
        leftFrame={
          <CountrySelector isEditable={isEditable} country={country} onChange={onCountryChanged} />
        }
        large={large}
        value={formattedNumber}
        label={label}
        onChange={onNumberChanged}
        error={error}
        display={display}
        disabled={disabled}
        name={name}
        onBlur={onBlur}
        ref={inputRef}
      />
    </div>
  )
}

const EMPTY_STATE = [Countries.US.alpha2, ""]

const extractCountryAndNumber = (value, selectedCountry) => {
  const countryCode = selectedCountry || Countries.US.alpha2

  if (!value) {
    return [countryCode, ""]
  }

  try {
    const parsed = parsePhoneNumber(value, countryCode)
    const country = selectedCountry ? selectedCountry : parsed.country ?? guessCountryCode(value)
    return country ? [country, parsed.nationalNumber] : EMPTY_STATE
  } catch (error) {
    let incompleteNumber
    let countryPrefix = "+" + Countries[countryCode].countryCode

    if (value.startsWith(countryPrefix)) {
      incompleteNumber = value.substring(countryPrefix.length)
    } else if (value.startsWith("+")) {
      incompleteNumber = value.substring(1)
    } else {
      incompleteNumber = value
    }

    return [countryCode, incompleteNumber]
  }
}

/**
 * It's possible that parsing the phone number does not fail, but the resulting
 * object does not have `country` populated, probably because it considers it
 * ambiguous. This function attempts to pick a country anyway because we need to
 * show something.
 *
 * This is only an issue for country codes which are shared between countries
 * like +1 is between US, Candada and several other countries.
 */
export const guessCountryCode = phoneNumber => {
  // For +1 numbers favor US since it's probably be the most frequent
  if (phoneNumber.startsWith("+1")) {
    return Countries.US.alpha2
  }

  // Otherwise, pick the first country with the correct country code
  const country = Object.values(Countries).find(
    c => c.countryCode && phoneNumber.startsWith(`+${c.countryCode}`)
  )

  return country ? country.alpha2 : null
}

export default React.forwardRef((props, ref) => <PhoneNumberInput inputRef={ref} {...props} />)
