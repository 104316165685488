import React from "react"
import PropTypes from "prop-types"

const ActionsVisitRequestVisitIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M11.017 8.217a2.8 2.8 0 100 5.6 2.8 2.8 0 000-5.6zM7 11.017a4.017 4.017 0 118.035 0 4.017 4.017 0 01-8.035 0z" />
      <path d="M11.018 8.704c.336 0 .609.273.609.609v3.409a.609.609 0 01-1.218 0v-3.41c0-.335.273-.608.609-.608z" />
      <path d="M8.705 11.017c0-.336.273-.608.609-.608h3.409a.609.609 0 010 1.217h-3.41a.609.609 0 01-.608-.609zM2.313 2.946a.25.25 0 00-.25.25v6.992a.25.25 0 00.25.25h3.5a.625.625 0 010 1.25h-3.5a1.5 1.5 0 01-1.5-1.5V3.196a1.5 1.5 0 011.5-1.5h7.874a1.5 1.5 0 011.5 1.5v2.617a.625.625 0 11-1.25 0V3.196a.25.25 0 00-.25-.25H2.314z" />
      <path d="M11.063 5.563H1.437v-1.25h9.626v1.25zM4.058.813c.345 0 .625.28.625.625v1.75a.625.625 0 01-1.25 0v-1.75c0-.346.28-.625.625-.625zM8.433.813c.345 0 .625.28.625.625v1.75a.625.625 0 01-1.25 0v-1.75c0-.346.28-.625.625-.625z" />
    </g>
  </svg>
)

ActionsVisitRequestVisitIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsVisitRequestVisitIcon svgRef={ref} {...props} />
))
export default ForwardRef
