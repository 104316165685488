import React from "react"
import classNames from "classnames"

export const SmallOverline = ({ className, children }) => {
  const classes = "uppercase font-bold text-overline-sm tracking-overline-sm"
  return <div className={classNames(classes, className)}>{children}</div>
}

export const MediumOverline = ({ className, children }) => {
  const classes = "uppercase text-overline-md tracking-overline-md"
  return <div className={classNames(classes, className)}>{children}</div>
}

export default {
  Small: SmallOverline,
  Medium: MediumOverline
}
