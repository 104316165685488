import React from "react"
import PropTypes from "prop-types"

const AppsHotelIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="currentColor">
      <path d="M15 7.833H1a1 1 0 00-1 1V11.5a1 1 0 00.75.967.334.334 0 01.25.322v.711a.666.666 0 101.333 0v-.667a.333.333 0 01.334-.333h10.666a.333.333 0 01.334.333v.667a.667.667 0 101.333 0v-.71a.334.334 0 01.25-.321A1 1 0 0016 11.5V8.833a1 1 0 00-1-1zM1.667 6.833A.333.333 0 002 7.167h12a.333.333 0 00.333-.334V3.5a1.667 1.667 0 00-1.667-1.667H3.333A1.667 1.667 0 001.666 3.5v3.333zm2.666-2h1.333a1.667 1.667 0 011.444.834.333.333 0 01-.288.5H3.178a.333.333 0 01-.289-.5 1.667 1.667 0 011.444-.834zm6 0h1.333a1.667 1.667 0 011.444.834.333.333 0 01-.288.5H9.178a.333.333 0 01-.289-.5 1.667 1.667 0 011.444-.834z" />
    </g>
  </svg>
)

AppsHotelIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsHotelIcon svgRef={ref} {...props} />)
export default ForwardRef
