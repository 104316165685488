import React from "react"
import { parseISO, sub } from "date-fns"

import Overline from "@sonato/core/components/Overline"

const LocationBadge = ({ location }) => {
  if (location.profile && !location.profile.visitRequestsEnabled) {
    return <Badge>Coming Soon</Badge>
  } else if (isNew(location)) {
    return <Badge>New</Badge>
  }
  return null
}

const Badge = ({ children }) => (
  <span
    style={{ top: "-5px" }}
    className="inline-block font-sans h-6 pt-1 px-3 bg-gold-900 text-white rounded-full relative"
  >
    <Overline.Small>{children}</Overline.Small>
  </span>
)

const isNew = location => {
  const created = parseISO(location.insertedAt)
  const thirtyDaysAgo = sub(new Date(), { days: 30 })
  return created > thirtyDaysAgo
}

export default LocationBadge
