import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import DatePicker, { dateStringProp } from "../DatePicker"
import InputWrapper from "./InputWrapper"
import Label from "./Label"
import { ChevronDownIcon, ChevronUpIcon } from "../../icons"
import { noOp } from "@sonato/core/utils"
import { useFormatters } from "../../format"
import { useMouseClickOutside } from "@sonato/core/hooks/document"

const Closed = ({ value, disabled, placeholder }) => {
  const formatters = useFormatters()
  const content = value ? formatters.longDate(value) : placeholder
  const contentClasses = value ? "font-bold" : "opacity-40"
  const wrapperClasses = classNames("mt-2 flex justify-between w-full", {
    "cursor-pointer": !disabled
  })

  return (
    <div className={wrapperClasses}>
      <span className={contentClasses}>{content}</span>
      <ChevronDownIcon className="w-4 h-4" />
    </div>
  )
}

Closed.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool.isRequired
}

const Open = ({ disabled, value, onChange, onDismiss, dropdownOffset, ...props }) => {
  const ref = useMouseClickOutside(onDismiss)

  return (
    <div className="w-full flex justify-end z-50">
      <ChevronUpIcon className="w-4 h-4 mb-2" />
      <div ref={ref} className="w-80 bg-white absolute z-10" style={dropdownOffset}>
        <DatePicker
          onChange={onChange}
          value={value}
          style={DatePicker.STYLE.floating}
          {...props}
        />
      </div>
    </div>
  )
}

Open.propTypes = {
  dropdownOffset: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number
  }),
  disabled: PropTypes.bool.isRequired,
  value: dateStringProp,
  onChange: PropTypes.func,
  onDismiss: PropTypes.func,
  minDate: dateStringProp
}

const DateInput = ({
  name,
  value,
  label,
  error,
  placeholder,
  dropdownOffset = { top: 44, left: 3 },
  display = false,
  disabled = false,
  onChange = noOp,
  onBlur = noOp,
  ...props
}) => {
  const [isActive, setActive] = useState(false)

  const wrapperClasses = classNames(
    "relative h-16 px-3 flex flex-col justify-center focus:outline-none",
    {
      "cursor-pointer": !disabled
    }
  )

  const onDateSelected = date => {
    setActive(false)
    onChange(date)
    onBlur()
  }

  const onWrapperClicked = e => {
    e.preventDefault()
    if (!disabled && !isActive) {
      setActive(true)
    }
  }

  const Input = isActive ? Open : Closed

  return (
    <InputWrapper error={error} disabled={disabled} display={display} onClick={onWrapperClicked}>
      <div className={wrapperClasses} tabIndex="0">
        {label && <Label text={label} error={error} />}

        <Input
          onChange={onDateSelected}
          onDismiss={() => setActive(false)}
          name={name}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          dropdownOffset={dropdownOffset}
          {...props}
        />
      </div>
    </InputWrapper>
  )
}

DateInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: dateStringProp,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  display: PropTypes.bool,
  minDate: dateStringProp,
  maxDate: dateStringProp,
  dropdownOffset: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number
  })
}

export default DateInput
