import React from "react"
import PropTypes from "prop-types"

const ThirdPartyGoogleIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fill="currentColor">
      <path d="M13.993 7.776a5.68 5.68 0 00-.14-1.355H7.63v2.46h3.654c-.074.611-.472 1.532-1.356 2.15l-.012.083 1.968 1.524.136.014c1.252-1.156 1.974-2.858 1.974-4.876z" />
      <path d="M7.63 14.258c1.79 0 3.292-.59 4.39-1.606l-2.093-1.62c-.56.39-1.31.663-2.298.663-1.753 0-3.24-1.157-3.77-2.755l-.079.007-2.046 1.583-.026.075a6.624 6.624 0 005.921 3.653z" />
      <path d="M3.858 8.94a4.08 4.08 0 01-.22-1.311c0-.457.08-.899.213-1.311l-.004-.088-2.072-1.61-.067.033A6.635 6.635 0 001 7.63c0 1.068.258 2.077.708 2.975l2.15-1.664z" />
      <path d="M7.63 3.563c1.244 0 2.084.538 2.562.987l1.871-1.827C10.914 1.656 9.42 1 7.63 1a6.624 6.624 0 00-5.921 3.653L3.85 6.318c.538-1.598 2.025-2.755 3.778-2.755z" />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" transform="translate(1 1)" d="M0 0h13v13.303H0z" />
      </clipPath>
    </defs>
  </svg>
)

ThirdPartyGoogleIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ThirdPartyGoogleIcon svgRef={ref} {...props} />
))
export default ForwardRef
