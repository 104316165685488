import React from "react"
import PropTypes from "prop-types"

const EssentialsGymIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M5.167 7.733a.5.5 0 01.5-.5h4.667a.5.5 0 010 1H5.667a.5.5 0 01-.5-.5z" />
      <path d="M4.734 4.5a.433.433 0 00-.434.433v5.6a.433.433 0 10.867 0v-5.6a.433.433 0 00-.433-.433zM3.72 3.92a1.433 1.433 0 012.447 1.013v5.6a1.433 1.433 0 11-2.867 0v-5.6c0-.38.151-.744.42-1.013zM.5 7.733a.5.5 0 01.5-.5h.933a.5.5 0 110 1H1a.5.5 0 01-.5-.5z" />
      <path d="M1.853 5.787A1.433 1.433 0 014.3 6.8v1.867a1.433 1.433 0 11-2.866 0V6.8c0-.38.15-.745.42-1.013zm1.014.58a.433.433 0 00-.433.433v1.867a.433.433 0 00.866 0V6.8a.433.433 0 00-.433-.433zM10.253 3.92A1.433 1.433 0 0112.7 4.933v5.6a1.433 1.433 0 11-2.867 0v-5.6c0-.38.151-.744.42-1.013zm1.013.58a.433.433 0 00-.433.433v5.6a.433.433 0 10.867 0v-5.6a.434.434 0 00-.434-.433zM13.567 7.733a.5.5 0 01.5-.5H15a.5.5 0 110 1h-.933a.5.5 0 01-.5-.5z" />
      <path d="M13.133 6.367a.433.433 0 00-.433.433v1.867a.434.434 0 00.867 0V6.8a.433.433 0 00-.434-.433zm-1.013-.58A1.433 1.433 0 0114.567 6.8v1.867a1.433 1.433 0 01-2.867 0V6.8c0-.38.151-.745.42-1.013z" />
    </g>
  </svg>
)

EssentialsGymIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <EssentialsGymIcon svgRef={ref} {...props} />)
export default ForwardRef
