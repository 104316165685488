import React from "react"
import PropTypes from "prop-types"

const ActionsCheckIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.953 3.41c.339.254.396.72.128 1.041l-6.816 8.171c-.331.41-.85.643-1.395.627a1.72 1.72 0 01-1.351-.703L2.393 9.687a.717.717 0 01.189-1.033.81.81 0 011.09.18l2.13 2.864.011.014a.131.131 0 00.104.055.132.132 0 00.107-.048l.01-.012 6.82-8.175a.812.812 0 011.099-.122z"
      fill="currentColor"
    />
  </svg>
)

ActionsCheckIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ActionsCheckIcon svgRef={ref} {...props} />)
export default ForwardRef
