import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { Trans } from "react-i18next"
import Overline from "@sonato/core/components/Overline"
import { privacyUrl } from "@sonato/core/urls"

const Footer = ({ children }) => {
  const privacyPolicy = (
    <a target="_blank" rel="noopener noreferrer" href={privacyUrl()}>
      <Trans>Privacy Policy</Trans>
    </a>
  )
  const items = React.Children.toArray(children)
  items.push(privacyPolicy)

  const itemClasses = "inline-block"
  return (
    <footer className="py-8 text-center text-gray-300">
      <Overline.Small>
        {items.map((c, idx) => (
          <span key={idx}>
            {idx !== 0 && <span className={classNames(itemClasses, "mx-2")}>&bull;</span>}
            <span className={itemClasses}>{c}</span>
          </span>
        ))}
      </Overline.Small>
    </footer>
  )
}

Footer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.node
}

export default Footer
