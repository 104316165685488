import React from "react"
import PropTypes from "prop-types"

const ActionsDownloadCSVIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path d="M1.434 1.078a.358.358 0 0 0-.359.358V13.57a.358.358 0 0 0 .359.359H3.3a.575.575 0 0 1 0 1.15H1.434a1.509 1.509 0 0 1-1.509-1.509V1.436A1.508 1.508 0 0 1 1.434-.072h6.613c.4 0 .783.159 1.066.441l3.654 3.653a1.508 1.508 0 0 1 .442 1.067v2.88a.575.575 0 1 1-1.15 0V5.09a.36.36 0 0 0-.105-.254L8.3 1.183a.359.359 0 0 0-.253-.105H1.434Z" />
    <path d="M7.967-.072c.318 0 .575.257.575.575v3.733a.358.358 0 0 0 .358.358h3.734a.575.575 0 0 1 0 1.15H8.9a1.508 1.508 0 0 1-1.508-1.508V.503c0-.318.258-.575.575-.575ZM5.307 9.976a2.442 2.442 0 0 1 1.726-.715.575.575 0 1 1 0 1.15 1.292 1.292 0 0 0-1.291 1.292v.933a1.291 1.291 0 0 0 1.291 1.292.575.575 0 1 1 0 1.15 2.442 2.442 0 0 1-2.441-2.442v-.933c0-.648.257-1.269.715-1.727ZM8.767 9.703a1.508 1.508 0 0 1 1.067-.442h.933a.575.575 0 0 1 0 1.15h-.933a.358.358 0 0 0-.359.358c0 .378.208.568.704.94l.042.032c.44.329 1.12.838 1.12 1.828a1.509 1.509 0 0 1-1.507 1.509H8.9a.575.575 0 0 1 0-1.15h.934a.358.358 0 0 0 .358-.359c0-.377-.207-.568-.703-.94l-.043-.031c-.44-.33-1.12-.838-1.12-1.829 0-.4.158-.783.441-1.066ZM12.634 9.261c.317 0 .575.257.575.575v1.584c0 .639.122 1.269.358 1.856a4.986 4.986 0 0 0 .358-1.856V9.836a.575.575 0 1 1 1.15 0v1.584c0 1.21-.358 2.395-1.03 3.402a.575.575 0 0 1-.957 0 6.134 6.134 0 0 1-1.03-3.402V9.836c0-.318.258-.575.576-.575Z" />
  </svg>
)
ActionsDownloadCSVIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsDownloadCSVIcon svgRef={ref} {...props} />
))
export default ForwardRef
