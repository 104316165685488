import React from "react"
import PropTypes from "prop-types"

const ActionsCrossIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M12.769 3.231a.79.79 0 010 1.117l-8.421 8.42a.79.79 0 01-1.117-1.116l8.421-8.42a.79.79 0 011.117 0z" />
      <path d="M3.231 3.231a.79.79 0 011.117 0l8.42 8.421a.79.79 0 01-1.116 1.117l-8.42-8.421a.79.79 0 010-1.117z" />
    </g>
  </svg>
)

ActionsCrossIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ActionsCrossIcon svgRef={ref} {...props} />)
export default ForwardRef
