import { useEffect, useRef } from "react"

const useDocumentEvent = (event, callback) => {
  useEffect(() => {
    document.addEventListener(event, callback)
    return () => document.removeEventListener(event, callback)
  }, [event, callback])
}

export const useDocumentMouseDown = callback => {
  return useDocumentEvent("mousedown", callback)
}

export const useDocumentKeyDown = callback => {
  return useDocumentEvent("keydown", callback)
}

/**
 * Detect when user clicks outside of a given element.
 *
 * @param {callable} callback - Function to invoke on such a click.
 *
 * @returns {object} - A React ref which should be set on the element for which
 *                     clicks are monitored.
 */
export const useMouseClickOutside = callback => {
  const ref = useRef()

  useDocumentMouseDown(e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  })

  return ref
}
