import React from "react"
import PropTypes from "prop-types"

const ActionsSearchIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M5.427 3.426a3.781 3.781 0 102.959 6.96 3.781 3.781 0 00-2.959-6.96zM2.046 8.972a5.281 5.281 0 119.721-4.132 5.281 5.281 0 01-9.721 4.132z" />
      <path d="M9.58 9.58a.75.75 0 011.06 0l3.515 3.515a.75.75 0 11-1.06 1.06L9.58 10.64a.75.75 0 010-1.06z" />
    </g>
  </svg>
)

ActionsSearchIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ActionsSearchIcon svgRef={ref} {...props} />)
export default ForwardRef
