import React from "react"
import PropTypes from "prop-types"

const EssentialsMoneyIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g stroke="currentColor" strokeWidth={1.25} strokeLinecap="round" strokeLinejoin="round">
      <path d="M8 .667v14.666M11.333 3.333h-5a2.333 2.333 0 000 4.667h3.334a2.333 2.333 0 010 4.667H4" />
    </g>
  </svg>
)

EssentialsMoneyIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <EssentialsMoneyIcon svgRef={ref} {...props} />)
export default ForwardRef
