import React, { useState } from "react"
import PropTypes from "prop-types"
import Checkbox from "@sonato/core/components/form/Checkbox"
import TextInput from "@sonato/core/components/form/TextInput"
import useHover from "@sonato/core/hooks/useHover"
import { ActionsCrossIcon, EssentialsPeopleMemberIcon } from "@sonato/core/icons"

const CloseIcon = ({ disabled, onDismiss }) => {
  const { ref, isHovered } = useHover()
  const allowRemove = !disabled && isHovered
  return (
    <span ref={ref}>
      {allowRemove ? (
        <button onClick={onDismiss}>
          <ActionsCrossIcon className="text-gray-500" />
        </button>
      ) : (
        <EssentialsPeopleMemberIcon />
      )}
    </span>
  )
}

const CheckedTextInput = props => {
  const {
    checked = false,
    disabled = false,
    value = "",
    onDismiss = () => {},
    onChange = () => {},
    ...rest
  } = props

  const [state, setState] = useState({
    checked,
    value,
    name: rest.name
  })

  if (props.display || props.maxLength) {
    return <TextInput {...props} />
  }

  const stateToEvent = s => {
    return { name: s.name, value: s.value, checked: s.checked }
  }

  const handleCheckboxChange = e => {
    const newState = {
      ...state,
      checked: e
    }
    setState(newState)
    onChange(stateToEvent(newState))
  }

  const handleTextChange = e => {
    const newState = {
      ...state,
      value: e
    }

    setState(newState)
    onChange(stateToEvent(newState))
  }

  const handleDismiss = () => {
    onDismiss(stateToEvent(state))
  }

  return (
    <div className="flex flex-row items-center relative">
      <div className="inline-block w-full">
        <TextInput
          disabled={disabled}
          leftFrame={<CloseIcon disabled={disabled} onDismiss={handleDismiss} />}
          value={state.value}
          onChange={handleTextChange}
          {...rest}
        />
      </div>
      <div className="absolute right-0 inline-block mr-3">
        <Checkbox disabled={disabled} value={state.checked} onChange={handleCheckboxChange} />
      </div>
    </div>
  )
}

CheckedTextInput.propTypes = {
  ...TextInput.PropTypes,
  onDismiss: PropTypes.func,
  checked: PropTypes.bool
}

export default React.forwardRef((props, ref) => <CheckedTextInput inputRef={ref} {...props} />)
