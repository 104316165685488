import React, { createContext, useContext, useReducer, useEffect } from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import { client, useSubscription } from "@sonato/core/client"
import { groupBy } from "lodash"
import * as Storage from "@sonato/core/storage"

const AuthenticationContext = createContext()

const initialState = {
  identity: null,
  location: null,
  isAuthenticated: false,
  isPending: true,
  token: null,
  identitySummaries: []
}

const Actions = {
  CLEAR: "CLEAR",
  IDENTITY_SUMMARIES: "IDENTITY_SUMMARIES",
  AUTHENTICATED: "AUTHENTICATED",

  SIGN_OUT: "SIGN_OUT",
  RESEND_AUTHENTICATION: "RESEND_AUTHENTICATION",
  CHANGE_TOKEN: "CHANGE_TOKEN",

  identitySummaries: summaries => ({ type: Actions.IDENTITY_SUMMARIES, summaries }),
  authenticated: (identity, permissions) => ({
    type: Actions.AUTHENTICATED,
    allPermissions: permissions,
    identity
  }),
  clear: () => ({ type: Actions.CLEAR }),
  resendAuthentication: () => ({ type: Actions.RESEND_AUTHENTICATION }),
  changeIdentity: identity => ({ type: Actions.CHANGE_TOKEN, token: identity.token })
}

const resetTokens = () => {
  Storage.clearAuthToken()
  Storage.clearIdentities()
}

const reducer = (state, action) => {
  switch (action.type) {
    case Actions.IDENTITY_SUMMARIES:
      Storage.setIdentities(action.summaries)
      return { ...state, identitySummaries: action.summaries }

    case Actions.AUTHENTICATED:
      const identity = action.identity

      i18n.changeLanguage(identity.subject.locale)
      // If we're resuming, then the token won't be returned to us since it
      // we already have it locally
      if (identity.token) {
        Storage.setAuthToken(identity.token)
        client.updateParams({ token: identity.token })
      }

      let byResourceAndAction

      if (action.allPermissions) {
        byResourceAndAction = Object.entries(groupBy(action.allPermissions, "resource")).reduce(
          (acc, [k, v]) => {
            v.forEach(permission => {
              acc[k] = acc[k] || {}
              acc[k][permission.action] = permission
            })
            return acc
          },
          {}
        )
      } else {
        byResourceAndAction = state.permissionsByResourceAndAction
      }

      return {
        permissionsByResourceAndAction: byResourceAndAction,
        identity: identity,
        location: identity.location,
        isAuthenticated: true,
        isPending: false,
        token: action.identity.token ? action.identity.token : state.token,
        identitySummaries: Storage.getIdentities()
      }

    case Actions.CLEAR:
      resetTokens()

      return {
        identity: null,
        location: null,
        isAuthenticated: false,
        isPending: false,
        token: null,
        identitySummaries: []
      }

    case Actions.RESEND_AUTHENTICATION:
      client.push("public/authentication", "resend")
      return state

    case Actions.CHANGE_TOKEN:
      Storage.setAuthToken(action.token)
      client.updateParams({ token: action.token })
      return { ...state, token: action.token }

    default:
      console.log("Unknown action type", action.type)
      return state
  }
}

const AuthenticationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    client.connectPromise
      .then(response => {
        if (!response || !(response.identity || response.identitySummaries)) {
          dispatch(Actions.clear())
          return
        }

        if (response?.identitySummaries) {
          dispatch(Actions.identitySummaries(response.identitySummaries))
        }
        if (response?.identity) {
          dispatch(Actions.authenticated(response.identity, response.allPermissions))
        }
      })
      .catch(() => dispatch(Actions.clear()))
  }, [])

  useSubscription(
    "public/authentication",
    payload => {
      dispatch(Actions.authenticated(payload, null))
    },
    "authenticated"
  )

  useSubscription(
    "public/authentication",
    payload => {
      dispatch(Actions.identitySummaries(payload.identitySummaries))
    },
    "identitySummaries"
  )

  useSubscription(
    "public/authentication",
    () => dispatch(Actions.resendAuthentication()),
    "refreshIdentity"
  )
  return (
    <AuthenticationContext.Provider value={[state, dispatch]}>
      {children}
    </AuthenticationContext.Provider>
  )
}

AuthenticationContextProvider.propTypes = {
  children: PropTypes.node
}

const useAuthentication = () => {
  const context = useContext(AuthenticationContext)

  if (!context) {
    throw Error(
      "Authentication context not available. Is this wrapped inside <AuthenticationContextProvider>?"
    )
  }

  const [auth, dispatch] = context

  const resendAuthentication = () => {
    dispatch(Actions.resendAuthentication())
  }

  const signOut = () => {
    client.push("public/authentication", "signOut")
    dispatch(Actions.clear())
  }

  const permissionsByResource = (resource, action) => {
    const byResource = auth.permissionsByResourceAndAction

    if (Array.isArray(resource)) {
      return resource.flatMap(r => permissionsByResource(r, action))
    }

    const permission =
      byResource[resource] && byResource[resource][action] ? byResource[resource][action] : null
    return permission ? [permission] : []
  }

  const collectIds = permissions => {
    return Object.entries(permissions).flatMap(([action, resource]) =>
      permissionsByResource(resource, action).map(p => p.id)
    )
  }

  const requireAny = permissions => {
    const requiredPermissionIds = collectIds(permissions)
    if (requiredPermissionIds.length === 0) {
      console.log("No permission ids found for", permissions)
      return false
    }
    return requiredPermissionIds.some(id => auth.identity.permissions.includes(id))
  }

  const requireAll = permissions => {
    const requiredPermissionIds = collectIds(permissions)
    if (requiredPermissionIds.length === 0) {
      console.log("No permission ids found for", permissions)
      return false
    }
    return requiredPermissionIds.every(id => auth.identity.permissions.includes(id))
  }

  const changeIdentity = identitySummary => {
    const summaryUrl = new URL(identitySummary.url)
    summaryUrl.searchParams.set("t", identitySummary.token)
    window.location = summaryUrl.toString()
  }

  const isCurrentIdentity = identity => {
    const currentIdentity = auth.identity
    return (
      identity.location.id === currentIdentity.location.id &&
      identity.identityType === currentIdentity.identityType
    )
  }

  return {
    auth,
    requireAny,
    requireAll,
    changeIdentity,
    dispatch,
    isCurrentIdentity,
    resendAuthentication,
    signOut
  }
}

const useAuthLocation = () => {
  const { auth } = useAuthentication()
  return auth.location
}

export { useAuthentication, useAuthLocation, AuthenticationContext, AuthenticationContextProvider }
