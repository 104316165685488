import { Socket } from "phoenix"

const CHANNEL_REPLY_PREFIX = "chan_reply_"
const PHOENIX_EVENT_PREFIX = "phx_"
const PHOENIX_REPLY_EVENT = "phx_reply"
const RPC_CHANNEL_NAME = "halo:rpc"

const TYPE_CALL = "call"
const TYPE_PUSH = "push"

const noop = () => {}

/**
 * Halo Clients connect to the Halo Socket and provide bidirectional communication between the client and server.
 */
class Client {
  constructor(options = {}) {
    this.topics = new Map()
    this.channelName = options["channelName"] || RPC_CHANNEL_NAME
    this.debug = options["debug"] || false
    this.onMessage = this.defaultOnMessage
    this.timeout = options["timeout"] || 5000
    this.initState()
  }

  initState() {
    this.channel = null
    this.reconnectListeners = new Map()
    this.path = null
    this.pending = []
    this.ready = false
    this.socket = null
    this.connectResponse = null
    this.params = {}

    // Create the connect promise in constructor because other components depend
    // on it existing. It is resolved after calling `connect()`. If connect is
    // never called, this promise will never be resolved.
    this.connectPromise = new Promise((resolve, reject) => {
      this.resolveConnectPromise = resolve
      this.rejectConnectPromise = reject
    })
  }

  defaultOnMessage(eventName, { namespace, payload }) {
    const subscribers = this.topics.get(namespace)
    if (subscribers) {
      // it's important to clone the subscribers set here in case a callback attempts to add additional callbacks,
      // as this can cause an infinite loop (e.g. a React `useEffect` hook that subscribes)
      const cloned = [...subscribers]
      cloned.forEach(callback => callback(payload, eventName))
    }
    return payload
  }

  subscribe(topic, callback, eventName) {
    const callbackFn =
      eventName === undefined
        ? callback
        : (payload, incomingEventName) => {
            if (eventName === incomingEventName || eventName.includes(incomingEventName)) {
              callback(payload, incomingEventName)
            }
          }

    let subscribers = this.topics.get(topic)
    if (subscribers) {
      subscribers.add(callbackFn)
    } else {
      subscribers = new Set()
      subscribers.add(callbackFn)
      this.topics.set(topic, subscribers)
    }
    return () => subscribers.delete(callbackFn)
  }

  disableDebug() {
    this.debug = false
  }

  enableDebug() {
    this.debug = true
  }

  connect(path, params, callbacks) {
    this.params = params
    this.connectRpcChannel(path, this.params, callbacks)
  }

  connectRpcChannel(path, params, callbacks = {}) {
    const { onReconnect } = callbacks
    this.path = path

    this.socket = new Socket(this.path, { params })

    this.socket.onClose(() => {
      this.log("[SOCK] Connection Closed", this.socket)
    })
    this.socket.onError(() => {
      this.log("[SOCK] Connection Error", this.socket)
    })

    this.log("[SOCK] Connect to Socket", this.path, this.socket, "with params", params)
    this.socket.connect()

    this.socket.onOpen(() => {
      this.log("[SOCK] Socket Connected", this.path, this.socket)

      if (this.channel) {
        this.log("[CHAN] Re-establishing Channel", this.channel)
        onReconnect && onReconnect()
        this.reconnectListeners.forEach(callback => callback())
        this.resolveConnectPromise(this.connectResponse)
        return
      }

      this.channel = this.socket.channel(this.channelName)

      this.channel.onClose(() => {
        this.log("[CHAN] Channel Closed", this.channel)
      })
      this.channel.onError(() => {
        this.log("[CHAN] Channel Error", this.channel)
      })

      this.channel.onMessage = (event, payload, ref) => {
        // Don't intercept System Events
        if (this.isSystemEvent(event)) {
          if (this.isPhoenixReply(event)) {
            this.log(`[REPL:${ref}] <-`, payload.status, payload.response)
          }

          return payload
        }

        this.log("[RECV] <-", payload.namespace, event, payload.payload)
        return this.onMessage(event, payload, ref)
      }

      this.log("[JOIN] Join Channel", this.channelName, this.channel)

      this.channel
        .join()
        .receive("ok", resp => {
          this.log("[JOIN] Join Accepted", this.channelName, this.channel, resp)
          this.log("[JOIN] response", resp)

          this.connectResponse = resp
          this.ready = true
          this.flush()

          this.resolveConnectPromise(resp)
        })
        .receive("error", resp => {
          this.log("[JOIN] Join Rejected", this.channelName, this.channel, resp)
          this.rejectConnectPromise()
        })
    })
  }

  updateParams(newParams) {
    this.params = {
      ...this.params,
      ...newParams
    }
    if (this.socket) {
      this.socket.params = () => ({ ...this.params })
    }
  }

  disconnect(onDone) {
    this.socket.disconnect(() => {
      this.initState()
      onDone()
    })
  }

  flush() {
    if (!this.ready) {
      return
    }

    // Clear all timeouts
    this.pending.forEach(pending => {
      pending.clearTimeout()
    })

    this.pending.forEach(pending => {
      // Fire the pending request
      const req = this.channelPush(pending.event, pending.payload, pending.desiredTimeout)

      // Hook up the real requests callbacks to the pending request's callbacks
      for (const status of Object.getOwnPropertyNames(pending.callbacks)) {
        req.receive(status, pending.callbacks[status])
      }
    })

    // Now that the pending requests have been processed, discard them.
    this.pending = []
  }

  addReconnectListener(name, callback) {
    this.reconnectListeners.set(name, callback)
  }

  removeReconnectListener(name) {
    this.reconnectListeners.delete(name)
  }

  push(namespace, event, payload = {}, options = {}) {
    const timeout = options.timeout || this.timeout

    this.doPush(namespace, event, payload, TYPE_PUSH, timeout)
  }

  call(namespace, event, payload = {}, onSuccess = noop, onError = noop, options = {}) {
    const timeout = options.timeout || this.timeout

    this.doPush(namespace, event, payload, TYPE_CALL, timeout)
      .receive("ok", onSuccess)
      .receive("error", onError)
  }

  doPush(namespace, event, payload, type, timeout) {
    const host = window.location.host
    const serializedPayload = { namespace, host, type, payload }

    if (this.ready) {
      return this.channelPush(event, serializedPayload, timeout)
    } else {
      return this.queuePush(event, serializedPayload, timeout)
    }
  }

  channelPush(event, payload, timeout) {
    const push = this.channel.push(event, payload, timeout)

    const label = payload.type === "call" ? `[CALL:${push.ref}]` : `[${payload.type.toUpperCase()}]`
    this.log(`${label} ->`, payload.namespace, event, payload.payload)

    return push
  }

  queuePush(event, payload, timeout) {
    const pendingRequest = new PendingRequest(event, payload, timeout)

    this.pending.push(pendingRequest)

    const type = payload.type.toUpperCase()
    this.log(`[Q${type}] |>`, payload.namespace, event, payload.payload)

    return pendingRequest
  }

  isSystemEvent(event) {
    return this.isChannelReply(event) || this.isPhoenixEvent(event)
  }

  isChannelReply(event) {
    return event.startsWith(CHANNEL_REPLY_PREFIX)
  }

  isPhoenixEvent(event) {
    return event.startsWith(PHOENIX_EVENT_PREFIX)
  }

  isPhoenixReply(event) {
    return event === PHOENIX_REPLY_EVENT
  }

  underTest() {
    return typeof jest !== "undefined"
  }

  log() {
    if (this.debug && !this.underTest()) {
      console.log(...arguments)
    }
  }
}

class PendingRequest {
  constructor(event, payload, desiredTimeout) {
    this.callbacks = {}
    this.event = event
    this.payload = payload
    this.desiredTimeout = desiredTimeout
    this.timeoutRef = setTimeout(() => {
      this.timeout()
    }, this.desiredTimeout)
  }

  clearTimeout() {
    clearTimeout(this.timeoutRef)
  }

  receive(status, callback) {
    this.callbacks[status] = callback
    return this
  }

  timeout() {
    const timeoutCallback = this.callbacks["timeout"]
    if (timeoutCallback) {
      timeoutCallback()
    }
  }
}

export { Client }
