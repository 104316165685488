import React from "react"
import PropTypes from "prop-types"

const EssentialsWorkspaceIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M7.06 4.044a.488.488 0 01.656-.212l4.521 2.315a.488.488 0 11-.444.868L7.27 4.7a.488.488 0 01-.211-.656zM12.571 7.144c.241.12.339.413.218.654l-1.754 3.507a.488.488 0 11-.872-.436l1.754-3.507a.488.488 0 01.654-.218z" />
      <path d="M11.8 6.058a1.222 1.222 0 111.729 1.729A1.222 1.222 0 0111.8 6.058zm.865.618a.247.247 0 100 .494.247.247 0 000-.494zM6.519 1.85a1.686 1.686 0 011.187 2.915l-.33.33.26 1.815a1.127 1.127 0 01-1.913.956l-.001-.001-3.498-3.498a1.124 1.124 0 01.954-1.914l1.814.259.33-.329a1.686 1.686 0 011.197-.534zm.453 5.671l-.345-.344a.152.152 0 00.043-.129l-.294-2.057a.488.488 0 01.138-.414l.508-.508a.71.71 0 10-1.005-1.002l-.508.507a.488.488 0 01-.414.138l-2.061-.295a.15.15 0 00-.127.254l.005.005 3.5 3.5.002.002a.15.15 0 00.212 0l.346.343zM9.807 10.507a3.329 3.329 0 00-3.24 2.106.966.966 0 00.92 1.265h4.64a.966.966 0 00.907-1.298 3.328 3.328 0 00-3.227-2.073zm-.025.975a2.353 2.353 0 00-2.28 1.421h4.61a2.354 2.354 0 00-2.28-1.42h-.05z" />
    </g>
  </svg>
)

EssentialsWorkspaceIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsWorkspaceIcon svgRef={ref} {...props} />
))
export default ForwardRef
