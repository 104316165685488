import React from "react"
import PropTypes from "prop-types"

const ActionsDeleteDashIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.335 8.177a.95.95 0 01.951-.951h7.099a.95.95 0 110 1.901H4.286a.95.95 0 01-.95-.95z"
      fill="currentColor"
    />
  </svg>
)

ActionsDeleteDashIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsDeleteDashIcon svgRef={ref} {...props} />
))
export default ForwardRef
