import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import AmenityTypeIcon from "./AmenityTypeIcon"
import ColorMode from "@sonato/core/components/ColorMode"
import { locationShape } from "@sonato/core/shapes"

const AmenityIndicator = ({ type, title, amenities, onClick }) => {
  const colorMode = amenities.has(type) ? ColorMode.medium : ColorMode.light

  return (
    <div className="mt-0.5 mr-3 xs:mt-3 xs:mr-3">
      <AmenityTypeIcon
        onClick={amenities.has(type) ? onClick : undefined}
        size="responsive"
        type={type}
        colorMode={colorMode}
        hasBorder={true}
        title={title}
      />
    </div>
  )
}

AmenityIndicator.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  amenities: PropTypes.instanceOf(Set),
  onClick: PropTypes.func
}

const AmenitiesStrip = ({ location, onIconClick }) => {
  const { t } = useTranslation()
  const amenities = new Set(location.amenities.map(amenity => amenity.type))

  return (
    <div className="flex flex-wrap -mt-3 -ml-3">
      <AmenityIndicator
        onClick={onIconClick}
        type="dining"
        title={t("Restaurant")}
        amenities={amenities}
      />
      <AmenityIndicator onClick={onIconClick} type="bar" title={t("Bar")} amenities={amenities} />
      <AmenityIndicator
        onClick={onIconClick}
        type="gym"
        title={t("Fitness")}
        amenities={amenities}
      />
      <AmenityIndicator
        onClick={onIconClick}
        type="hotel"
        title={t("Hotel")}
        amenities={amenities}
      />
      <AmenityIndicator onClick={onIconClick} type="spa" title={t("Spa")} amenities={amenities} />
      <AmenityIndicator
        onClick={onIconClick}
        type="workspace"
        title={t("Workspace")}
        amenities={amenities}
      />
    </div>
  )
}

AmenitiesStrip.propTypes = {
  location: locationShape.isRequired,
  onIconClick: PropTypes.func
}

export default AmenitiesStrip
