import PropTypes from "prop-types"
import * as React from "react"
import { useFormatters } from "../format"

// September 2, 2020
const AbsoluteDate = ({ date, timezone = null }) => {
  const { longDate, longDateTime } = useFormatters()
  return <span title={longDateTime(date, timezone)}>{longDate(date, timezone)}</span>
}
AbsoluteDate.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  timezone: PropTypes.string
}

// September 2, 2020 at 1:23 PM
const AbsoluteDateTime = ({ date, timezone = null }) => {
  const { longDateTime } = useFormatters()
  return <span title={longDateTime(date, timezone)}>{longDateTime(date, timezone)}</span>
}
AbsoluteDateTime.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  timezone: PropTypes.string
}

// Sep 2, 1:23 PM
const AbsoluteShortDateTime = ({ date, timezone = null }) => {
  const { longDateTime, shortDateTime } = useFormatters()
  return <span title={longDateTime(date, timezone)}>{shortDateTime(date, timezone)}</span>
}
AbsoluteShortDateTime.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  timezone: PropTypes.string
}

// 1:23 PM
const AbsoluteTime = ({ date, timezone = null }) => {
  const { longDateTime, shortTime } = useFormatters()
  return <span title={longDateTime(date, timezone)}>{shortTime(date, timezone)}</span>
}
AbsoluteTime.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  timezone: PropTypes.string
}

export { AbsoluteDate, AbsoluteDateTime, AbsoluteShortDateTime, AbsoluteTime }
