import { useMutation, useQuery } from "react-query"
import { currentLocationSubdomain } from "@sonato/core/urls"
import { useAjax } from "@sonato/core/hooks/ajax"

const queryKeys = {
  currentLocation: () => "currentLocation",
  publicLocation: subdomain => ["publicLocation", subdomain]
}

/**
 * Returns the location whose slug matches current subdomain. Full locations
 * only. Returns null if location does not exist or is a stub.
 */
export function useCurrentLocation(passedOptions = {}) {
  const ajax = useAjax()
  const subdomain = currentLocationSubdomain()
  const options = { ...passedOptions, enabled: !!subdomain }

  const queryKey = queryKeys.currentLocation()
  const queryFn = () => ajax.get("/public/locations/location", { subdomain })
  return useQuery(queryKey, queryFn, options)
}

export function usePublicLocation(subdomain, options = {}) {
  const ajax = useAjax()
  const queryKey = queryKeys.publicLocation(subdomain)
  const queryFn = () => ajax.get("/public/locations/location", { subdomain })
  return useQuery(queryKey, queryFn, options)
}

export const useVerifyEmailMutation = () => {
  const ajax = useAjax()
  return useMutation(token => ajax.post("/public/auth/verifyEmailAddress", { token }))
}
