import React from "react"
import PropTypes from "prop-types"

const EssentialsPeopleMemberIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.528.613a2.076 2.076 0 012.944 0c.361.363.576.846.606 1.356h2.33c.422 0 .827.169 1.126.469.298.3.466.707.466 1.131V14.4c0 .424-.168.831-.466 1.131-.299.3-.704.469-1.126.469H3.592c-.422 0-.827-.169-1.126-.469A1.604 1.604 0 012 14.4V3.57c0-.425.168-.832.466-1.132.299-.3.704-.469 1.126-.469h2.33c.03-.51.245-.993.606-1.356zm5.88 2.587h-2.326v.862c0 .293-.116.575-.323.783a1.1 1.1 0 01-.78.324H7.02a1.1 1.1 0 01-.779-.324 1.11 1.11 0 01-.323-.783V3.2H3.592a.366.366 0 00-.368.37V14.4a.37.37 0 00.368.37h.468a4.158 4.158 0 011.289-2.28 3.948 3.948 0 012.733-1.022 3.958 3.958 0 012.687 1.146 4.165 4.165 0 011.158 2.155h.481a.366.366 0 00.367-.369V3.57a.37.37 0 00-.367-.37zm-5.265.738V2.092a.855.855 0 111.714 0v1.846H7.143zM5.622 8.4c0 .637.247 1.247.686 1.697.439.45 1.034.703 1.655.703.62 0 1.216-.253 1.655-.703.44-.45.686-1.06.686-1.697s-.247-1.247-.686-1.697A2.312 2.312 0 007.963 6c-.62 0-1.216.253-1.655.703A2.431 2.431 0 005.622 8.4z"
      fill="currentColor"
    />
  </svg>
)

EssentialsPeopleMemberIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsPeopleMemberIcon svgRef={ref} {...props} />
))
export default ForwardRef
