import React from "react"
import PropTypes from "prop-types"

const ThirdPartyAppStoreIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.927 0a3.029 3.029 0 01-.755 2.244c-.264.31-.598.56-.977.731-.38.17-.796.257-1.216.253a2.84 2.84 0 01.161-1.154c.133-.37.342-.713.614-1.007A3.42 3.42 0 0110.927 0zm1.532 5.916a3.06 3.06 0 00-.436 1.53c0 .603.188 1.193.54 1.696.351.503.851.896 1.437 1.131a6.907 6.907 0 01-1.028 1.989c-.606.86-1.241 1.7-2.25 1.715-.479.011-.803-.12-1.14-.256-.352-.142-.718-.29-1.292-.29-.608 0-.991.152-1.36.3-.32.127-.629.25-1.064.268-.96.034-1.694-.918-2.322-1.77-1.255-1.74-2.232-4.904-.922-7.057.302-.516.74-.948 1.271-1.256a3.742 3.742 0 011.759-.498c.544-.01 1.067.19 1.525.364.35.133.663.253.919.253.225 0 .529-.115.883-.248.558-.21 1.24-.468 1.937-.399.533.016 1.055.15 1.524.39.47.242.873.583 1.179.998-.482.28-.88.673-1.16 1.14z"
      fill="currentColor"
    />
  </svg>
)

ThirdPartyAppStoreIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ThirdPartyAppStoreIcon svgRef={ref} {...props} />
))
export default ForwardRef
