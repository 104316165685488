import React from "react"
import PropTypes from "prop-types"

const AppsContentIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g>
      <path
        d="M13.444 2.836a.666.666 0 00-.52-.165L4.3 3.667A.667.667 0 013.666 3c0-.208 0-.523.796-.679 1.104-.218 7.226-.988 7.288-.993a.667.667 0 00-.167-1.323c-.25.032-6.23.782-7.379 1.008-1.628.32-1.87 1.382-1.87 1.987v10.333A2.591 2.591 0 005 16c.066 0 8.082-1.005 8.082-1.005a.667.667 0 00.584-.662v-11a.666.666 0 00-.222-.497z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)

AppsContentIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <AppsContentIcon svgRef={ref} {...props} />)
export default ForwardRef
