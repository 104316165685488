import React from "react"
import PropTypes from "prop-types"

const ArrowDownIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M13.756 7.12a.727.727 0 00-1.015.063l-3.79 4.232a.128.128 0 01-.141.034.127.127 0 01-.082-.117V2.706a.7.7 0 00-.21-.5.725.725 0 00-1.017 0 .7.7 0 00-.21.5v8.626a.124.124 0 01-.083.117.13.13 0 01-.14-.034L3.275 7.183a.717.717 0 00-.498-.257.731.731 0 00-.534.175.708.708 0 00-.243.498.695.695 0 00.196.518l4.913 5.482a1.196 1.196 0 00.899.4 1.215 1.215 0 00.9-.4l4.912-5.483a.704.704 0 00.178-.511.696.696 0 00-.243-.486z"
      fill="currentColor"
    />
  </svg>
)

ArrowDownIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ArrowDownIcon svgRef={ref} {...props} />)
export default ForwardRef
