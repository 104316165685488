import { useMutation, useQuery, useQueryClient } from "react-query"
import { useAjax } from "@sonato/core/hooks/ajax"
import { useAuthentication } from "@sonato/core/hooks/authentication"
import { useSubscription } from "@sonato/core/socket"

const queryKeys = {
  partnerLocation: subdomain => ["partnerLocation", subdomain],
  partnerLocations: () => "partnerLocations",
  hotelLocations: () => "hotelLocations",
  suggestedLocations: () => "suggestedLocations",
  favoriteLocationIds: () => "favoriteLocationIds"
}

export function usePartnerLocation(subdomain) {
  const ajax = useAjax()
  const queryKey = queryKeys.partnerLocation(subdomain)
  const queryFn = () => ajax.get("/member/locations/partnerLocation", { subdomain })
  return useQuery(queryKey, queryFn, { suspense: true })
}

export function usePartnerLocations() {
  const ajax = useAjax()
  const queryKey = queryKeys.partnerLocations()
  const { auth } = useAuthentication()
  const queryClient = useQueryClient()

  useSubscription(`location/${auth.location.id}/partnerships`, payload => {
    queryClient.invalidateQueries(queryKey)
  })

  const queryFn = () => ajax.get("/member/locations/partnerLocations")
  return useQuery(queryKey, queryFn, { suspense: true })
}

export function useHotelPartnerLocations() {
  const ajax = useAjax()
  const queryKey = queryKeys.hotelLocations()
  const { auth } = useAuthentication()
  const queryClient = useQueryClient()

  useSubscription(`location/${auth.location.id}/partnerships`, payload => {
    queryClient.invalidateQueries(queryKey)
  })

  const queryFn = () => ajax.get("/member/locations/hotelLocations")
  return useQuery(queryKey, queryFn, { suspense: true })
}

export function useSuggestedLocations() {
  const ajax = useAjax()
  const queryKey = queryKeys.suggestedLocations()
  const queryFn = () => ajax.get("/member/locations/suggestedLocations")
  return useQuery(queryKey, queryFn, { suspense: true })
}

export function useFavoriteLocationIds() {
  const ajax = useAjax()
  const queryKey = queryKeys.favoriteLocationIds()
  const queryFn = () => ajax.get("/member/locations/favoriteLocationIds")
  return useQuery(queryKey, queryFn, { suspense: true })
}

export function useAddFavoriteLocationMutation() {
  const ajax = useAjax()
  const queryClient = useQueryClient()
  const mutateFn = locationId => ajax.post("/member/locations/addFavorite", { locationId })

  const onSuccess = () => {
    queryClient.invalidateQueries(queryKeys.favoriteLocationIds())
  }

  return useMutation(mutateFn, { onSuccess })
}

export function useRemoveFavoriteLocationMutation() {
  const ajax = useAjax()
  const queryClient = useQueryClient()
  const mutateFn = locationId => ajax.post("/member/locations/removeFavorite", { locationId })

  const onSuccess = () => {
    queryClient.invalidateQueries(queryKeys.favoriteLocationIds())
  }

  return useMutation(mutateFn, { onSuccess })
}
