import PropTypes from "prop-types"

/** Sonato.Images.Image.Display */
export const imageShape = PropTypes.shape({
  uri: PropTypes.string.isRequired
})

export const uploadcareImageShape = PropTypes.shape({
  aspect: PropTypes.arrayOf(PropTypes.number),
  fallback: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired
})

export const resizedImageShape = PropTypes.shape({
  "1:1": imageShape.isRequired,
  "2:3": imageShape.isRequired,
  "4:5": imageShape.isRequired,
  "21:8": imageShape.isRequired
})

/** Sonato.Types.Address */
export const addressShape = PropTypes.shape({
  countryCode: PropTypes.string.isRequired,
  locality: PropTypes.string.isRequired,
  postalCode: PropTypes.string,
  postOfficeBox: PropTypes.string,
  region: PropTypes.string,
  streetAddress: PropTypes.string.isRequired
})

/** Sonato.Types.Coordinates  */
export const coordinatesShape = PropTypes.shape({
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired
})

/** Sonato.Types.Currency */
export const currencyShape = PropTypes.shape({
  isoCode: PropTypes.oneOf(["usd", "gbp", "eur", "jpy"]).isRequired,
  points: PropTypes.number.isRequired
})

/** Sonato.Types.TimeRange */
export const timeRangeShape = PropTypes.shape({
  start: PropTypes.string.isRequired,
  stop: PropTypes.string.isRequired
})

/** Sonato.Alliance.Profile */
export const profileShape = PropTypes.shape({
  description: PropTypes.string,
  dressCodePolicy: PropTypes.string,
  amenities: PropTypes.arrayOf(PropTypes.string),
  hotelDiscount: PropTypes.number,
  houseRules: PropTypes.string,
  shortDescription: PropTypes.string,
  visitAdvanceNoticeHours: PropTypes.number.isRequired,
  monday: timeRangeShape,
  tuesday: timeRangeShape,
  wednesday: timeRangeShape,
  thursday: timeRangeShape,
  friday: timeRangeShape,
  saturday: timeRangeShape,
  sunday: timeRangeShape
})

export const amenityShape = PropTypes.shape({
  description: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  hoursMarkdown: PropTypes.string
})

/* Sonato.Alliance.Partnership */
export const partnershipShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  insertedAt: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  leftLocationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rightLocationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
})

/** Sonato.Accounts.Location */
export const locationShape = PropTypes.shape({
  address: addressShape.isRequired,
  amenities: PropTypes.arrayOf(amenityShape),
  businessPhone: PropTypes.string,
  coordinates: coordinatesShape,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  partnership: PropTypes.partnershipShape,
  partnershipStatus: PropTypes.string,
  profile: profileShape,
  images: PropTypes.shape({
    square: imageShape.isRequired,
    portrait: imageShape.isRequired,
    portraitTall: imageShape.isRequired,
    landscape: imageShape.isRequired,
    landscapeWide: imageShape.isRequired,
    logoSquare: imageShape.isRequired,
    logoFullDark: imageShape.isRequired,
    logoFullLight: imageShape.isRequired
  }).isRequired,
  uploadcareImages: PropTypes.shape({
    square: uploadcareImageShape.isRequired,
    portrait: uploadcareImageShape.isRequired,
    portraitTall: uploadcareImageShape.isRequired,
    landscape: uploadcareImageShape.isRequired,
    landscapeWide: uploadcareImageShape.isRequired,
    logoSquare: uploadcareImageShape.isRequired,
    logoFullDark: uploadcareImageShape.isRequired,
    logoFullLight: uploadcareImageShape.isRequired
  }).isRequired,
  subdomain: PropTypes.string.isRequired,
  websiteUrl: PropTypes.string
})

/** Sonato.Accounts.User */
export const userShape = PropTypes.shape({
  cellPhone: PropTypes.string,
  emailAddress: PropTypes.string.isRequired,
  flags: PropTypes.any,
  fullName: PropTypes.string.isRequired,
  homePhone: PropTypes.string,
  homeAddress: addressShape,
  shortName: PropTypes.string.isRequired,
  avatar: resizedImageShape.isRequired
})

/** Sonato.Admin.Staff */
export const staffShape = PropTypes.shape({
  emailAddress: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
})

/** Sonato.Alliance.VisitRequest */
export const visitRequestShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  approvedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  sourceLocation: locationShape,
  destinationLocation: locationShape,
  guestCount: PropTypes.number.isRequired,
  insertedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  message: PropTypes.string,
  scheduledAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  status: PropTypes.oneOf(["pending", "approved", "declined", "canceled"])
})

/** Sonato.Alliance.PartnershipRequest */
export const partnershipRequestShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  sourceLocation: locationShape,
  destinationLocation: locationShape,
  status: PropTypes.oneOf(["pending", "ignored", "accepted"]),
  message: PropTypes.string
})

/** Sonato.Members.Membership **/
export const membershipShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  location: locationShape,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  memberUser: userShape,
  memberRef: PropTypes.string
})

/** Sonato.Accounts.Area */
export const areaShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
})

/** Options passed into a select **/
export const optionShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
})

export const optionsShape = PropTypes.arrayOf(optionShape)

export const organizationShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  contactBusinessPhone: PropTypes.string,
  contactCellPhone: PropTypes.string,
  contactName: PropTypes.string,
  location: PropTypes.arrayOf(locationShape),
  ownerUser: userShape,
  websiteUrl: PropTypes.string
})

export const permissionsShape = PropTypes.shape({
  id: PropTypes.number,
  group: PropTypes.string,
  action: PropTypes.string,
  resource: PropTypes.string
})

export const checkinGuestShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  presentAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
})

/** Sonato.Reception.Checkin */
export const checkinShape = PropTypes.shape({
  employeeUser: userShape,
  guests: PropTypes.arrayOf(checkinGuestShape).isRequired,
  id: PropTypes.string.isRequired,
  localeDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  location: PropTypes.locationShape,
  memberUser: userShape,
  memberPresentAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  updatedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired
})

/** Sonato.Messaging.InboundSms */
export const inboundSmsShape = PropTypes.shape({
  body: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  location: PropTypes.locationShape,
  memberUser: userShape,
  receiver: PropTypes.string,
  sender: PropTypes.string,
  updatedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired
})

/** Sonato.Messaging.InboundSms */
export const outboundSmsShape = PropTypes.shape({
  body: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  employeeUser: userShape,
  location: PropTypes.locationShape,
  memberUser: userShape,
  receiver: PropTypes.string,
  sender: PropTypes.string,
  status: PropTypes.string,
  updatedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired
})

/** Sonato.Messaging.Message */
export const messageShape = PropTypes.shape({
  eventData: PropTypes.shape({
    fromShortName: PropTypes.string.isRequired,
    fromImageUrl: PropTypes.string,
    eventAt: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired
})

/** Sonato.Members.Interaction */
export const interactionShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  related: PropTypes.oneOfType([checkinShape, inboundSmsShape, outboundSmsShape])
})

export const feedShape = PropTypes.shape({
  eventData: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  relatedType: PropTypes.string.isRequired
})

/** Sonato.Workforce.Job **/
export const jobShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  location: locationShape,
  locationId: PropTypes.string.isRequired
})

/** Sonato.Workforce.EmployeeInvite **/
export const employeeInviteShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  job: jobShape,
  organization: organizationShape,
  location: locationShape,
  invitedByUser: userShape,
  emailAddress: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  redeemedAt: PropTypes.string
})

/** Sonato.AccessControl.Role **/
export const roleShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  organization: organizationShape,
  permissions: PropTypes.arrayOf(PropTypes.number).isRequired
})

/** React ref - nicked from https://stackoverflow.com/a/51127130 */
export const refShape = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.any })
])
