import React, { useState } from "react"
import TextInput from "@sonato/core/components/form/TextInput"
import { EssentialsPasswordHideIcon, EssentialsPasswordShowIcon } from "@sonato/core/icons"

function PasswordInput({ value, onChange, ...rest }) {
  const [revealed, setRevealed] = useState(false)
  const name = rest.name || "password"

  const inputType = revealed ? "text" : "password"
  const RevealIcon = revealed ? EssentialsPasswordShowIcon : EssentialsPasswordHideIcon

  return (
    <TextInput
      name={name}
      value={value}
      type={inputType}
      onChange={onChange}
      rightFrame={<RevealIcon onClick={() => setRevealed(!revealed)} />}
      {...rest}
    />
  )
}

export default PasswordInput
