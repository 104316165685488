import React from "react"
import PropTypes from "prop-types"

const ThirdPartyLinkedInIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <g stroke="currentColor">
      <path
        d="M7.322 10.779v-.144l.005-1.453c.002-.696.003-1.43 0-1.916h.485l-.436.654h.684a1.494 1.494 0 00-.154.286 1.51 1.51 0 00-.086.552v2.02h-.498zM9.14 7.344c-.038 0-.075.001-.11.004a.86.86 0 01.027-.02c.128-.089.316-.168.616-.168.382 0 .67.122.866.335.2.216.361.59.361 1.196v2.088h-.5V8.864c0-.349-.06-.72-.263-1.019-.225-.33-.578-.501-.997-.501zM4.9 5.37c0-.09.032-.152.076-.192.044-.042.128-.088.273-.088.145 0 .222.045.262.083.04.039.074.102.078.202a.254.254 0 01-.075.187c-.043.04-.126.088-.274.088h-.01c-.24 0-.33-.134-.33-.28zm.59 1.896v3.513h-.5V7.266h.5z"
        fill="currentColor"
      />
      <path d="M2.074 13.726l-.354.354.354-.354A2.3 2.3 0 011.4 12.1V3.7a2.3 2.3 0 012.3-2.3h8.4a2.3 2.3 0 012.3 2.3v8.4a2.3 2.3 0 01-2.3 2.3H3.7a2.3 2.3 0 01-1.626-.674zM3.7 1.8a1.9 1.9 0 00-1.9 1.9v8.4A1.9 1.9 0 003.7 14h8.4a1.9 1.9 0 001.9-1.9V3.7a1.9 1.9 0 00-1.9-1.9H3.7z" />
    </g>
  </svg>
)

ThirdPartyLinkedInIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ThirdPartyLinkedInIcon svgRef={ref} {...props} />
))
export default ForwardRef
