import React from "react"
import PropTypes from "prop-types"

const ActionsAddObjectIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.871 0A3.871 3.871 0 000 3.871v8.258A3.871 3.871 0 003.871 16h8.258A3.871 3.871 0 0016 12.129V3.871A3.871 3.871 0 0012.129 0H3.871zM1.548 3.871a2.323 2.323 0 012.323-2.323h8.258a2.323 2.323 0 012.323 2.323v8.258a2.323 2.323 0 01-2.323 2.323H3.871a2.323 2.323 0 01-2.323-2.323V3.871zM8 4.336c.428 0 .774.346.774.774v2.116h2.117a.774.774 0 110 1.548H8.773v2.116a.774.774 0 11-1.548 0V8.774H5.11a.774.774 0 110-1.548h2.116V5.11c0-.428.346-.774.774-.774z"
      fill="currentColor"
    />
  </svg>
)

ActionsAddObjectIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsAddObjectIcon svgRef={ref} {...props} />
))
export default ForwardRef
