import React from "react"
import PropTypes from "prop-types"

const EssentialsContactPhoneIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M3.49 0c-.32 0-.638.117-.902.326l-.037.02-.02.019-1.918 1.98.019.018a1.931 1.931 0 00-.519 2.075c.002.003 0 .015 0 .019.52 1.493 1.853 4.371 4.452 6.974 2.61 2.613 5.524 3.892 6.965 4.458h.018a2.206 2.206 0 002.13-.423l.02-.019.019-.02 1.9-1.901c.51-.51.51-1.392 0-1.902l-2.493-2.498-.02-.038c-.51-.51-1.409-.51-1.919 0l-.018.02-1.21 1.21A9.925 9.925 0 017.444 8.59C6.44 7.629 5.925 6.523 5.737 6.09l1.227-1.23c.517-.517.525-1.374-.018-1.882l.018-.02-.057-.057L4.451.365l-.02-.019-.038-.02A1.456 1.456 0 003.49 0zm0 1.23c.046 0 .091.022.136.057l2.456 2.517.038.039.018.018c-.004-.003.037.06-.038.135L4.565 5.533l-.288.27.135.383s.706 1.89 2.188 3.306l.133.115c1.427 1.304 3.109 2.016 3.109 2.016l.384.174.288-.288 1.535-1.537c.105-.105.086-.105.19 0l2.515 2.516c.104.105.104.068 0 .172l-1.88 1.884c-.283.243-.582.292-.94.173-1.393-.547-4.095-1.738-6.505-4.15C3 8.134 1.73 5.374 1.266 4.035c-.094-.25-.027-.62.191-.807l.019-.019.02-.02 1.861-1.902a.218.218 0 01.133-.057z"
      fill="currentColor"
    />
  </svg>
)

EssentialsContactPhoneIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <EssentialsContactPhoneIcon svgRef={ref} {...props} />
))
export default ForwardRef
