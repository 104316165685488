import React, { useContext, useCallback, useState, useEffect } from "react"
import PropTypes from "prop-types"

import { noOp } from "@sonato/core/utils"
import Checkbox from "@sonato/core/components/form/Checkbox"
import CheckedTextInput from "@sonato/core/components/form/CheckedTextInput"
import CountrySelect from "@sonato/core/components/form/CountrySelect"
import ColorInput from "@sonato/core/components/form/ColorInput"
import DateInput from "./components/form/DateInput"
import ErrorHeader from "./components/form/ErrorHeader"
import PasswordInput from "@sonato/core/components/form/PasswordInput"
import PhoneNumberInput from "@sonato/core/components/form/PhoneNumberInput"
import RadioButtonGroup from "@sonato/core/components/form/RadioButtonGroup"
import Select from "@sonato/core/components/form/Select"
import Switch from "@sonato/core/components/form/Switch"
import TextArea from "@sonato/core/components/form/TextArea"
import TextInput from "@sonato/core/components/form/TextInput"
import TextInputTypeahead from "@sonato/core/components/form/TextInputTypeahead"
import TimeInput from "./components/form/TimeInput"
import TimeRange from "@sonato/core/components/form/TimeRange"
import TimeZoneSelect from "@sonato/core/components/form/TimeZoneSelect"
import { Changeset, useServerChangeset } from "./changeset"
import { client } from "@sonato/core/client"
import { useObjectState } from "@sonato/core/hooks"
import { useTranslation } from "react-i18next"
import { useAjax } from "./hooks/ajax"

const FormContext = React.createContext()

const HiddenInput = ({ name, value, onChange }) => {
  useEffect(() => {
    onChange(value)
    // Adding onChange here triggers an infinite loop since it's not stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, value])

  return <input type="hidden" name={name} value={value || ""} />
}

HiddenInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired
}

const findMaxLength = validations => {
  const lengthValidation = validations.find(([t, _]) => t === "length")
  if (lengthValidation) {
    const maxLengthValidation = lengthValidation[1].max
    if (maxLengthValidation) {
      return maxLengthValidation[1]
    }
  }
  return null
}

const wrapInput = component => {
  const BaseComponent = component
  // eslint-disable-next-line
  return ({ name, onChange = noOp, onBlur = noOp, formatter = v => v, ...inputProps }) => {
    const { isLoaded, setValue, hasField, fieldNames, error, value, validations } = useContext(
      FormContext
    )

    if (isLoaded() && !hasField(name, false)) {
      const definedFields = (fieldNames() || []).sort().join(",\n\t")
      throw new Error(
        `Form does not have the field: ${name}. The following fields were defined: \n\t${definedFields}`
      )
    }

    const displayValue = isLoaded() ? formatter(value(name)) : value(name)

    const [hasBlurred, setHasBlurred] = useState(false)
    const [hasChanged, setHasChanged] = useState(false)

    // we show an error only if the user has blurred this field or
    // if the field has an error and hasn't changed, in which case, the error
    // was likely set programmatically.
    const canShowError = hasBlurred || !hasChanged
    const errorValue = canShowError ? error(name) : ""

    const handleChange = newValue => {
      setHasChanged(true)
      setValue(name, newValue)
      onChange(newValue)
    }

    const handleBlur = () => {
      setHasBlurred(true)
      setHasChanged(false)
      onBlur()
    }

    const maxLength = findMaxLength(validations(name))
    if (maxLength && !inputProps["maxLength"]) {
      inputProps["maxLength"] = maxLength
    }

    return (
      <BaseComponent
        key={name}
        name={name}
        value={displayValue}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errorValue}
        {...inputProps}
      />
    )
  }
}

const Form = ({ value, children }) => (
  <FormContext.Provider value={value}>{children}</FormContext.Provider>
)

Form.propTypes = {
  value: PropTypes.object,
  children: PropTypes.node
}

Form.shape = PropTypes.shape({
  clearError: PropTypes.func,
  clearErrors: PropTypes.func,
  error: PropTypes.func,
  fieldNames: PropTypes.func,
  globalError: PropTypes.func,
  hasErrors: PropTypes.func,
  hasField: PropTypes.func,
  isLoaded: PropTypes.func,
  reset: PropTypes.func,
  setError: PropTypes.func,
  setGlobalError: PropTypes.func,
  setValue: PropTypes.func,
  submit: PropTypes.func,
  validations: PropTypes.func,
  value: PropTypes.func,
  values: PropTypes.func
})

const GlobalErrors = ({ titleText }) => {
  const { globalError } = useContext(FormContext)

  const error = globalError()

  if (!error) {
    return null
  }

  return <ErrorHeader titleText={titleText}>{error}</ErrorHeader>
}

Form.Checkbox = wrapInput(Checkbox)
Form.CheckedTextInput = wrapInput(CheckedTextInput)
Form.ColorInput = wrapInput(ColorInput)
Form.CountrySelect = wrapInput(CountrySelect)
Form.DateInput = {
  Inline: wrapInput(DateInput.Inline),
  Dropdown: wrapInput(DateInput.Dropdown)
}
Form.HiddenInput = wrapInput(HiddenInput)
Form.PhoneNumberInput = wrapInput(PhoneNumberInput)
Form.PasswordInput = wrapInput(PasswordInput)
Form.RadioButtonGroup = wrapInput(RadioButtonGroup)
Form.Select = wrapInput(Select)
Form.Switch = wrapInput(Switch)
Form.TextArea = wrapInput(TextArea)
Form.TextInput = wrapInput(TextInput)
Form.TextInputTypeahead = wrapInput(TextInputTypeahead)
Form.TimeInput = wrapInput(TimeInput)
Form.TimeRange = wrapInput(TimeRange)
Form.TimeZoneSelect = wrapInput(TimeZoneSelect)
Form.GlobalErrors = GlobalErrors

const GLOBAL_ERROR_FIELD_NAME = "__global__"

const changesetForm = (changeset, setChangeset) => {
  const fieldNames = () => changeset.fieldNames()
  const hasField = (fieldName, strict = false) => changeset.hasField(fieldName, strict)

  const fieldChanged = fieldName =>
    fieldName in changeset.changes && changeset.changes[fieldName] !== changeset.data[fieldName]

  const resetField = fieldName => changeset.resetField(fieldName)
  const dataValue = fieldName => changeset.getDataValue(fieldName)

  const error = fieldName => changeset.getError(fieldName)
  const globalError = () => changeset.getError(GLOBAL_ERROR_FIELD_NAME)
  const setError = (fieldName, message) =>
    setChangeset(changeset => changeset.setError(fieldName, message))
  const setGlobalError = message => setError(GLOBAL_ERROR_FIELD_NAME, message)
  const clearError = fieldName => setChangeset(changeset => changeset.clearError(fieldName))
  const clearErrors = () => setChangeset(changeset => changeset.clearErrors())
  const hasErrors = () => changeset.hasErrors()

  const validations = fieldName => changeset.getValidations(fieldName)
  const value = fieldName => changeset.getValue(fieldName)
  const values = (...fieldNames) => changeset.getValues(...fieldNames)
  const setValue = (fieldName, value) =>
    setChangeset(changeset => changeset.setValue(fieldName, value))

  const reset = () => setChangeset(changeset => changeset.reset())
  const isLoaded = () => changeset.loaded

  return {
    clearError,
    clearErrors,
    dataValue,
    error,
    fieldChanged,
    fieldNames,
    globalError,
    hasErrors,
    hasField,
    isLoaded,
    reset,
    resetField,
    setError,
    setGlobalError,
    setValue,
    validations,
    value,
    values
  }
}

const useLocalChangesetForm = changesetProp => {
  const [changeset, setChangeset] = useObjectState(changesetProp)
  const form = changesetForm(changeset, setChangeset)
  const submit = (onSuccess = () => {}, onFailure = () => {}, validate = () => true) => {
    if (!changeset.validate() || validate(form)) {
      setChangeset(changeset => {
        changeset.validate()
        return changeset
      })
      onFailure(form)
      return
    }
    onSuccess(form)
  }

  return { ...form, submit }
}

const useServerChangesetForm = (
  path,
  event,
  payload,
  { onFetchFailure = noOp, ajax = false } = {}
) => {
  const ajaxHook = useAjax()
  const { t } = useTranslation()
  const [changeset, setChangeset] = useObjectState(Changeset.empty())
  const [version, setVersion] = useState(0)

  const onFetchSuccess = useCallback(apiChangeset => setChangeset(apiChangeset), [setChangeset])

  const onFetchError = useCallback(
    ({ error: changesetData }) => {
      if (changesetData?.type === "changeset") {
        const changeset = Changeset.fromApi(changesetData, t)
        setChangeset(changeset)
        onFetchFailure(changeset)
      }
    },
    [setChangeset, t, onFetchFailure]
  )

  useServerChangeset(path, event, payload, version, { onFetchSuccess, onFetchError, ajax })

  const form = changesetForm(changeset, setChangeset)
  const refresh = () => setVersion(version + 1)

  const submit = (
    onSuccess = () => {},
    onFailure = () => {},
    validate = () => true,
    onFormInvalid = () => {}
  ) => {
    // we validate both the changeset and form out of the if statement
    // so the if doesn't short circuit and validate one or the other
    const changesetValid = changeset.validate()
    const formValid = validate(form)

    if (!(changesetValid && formValid)) {
      onFormInvalid()
      setChangeset(() => {
        changeset.validate()
        return changeset
      })
      return
    }

    const mergedPayload = Object.assign({}, changeset.applyChanges(), payload)

    form.clearErrors()

    const handleSuccess = response => {
      if (response?.reply?.type === "changeset") {
        setChangeset(() => Changeset.fromApi(response.reply))
      } else if (response.reply instanceof Object) {
        setChangeset(changeset => changeset.setData(response.reply))
      }
      onSuccess(response)
    }

    const handleError = ({ error: apiError }) => {
      let error = apiError
      if (apiError?.type === "changeset") {
        error = Changeset.fromApi(apiError, t)
        setChangeset(error)
      }
      onFailure(error)
    }

    if (ajax) {
      ajaxHook.post(`/${path}/${event}`, mergedPayload).then(
        response => handleSuccess({ reply: response }),
        response => handleError({ error: response })
      )
    } else {
      client.call(path, event, mergedPayload, handleSuccess, handleError)
    }
  }

  return { ...form, refresh, submit }
}

const formShape = PropTypes.shape({
  submit: PropTypes.func,
  refresh: PropTypes.func,
  clearError: PropTypes.func,
  clearErrors: PropTypes.func,
  error: PropTypes.func,
  fieldNames: PropTypes.func,
  globalError: PropTypes.func,
  hasErrors: PropTypes.func,
  hasField: PropTypes.func,
  isLoaded: PropTypes.func,
  reset: PropTypes.func,
  setError: PropTypes.func,
  setGlobalError: PropTypes.func,
  setValue: PropTypes.func,
  validations: PropTypes.func,
  value: PropTypes.func,
  values: PropTypes.func
})

export {
  Form,
  useLocalChangesetForm,
  useServerChangesetForm,
  FormContext,
  wrapInput,
  ErrorHeader,
  formShape
}
