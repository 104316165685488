import React from "react"
import PropTypes from "prop-types"

const ActionsStarIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.456 1.153a1.042 1.042 0 011.47.414l-.51.261.512-.258 1.77 3.505 3.36.333a1.033 1.033 0 01.637 1.764l-2.789 2.764 1.035 3.76a1.038 1.038 0 01-1.47 1.193L8 13.17l-3.466 1.717-.002.001a1.037 1.037 0 01-1.468-1.195l.001-.005 1.033-3.754-2.79-2.764a1.034 1.034 0 01.636-1.764l3.361-.333 1.768-3.506c.087-.17.22-.314.384-.414zm.543 1.124L6.187 5.87a.574.574 0 01-.456.313l-3.445.341 2.858 2.83c.147.146.204.36.149.56l-1.057 3.838 3.509-1.737c.16-.08.348-.08.509 0l3.513 1.74-1.056-3.839a.574.574 0 01.149-.56l2.856-2.83-3.446-.34a.574.574 0 01-.455-.313L7.999 2.277z"
      fill="currentColor"
    />
  </svg>
)

ActionsStarIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => <ActionsStarIcon svgRef={ref} {...props} />)
export default ForwardRef
