import React from "react"
import PropTypes from "prop-types"

const ActionsDeletePersonIcon = ({ svgRef, title, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    className="text-lg"
    ref={svgRef}
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.443 2.217a1.735 1.735 0 100 3.47 1.735 1.735 0 000-3.47zM2.491 3.952a2.952 2.952 0 114.075 2.732c.211.055.42.125.622.212a.609.609 0 01-.478 1.12 3.228 3.228 0 00-4.493 2.967.609.609 0 11-1.217 0 4.445 4.445 0 013.322-4.299 2.953 2.953 0 01-1.83-2.732zm5.692 7.03a2.8 2.8 0 115.6 0 2.8 2.8 0 01-5.6 0zm2.8-4.017a4.017 4.017 0 100 8.035 4.017 4.017 0 000-8.035zm-1.705 3.409a.609.609 0 100 1.217h3.409a.609.609 0 000-1.217H9.278z"
      fill="currentColor"
    />
  </svg>
)

ActionsDeletePersonIcon.propTypes = {
  svgRef: PropTypes.any,
  title: PropTypes.string
}
const ForwardRef = React.forwardRef((props, ref) => (
  <ActionsDeletePersonIcon svgRef={ref} {...props} />
))
export default ForwardRef
